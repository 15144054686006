<template lang="pug">
    #kitOnboarding
        .header-nav
            a(href="/")
                img(:src="wlLogo")
            a.contact-us.waves-effect.waves-light(:href='wlContact') Contact
        .step-cards
            .row.step-card
                .col.s12.l10.offset-l1
                    .row.controlRow
                        .col.s12(v-if="currentStep")
                            .col.s12.contents
                                //- button.backButton(@click="previousStep" v-show="this.currentStep.order > 1")
                                //-     i.material-icons.black-text keyboard_backspace
                                h4#setup-title.salutation(v-if="this.currentStep.order == 0") Trust the Air You Breathe
                                h4#setup-title.salutation(v-if="this.currentStep.displayedStep == 1") Onboard Your Site
                                h4#setup-title.salutation(v-if="this.currentStep.displayedStep == 2") Setup Your Hub
                                h4#setup-title.salutation(v-if="this.currentStep.displayedStep == 3") Setup Your {{this.currentStep.data.deviceType.friendlyModelName}}
                                h4#setup-title.salutation(v-if="this.currentStep.displayedStep == 4") Completing Setup...
                                //- h4.salutation(v-show="this.currentStep.displayedStep == 5") Setup Complete
                            .col.s12(style="margin-top:2vh" v-show="this.currentStep.order > 0 && this.currentStep.displayedStep != 4")
                                .stepIndicator(style="margin-bottom:1vh") Step {{this.currentStep.displayedStep}} of {{this.numSteps}}
                                .progress.undetermined
                                    .determinate(:style="'width:'+String(progress)+'%;'").determined
                            .col.s12
                                Step0(v-if="currentStep.order == 0" v-on:nextStep="nextStep" v-on:previousStep="previousStep" :data="currentStep.data")
                                Step1(v-if="currentStep.order == 1" v-on:nextStep="nextStep" v-on:setSiteName="setSiteName" 
                                    v-on:previousStep="previousStep" :data="currentStep.data" :siteName="siteName" :kit="kit" v-on:setSite="setSite")
                                Step2(v-if="currentStep.order == 2" v-on:nextStep="nextStep" v-on:previousStep="previousStep" :data="currentStep.data")
                                Step3(v-if="currentStep.order == 3" v-on:nextStep="nextStep" v-on:previousStep="previousStep" :data="currentStep.data")
                                Step4(v-if="currentStep.order == 4" v-on:nextStep="nextStep" v-on:previousStep="previousStep" v-on:hubOnboarded="hubOnboarded" :data="currentStep.data")
                                Step5(v-if="currentStep.order == 5" v-on:nextStep="nextStep" :kit="kit" :site="site" v-on:previousStep="previousStep" :data="currentStep.data")
                                Step6(v-if="currentStep.order == 6" v-on:nextStep="nextStep" v-on:previousStep="previousStep" :data="currentStep.data")
                                Step7(v-if="currentStep.order == 7" v-on:nextStep="nextStep" v-on:previousStep="previousStep" :data="currentStep.data")
                                Step8(v-if="currentStep.order == 8" v-on:nextStep="nextStep" v-on:previousStep="previousStep" :data="currentStep.data")
                                //- Step9(v-if="currentStep.order == 8" v-on:nextStep="nextStep" v-on:previousStep="previousStep" :data="currentStep.data")
</template>

<script>

import {wlData} from '../../assets/js/constants';
import {HTTPClient} from '../../assets/js/http'
// eslint-disable-next-line no-unused-vars
let client = new HTTPClient();

import Step0 from './components/Step0';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import Step5 from './components/Step5';
import Step6 from './components/Step6';
import Step7 from './components/Step7';
import Step8 from './components/Step8';

export default {
    name: "KitOnboarding",
    components: {
        Step0,
        Step1,
        Step2,
        Step3,
        Step4,
        Step5,
        Step6,
        Step7,
        Step8 // Import All Components here
    },
    data(){
        return {
            loadedAt: (new Date).valueOf(),
            currentStep: null,
            currentStepIndex: 0,
            steps: [],
            progress: 0,
            wlName: wlData.name ? wlData.name : "HawkenAQ",
            wlLogo: wlData.loginLogo ? wlData.loginLogo : "/images/hawkenAqBlue.png",
            wlContact: wlData.contactUrl||'https://gethawken.com/contact-hawkenaq',
            siteName: null,
            kit: null,
            site: null
        }
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        setSiteName(data){
            // console.log('Main setSiteName', data);
            this.siteName = data;
        },
        setSite(data){
            console.log('main setSite', data);
            this.site = data;
        },
        nextStep(data){
            if(this.currentStepIndex == this.steps.length-1){
                window.M.toast({html: "You have finshed onboarding"});
                this.progress = 100;
                this.currentStep = this.steps[this.steps.length - 1];
            } else {
                this.currentStepIndex++;
                this.currentStep = this.steps[this.currentStepIndex];
                this.progress = Math.round(((this.currentStep.displayedStep) / (this.numSteps)) * 100);
            }
            // do something with data
        },
        previousStep(){
            this.currentStepIndex--;
            this.currentStep = this.steps[this.currentStepIndex];
            this.progress = Math.round(((this.currentStep.displayedStep) / (this.numSteps)) * 100);
        },
        hubOnboarded(){
            // the hub onboarding is complete. look for devices and load them into step 5.
            console.log('hubOnboarded', this.kit);
            this.nextStep();
        },
        async loadSteps(){
            try{
                await this.loadKit();
                this.numSteps = 3;
                this.steps = [{order: 0, data: null, displayedStep: 0}, {order: 1, data: null, displayedStep: 1}, 
                    {order: 2, data: null, displayedStep: 2}, {order: 3, data: null, displayedStep: 2}, {order: 4, data: null, displayedStep: 2}];
                // for each unonboarded device, push one
                console.log('devices', this.devices);
                for(let device of this.devices){
                    if(device.deviceType.type=='hub') continue;
                    if(device.onboarded) continue;
                    // console.log('device', device);
                    let images = {
                        actImage: '/images/kitSetup-lht65Step1.png'
                    };
                    if(device.deviceType.actImage)
                        images.actImage = device.deviceType.actImage.url;
                    images.actImage = images.actImage.replace(/http:/g, 'https:');
                    device.images = images;
                    this.steps.push(
                        {order: 5, data: device, displayedStep: 3},{order: 6, data: device, displayedStep: 3}, {order: 7, data: device, displayedStep: 3});
                }
                this.steps.push({order: 8, data: null, displayedStep: 4});
                // console.log('steps', this.steps);
                // an api results for example
                let currentStep = {order: 0, data: null}; // Some api response
                this.currentStep = currentStep
                this.progress = 0;
            }catch(e){
                console.error(e);
            }
        },
        async loadKit(){
            try{
                if(window.Kit&&window.Devices)
                    this.kit = window.Kit;
                else{
                    // fetch kit from api
                    let result = await client.robustQuery('/api/kits/'+this.$route.params.id);
                    if(result.results){
                        let kit = result.results;
                        result = await client.robustQuery('/api/kits/'+this.$route.params.id+'/devices');
                        console.log('result', result);
                        if(result.results){
                            this.devices = result.results;
                            this.kit = kit;
                        }
                    }

                }
            }catch(e){
                console.error(e);
            }
        }
    },
    async mounted(){
        this.loadSteps();
        setTimeout(() => {window.M.AutoInit()}, 100);
        try {
            let sites = await this.$store.dispatch('getSites');
            // console.log('sites', sites);
            // console.log('this.store', this.$store);
        } catch (error) {
            console.error(error);
            window.M.toast({html: '<p> Cannot Load Sites </p>'});
        }
    }
}
</script>

<style lang="sass">
    #kitOnboarding
        background: #fff url("../../../public/images/bg.png") top right no-repeat
        background-size: cover
        min-height: 100vh
        font-size: 18px

        .stepImage
            background: #fff
            width: 120%
            margin-left: -10%
            height: 285px
            background-size: contain
            background-repeat: no-repeat
            background-position: 50%

    .header-nav
        background: #fff
        box-shadow: 0px 100px 80px rgba(0, 91, 154, 0.12), 0px 41.7776px 33.4221px rgba(0, 91, 154, 0.0862625), 0px 22.3363px 17.869px rgba(0, 91, 154, 0.0715329), 0px 12.5216px 10.0172px rgba(0, 91, 154, 0.06), 0px 6.6501px 5.32008px rgba(0, 91, 154, 0.0484671), 0px 2.76726px 2.21381px rgba(0, 91, 154, 0.0337375)
        display: flex
        align-items: center
        justify-content: space-between
        height: 80px
        padding: 18px 36px
        
        @media (max-width: 600px)
            padding: 18px 15px

        img
            max-height: 44px
        a.contact-us
            border: 1px solid #005B9A
            border-radius: 12px
            color: #005B9A
            font: normal 400 16px/24px Roboto
            letter-spacing: 0.18px
            height: 40px
            line-height: 40px
            text-align: center
            width: 140px
            @media (max-width: 600px)
                font-size: 14px
                height: 36px
                line-height: 36px
                width: 90px

    .step-cards
        display: flex
        align-items: center
        min-height: calc(100vh - 80px)
        
        @media (max-width: 600px)
            align-items: flex-start

        .step-card
            background: #fff
            border-radius: 20px
            box-shadow: 0px 100px 80px rgba(0, 91, 154, 0.12), 0px 41.7776px 33.4221px rgba(0, 91, 154, 0.0862625), 0px 22.3363px 17.869px rgba(0, 91, 154, 0.0715329), 0px 12.5216px 10.0172px rgba(0, 91, 154, 0.06), 0px 6.6501px 5.32008px rgba(0, 91, 154, 0.0484671), 0px 2.76726px 2.21381px rgba(0, 91, 154, 0.0337375)
            // color: #005B9A
            color: black
            margin: 16px auto
            // max-height: 980px
            max-width: 800px
            padding: 40px
            width: 100%

            @media (max-width: 600px)
                // background: rgba(255,255,255,.8)
                margin: 16px 0px
                padding: 20px 20px
                max-width: 548px

            h4
                // color: #4385c8
                color: #0098ff

            .content
                margin: 0 auto
                max-width: 360px
                width: 100%
                text-align: center

                .title
                    font: normal 400 32px/46px Roboto
                    margin-bottom: 32px
                    @media (max-width: 600px)
                        font-size: 24px
                .input-group
                    text-align: left
                    label
                        font: normal 400 14px/20px Roboto
                        color: #005B9A
                    input
                        border: 1px solid #005B9A
                        box-sizing: border-box
                        border-radius: 12px
                        color: #005B9A
                        font: normal 400 16px/24px Roboto
                        margin: 8px 0 24px
                        padding: 8px 20px
                    ::placeholder
                        color: #005B9A
                        opacity: 0.4

    #kitOnboarding
        // width: 100vw
        // height: 100vh
        // background-color: white
        // background-image: url(/images/bg.png)
        // background-repeat: no-repeat
        // background-size: cover
        // background-position-y: 45vh
        // color: #3456F7
        .hawkenLogo
            padding: 15px
            img
                position: absolute
        .controlRow
            // margin-top: 5vh
            .contents
                display: flex
                width: 100%
                justify-content: flex-start
                align-items: center
                .backButton
                    height: 58px
                    width: 58px
                    background-color: Transparent
                    background-repeat: no-repeat
                    border: 1px solid white
                    cursor: pointer
                    overflow: hidden
                    outline: none
                    border-radius: 29px
                    margin: 0px
                    color: #95a5a6
            .salutation
                margin-left: auto
                margin-right: auto
                text-align: center
            .undetermined
                background-color: #a5b5b6
            .determined
                background-color: #0098ff

</style>