<template lang='pug'>
    #modal-airnow.haq-modal.modal
        .modal-content
            .modal-header
                h2 EPA AirNow AQI
                i.material-icons.modal-close close
            .modal-body
                iframe(
                    title="EPA AirNow AQI" 
                    height="230" 
                    width="230" 
                    src="https://widget.airnow.gov/aq-dial-widget/?city=Chicago&state=IL&country=USA&transparent=true" 
                    style="border: none; border-radius: 25px;"
                )
                br
                p
                    //- | The AQI is an index for reporting daily air quality. It tells you how clean or polluted your air is, and what associated health effects might be a concern for you. The AQI focuses on health effects you may experience within a few hours or days after breathing polluted air. EPA calculates the AQI for five major air pollutants regulated by the Clean Air Act: ground-level ozone, particle pollution (also known as particulate matter), carbon monoxide, sulfur dioxide, and nitrogen dioxide. For each of these pollutants, EPA has established national air quality standards to protect public health. Ground-level ozone and airborne particles are the two pollutants that pose the greatest threat to human health in this country.
                    | For more information or forecasts, 
                    a(href="https://www.airnow.gov/?city=Chicago&state=IL&country=USA", target="_blank") click here
                    | .
</template>
<script>
export default {
    name: 'AirNowModal',
};
</script>
<style lang="sass" scoped>
#modal-airnow
    .modal-header
        display: flex
        justify-content: space-between
        .modal-close
            font-size: 38px
    .modal-body
        display: flex
        align-items: center
        flex-direction: column
</style>
