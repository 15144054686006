<template lang="pug">
    #sitePage
        Navbar(v-if="hasAccess & !loadingProjects" tab="monitor" :siteId="siteId" :zoneId="zoneId" :site="site" :notifications="notifications" :scoreHelp="scoreHelp")
        SiteAlert(v-on:closeSiteAlert="closeSiteAlert" 
                    :id=0 
                    :trigger="newSensor" 
                    alertType="onboarding" 
                    name="calibration banner" 
                    message="Thank you for setting up your new devices. It is now connected, and you will see live data soon.")
        main(v-if="ready")
            v-btn(elevation="2" fab bottom right color="var(--primary-alert)" fixed @click="$store.commit('toggleEditMode')" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'superAdmin'")
                v-icon(v-show="!$store.state.editMode") mdi-pencil
                v-icon(v-show="$store.state.editMode") mdi-close
            v-snackbar(v-model="snackbar" top right) {{snackMsg}}
                template(v-slot:action="{ attrs }")
                    v-icon.close-icon(text @click="snackbar=false") mdi-close				
            v-container(v-if="site && hasAccess")
                v-row.align-center.my-1
                        v-col(:cols="12" :md="6" :sm="12").align-center.d-flex
                            .breadcrumbs
                                router-link(to="/") Sites
                                v-icon mdi-chevron-right
                                span(v-for="(b,index) in breadcrumbs" :key="index")
                                    span(v-if="b.zoneId") {{b.name}}
                                    router-link(v-else :to="{name: 'Site'}") {{b.name}}
                                    v-icon(v-show="index!== breadcrumbs.length - 1") mdi-chevron-right
                        v-col(:cols="12" :md="6" :sm="12").d-flex.justify-end.align-center
                            v-switch(v-model="floorPlanView" v-if="floorPlanViewAvailable" style="max-height:33px").ma-0
                                template(v-slot:label)
                                    span(style="color:var(--app-primary-txt)") Floorplan View
                            .date-range(v-show="timePeriod != 'custom'" style="margin-left:63px") {{ dateRange }}
                v-row.site-header.no-flex
                    v-col.m12.l6.site-info
                        .site-image(:style='"background-image:url(" + (site.logo ? site.logo.url.replace("http", "https") : "") + ")"')
                        .site-progress(v-if="pageTitle.score && site.aqEnabled == true")
                            TooltipsSite(v-if="tooltipCount === 1 && site.aqEnabled == true" :id=0 name="site progress" position="bottom" arrowAlignment="left" message="Click this radial to see how the score is calculated." @next="advanceOnboardingTooltip")
                            a.modal-trigger(v-if="pageTitle.score" href="#modal-score-breakdown" style="color:var(--app-tertiary-txt)")
                                DonutIndicator(:key="pageTitle.id+'-pt-donut'" :id="pageTitle.id +'-scoreOA'" :cssClass="'main-donut'" :value="Math.round(pageTitle.score)" :size="60" v-if="site.aqEnabled !== false")
                            //- DonutIndicator(v-else :key="pageTitle.id+'-pt-donut'" :id="pageTitle.id +'-scoreOA'" :cssClass="'main-donut'" :value="Math.round(pageTitle.score)" :size="60")
                        .site-title(style="display:flex;flex-direction:column;")
                            router-link(:to="{name: 'Site', params: {siteId: pageTitle.id}}").siteLink {{pageTitle.title}}
                            div(v-if="projectId&&projectId=='pwNeghwfDaNcSlOApelIYmA6'")
                                a.modal-trigger(href="#modal-airnow" style="color:var(--app-tertiary-txt)")
                                    span EPA AirNow AQI
                    v-col.m12.l6
                        .site-filter
                            .site-filter-ddl
                                label(for='scope') Zone
                                .vuetify(data-app)
                                    v-select.haq-text-field(v-model="scope" :items="scopeZones" item-text="name" item-value="objectId" @change="loadZone" hide-details
                                        :menu-props="{ bottom: true, offsetY: true, closeOnClick: true, closeOnContentClick: true, }")
                            .site-filter-ddl
                                label(for='timePeriod') Time Period
                                .vuetify(data-app)
                                    v-select.haq-text-field(v-model="timePeriod" :items="timePeriods" item-text="name" item-value="value" hide-details disabled=(viewOnly?'true':null)
                                        :menu-props="{ bottom: true, offsetY: true, closeOnClick: true, closeOnContentClick: true, }")
                        .time-period(v-show="timePeriod === 'custom'")
                            .tp-input
                                label(for='dpStart') Starting Date
                                input.datepicker.start-date(ref='dpStart',name='dpStart',autocomplete='off',@change='setCustomTimePeriod')
                            .tp-input
                                label(for='dpEnd') Ending Date
                                input.datepicker.end-date(ref='dpEnd',name='dpEnd',autocomplete='off',@change='setCustomTimePeriod')
                TooltipsSite(v-if="tooltipCount === 0 && !zoneId" :id=1 name="zone button" position="top" arrowAlignment="center" message="Click this card to navigate into a zone." @next="advanceOnboardingTooltip")
                //- No cards?
                div(v-show="!floorPlanView")
                    AqCard(:title="measurementsAF.name||'Air Freshness'", type="scoreAF", :score="measurementsAF.curScore", :measurements="measurementsAF.childMeasurements", v-if="measurementsAF&&measurementsAF.childMeasurements" :timePeriod="timePeriod" :tpCustomStart="tpCustomStart" :tpCustomEnd="tpCustomEnd" v-on:aqCardZoneChange="aqCardZoneChange" :zoneTracker="zoneTracker" :measurementId="measurementsAF.uuid"
                        :measurementObjectId="measurementsAF.objectId")
                    AqCard(:title="measurementsAQ.name||'Air Quality'", type="scoreAF", :score="measurementsAQ.curScore", :measurements="measurementsAQ.childMeasurements", v-if="measurementsAQ&&measurementsAQ.childMeasurements" :timePeriod="timePeriod" :tpCustomStart="tpCustomStart" :tpCustomEnd="tpCustomEnd" v-on:aqCardZoneChange="aqCardZoneChange" :zoneTracker="zoneTracker"
                        :measurementId="measurementsAQ.uuid" :measurementObjectId="measurementsAQ.objectId")
                    AqCard(:title="measurementsOW.name||'Environment Comfort'", type="scoreOW", :score="measurementsOW.curScore", :measurements="measurementsOW.childMeasurements", v-if="measurementsOW&&measurementsOW.childMeasurements" :timePeriod="timePeriod" :tpCustomStart="tpCustomStart" :tpCustomEnd="tpCustomEnd" v-on:aqCardZoneChange="aqCardZoneChange" :zoneTracker="zoneTracker" :measurementId="measurementsOW.uuid"
                        :measurementObjectId="measurementsOW.objectId")
                    AqCard(:title="measurementsEQ.name||'Equipment Overview'", type="scoreEQ", :score="measurementsEQ.curScore", :measurements="measurementsEQ.childMeasurements", v-if="measurementsEQ&&measurementsEQ.childMeasurements" :timePeriod="timePeriod" :tpCustomStart="tpCustomStart" :tpCustomEnd="tpCustomEnd" v-on:aqCardZoneChange="aqCardZoneChange" :zoneTracker="zoneTracker" :isEquipment="true"
                        :measurementObjectId="measurementsEQ.objectId" v-on:updateMeasurementText="measurementsEQ.name = $event")
                div(v-if="floorPlanViewAvailable" v-show="floorPlanView")
                    FloorPlanView
            v-container(v-else-if="!site && hasAccess")
                v-row
                    v-col
                        .empty-card There are no measurements for this site.
            v-container(v-else-if="!hasAccess")
                v-row
                    v-col
                        .empty-card Sorry, you do not have access to that project. Please contact your site administrator to request access.
        footer
            .container
                v-row
                    v-col.s12
                        span.powered(v-if="whitelabel") {{whitelabel}}
        ScoreBreakdownModal(:overallCurScore="pageTitle.score",:measurements="helpMeasurements" :key="helpMeasurements.length+1" v-if="pageTitle.score")
        AirNowModal(v-if="true")
</template>
<script>
import Navbar from '@/components/Navbar.vue';
import SiteAlert from '@/components/SiteAlert.vue';
import DonutIndicator from '@/components/DonutIndicator.vue';
import AqCard from '@/components/AqCard.vue';
import moment from 'moment';
import {HTTPClient} from '../assets/js/http';
import ScoreBreakdownModal from '@/components/help/scoreBreakdown/ScoreBreakdownModal';
import AirNowModal from '@/components/AirNowModal';
import TooltipsSite from '@/components/NewUserOnboarding/TooltipsSite.vue'
import {wlData} from '../assets/js/constants';
let client = new HTTPClient();
import {useGetSite} from '../assets/js/hawken-query'
import FloorPlanView from './FloorPlan.vue'

export default {
    name: 'Site',
    components: {
		Navbar,
		SiteAlert,
		DonutIndicator,
		AqCard,
		ScoreBreakdownModal,
        AirNowModal,
		TooltipsSite,
		FloorPlanView
    },
    data(){
        return {
			ready: false,
			projectId: this.$route.params.projectId,
			siteId: this.$route.params.siteId ? this.$route.params.siteId : this.$route.query.siteId,
			zoneId: this.$route.params.zoneId,
			hasAccess: true,
			loadingProjects: false,			
			site: null,
			zone: null,
			siteLogo: '',
			zones: null,
			measurementsAF: null,
			measurementsAQ: null,
			measurementsOW: null,
			measurementsEQ: null,
			scope: this.$route.params.zoneId ? this.$route.params.zoneId : 'overview',
			scopeZones: null,
            floorPlanViewAvailable: false,
            floorPlanView: false,
			timePeriod: 'week',
			timePeriods: [
				{'name':'Today','value':'day'},
				{'name':'This Week','value':'week'},
				{'name':'This Month','value':'month'},
				{'name':'Custom','value':'custom'}
			],
			tpCustomStart: moment().startOf('week').valueOf(),
			tpCustomEnd: moment().valueOf(),
			breadcrumbs: [{
				name: "Home",
				zoneId: null,
				siteId: this.$route.params.siteId,
			}],
			pageTitle: {
				id: "",
				score: 0,
				title: "",
			},
			helpMeasurements: [],
			whitelabel: this.$store.state.project?.whitelabel?wlData.poweredByFooter:'',
			zoneTracker: {},
			tooltipCount: null,
			showSiteAlert: false,
			newSensor: false,
			notifications: {
				alerts: [],
				cssClass: null,				
			},
			scoreHelp: {
				overallCurScore: null,
				measurements: null,
				key: null,
			},
			activeDialog: null,
			snackbar: false,
			snackMsg: '',
		};
	},
	methods: {
        closeModal() {
			this.activeDialog = null;
		},
		startOnboardingTooltips(){
			let user = this.$store.state.user;
			let count = user.onboardingComplete?.tooltips ? Object.keys(user.onboardingComplete?.tooltips).length : 0;
			this.tooltipCount = count;
		},
		advanceOnboardingTooltip(){
			this.tooltipCount++;
		},
		openSiteAlert(measurements){
			let user = this.$store.state.user;
			let thirtyMin = moment().utc().subtract(30, 'minutes');
			let twoHours =  moment().utc().subtract(1, 'hours');
			let alertClosed = user.onboardingComplete?.alerts ? user.onboardingComplete.alerts[0].complete : 0;
			let recentlyClosed = (moment(alertClosed).utc()).isSameOrAfter(twoHours);
			let recentlyCreated = false;
			
			measurements.forEach(m => {
				if(m.type?.includes('score') && m.childMeasurements) {
					m.childMeasurements.forEach(cm => {
						let createdAt = (moment(cm.createdAt).utc());
						if (moment(createdAt).isSameOrAfter(thirtyMin))
							recentlyCreated = true;
					})
				}
				else {
					let createdAt = (moment(m.createdAt).utc());
					if (moment(createdAt).isSameOrAfter(thirtyMin))
						recentlyCreated = true;
				}
			});
			
			if(recentlyCreated && !recentlyClosed)
				this.newSensor = true;
			else
				this.newSensor = false;
		},
		closeSiteAlert(alertType){
			if(alertType == "onboarding")
				this.newSensor = false;
		},
		getAlertCssClass(){
			let criticalCount = 0;
			let warningCount = 0;
			this.notifications.alerts.forEach(a => {
				if (a.alertConfig.alertType === 'critical')
					criticalCount++;
				else if(a.alertConfig.alertType === 'warning')
					warningCount++;
			})
			if (criticalCount)
				this.notifications.cssClass = 'msmt-alarm msmt-critical';
			else if(warningCount)
				this.notifications.cssClass = 'msmt-alarm msmt-warning';
		},
		loadZone(zoneId) {
			if(zoneId === "overview") {
				this.zoneId = null;
				let siteId = this.siteId;
				this.$router.push({name: 'Site', params: {siteId}});
			}
			else {
				this.zoneId = zoneId;
				this.$router.push({name: 'Zone', params: {zoneId}});
			}
		},
		aqCardZoneChange(zoneId){
			console.log("trying to change zones...")
			let zone = this.zones.find((el) => el.objectId == zoneId);
			if(!zone){
				zone = this.zones.find((el) => el.source && el.source.objectId == zoneId);
				if(!zone){
					throw `No zone found ${zoneId}`
				} else {
					console.log('Found zone ' + zone.name)
				}
			}
			this.zoneId = zone.objectId;
			this.scope = zone.objectId;
			this.$router.push({name: 'Zone', params: {zoneId}});
		},
		setCustomTimePeriod() {
			console.log("trying to set custom range")

			let startVal = this.$refs.dpStart.value;
			let endVal = this.$refs.dpEnd.value
			
			if(!startVal || !endVal)
				return;

			console.log("both dates exist", startVal, endVal)

			let startISO = Date.parse(startVal);
			let endISO = Date.parse(endVal);
			let start = moment(startISO);
			let end = moment(endISO);

			if(start > end) {
				this.snackMsg = 'Starting date greater than the ending date';
				this.snackbar = true;
			}
			else if((end.diff(start,'day')) > 60) {
				this.snackMsg = 'Time period exceeds 60 days';
				this.snackbar = true;
			}
			else {
				this.tpCustomStart = startISO;
				this.tpCustomEnd = endISO;
				this.resetMeasurements();
				this.loadSite();
			}
        },
		setSensorCounts(zones,measurements){
            if(this.site.aqEnabled == false){
                measurements.forEach(m => {
                    m.sensorCount = m.childMeasurements?.length;
                })
                return measurements
            }
			zones.forEach(z => {
				if(z.name != "Overview") {
					measurements.forEach(cm => {
						if(z.id == cm.zone?.id) {
							let count = cm.childMeasurements?.length;
							let equipmentCount = z.scoreEQ?.childMeasurements?.length;
							if(equipmentCount)
								cm.sensorCount = count + equipmentCount;
							else
								cm.sensorCount = count;
						}
					})
				}
			})
			return measurements;
		},
		setMeasurements(zones,measurements){
			measurements.forEach(m => {
				if(m.type == 'scoreAF') {
					if(m.childMeasurements)
						this.sortMeasurements(m.childMeasurements);
					
					if(zones && m.childMeasurements)
						m.childMeasurements = this.setSensorCounts(zones, m.childMeasurements)

					this.measurementsAF = m;
					this.helpMeasurements.push(m);
				}
				if(m.type == 'scoreAQ') {
					if(m.childMeasurements)
						this.sortMeasurements(m.childMeasurements);

					if(zones && m.childMeasurements)
						m.childMeasurements = this.setSensorCounts(zones, m.childMeasurements)

					this.measurementsAQ = m;
					this.helpMeasurements.push(m);
				}
				if(m.type == 'scoreOW') {
					if(m.childMeasurements)
						this.sortMeasurements(m.childMeasurements);
					
					if(zones && m.childMeasurements)
						m.childMeasurements = this.setSensorCounts(zones, m.childMeasurements)

					this.measurementsOW = m;
					this.helpMeasurements.push(m);
				}
				if(m.type == 'scoreEQ') {
					if(m.childMeasurements)
						this.sortMeasurements(m.childMeasurements);

                    if(zones && m.childMeasurements)
						m.childMeasurements = this.setSensorCounts(zones, m.childMeasurements)
                        
					this.measurementsEQ = m;
					this.helpMeasurements.push(m);
				}
			});
		},
		sortAndSetChildMeasurements(childMeasurements, measurements){
			let cmf = measurements.filter((el) => childMeasurements.find((el2) => el2.objectId == el.objectId))
            //- console.log("cmf", cmf)
			cmf.forEach((el) => {
				if(el.childPoints?.length == 1){
					if(el.childPoints[0].functions?.length >= 1){
						el.writable = {
							endpoint: `/api/points/${el.childPoints[0].uuid}/runFunction`,
							function:  el.childPoints[0].functions
						}
					}
				}
			})
			return cmf.sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'}))
		},
		sortMeasurements(measurements){
			measurements.sort(function(a,b){ 
				return a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})
			})
		},
		resetMeasurements() {
			this.zoneId = this.$route.params.zoneId;
			this.measurementsAF = null;
			this.measurementsAQ = null;
			this.measurementsOW = null;
			this.measurementsEQ = null;
			this.helpMeasurements = [];
		},
		/**
		 * @description Recursive Zone Creator
		 * @param {Array} array,
		 * @param {String} zoneId
		 */
		createBreadcrumbs(array, zoneId) {
            let zone = this.zones.find((el) => el.objectId == zoneId || el.source?.objectId);
			array.unshift({
				name: zone.name,
				zoneId,
				siteId: this.siteId
			})
			if(zone.parent){
				return this.createBreadcrumbs(array, zone.parent.objectId)
			} else {
				return array;
			}
		},
		setBreadCrumbsAndTitle() {
			if(this.zoneId){
				let zoneCrumbs = this.createBreadcrumbs([], this.zoneId)
				zoneCrumbs.unshift({
					name: this.site.name,
					zoneId: null,
					siteId: this.$route.params.siteId,
				})
				this.breadcrumbs = zoneCrumbs;

				let zone = this.zones.find((el) => el.objectId == this.zoneId || el.source?.objectId == this.zoneId);
				this.pageTitle = {
					id: zone.objectId,
					score: zone.scoreOA?.curScore,
					title: zone.name,
				}
			} else {
				this.breadcrumbs = [{
					name: this.site.name,
					zoneId: null,
					siteId: this.siteId
				}]
				this.pageTitle = {
					id: this.siteId,
					score: this.site.scoreOA?.curScore,
					title: this.site.name,
				}
			}
		},
		async fetchAlerts(){
			try {
				let results = await client.robustQuery(`/api/sites/${this.siteId}/alerts?isActive=true`);
				if(results.alerts.length > 0) {
					results.alerts.sort((a, b) => (a.alertConfig.alertType > b.alertConfig.alertType) ? 1 : -1);
					this.notifications.alerts = results.alerts;
					this.getAlertCssClass();
				}
			} catch (error) {
				console.error(error);
			}
		},
		async loadSite() {
			try {
				let userAccess = false;
				if (this.$store.state.projects.length <= 0) {
					this.loadingProjects = true;
				} else {
					this.loadingProjects = false;
				}
				for (let p of this.$store.state.projects) {
					if (p.objectId == this.projectId) {
						userAccess = true;
						break;
					}
				}
				if (!userAccess) {
					this.hasAccess = false;
					return;
				} else {
					this.hasAccess = true;
				}
				if(this.zoneId){
					this.scope = this.zoneId;
				} else {
					this.scope = "overview";
				}
				let zoneRes = await client.robustQuery('/api/zones', {siteId: this.siteId, withMeasurements: true});
				let measurementsRes = await client.robustQuery('/api/measurements', {siteId: this.siteId, zoneId: this.zoneId});
                this.site = await useGetSite(this.siteId)
                this.ready = true;
				this.$store.commit("setSite", this.site);
				this.$store.commit('setProject', this.site.project)
				this.zones = zoneRes.results;
				this.scopeZones = zoneRes.results;
				this.scopeZones.unshift({'name':'Overview','objectId':'overview'});
                 const { results: floors } = await client.robustQuery(`/api/sites/${this.siteId}/floors`);
                this.floors = floors;
                if(this.floors.length > 0){
                    this.floorPlanViewAvailable = true;
                }
                //- console.log('measurementRes', measurementsRes);

				zoneRes.results.forEach(el => {
					this.zoneTracker[el.objectId] = el.name;
					if(el.source)
						this.zoneTracker[el.source.objectId] = el.name;
				})

				this.setBreadCrumbsAndTitle();
				this.openSiteAlert(measurementsRes.results);
				this.setMeasurements(zoneRes.results,measurementsRes.results);
				this.fetchAlerts();
				this.startOnboardingTooltips();

				this.scoreHelp = {
					overallCurScore: this.site.scoreOA?.curScore,
					measurements: this.helpMeasurements,
					key: this.helpMeasurements.length,
				}

				this.$nextTick(() => {
					let sn = document.querySelectorAll('.sidenav');
					window.M.Sidenav.init(sn);

					let sl = document.querySelectorAll('select');
					window.M.FormSelect.init(sl);

					let dp = document.querySelectorAll('.datepicker');
					let dpOptions = {
						minDate: new Date(this.site.firstDataDate || this.site.createdAt),
						maxDate: new Date(),
						autoClose: true,
						format: 'mmm d, yyyy',
					}
					window.M.Datepicker.init(dp, dpOptions);

					let dd = document.querySelectorAll('.dropdown-trigger');
					let dropOptions = { coverTrigger: false }
					window.M.Dropdown.init(dd,dropOptions);

					let mod = document.querySelectorAll('.modal');
                    window.M.Modal.init(mod, {dismissible: false});
					
				})
			} catch (error) {
				console.error(error);
				window.M.toast({html: 'Cannot Load Site'});
			}
		}
	},
	computed: {
		dateRange(){
			if(this.timePeriod && this.timePeriod!== "custom")
				return `${moment().startOf(this.timePeriod).format('MMM D')} - ${moment().format('MMM D, YYYY')}`
			else
				return ''
		}
	},
	mounted() {
		this.loadSite();
	},
	watch: {
		// eslint-disable-next-line no-unused-vars
		$route(to, from){
			this.resetMeasurements();
			this.loadSite();
		},
		'$store.state.projects': {
			handler() {
				this.resetMeasurements();
				this.loadSite();
			}
		}
	}
};
</script>
<style lang="sass" scoped>
	.no-flex
		display: block
	.v-app
		background: transparent
		.v-application--wrap
			display: block
	.modal-trigger
		&:hover
			.main-donut
				background: rgba(255, 255, 255, 0.1)
				-webkit-box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.2)
				box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.2)
				border-radius: 50%
	.siteLink
		color: var(--app-primary-txt)
		font-size: 28px
		line-height: inherit
		&:hover
			text-decoration: underline
	.date-range
		color: var(--app-tertiary-txt)
		text-align: right
		opacity: 1
</style>