<template lang="pug">
    #step8
        //- p.blue-text Please wait while we complete setup...
        //- .preloader-wrapper.big.active
        //-     .spinner-layer.spinner-blue
        //-         .circle-clipper.left
        //-             .circle
        //-         .gap-patch
        //-             .circle
        //-         .circle-clipper.right
        //-             .circle
        //-     .spinner-layer.spinner-red
        //-         .circle-clipper.left
        //-             .circle
        //-         .gap-patch
        //-             .circle
        //-         .circle-clipper.right
        //-             .circle
        //-     .spinner-layer.spinner-yellow
        //-         .circle-clipper.left
        //-             .circle
        //-         .gap-patch
        //-             .circle
        //-         .circle-clipper.right
        //-             .circle
        #donecheck.videoWrapper(style="max-height:290px;height:380px;width:100%;overflow:hidden;position:relative;top:-26px;opacity:0;transition:opacity 300ms;").right
            video#donecheckvideo(src="/images/checkmark-animation.mp4" muted playsinline style="height: 620px;position: relative;top: -160px;")
        #bluespinner.stepImage(style="background-image: url(/images/bluespinner.gif); margin-bottom:20px; margin-top:30px; max-height:250px;transition:opacity 300ms;")
        //- .stepImage(style="background-image: url(/images/checkmark-animation.mp4); margin-bottom:1.5em; max-height:340px;")
        
        //- button.btn.waves-effect.white.haq-btn.blue-text(@click="previousStep") Back
        .center-align
            a#launchbtn.btn.waves-effect.haq-btn.haq-color(href="/" target="none" style="color:#fff;width:300px;opacity:0;font-size:24px;").disabled Launch Site
</template>
<script>
export default {
    name: "Step8",
    data(){
        return {}
    },
    methods: {
        nextStep(){
            this.$emit('nextStep')
        },
        previousStep(){
            this.$emit('previousStep')
        }
    },
    mounted(){
        setTimeout(function(){
            // console.log('there');
            $('#bluespinner').css('opacity', '0');
            $('#launchbtn').removeClass('disabled');
            setTimeout(function(){
                $('#donecheck').css('opacity', '1');
                $('#donecheckvideo')[0].play();
                $('#setuptitle').text('Setup Complete');
                setTimeout(function(){
                    $('#launchbtn').css('opacity', 1);
                }, 1000);
            }, 300);
        },2000);
    }
}
</script>
<style lang="sass">
    #donecheckvideo
        left: -135px
        @media (max-width: 992px)
            left: -76px
        @media (max-width: 800px)
            left: calc(-58px - 414px + 50vw)
        @media (max-width: 600px)
            left: calc(-452px + 50vw)
</style>