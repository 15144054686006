<template lang="pug">
	.kiosk-card
		.kiosk-header
			span.kiosk-title {{kioskName}}
			.spacer
			a.dropdown-trigger(:data-target="'kiosk-' + index")
				i.material-icons.settings settings 
			ul.dropdown-content(:id="'kiosk-' + index" style='min-width:200px;')
				//-li
					a Edit 
				//-li
					a Rename 
				li.modal-trigger(href="#modal-share-kiosk" @click.stop="currAction = 'share';sendAction()")
					a Share 
				//-li
					a Delete 
		.kioskcard-measurement
			KioskPublic(:kioskUUID="kiosk.uuid" :shareKiosk="true")
</template>
<script>
import DonutIndicator from './DonutIndicator'
import KioskPublic from '../views/KioskPublic'
import AreaChart from './AreaChart'
import AreaChartLarge from './AreaChartLarge'
import Kiosk from '../views/Kiosk.vue'
import {HTTPClient} from '../assets/js/http'
let client = new HTTPClient();

export default {
	name: 'KioskCard',
	components: {
		DonutIndicator,
		AreaChart,
		AreaChartLarge,
		Kiosk,
		KioskPublic,
	},
	data() {
		return {
			siteId: this.$route.query.siteId,
			expanded: {
				status: false,
				icon: 'keyboard_arrow_down',
				sid: this.$route.query.siteId
			},
			currentSite: {},
			currentKiosk: {},
			modalInstance: null,
			currAction: null,
			kioskName: this.kiosk.name ? this.kiosk.name : this.kiosk?.zone?.name || this.kiosk?.site?.name,
		}
	},
    props: {
		title: String,
		kiosk: Object,
		index: Number,
	},
	updated() {
		
		const modal = document.querySelectorAll('.modal')
		this.modalInstance = window.M.Modal.init(modal)
	},
	mounted() {
		const modal = document.querySelector('.modal')
		this.modalInstance = window.M.Modal.init(modal)
		let dd = document.querySelectorAll('.dropdown-trigger');
		let dropOptions = { coverTrigger: false }
		window.M.Dropdown.init(dd,dropOptions);
	},
	methods: {
		expand(){
			if(this.expanded.status){
				this.expanded.status = false;
				this.expanded.icon = 'keyboard_arrow_down'
			} else {
				this.expanded.status = true;
				this.expanded.icon = 'keyboard_arrow_up'
			}
		},
		shareKiosk() {
		},
		sendAction() {
			this.$emit('sendAction', {action: this.currAction, kiosk: this.kiosk, index: this.index});
		},
	}
}
</script>
<style lang="scss" scoped>
	.kiosk-card {
		background: linear-gradient(139.8deg, var(--card-primary-bg) 23.95%, var(--card-secondary-bg) 109.29%);
		border-radius: 12px;
		box-shadow: var(--box-shadow);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-bottom: 24px;
		padding-bottom: 24px;
		min-width: 100px;
		width: 100%;
		height: 485px;
		@media (min-width: 960px) {
			max-width: 615px;
		}
		.kiosk-header {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			padding: 24px;
			gap:14px;

			.kiosk-title {
				color: var(--app-primary-txt);
				font-size: 20px;
			}
			.project-logo {
				height: 30px;
				margin-right: 15px;
			}
			@media (max-width: 500px) {
				.project-title {
					flex-basis: 100%;
					order: 15;              
				}
			}
		}
		.kioskcard-measurement {
			height: 385px;
			width: 580px;
			margin: 0 auto;
			overflow: hidden;
		}
		.spacer {
			flex-grow: 1 !important
		}
		.material-icons {
			&.settings {
				cursor: pointer;
				color: var(--app-primary-txt);
			}
		}
	}
</style>