// This will be used as a sub component for selected sites on onboarding

<template lang="pug">
    #siteTable
        table
            thead
                tr
                    th #
                    th Name
                    th Status
            tbody
                tr(v-for="(site,index) in sites" :key="site.objectId")
                    td {{index + 1}}
                    td
                        span.siteLink(@click="goToLink(site)") {{site.name}}
                    td(class="right")
                        div(v-show="site.onboarded")
                            font-awesome-icon(icon="circle" style="color:#2ecc71")
                            span(style="margin-right:1rem")
                            | Complete
                        div(v-show="!site.onboarded")
                            font-awesome-icon(icon="circle" style="color:#e74c3c")
                            span(style="margin-right:1rem")
                            | Start


</template>

<style lang="scss" scoped>
    .siteLink {
        cursor: pointer;
        text-decoration: underline;
        &:focus {
            color: var(--app-primary-txt)
        }
    }
</style>
<script>
import {HTTPClient} from '../assets/js/http'
let client = new HTTPClient();
export default {
    name: 'SiteSelector',
    data() {
        return {
            sites: []
        }
    },
    methods: {
        goToLink(site){
            console.log(site);
            this.$router.replace({path: 'onboarding', params: {siteId: site.objectId}});
            // window.location.reload();
        }
    },
    async mounted(){
        try {
            this.sites = await this.$store.dispatch('getSites')
        } catch (error) {
            console.error(error);
            window.M.toast({html: '<h3> Cannot Load Sites </h3>'})
        }
    }
}
</script>