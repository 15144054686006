<template lang="pug">
    div(data-heatmap)
        //- table(dense)
        //-     thead 
        //-         tr
        //-             th(data-header-index="0" data-header-label)
        //-                 span Zones
        //-             th(v-for="dates,index in columns" :data-header-index="index + 1" data-header-value :key="index") {{dates.text}}
        //-     tbody
        //-         tr(v-for="zone in zones")
        //-             td(data-row-label)
        //-                 span(data-zone-name) {{zone.name}}
        //-             td(data-row-value v-for="h,index in heatMaps" :key="index" :data-gradient-level="getGradientLevel(h,zone)") {{getZoneValue(h, zone)}}
        div.heatMapParent
            .zonesRowColumn
                div(data-zone-name-header) Zones
                //- div(data-zone-name-value v-for="zone in zones") {{zone.name}}
                div(data-zone-name-value v-for="zone in zones")
                    a(:href="'/projects/'+zone.project.objectId+'/sites/'+zone.site.objectId+'/zones/'+zone.objectId") {{zone.name}}
            .valuesRowColumn
                .valueRow
                    div(v-for="dates,index in columns" :data-row-header="index" :key="index" v-html="dates.text")
                .valueRow(v-for="zone in zones" :key="Math.random()")
                    div(data-row-value v-for="h,index in heatMaps" :key="Math.random()" :style="getGradientLevel(h,zone)") {{getZoneValue(h, zone)}}
                


</template>
<style lang="scss" scoped>
    [data-heatmap] {
        width: 100%;
        max-height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        table {
            background-color: var(--app-secondary-bg);
            th, td {
                padding: 0.5rem;
                border: 1px solid var(--app-primary-bg);
                text-align: center;
            }
            [data-header-label],[data-row-label] {
                width: 100px;
                text-align: start;
                overflow-y: hidden;
                overflow-x: auto;
                * {
                    margin-left: 0.5rem;
                }
            }
            [data-row-value], [data-header-value] {
                width: 25px;
                max-width: 25px;
                overflow-y: hidden;
                overflow-x: hidden;
            }
            [data-row-value] {
                cursor: pointer;
                &:hover {
                    background-color: var(--app-primary-bg);
                }
                &[data-gradient-level='1'] {
                    background-color: #fee0e0;
                    color: #000;
                }

                &[data-gradient-level='2'] {
                    background-color: #fda1a1;
                    color: #000;
                }

                &[data-gradient-level='3'] {
                    background-color: #fc6262;
                    color: #000;
                }

                &[data-gradient-level='4'] {
                    background-color: #fa5f5f;
                    color: #000;
                }
            }
        }
        .heatMapParent {
            width: 100%;
            display: flex;
            .zonesRowColumn {
                width: 150px;
                display: flex;
                flex-direction: column;
                [data-zone-name-header], [data-zone-name-value] {
                    max-width: 150px;
                    width: 150px;
                    overflow: hidden;
                    height: 4rem;
                    display: flex;
                    align-items: center;
                    padding: 0.75rem;
                    border: 2px solid var(--app-primary-bg);
                    background-color: var(--app-secondary-bg);
                }
                [data-zone-name-value] {
                    justify-content: left;
                }
                [data-zone-name-header] {
                    justify-content: center;
                }
            }
            .valuesRowColumn {
                display: flex;
                max-width: calc(100% - 150px);
                overflow-x: auto;
                overflow-y: auto;
                flex-direction: column;
                .valueRow {
                    display: flex;
                    width: 100%;
                    flex-direction: row;
                    [data-row-header], [data-row-value] {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        max-width: 4rem;
                        min-width: 4.2rem;
                        height: 4rem;
                        max-height: 4rem;
                        min-height: 4rem;
                        width: 4rem;
                        text-align: center;
                        border: 2px solid var(--app-primary-bg);
                        background-color: var(--app-secondary-bg);
                    }
                    [data-row-value] {
                        cursor: pointer;
                    }
                    // background color on mouse hover
                    &:hover {
                        background-color: var(--app-secondary-bg);
                    }
                }
            }
        }
    }
    
</style>
<script>
/**
 * @typedef {Object} Heatmap
 * @property {string} start - Start date/time of the heatmap
 * @property {string} end - End date/time of the heatmap
 * @property {Zones} zones - The zones in the heatmap
 */

/**
 * @typedef {Object.<string, AlertType>} Zones
 */

/**
 * @typedef {Object} AlertType
 * @property {string} name - The name of the alert type
 * @property {number} alerts - The number of alerts for this type
 */

import moment from 'moment';
export default {
    name: "HeatMapChart",
    props: {
        HeatMapObject: {
            type: Array,
            required: true
        },
        zones: {
            type: Array,
            required: true
        },
        isAlert : {
            type: Boolean,
            required: true
        },
        timePeriod: {
            type: String,
            required: true
        }
    },
    data(){
        return {
            /** @typedef {Array<Heatmap>} */
            heatMaps: this.HeatMapObject,
            columns: [],
            minVal: Infinity,
            maxVal: 0
        }
    },
    mounted(){
        for(let h=0; h < this.heatMaps.length; h++){
            let heatMap = this.heatMaps[h];
            let start = moment(heatMap.start);
            this.columns.push({
                text: this.timePeriod == "today" ? start.format("ha") : start.format("ddd M/D").replace(" ", "<br/>"),
            })
            for(let z in heatMap.zones){
                let zone = heatMap.zones[z];
                if(zone[this.isAlert ? "alerts" : "curValue"] < this.minVal){
                    this.minVal = zone[this.isAlert ? "alerts" : "curValue"];
                }
                if(zone[this.isAlert ? "alerts" : "curValue"] > this.maxVal){
                    this.maxVal = zone[this.isAlert ? "alerts" : "curValue"];
                }
            }
        }
    },
    computed: {
        getZoneValue(){
            /**
             * @param {Heatmap} heatMap
             * @param {object} zone
             */
            return (heatMap, zone) => {
                if(heatMap.zones[zone.objectId] == null){
                    return 0
                }
                return heatMap.zones[zone.objectId][this.isAlert ? "alerts" : "curValue"];
            }
        },
        getGradientLevel(){
            return (heatMap, zone) => {
                if(this.isAlert){
                    if(heatMap.zones[zone.objectId] == null){
                        return 'background-color:transparent';
                    }
                    let alerts = heatMap.zones[zone.objectId].alerts;
                    let gradientLevel = (alerts/this.maxVal);
                    return 'background-color:rgba(250, 0, 0, ' + gradientLevel + ')';
                    
                } else {
                    return '';
                }
            }
        }
    }

}
</script>