<template lang="pug">
    #step6
        p Activate the sensor by inserting the batteries or holding the button for at least three seconds. You will see the light blink off and on. This means your sensor has been activated!
        .stepImage(:style="'background-image: url('+data.images.actImage+'); margin-bottom:1.5em; max-height:220px;'")
        //- button.btn.waves-effect.white.haq-btn.blue-text(@click="previousStep") Back
        button.btn.waves-effect.haq-btn.haq-color.right(@click="nextStep") Next
</template>
<script>
export default {
    name: "Step6",
    props: ['data'],
    data(){
        return {}
    },
    methods: {
        nextStep(){
            this.$emit('nextStep')
        },
        previousStep(){
            this.$emit('previousStep')
        }
    }
}
</script>