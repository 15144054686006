<template>
    <div class="loadingDiv">
        <KioskBranded v-if="kiosk && kiosk.type == 'branded'" :site="site" :kioskUUID="kioskUUID" :s="shareKiosk"/>
        <Kiosk v-if="kiosk && kiosk.type !== 'branded'" :kioskUUID="kioskUUID" :site="site" :s="shareKiosk"/>
    </div>
</template>
<script>
import { HTTPClient } from "../assets/js/http";
import KioskBranded from './KioskBranded.vue'
import Kiosk from './Kiosk.vue'
let client = new HTTPClient();
export default {
    name: 'KiosPublic',
    components: {
        KioskBranded,
        Kiosk,
    },
    props: {
        kioskUUID: String,
        shareKiosk: Boolean,
    },
    data(){
        return {
            kioskId: this.$route.params.id || this.kioskUUID,
            kiosk: null,
            site: null,
        }
    },
    methods: {
        async redirectToKiosk(){
            try {
                let kiosk = await client.robustQuery('/api/kiosk/' + this.kioskId);
                console.log('kiosk', kiosk)
                this.site = kiosk.site;
                this.kiosk = kiosk;
                console.log(this.site)
                // window.location.href = `/${path}?kioskId=${this.kioskId}&siteId=${query.siteId}&zoneId=${query.zoneId}`
            } catch (error) {
                console.error(error);
            }
        }
    },
    mounted(){
        if(!this.kioskId){
            console.log('No Kiosk Id provided')
            //window.location.href = "/"
        } else {
            this.redirectToKiosk();
        }
    }
}
</script>