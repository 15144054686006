<template lang="pug">
    #modal-project-select.modal.haq-modal
        .modal-content
            .modal-header
                h2 Select a Project
                i.material-icons.modal-close close
            .modal-divider
            .modal-body
                .vuetify(v-if="this.$route.params.projectId")
                    v-select.haq-text-field(v-model="projectId" :items="$store.state.projects" item-text="name" item-value="objectId" label="Select a Project" hide-details
                        :menu-props="{ bottom: true, offsetY: true, closeOnClick: true, closeOnContentClick: true, }"
                        @change="authenticateAPI")
                .vuetify(v-else data-app)
                    v-select.haq-text-field(v-model="projectId" :items="$store.state.projects" item-text="name" item-value="objectId" label="Select a Project" hide-details
                        :menu-props="{ bottom: true, offsetY: true, closeOnClick: true, closeOnContentClick: true, }"
                        @change="authenticateAPI")
</template>

<script>
export default {
  name: 'ModalProjectSelect',
  data() {
      return {
        errorText: null,
        projectId: '',
        projects: []
      };
  },
  methods: {
    async authenticateAPI(event) {
        try {
            let user = this.$store.state.user;
            let userId = user.objectId;
            let projectId = this.projectId;
            await this.$store.dispatch('updateToken', {userId,projectId});
            window.location.replace('/');
        } catch (error) {
            event.target.disabled = false;
            this.errorText = true;
            console.error(error);
        }
    },
    initializeMaterialize() {
        this.$nextTick(() => {
            if (document.querySelectorAll('.modal').length > 0) {
                window.M.Modal.init(document.querySelectorAll('.modal'));
            }
        });
    },
  },
  mounted(){
       this.initializeMaterialize();
  },
  watch: {
      '$store.state.projects': {
          handler(){
              this.initializeMaterialize();
          },
          immediate: true,
          deep: true
      }
  }
};
</script>
<style lang="sass">
#modal-project-select
    .vuetify
        position: relative
        .v-menu__content
            position: absolute !important
            top: auto !important
            left: auto !important
</style>
<style lang="sass" scoped>
#modal-project-select
    overflow-y: visible  // allows dropdowns to display outside of the modal
    .modal-header
        display: flex
        justify-content: space-between
        .modal-close
            font-size: 38px
</style>