<template lang="pug">
    #step1
        p What would you like to name your site?
        .row
            .col.s12.input-field-outlined
                input#sitename(placeholder="123 Main St" type="text" name="sitename" v-on:keyup="setSiteName" :value="mySiteName")
                label(for="sitename") Site Name
                //- span.helper-text#sitenamehelper.red-text asdf
        button#nextbtn.btn.waves-effect.haq-btn.brandColor.right(@click="nextStep" :disabled="!mySiteName") Next
</template>
<script>
import {HTTPClient} from '../../../assets/js/http';
let client = new HTTPClient();

export default {
    name: "Step1",
    props: ['siteName', 'kit'],
    data(){
        return {
            mySiteName: this.siteName
        }
    },
    methods: {
        async nextStep(){
            try{
                $('#nextbtn').addClass('disabled');
                // make sure sitename exists
                this.$emit('setSiteName', this.$data.mySiteName);
                let results = await client.robustPost('/api/sites', {
                    name: this.$data.mySiteName,
                    kitId: this.kit&&this.kit.objectId,
                    productId: this.kit.product.objectId
                });
                // let results = JSON.parse('{"successMessage":"Success","results":{"name":"Test Site","product":{"objectId":"1nZXAA73qu"},"project":{"objectId":"AqeRT1fAGW"},"id":"xh7diBsGcp","createdAt":"2022-01-05T22:07:01.679Z","updatedAt":"2022-01-05T22:07:01.679Z","uuid":"ba6849eb-9e56-46d4-80de-71bc3dd5f4af"}}');
                // console.log(JSON.stringify(results));
                if(results.results){
                    this.$emit('setSite', results.results);
                    this.$emit('nextStep');
                }
            }catch(e){
                window.M.toast({html: e.message});
                console.error(e);
            }finally{
                $('#nextbtn').removeClass('disabled');
            }
        },
        previousStep(){
            this.$emit('previousStep');
        },
        setSiteName(data){
            // console.log('Step1 setSiteName', data);
            this.mySiteName = $('#sitename').val();
        }
    },
    mounted(){
        // if(this.siteName)
            window.M.updateTextFields();
        if(this.kit&&this.kit.site){
            // kit already has a site so continue
            this.$emit('nextStep');
            this.$emit('setSite', this.kit.site);
        }

        // $('#sitename').on('keyup', function(){
        //     console.log('change', $('#sitename').val());
        //     // this.$emit('setSiteName', $('#sitename').val());
        // });
    }
}
</script>