<template lang="pug">
    #step2
        p Find the antenna port on the back of the HawkenAQ Hub. Using your hands, gently twist the antenna into the port until it is snug.
        .stepImage(style="background-image: url(/images/kitSetup1.png); margin-bottom:1.5em;")
        button.btn.waves-effect.white.haq-btn.blue-text(@click="previousStep") Back
        button.btn.waves-effect.haq-btn.haq-color.right(@click="nextStep") Next
</template>
<script>
export default {
    name: "Step2",
    data(){
        return {}
    },
    methods: {
        nextStep(){
            this.$emit('nextStep')
        },
        previousStep(){
            this.$emit('previousStep')
        }
    }
}
</script>