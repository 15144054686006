<template lang="pug">
    #reportChart
        highcharts(:options="chartOptions")
</template>
<script>
import {
    Chart
} from 'highcharts-vue'
export default {
    name: "ReportChart",
    props: {
        report: Object,
    },
    components: {
        highcharts: Chart
    },
    data() {
        let useScore = /score/i.test(this.report.measurementType)
        let isBooleanMeasurement = /bool/i.test(this.report.measurementKind);
        let series;
        if(isBooleanMeasurement === false){
            series = [{
                name: "Min Value",
                color: "#2196F3",
                data: this.report.intervals.map((el) => [el.startTime, useScore ? el.minScore : el.minVal])
            },
            {
                name: "Max Value",
                color: "#BC93FF",
                data: this.report.intervals.map((el) => [el.startTime, useScore ? el.maxScore : el.maxVal])
            }
            ];
            if(this.report.intervals.find((el) => el.aveVal !== null)){
                series.push({
                    name: "Avg Value",
                    color: "#feca12",
                    data: this.report.intervals.map((el) => [el.startTime, useScore ? el.aveScore : el.aveVal])
                })
            }
        } else {
            series = [{
                name: "Count",
                color: "#2196F3",
                data: this.report.intervals.map((el) => [el.startTime, el.count])
            }]
        }
        let chartOptions = {
            chart: {
                backgroundColor: "var(--app-secondary-bg)",
                type: "line"
            },
            time: {
                timezone: this.report.timezone || 'America/New_York'
            },
            legend: {
                enabled: true,
                itemStyle: {
                    color: "var(--app-primary-txt)"
                }
            },
            title: {
                text: this.report.measurementName + ` ${this.report.measurementZone  ?  `(${this.report.measurementZone})` : 
                ""}`,
               style: {
                    color: "var(--app-primary-txt)"
               }
            },
            xAxis: {
                type: "datetime",
                tickColor: 'rgba(255, 255, 255, 0.2)',
                labels: {
                    y: 25,
                    style: {
                        color: '#7D85C5',
                        fontSize: "14px"
                    }
                },
                dateTimeLabelFormats: {
                    day: '%b %e',
                    minute: '%I:%M',
                    hour: '%I:%M'
                }
            },
            yAxis: {
                title: {
                    text: null
                },
                lineColor: '#70707300',
                minorGridLineColor: '#50505300',
                tickColor: '#70707300',
                tickWidth: 1,
                labels: {
                    x: -25,
                    style: {
                        color: '#7D85C5',
                        fontSize: "14px"
                    }
                },
                gridLineColor: "rgba(255, 255, 255, 0.1)"
            },
            series,
            tooltip: {
                backgroundColor: 'rgba(0, 0, 0, 0.85)',
                style: {
                    color: '#F0F0F0'
                }
            },
            labels: {
                style: {
                    color: '#70707300'
                }
            },
            // scroll charts
            // time: {
            //     useUTC: false
            // }
        }
        return {
            chartOptions
        }
    },
    mounted(){
        console.log("Report Chart Object", this.report)
    }
}
</script>