<template lang="pug">
    highcharts(:options="chartOptions" v-if="chartOptions")
</template>

<script>
import {Chart} from 'highcharts-vue'
import {ChartOptions} from '../assets/js/chart.theme.js'

let theme = new ChartOptions();

export default {
    name: 'AreaChart',
    components: {
        highcharts: Chart
    },
    props: {
        dataArray: Array,
        colorMode: String,
        units: String,
        range: Array,
        enumeration: Object,
        multiStateValues: Array
    },
    data() {
        return {
            chartOptions: null
        }
    },
    methods : {
        loadChartOptions(){
            let chartOptions;
            if(this.enumeration){
                let dataset = [];
                dataset = this.dataArray.map((el) => {
                    let t = [el[0]];
                    if(el[1] === this.enumeration.activeText){
                        t[1] = 100;
                    } else {
                        t[1] = 0;
                    }
                    return t;
                })
                chartOptions = theme.getChartOptions(dataset, this.colorMode, this.units, [0,100], this.enumeration);
            } else if(this.multiStateValues) {
                let dataset = [];
                dataset = this.dataArray.map((el) => {
                    let t = [el[0]];
                    t[1] = this.multiStateValues.indexOf(el[1])
                    return t;
                })
                chartOptions = theme.getChartOptions(dataset, this.colorMode, this.units, [0, this.multiStateValues.length], null, this.multiStateValues)
            } 
            else if(isNaN(this.dataArray[0][1])) {
                let dataset = [];
                dataset = this.dataArray.map((el) => {
                    let t = [el[0]];
                    t[1] = 0;
                    return t;
                })
                chartOptions = theme.getChartOptions(dataset, this.colorMode, this.units, [0,100], this.enumeration);
            }
            else {
                chartOptions = theme.getChartOptions(this.dataArray, this.colorMode, this.units, this.range, this.enumeration);
            }
            this.chartOptions = chartOptions
        }
    },
    mounted() {
        this.loadChartOptions();
    },
    watch: {
        "$props.dataArray": {
            handler(){
                this.chartOptions = null
                this.$nextTick(() => this.loadChartOptions())
            },
            immediate: false,
            deep: true
        }
    }
}
</script>