/* eslint-disable require-atomic-updates */
import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment';
import {HTTPClient} from '../assets/js/http';
import { wlData } from '../assets/js/constants';
let client = new HTTPClient();

Vue.use(Vuex)

function getCookieByName(name) {
   try {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if(match){
      return decodeURIComponent(match[2])
    } else {
      return null;
    }
   } catch (error) {
     console.error(error);  
   }
}

export default new Vuex.Store({
    state: {
        logo:  '',
        defaultLogo: true,
        project: {},
        projects: [],
        sites: [],
        site: null,
        theme: window.User?.theme || 'dark',
        themes: window.Project?.themes || null,
        user: window.User,
        kit: window.Kit,
        editMode: false,
        TrendData: {},
        trendDaemon: {},
        alerts: [],
        alertsCssClass: 'msmt-alarm',
        daemonFetchTime: (new Date()).valueOf()
    },
    getters: {
        isLoggedIn(state) {
            if (!state.token) {
                state.token = getCookieByName('authToken')
            }
            return !!state.token
        },
        getUser(state) {
            if (!state.user) {
                let user = localStorage.getItem('user');
                if (user) {
                    state.user = JSON.parse(user);
                }
            }
            return !!state.user;
        },
        authStatus: state => state.status,
        getSite(state) {
            return state.site || null
        }
    },
    mutations: {
        setAlerts(state, alerts){
            if(alerts.length > 0){
                alerts.sort(function(a,b){
                    if(a.alertConfig && b.alertConfig){
                        if(a.alertConfig.alertType > b.alertConfig.alertType)
                            return 1;
                        return -1;
                    }
                    return -1;
                });
            }
            let criticalCount = 0;
			let warningCount = 0;
            let infoCount = 0;
			alerts.forEach(a => {
				if (a.alertConfig.alertType === 'critical')
					criticalCount++;
				else if(a.alertConfig.alertType === 'warning')
					warningCount++;
                else if(a.alertConfig.alertType === 'info')
                    infoCount++;
			});
            let cssClass = 'msmt-alarm';
			if (criticalCount)
				cssClass = 'msmt-alarm msmt-critical';
			else if(warningCount)
				cssClass = 'msmt-alarm msmt-warning';
            else if(infoCount)
                cssClass = 'msmt-alarm msmt-info';
            state.alertsCssClass = cssClass;
            state.alerts = alerts;
        },
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, token, user) {
            state.status = 'success';
            state.token = token;
            state.user = user;
        },
        auth_error(state) {
            state.status = 'error';
        },
        setSite(state, site) {
            state.site = site
        },
        setUser(state, user){
            state.user = user;
        },
        logout(state) {
            state.status = null;
            state.token = null;
        },
        setProject(state, project){
            state.project = project;
        },
        setProjects(state, projects){
            if(projects){
                projects.sort(function(a,b){
                    if(a.name < b.name)
                        return -1;
                    return 1;
                });
            }
            state.projects = projects;
        },
        setThemes(state, themes) {
            state.themes = themes
        },
        setTheme(state, theme){
            state.theme = theme
        },
        setLogo(state,logo){
            let theme = state.theme ? state.theme : 'dark';
            if (logo) {
                state.logo = logo;
                state.defaultLogo = false;
            }
            else if (state.project?.whitelabel === "CommanderAQ") {
                state.logo = `/images/logo/${theme}/commander.png`;
            }
            else {
                if(theme == 'light'){
                    state.logo = wlData.lightCornerLogo||`/images/logo/light/hawken.png`;
                }else{
                    state.logo = wlData.darkCornerLogo||`/images/logo/dark/hawken.png`;
                }
            }
        },
        setSites(state, sites){
            state.sites = sites;
        },
        setTrendData(state, {options, trendData}){
            if(state.TrendData[options.pointId]){
                let cache = state.TrendData[options.pointId]
                // if(options.start < cache.start){ // always update the cache if setTrendData is called
                    Object.assign(state.TrendData[options.pointId], trendData);
                    state.TrendData[options.pointId].data = state.TrendData[options.pointId].data.map((el) => {
                        if(!isNaN(Number(el[1]))){
                            return [el[0], Math.round(el[1] * 100)/100]
                        } else {
                            return el
                        }
                    })
                    state.TrendData[options.pointId].start = options.start;
                    state.TrendData[options.pointId].startString = moment(options.start).format('hh:mm:ss a DD dddd MMM YYYY')
                // }
            } else {
                state.TrendData[options.pointId] = new Object();
                state.TrendData[options.pointId] = trendData;
                state.TrendData[options.pointId].data = state.TrendData[options.pointId].data.map((el) => {
                    if(!isNaN(Number(el[1]))){
                        return [el[0], Math.round(el[1] * 100)/100]
                    } else {
                        return el
                    }
                })
                state.TrendData[options.pointId].start = options.start;
                if(options.type){
                    if(['scoreAQ', 'scoreOW', 'scoreOA'].includes(options.type)){
                        state.TrendData[options.pointId].field = "curScore"
                    } else {
                        state.TrendData[options.pointId].field = "curVal"
                    }
                }
                state.TrendData[options.pointId].startString = moment(options.start).format('hh:mm:ss a DD dddd MMM YYYY')
            }
        },
        setLatestTrendData(state, {pointId, curVal, curScore}){
            if(state.TrendData[pointId]){
                let field = state.TrendData[pointId].field;
                let lastTimeStamp = state.TrendData[pointId].data[state.TrendData[pointId].data.length - 1]?.[0] || 0;
                // console.log(lastTimeStamp)
                // console.log(new Date(lastTimeStamp).toString());
                let lastestNewVal = field == "curVal" ? curVal : curScore;
                if(lastestNewVal.ts > lastTimeStamp){
                    // console.log('Pushing to trendData ', lastestNewVal)
                    state.TrendData[pointId].data.push([lastestNewVal.ts, !isNaN(lastestNewVal.val) ? Math.round(lastestNewVal.val * 100)/100 : lastestNewVal.val])
                }
            }
        },
        setUserNotifications(state, notifications){
            state.user.notifications = notifications;
        },
        toggleEditMode(state){
            state.editMode = !state.editMode;
        }
    },
    actions: {
        async fetchAlerts({commit}, siteId){
            if(!siteId) return;
            let results = await client.robustQuery(`/api/sites/${siteId}/alerts?isActive=true`);
            if(results && results.alerts){
                commit('setAlerts', results.alerts);
            }
        },
        setDefaultTheme({commit}, theme) {
            commit('setTheme',theme);
            commit('setLogo');
        },
        setCustomTheme({commit, state}, [usertheme, themes]) {
            try {
                if(!themes) return;
                let theme = themes[usertheme ? usertheme : state.theme];
                if(!theme) return;
                commit('setTheme',usertheme ? usertheme : state.theme);
                commit('setThemes',theme);
                if(theme.logo)
                    commit('setLogo', theme.logo);
                else
                    commit('setLogo');

                let vapp = document.querySelectorAll('.v-application')[0];
                if (vapp) {
                    vapp.style.setProperty('--app-primary-bg', theme.bg1)
                    vapp.style.setProperty('--app-secondary-bg', theme.bg3)
                    vapp.style.setProperty('--app-tertiary-bg', theme.bg3)

                    vapp.style.setProperty('--app-primary-txt', theme.txt1)
                    vapp.style.setProperty('--app-secondary-txt', theme.txt2)
                    vapp.style.setProperty('--app-tertiary-txt', theme.txt3)

                    // vapp.style.setProperty('--navbar-txt', theme.)
                    // vapp.style.setProperty('--navbar-link-active', theme.)
                    // vapp.style.setProperty('--navbar-link-inactive', theme.)
                    // vapp.style.setProperty('--navbar-theme-toggle', theme.)

                    // vapp.style.setProperty('--btn-primary-bg', theme.)
                    // vapp.style.setProperty('--btn-primary-border', theme.)
                    // vapp.style.setProperty('--btn-primary-txt', theme.)

                    // vapp.style.setProperty('--btn-secondary-bg', theme.)
                    // vapp.style.setProperty('--btn-secondary-border', theme.)
                    // vapp.style.setProperty('--btn-secondary-txt', theme.)
                    
                    vapp.style.setProperty('--input-bg', theme.fbg1)
                    vapp.style.setProperty('--input-txt', theme.ftxt1)

                    // vapp.style.setProperty('--bg-tooltip', theme.)

                    vapp.style.setProperty('--card-primary-bg', theme.bg2)
                    // vapp.style.setProperty('--card-secondary-bg', theme.)
                    // vapp.style.setProperty('--card-button-bg', theme.)
                    // vapp.style.setProperty('--card-button-deactivated-bg', theme.)
                    // vapp.style.setProperty('--card-button-deactivated-txt', theme.)

                    vapp.style.setProperty('--kiosk-bg', theme.bg1)
                    // vapp.style.setProperty('--kiosk-card-bg', theme.)

                    // vapp.style.setProperty('--table-check-bg', theme.)
                    // vapp.style.setProperty('--table-selected-bg', theme.)
                    // vapp.style.setProperty('--table-highlight-bg', theme.)

                    // vapp.style.setProperty('--border', theme.)
                    // vapp.style.setProperty('--box-shadow', theme.)

                    // vapp.style.setProperty('--primary-alert', theme.)

                    // vapp.style.setProperty('--score-blue-color', theme.)
                    // vapp.style.setProperty('--score-yellow-color', theme.)
                    // vapp.style.setProperty('--score-red-color', theme.)

                    vapp.style.setProperty('--airquality', theme.aq1)
                    vapp.style.setProperty('--co', theme.co1)
                    vapp.style.setProperty('--rh', theme.rh1)
                    vapp.style.setProperty('--plt', theme.plt1)
                    vapp.style.setProperty('--vt', theme.vt1)
                    vapp.style.setProperty('--spt', theme.spt1)

                    vapp.style.setProperty('--airquality2', theme.aq2)
                    vapp.style.setProperty('--co2', theme.co2)
                    vapp.style.setProperty('--rh2', theme.rh2)
                    vapp.style.setProperty('--plt2', theme.plt2)
                    vapp.style.setProperty('--vt2', theme.vt2)
                    vapp.style.setProperty('--spt2', theme.spt2)
                }
            } catch (error) {
                console.error(error)
            }
        },
        async login({commit}, user) {
            try {
                let res = await client.robustPost('/users/login', user);
                commit('auth_success', res.token, res.user)
            } catch (error) {
                commit('auth_error')
                window.alert('Incorrect Username and Password');
                throw new Error(error);
            }
        },
        async loginWithToken({commit}, object) {
            try {
                commit('auth_success', object.token, object.user)
            } catch (error) {
                commit('auth_error');
                console.log(error);
                window.alert('Invalid token');
                throw new Error(error);
            }
        },
        async logout({commit}) {
            try {
                window.location = "/logout";
            } catch (error) {
                commit('auth_error')
                throw new Error(error)
            }
        },
        async updateToken({commit}, data) {
            try {
                let res = await client.robustPost('/users/updatetoken', data);
                commit('auth_success', res.token, res.user);
            } catch (error) {
                commit('auth_error')
                console.log(error);
                throw new Error(error);
            }
        },
        async loadLocals({commit}){
            try {
                let res = await client.robustQuery('/locals');
                commit('setUser', res.user);
                commit('setProject', res.project);

                this.state.kit = res.kit;
                this.state.devices = res.devices;
                
                if(Array.isArray(res.projects))
                    commit('setProjects', res.projects)
                
                // Setting Theme
                // let theme = res.user.theme || 'dark'; 
                // if(res.project.themes!= null)
                //     this.dispatch('setCustomTheme', [theme, res.project.themes])
                // else
                //     this.dispatch('setDefaultTheme', theme);
                
                window.User = res.user;
                window.Project = res.project;
                window.Projects = res.projects;
                window.Kit = res.kit;
                window.Devices = res.devices;
                return res;
            } catch (error) {
                console.error(error);
            }
        },
        localsThroughWindow({commit}){
            console.warn("Loading locals through window")
            commit('setProject', window.Project);

            this.state.kit = window.Kit;
            this.state.devices = window.Devices;
            
            if(Array.isArray(window.Projects))
                commit('setProjects', window.Projects)
            
            // Setting Theme
            // let theme = window.User.theme || 'dark'; 
            // if(window.Project.themes!= null)
            //     this.dispatch('setCustomTheme', [theme, window.Project.themes])
            // else
            //     this.dispatch('setDefaultTheme', theme);
        },
        async getSites({state, commit}){
            try {
                if(state.sites.length == 0){
                    let res = await client.robustQuery('/api', {slim: true});
                    commit('setSites', res.sites);
                }
                return state.sites
            } catch (error) {
                console.error(error);
            }
        },
        async getTrendData({commit, state, dispatch}, options={}){
            try {
                if(!options.force && state.TrendData[options.pointId]!= null && options.startTime>= state.TrendData[options.pointId].start){
                    // console.log('Fetching cached data');
                    let chartData = Object.assign({}, state.TrendData[options.pointId])
                    // console.log(chartData.data.length)
                    if(options.endTime)
                        chartData.data = chartData.data.filter((el) => el[0]< options.endTime);
                    chartData.data = chartData.data.filter((el) => el[0]>= options.startTime);
                    // console.log(chartData.data.length)
                    return chartData
                }
                let chartData = await client.robustQuery('/api/trendData', {
                    siteId: options.siteId,
                    pointId: options.pointId,
                    version: 'highcharts',
                    startTime: options.startTime,
                    type: options.type
                });
                let subOptions = {pointId: options.pointId, start: options.startTime, type: options.type};
                // console.log(options.type, moment(chartData.results.data[0][0] || null).format('hh:mm:ss a DD dddd MMM YYYY'))
                commit('setTrendData', {options: subOptions, trendData: chartData.results})
                if(state.trendDaemon[options.siteId] == null){
                    setInterval(dispatch, state.site?.uiCurvalUpdateInterval || 1000 * 60 * 10, 'trendDaemonRunner', options.siteId) // every 10 minutes
                    state.trendDaemon[options.siteId] = true;
                }
                return state.TrendData[options.pointId];
            } catch (error) {
                console.error(error);
            }
        },
        async trendDaemonRunner({state, commit}, siteId){
            try {
                // console.log('Kicking on trendDaemon Runner for ' + siteId)
                let measurementIds = JSON.stringify(Object.keys(state.TrendData))
                let latestValues = await client.robustQuery(`/api/site/${siteId}/latest`, {measurementIds});
                let updated = false;
                for(let i in latestValues.results){
                    if(latestValues.results[i].curVal || latestValues.results[i].curScore){
                        commit('setLatestTrendData', {curVal: latestValues.results[i].curVal, curScore: latestValues.results[i].curScore, pointId: i})
                        updated = true 
                    }
                }
                if(updated){
                    state.daemonFetchTime = (new Date()).valueOf()
                }
            } catch (error) {
                console.error(error);
            }
        }
    },
    modules: {}
});