<template lang='pug'>
    v-dialog(v-model="dialog" content-class="haq-dialog" max-width="1000px" @input="closeModal" scrollable)
        v-card#modal-score-help.haq-dialog-card
            v-icon.close-icon(text @click="closeModal") mdi-close
            v-tabs-items(v-model="tab")
                v-tab-item(key=0)
                    v-card-title.tab-title 
                        h2 Overview
                        //- v-spacer
                        //- v-icon.close-icon(text @click="closeModal") mdi-close
                    v-card-text
                        .modal-card
                            p Sensor readings are used as Metrics to evaluate air quality and comfort. Since various types of phenomena are being measured, the range of values can vary greatly between the different Metrics. To evaluate such a broad range, HawkenAQ normalizes Metrics into Scores that range from 0-100, with 100 representing the best quality that can be measured for that type of sensor. These Scores are then ranged into Ratings for ease of visualization:
                            v-simple-table.simple-table(dense)
                                thead
                                    tr
                                        th.text-left Rating
                                        th.text-left Score Range
                                        th.text-left Graph Color
                                tbody
                                    tr(v-for="item in simpleTaleOverview")
                                        td {{item.name}}
                                        td {{item.range}}
                                        td 
                                            span.score-dot(:class="item.colorCssClass")
                                            | {{item.color}}
                            p Individual Scores are also aggregated into composite Scores to quickly indicate the air quality and comfort for zones and sites. Aggregation is done by a weighted average of the individual Scores, or the lowest Score of the group is shown.
                            p In summary, a Metric is a sensor reading, which has some range of values that can be measured. A Score is a mapping of the Metric range into a normalized range of 0-100, with 100 being best quality. A Rating is a sub-range of Scores that provides a simple label and color for quick evaluation of the conditions.
                v-tab-item(key=2)
                    section.help-section(v-for='s in scores')
                        v-card-title.section-header(v-if='s.children.length > 0')
                                .donut-wrap
                                    .donut
                                        DonutIndicator(id="saoHelp",:value="Math.round(s.curScore)",:size="40")
                                    h2 {{ s.title }}
                        v-card-text
                            .section-body
                                .modal-card(v-for='sc in s.children')
                                    .card-header
                                        h3 {{ sc.name }} 
                                            span.hc-unit(v-if="sc.unit") {{ sc.unit }}
                                        .donut(v-if="sc.curScore")
                                            DonutIndicator(:value="Math.round(sc.curScore)",:size="40")
                                    .card-body
                                        p.hc-description {{ sc.description }}
                                        .hc-more-info-wrap(v-if="sc.threshold || sc.extendedDescription")
                                            .hc-more-info(:id="'more-info-' + sc.objectId")
                                                v-simple-table.simple-table(v-if="sc.threshold" dense)
                                                    thead
                                                        tr
                                                            th.text-left Range
                                                            th.text-left Threshold
                                                            
                                                    tbody
                                                        tr
                                                            td {{sc.threshold.Excellent}}
                                                            td 
                                                                span.score-dot.score-blue
                                                                | Excellent
                                                        tr
                                                            td {{sc.threshold.Good}}
                                                            td
                                                                span.score-dot.score-yellow
                                                                | Good
                                                        tr
                                                            td {{sc.threshold.Poor}}
                                                            td
                                                                span.score-dot.score-red
                                                                | Poor
                                                .hc-desc-more-info(v-if="sc.extendedDescription", v-html="sc.extendedDescription")
                                            a(:id="'mi-btn-' + sc.objectId",v-on:click="toggleMoreInfo(sc.objectId)") More Information
            v-card-actions
                v-tabs(v-show="scores.length > 0" v-model="tab")
                    v-tab(key=0) Overview
                    v-tab(key=2) Definitions
                    v-spacer
                    .tab-link
                        a(href="https://drive.google.com/file/d/1oX1qZ17mDuz099TCX0gFKR09d4Wncemu/view?usp=sharing",target="_blank") Standards
                        v-icon mdi-open-in-new

</template>

<script>
import {HTTPClient} from '@/assets/js/http';
import DonutIndicator from '@/components/DonutIndicator.vue';
let client = new HTTPClient();

export default {
    name: 'ScoreHelp',
    components: {
        DonutIndicator,
    },
    props: {
        openModal: String,
        overallCurScore: Number,
        measurements: Array,
    },
    data() {
        return {
            dialog: this.openModal == 'scorehelp' ? true : false,
            errorText: null,
            projectId: String,
            scores: [],
            thresholds: [],
            childrenOA: [],
            tab: null,
            items: [
                'Overview', 
                'Definitions',
                'Standards',
            ],
            simpleTaleOverview: [
                {
                    name: 'Excellent',
                    range: '75 - 100',
                    color: 'Blue',
                    colorCssClass: 'score-blue',
                },
                {
                    name: 'Good',
                    range: '50 - 75',
                    color: 'Yellow',
                    colorCssClass: 'score-yellow',
                },
                {
                    name: 'Poor',
                    range: '0 - 50',
                    color: 'Red',
                    colorCssClass: 'score-red',
                },
            ],
        };
    },
    methods: {
        closeModal() {
            this.dialog = false;
			this.$emit("closeModal")
		},
		toggleMoreInfo(id) {
			let mi = '#more-info-' + id
            let miBtn = '#mi-btn-' + id

            $(mi).toggle('slow',function(){}); // eslint-disable-line no-undef

            if($(miBtn).text() == 'More Information') // eslint-disable-line no-undef
                $(miBtn).text('Less Information') // eslint-disable-line no-undef
            else
                $(miBtn).text('More Information') // eslint-disable-line no-undef
		},
        buildScore(measurement,pt,allPoints) {
            if(measurement.curScore && !pt.curScore)
                pt['curScore'] = measurement.curScore;
            this.childrenOA.push(pt);
            
            let children = [];

            measurement.childMeasurements.forEach(cm => {
                if(!cm.type.includes('score')) {
                    let cpt = allPoints.results.find(p => p.type.includes(cm.type));
                    if (cpt && cpt.description) {
                        if(cm.unit)
                            cpt['unit'] = cm.unit
                        if(cm.curScore)
                            cpt['curScore'] = cm.curScore
                        children.push(cpt);
                        let t = {
                            'name' : cpt.name,
                            'threshold' : cpt.threshold,
                        }
                        this.thresholds.push(t);
                    }
                }
            });
            
            let score = {
                'title' : pt.name,
                'curScore' : measurement.curScore,
                children,
                'scoreOrder' : pt.scoreOrder,
            };
            return score;
        },
        async fetchPointTypes(){
            try {
                let ptRes = await client.robustQuery('/api/pointtypes');
                if (this.measurements) {
                    this.measurements.forEach(m => {
                        let pt = ptRes.results.find(p => p.type.includes(m.type));
                        if(m.type == 'scoreAF' && pt && m.childMeasurements)
                            this.scores.push(this.buildScore(m, pt, ptRes));
                        if(m.type == 'scoreAQ' && pt && m.childMeasurements)
                            this.scores.push(this.buildScore(m, pt, ptRes));
                        if(m.type == 'scoreOW' && pt && m.childMeasurements)
                            this.scores.push(this.buildScore(m, pt, ptRes));
                        if(m.type == 'scoreEQ' && pt && m.childMeasurements)
                            this.scores.push(this.buildScore(m, pt, ptRes));
                    });

                    let pt = ptRes.results.find(p => p.type.includes('scoreOA'));
                    let scoreOA = {
                        'title' : 'Overall',
                        'curScore' : this.overallCurScore,
                        'children' : this.childrenOA.sort(function(a,b){ return a.scoreOrder - b.scoreOrder; }),
                        'scoreOrder' : pt.scoreOrder,
                    }
                    this.scores.push(scoreOA);
                    this.scores.sort(function(a,b){ return a.scoreOrder - b.scoreOrder; });
                }
            }
            catch (error) {
                console.error(error);
                window.M.toast({html: 'Cannot Retrieve Point Types'});
            }
            finally {
                let tb = document.querySelectorAll('.tabs');
                window.M.Tabs.init(tb);
            }
        },
    },
    mounted() {
        this.fetchPointTypes();
    },
};
</script>
<style lang="sass">
#modal-score-help
    .v-card__text
        font-size: 1rem
        padding: 0 !important
    .v-tabs-items
        background-color: transparent !important
        padding: 0 24px 20px !important
        overflow-y: auto
    .v-tabs-bar
        background-color: transparent !important
        .v-tab
            color: var(--app-tertiary-txt)
            opacity: .6
        .v-tab--active
            opacity: 1
            
</style>
<style lang="sass" scoped>
#modal-score-help
    .close-icon
        position: absolute
        top: 32px
        right: 30px
        font-size: 38px
        z-index: 100

    .tab-title
        border-bottom: 2px solid rgba(125,133,197,.2)
        display: flex
        margin: 0 auto 20px
        padding: 32px 0 20px
        justify-content: space-between
        h2
            color: var(--app-primary-txt)
            font: normal 400 34px/34px Roboto,sans-serif
            margin: 0
    
    .simple-table
        background-color: var(--card-primary-bg)
        margin: 0 auto 10px
        max-width: 550px
        .score-dot
            border-radius: 50%
            display: inline-block
            height: 10px
            margin-right: 5px
            width: 10px
        .score-blue
            background: var(--score-blue-color)
        .score-yellow
            background: var(--score-yellow-color)
        .score-red
            background: var(--score-red-color)
        thead
            th
                color: var(--app-primary-txt)
                font-size: 1rem
        tbody tr
            &:hover
                background-color: hsla(0,0%,94.9%,.5)
            td
                border-radius: 0

    // TAB 2 - Definitions
    .help-section
        .section-header
            background: var(--app-tertiary-bg)
            border-bottom: 2px solid rgba(125,133,197,.2)
            margin: 0 auto 20px
            padding: 32px 0 20px
            position: sticky
            top: 0
            z-index: 1
            .donut-wrap
                display: flex
                align-items: center
                .donut
                    margin-right: 27px
                h2
                    font: normal 400 34px/34px 'Roboto', sans-serif
                    margin: 0

        .section-body
            .modal-card
                background: var(--card-primary-bg)
                color: var(--app-primary-txt)
                border-radius: 12px
                padding: 30px 18px 20px
                margin-bottom: 20px
                
                .card-header
                    display: flex
                    align-items: center
                    justify-content: space-between
                    h3
                        font: normal 400 28px/30px 'Roboto', sans-serif
                        letter-spacing: 0.18px
                        margin: 0
                        .hc-unit
                            color: #ffffffb3
                            font: normal 300 14px/24px 'Roboto', sans-serif
                            letter-spacing: 0.5px
                .card-body
                    .hc-description
                        margin-top: 10px
                    .hc-more-info-wrap
                        .hc-more-info
                            display: none
                            .simple-table
                                background: var(--card-secondary-bg)
                            .hc-desc-more-info
                                p
                                    margin: 0
                                p.mi-title
                                    margin-top: 1em
                                ul
                                    margin: 0
                                    padding-left: 20px
                        a
                            cursor: pointer
                            font: normal 300 16px/24px 'Roboto', sans-serif
                            letter-spacing: 0.18px
                            margin-top: 10px
                .card-divider
                    padding: 20px

    .v-card__actions
        padding: 0
        .v-tabs
            .tab-link
                align-items: center
                cursor: pointer
                display: flex
                flex: 0 1 auto
                font-size: 0.875rem
                font-weight: 500
                justify-content: center
                letter-spacing: 0.0892857143em
                line-height: normal
                min-width: 90px
                max-width: 360px
                outline: none
                padding: 0 16px
                position: relative
                text-align: center
                text-decoration: none
                text-transform: uppercase
                transition: none
                -webkit-user-select: none
                -moz-user-select: none
                -ms-user-select: none
                user-select: none
                &:hover
                    background-color: var(--app-tertiary-bg)
                    opacity: 0.8
                    a,
                    .v-icon
                        opacity: 1
                a,
                .v-icon
                    color: var(--app-tertiary-txt)
                    opacity: 0.6
                .v-icon
                    font-size: 1.2rem
                    padding-left: 10px

</style>