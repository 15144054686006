<template lang="pug">
    .site-alert(v-if="trigger" :class="(alertClass ? alertClass : '')" style="padding-bottom:6px;")
        .container
            .row
                .col.s12
                    .alert-message {{message}}
                    .close-alert(@click="closeAlert")
                        i.material-icons close
</template>
<script>
import {HTTPClient} from '@/assets/js/http';
let client = new HTTPClient();

export default {
    name: "SiteAlert",
    props: {
        id: Number,
        name: String,
        trigger: Boolean,
        message: String,
        alertClass: String,
        alertType: String,
        alertTimeout: Number,
    },
    methods: {
        updateOnboarding(){
            let user = this.$store.state.user;
            if(!user.onboardingComplete)
                user.onboardingComplete = { "alerts": {} };
            else if(!user.onboardingComplete?.alerts)
                user.onboardingComplete.alerts = {};
            let calibrationBannerUpdateTime = {
                "step": this.id,
                "name": this.name,
                "complete": (new Date()).valueOf(),
            }
            user.onboardingComplete.alerts[this.id] = calibrationBannerUpdateTime;
            client.robustPost(`/api/users/${user.objectId}`,user).then(function(result){
                console.log('User Updated',result.user.onboardingComplete);
            }).catch(function(error){
                console.log("User Not Updated", error);
            })
        },
        closeAlert(){
            if(this.alertType === "onboarding")
                this.updateOnboarding();
            this.$emit('closeSiteAlert',this.alertType);
        },
    },
};
</script>
<style lang="scss" scoped>
    .site-alert {
        background: var(--primary-alert);
        font-size: 18px;
		padding: 20px;

        .container {
            .row {
                margin-bottom: 0;
                .col {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }

        .alert-message {
            color: var(--app-primary-txt);
        }
        .close-alert {
            cursor: pointer;
            line-height: 1;
            padding-left: 20px;
        }
    }
</style>