<template lang="pug">
	#kioskSharingPage
		Navbar(tab="kiosks" :siteId="siteId" :site="site")
		main(v-if="site")
			v-container
				v-row.kiosks-header
					v-col.site-info
						.site-image(:style='"background-image:url(" + (site.logo ? site.logo.url.replace("http", "https") : "") + ")"')
						.site-progress(v-if="site.aqEnabled != false")
							a.modal-trigger(v-if="site.score" href="#modal-score-breakdown" style="color:var(--app-tertiary-txt)")
								DonutIndicator(:key="site.objectId+'-pt-donut'" :id="site.objectId +'-scoreOA'" :cssClass="'main-donut'" :value="Math.round(site.scoreOA.curScore)" :size="60")
							DonutIndicator(v-else :key="site.objectId+'-pt-donut'" :id="site.objectId +'-scoreOA'" :cssClass="'main-donut'" :value="Math.round(site.scoreOA.curScore)" :size="60")
						.site-title {{site.name}} Kiosks
							//- router-link(:to="{name: 'Site', params: {siteId: site.objectId}}").siteLink {{site.name}} 
							//- | Kiosks
				v-row
					v-col
						.breadcrumbs
							router-link(to="/") Sites
							v-icon mdi-chevron-right
							router-link(:to="{name: 'Site', params: {projectId, siteId}}") {{site.name}}
							v-icon mdi-chevron-right 
							span Kiosks
			.container.container-lg
				v-row
					v-col.kiosks-cards
						KioskCard(v-for="k,index in kiosks" :kiosk="k" ref="kiosks" :key="index" :index="index" :users="users" v-on:sendAction="activateAction")

		ModalShareKiosk(v-show="currAction == 'share'" :shareDialog="currAction" :site="currKiosk.site" :kiosk="currKiosk" :users="users" v-on:closeShareModal="closeShareModal")
		.modal-overlay(v-if="currAction == 'share'" @click="closeShareModal()")
</template>
<script>
import Navbar from '@/components/Navbar.vue';
import DonutIndicator from '@/components/DonutIndicator.vue';
import KioskCard from '@/components/KioskCard.vue';
import ModalShareKiosk from '@/components/ModalShareKiosk'
import {HTTPClient} from '../assets/js/http';

let client = new HTTPClient();

export default {
    name: 'Kiosks',
    components: {
		Navbar,
		DonutIndicator,
		KioskCard,
		ModalShareKiosk
    },
    data(){
        return {
			projectId: this.$store.state.project.objectId,
			siteId: this.$route.params.siteId ? this.$route.params.siteId : this.$route.query.siteId,
			site: null,
			kiosks: [],
			users: [],
			currAction: null,
			currKiosk: {},
			currKioskIndex: 0,
			modalInstance: '',
		};
	},
	methods: {
		activateAction(options) {
			if (options) {
				this.currAction = options.action;
				this.currKiosk = options.kiosk;
				this.currKioskIndex = options.index;
			}
		},
		closeShareModal() {
			this.currAction = null
		},
		async getSite() {
			let siteRes = await client.robustQuery('/api/site', {siteId: this.siteId});
			this.site = siteRes.site;
		},
		async getKiosks() {
			let kiosks = await client.robustQuery(`/api/kiosks/site/${this.siteId}`);
			this.kiosks = kiosks.results;
		},
	},
	mounted() {		
		this.getSite();
		this.getKiosks();
		const modal = document.querySelectorAll('.modal')
		this.modalInstance = window.M.Modal.init(modal)
	},
	watch: {
		// eslint-disable-next-line no-unused-vars
		$route(to, from){
			//this.resetMeasurements();
			//this.loadSite();
		}
	}
};
</script>
<style lang="scss" scoped>
	.container-lg {
		@media only screen and (min-width: 993px) {
			width: 100%;
		}
	}
	
	.kiosks-header {
		margin-top: 40px;
        margin-bottom: 0;
		.site-info {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			.site-image {
				border-radius: 20px;
				width: 120px;
				height: 80px;
				min-width: 120px;
				min-height: 80px;
				background-size: cover;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				vertical-align: top;
				margin-right: 2rem;
			}
			.site-progress {
				margin-right: 1.5rem;
			}			
			.site-title {
				color: var(--app-primary-txt);
				font-size: 28px;
				.siteLink {
					color: var(--app-primary-txt);
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
	.kiosks-cards {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        &:after {
            content: "";
            flex: 0 0 50%;
        }
    }
	
	.modal-overlay {
		display: block;
		opacity: .5;
		position: fixed;
		height: 125%;
		width: 100%;
		background: #000;
		z-index: 1004;
		will-change: opacity;
		right: 0;
		bottom: 0;
		left: 0;
		top: -25%;
		animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
	}
</style>