<template lang="pug">
    .commanderLogin
        .loadingDiv(v-show="loading")
            .loadingText Logging in using KMC Commander
            .progress
                .indeterminate
        .loadingDiv.error(v-show="error")
            .loadingText {{error}}
</template>


<style lang="scss" scoped>
    .commanderLogin {
        display: flex;
        height: calc(100vh - 15px);
        width: 100vw;
        align-items: center;
        .loadingDiv {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-size: 24px;
            color: var(--app-primary-txt);
            flex-direction: column;
            &.error {
                color: red;
            }
        }
    }
</style>
<script>
import {HTTPClient} from '../assets/js/http';
let client = new HTTPClient();

export default {
    name: "CommanderLogin",
    data(){
        return {
            token: this.$route.query.jwt,
            loading: true,
            error: null,
        }
    },
    methods: {
        async authenticate(){
          try {
              let status = await client.robustPost('/users/commanderLogin', {token: this.token})
              console.log(status)
              if(status.token){
                this.$store.commit('auth_success', status.token, status.user);
              }
              if(status.siteId && status.projectId){
                  window.location = `/projects/${status.projectId}/sites/${status.siteId}`
              } else {
                  throw "Invalid site. Please contact your project administrator."
              }
          } catch (error) {
            console.error(error);
            this.loading = false;
            this.error = "You do not have access. Please contact your project administrator."
          }
        }
    },
    mounted(){
        this.authenticate()
    }
}
</script>