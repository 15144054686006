/*eslint no-undef: "warn"*/

<template lang="pug">
	#kiosk-wrapper(v-bind:class="{small: s}")
		#kiosk(v-bind:class="{small: s}")
			//- #k-learn-more(@click="learnMore")
			//- 	v-icon mdi-help-circle
			#k-carousel.carousel(:style="(measurements&&measurements.length<4)?'width:68%;':''")
				.carousel-slide(v-for="(i,slideindex) in measurements", :class="'slide-' + getKioskKind(i.type) + ' slide-count-' + measurements.length",:style="getSlideAnimationDelay(slideindex)")
					.k-slide-time {{ timestamp }}
					.k-slide-measurement
						.k-slide-measurement-icon
							MeasurementIcons(:measurement="getKioskKind(i.type)",:iconcolor="'currentColor'",:size="52")
						.k-slide-measurement-curVal(v-bind:class="{small: s}") {{getDisplayVal(i)}}
							span {{i.unit}}
						.k-slide-measurement-name {{ getMeasurementDisName(i) }}
						.k-slide-measurement-progress
							span(v-if="i.curScore >= 75") Excellent
							span(v-else-if="i.curScore < 75 && i.curScore >= 50") Good
							span(v-else) Poor
						.k-slide-counts
							.k-slide-count(v-for="(item,pagingindex) in measurements.length", :class="{'active':(pagingindex === slideindex)}")
			#k-cards(:style="(measurements&&measurements.length<4)?'width:32%;':''")
				.k-card(v-for="(i,index) in measurements.slice(0,measurements.length)", :class="'card-' + getKioskKind(i.type)")
					.k-card-measurement(:class="getKioskKind(i.type) + ' ' + (i.isAlerting ? getAlertCssClass(i.alertConfigs[0].alertType) : '')")
						.k-card-measurement-progress
							span(v-if="i.isAlerting") {{i.alertConfigs[0].alertType === 'warning' ? 'Warning' : 'Critical'}}
							span.progress-excellent(v-else-if="i.curScore >= 75") Excellent
							span(v-else-if="i.curScore < 75 && i.curScore >= 50") Good
							span(v-else) Poor
						.k-card-measurement-icon
							MeasurementIcons(v-if="i.isAlerting" :measurement="getKioskKind(i.type)",iconcolor="#fff")
							MeasurementIcons(v-else :measurement="getKioskKind(i.type)")
						.k-card-measurement-curVal {{getDisplayVal(i)}}
							span {{i.unit}}
						.k-card-measurement-name(v-bind:class="{small: s}") {{ getMeasurementDisName(i) }}
		
</template>

<script>
import { HTTPClient } from "../assets/js/http";
import MeasurementIcons from '@/components/MeasurementIcons.vue';
let client = new HTTPClient();

export default {
	name: "Kiosk",
	components: {
		MeasurementIcons,
	},
	props: {
		site: Object,
		s: Boolean,
		kioskUUID: String,
	},
	data() {
		return {
			siteId: this.$route.query.siteId,
			zoneId: this.$route.query.zoneId,
			measurements: [],
			timestamp: '',
			kioskId: this.$route.params.id || this.kioskUUID,
		}
	},
	methods: {
		learnMore(){

		},
		getCurrentTime() {
			const today = new Date();
			this.timestamp = today.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
		},
		getSlideAnimationDelay(index) {
			let delay = ['0s','10s','20s','30s','40s','50s'];
			return 'animation-delay:' + delay[index].toString() + ';';
		},
		getKioskKind(kind) {
			let k = kind.toLowerCase();
			if(k.includes('airquality') || k.includes('aq') || k.includes('scoreow'))
				return 'airquality';
			if(['co2', 'carbonmonoxide', 'ozone'].includes(k))
				return 'co2';
			if(['pollutant', 'pm2.5', 'pm10'].includes(k))
				return 'pollutant'
			return k.toLowerCase();
		},
		getAlertCssClass(alert){
			if(alert === 'warning')
				return 'msmt-alarm msmt-warning';
			else if (alert === 'critical')
				return 'msmt-alarm msmt-critical';
			else
				return '';
		},
		getDisplayVal(m){
			if(m.type.includes('score')){
				return Math.round(m.curScore)
			} else {
				if(isNaN(m.curVal)){
					return m.curVal
				} else {
					return Math.round(m.curVal);
				}
			}
		},
		resizeToScreen(id){
			try{
				let that = this;
				let el = $('#'+id)[0];
				let iw = window.innerWidth;
				let ih = window.innerHeight;
				if(iw > window.outerWidth || ih > window.outerHeight){
					iw = window.outerWidth;
					ih = window.outerHeight;
				}
				let sr = iw / ih;
				let er = el.clientWidth / el.clientHeight; 
				// use the relative aspect ratio to determine which dimension to resize by
				if(sr < er){
					// use width
					let b = iw / el.clientWidth;
					let c = Math.floor(b*100)/100;
					let d = Math.round(el.clientWidth*(1-c)*50)/100;
					let dd = Math.round(el.clientHeight*(1-c)*50)/100;
					if(c < 1){
						$(el).css('transform', 'translate(-'+d+'px, -'+dd+'px) scale('+c+')');
					}else if(c > 1){
						dd = Math.round(el.clientHeight*(c-1)*50)/100;
						$(el).css('transform', 'translate(0px, '+dd+'px) scale('+c+')');
					}else{
						$(el).css('transform', 'scale('+c+')');
					}
				}else{
					// use height
					let b = ih / el.clientHeight;
					let c = Math.floor(b*100)/100;
					// let dd = Math.round(el.clientHeight*(c-1)*50)/100;
					// $(el).css('transform', 'translate(0px, '+dd+'px) scale('+c+')');
					let d = Math.round(el.clientWidth*(1-c)*50)/100;
					let dd = Math.round(el.clientHeight*(1-c)*50)/100;
					let ddd = Math.round(Math.abs(iw-el.clientWidth*c)*50)/100;
					if(c < 1){
						$(el).css('transform', 'translate(-'+(d-ddd)+'px, -'+dd+'px) scale('+c+')');
					}else if(c > 1){
						dd = Math.round(el.clientHeight*(c-1)*50)/100;
						$(el).css('transform', 'translate(0px, '+dd+'px) scale('+c+')');
					}else{
						$(el).css('transform', 'scale('+c+')');
					}
				}
				if(!window['resizelistener'+id]){
					window['resizelistener'+id] = true;
					// window.addEventListener('resize', () => {
					// 	// detect window rotation and refresh
					// 	// console.log('resize');
					// 	that.resizeToScreen(id);
					// });
					let lastOrient = null;
					window.addEventListener('orientationchange', function(e){
						that.resizeToScreen(id);
						if(e.target.screen.orientation.angle == 0)
							window.location.reload();
						if(Math.abs(e.target.screen.orientation.angle)==90)
							window.location.reload();
						if(lastOrient != null){
							if(Math.abs(lastOrient) == 90 && Math.abs(e.target.screen.orientation.angle) != 90)
								window.location.reload();
							if(Math.abs(lastOrient) != 90 && Math.abs(e.target.screen.orientation.angle) == 90)
								window.location.reload();
						}
						lastOrient = e.target.screen.orientation.angle;
					});
				}
			}catch(e){
				console.error(e);
			}
		},
		getTheme(theme,customTheme){
            if(customTheme)
                this.$store.dispatch('setCustomTheme', [theme, customTheme]);
            else
                this.$store.dispatch('setDefaultTheme', theme);
        },
		async getMeasurements(){
			try{
				let measurementRes;
				if(this.site)
					measurementRes = await client.robustQuery(`/api/kiosk/${this.kioskId}/measurements`);
				else
					measurementRes  = await client.robustQuery('/api/measurements', {siteId: this.siteId, zoneId: this.zoneId});
				return measurementRes;
			} catch (error) {
				console.error(error);
				/*
				setTimeout(function() {
					window.location.href = '/login'
				}, 250);
				*/
			}
		},
		async loadSite() {
			try {
				/*eslint no-undef: "warn"*/
				this.resizeToScreen('kiosk');
				let components = ['spacetemperature','relativehumidity', 'co2', 'infectiontransmissibility', 'timeincomfortzone','ionlevel',
				'ionizerstatus', 'ionizationtime', 'pm10', 'carbonmonoxide', 'ozone', 'pm2.5', 'scoreaf', 'scoreaq','scoreow','equipment'];
				let measurementsRes = await this.getMeasurements();
				for(let c of components){
					for(let m of measurementsRes.results){
						if(this.measurements.length >= 6)
							break;
						if(m.type.toLowerCase() == c)
							this.measurements.push(m);
					}
				}
			} catch (error) {
				window.M.toast({html: 'Cannot Load Site'})
			} finally {
				//setTimeout(() => {window.M.AutoInit()}, 100);
			}
		}
	},
	mounted() {
		let theme = this.$store.state.theme;
		let customTheme = this.$store.state.project?.themes;
        this.getTheme(theme,customTheme);
		this.loadSite();
	},
	created(){
		setInterval(this.getCurrentTime, 333);
	},
	updated(){
		this.resizeToScreen('kiosk');
	},
	computed: {
		getMeasurementDisName(){
			let scoreNames = {
				scoreAQ: "Current Air Quality",
				scoreOW: "Occupant Comfort",
				scoreOA: "Overall"
			}
			return (m) => {
				if(scoreNames[m.type]){
					return scoreNames[m.type]
				} else {
					return m.name
				}
			}
		}
	}
};
</script>

<style lang="scss">
	.embed_documentation_footer--documentationFooter--2gpG4 {
		display: none;
	}
</style>
<style lang="scss" scoped>
	$airquality: var(--airquality);
	$co2: var(--co);
	$humidity: var(--rh);
	$pollutant: var(--plt);
	$temperature: var(--spt);
	$ventilation: var(--vt);
	$infectiontransmissibility: var(--plt);
	$equipment: var(--spt);

	@keyframes kf1 {
		0%,100% { opacity: 1; }
	}
	@keyframes kf2 {
		50% { opacity: 1; }
		80% { opacity: 0; }
	}
	@keyframes kf3 {
		33% { opacity: 1; }
		53% { opacity: 0; }
	}
	@keyframes kf4 {
		25% { opacity: 1; }
		45% { opacity: 0; }
	}
	@keyframes kf5 {
        10% { opacity: 1; }
		20% { opacity: 1; }
        30% { opacity: 0; }
	}
	@keyframes kf6 {
        7% { opacity: 1; }
		17% { opacity: 1; }
		27% { opacity: 0; }
	}
	
	#kiosk-wrapper {
		min-height: 100vh;
		width: 100vw;
		background-color:#000;
		&.small {
			height: 265px;
			width: unset;
			background-color: unset;
		}
	}

	#kiosk,
	#k-carousel,
	#k-cards {
		height: 100vh;
		max-height: 840px;
		min-height: 840px;
	}

	#k-carousel,
	#k-cards {
		float: left;
	}

	#kiosk {
		background: var(--app-secondary-bg);
		color: var(--app-primary-txt);
		font-family: 'Roboto', sans-serif;
		font-weight: 400;
		margin: 0 auto;
		max-width: 1280px;
		min-height: 840px;
		min-width: 1280px;
		width: 100%;
		-webkit-font-smoothing: antialiased;
		overflow: hidden;
		&.small {
			position: relative;
			top: -280px;
			left: -350px;
			transform: scale(.45) !important;
			margin-top: 50px;
			border-radius: 40px;
		}
	}

	#k-learn-more {
		position: absolute;
		right: 10px;
		top: 10px;
		z-index: 1;
	}

	#k-carousel {
		position: relative;
		width: 40.78%;

		.carousel-slide {
			padding: 80px 64px 0;
			left: 0;
			top: 0;
			opacity: 0;
			position: absolute;
			width: 100%;
			height: 100%;

			.k-slide-time {
				font-size: 1.6rem;
				height: 48%;
				opacity: .7;
			}

			.k-slide-measurement {
				.k-slide-measurement-icon {
					position: absolute;
					top: 405px;
					font-size: 1rem;
				}
				.k-slide-measurement-curVal {
					position: absolute;
					top: 481px;
					font-size: 8rem;
					font-weight: 300;
					line-height: 8rem;
					&.small {
						font-size: 6rem;
					}
					span {
						font-size: 2.133rem;
						opacity: .7;
					}
				}
				.k-slide-measurement-name {
					position: absolute;
					top: 625px;
					font-size: 2.133rem;
					line-height: 2.933rem;
				}
				.k-slide-measurement-progress {
					position: absolute;
					top: 677px;
					font-size: 1.6rem;
					line-height: 2.133rem;
					opacity: .7;
				}
			}

			.k-slide-counts {
				position: absolute;
				top: 750px;
				height: 10px;

				.k-slide-count {
					background: #fff;
					border-radius: 50%;
					float: left;
					height: 10px;
					opacity: .4;
					margin-right: 12px;
					width: 10px;

					&.active { opacity: 1; }
				}
			}

			&.slide-airquality ,
			&.slide-co2 {
				color: #25294A;
				.k-slide-count {
					background: #25294A;
				}
			}

			// transitions / bg colors
			&.slide-count-1 { animation: kf1 10s infinite; }
			&.slide-count-2 { animation: kf2 20s infinite; }
			&.slide-count-3 { animation: kf3 30s infinite; }
			&.slide-count-4 { animation: kf4 40s infinite; }
			&.slide-count-5 { animation: kf5 50s infinite; }
			&.slide-count-6 { animation: kf6 60s infinite; }

			&.slide-spacetemperature { background: $temperature; color: white; }
			&.slide-relativehumidity { background: $humidity; color: white; }
			&.slide-airquality { background: $airquality; }
			&.slide-ventilation { background: $ventilation; color: white; }
			&.slide-co2 { background: $co2; }
			&.slide-pollutant { background: $pollutant; color: white; }
			&.slide- { background: #1D213C; }
			&.slide-timeincomfortzone {background: $humidity; color: white; }
			&.slide-infectiontransmissibility { background: $infectiontransmissibility; }
			&.slide-equipment { background: $equipment; color: white; }
		}
	}

	#k-cards {
		background-color: var(--kiosk-bg);
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		font-weight: 400;
		padding: 32px;
		width: 59.22%;

		.k-card {
			flex: 1 50%;
			padding: 16px;
			max-width: 50%;
			max-height: 258px;

			.k-card-measurement {
				background: var(--kiosk-card-bg);
				border-radius: 20px;
				min-width: 315px;
				min-height: 226px;
				padding: 16px 24px 0 32px;
				box-shadow: var(--box-shadow);

				&.spacetemperature { background: var(--spt2); }
				&.relativehumidity { background: var(--rh2) }
				&.airquality { background: var(--airquality2) }
				&.ventilation { background: var(--vt2); }
				&.co2 { background: var(--co2); }
				&.pollutant { background: var(--plt2); }
				&.timeincomfortzone {background: var(--rh2)}
				&.infectiontransmissibility { background: var(--plt2) }
				&.equipment { background: var(--spt2);}

				.k-card-measurement-progress {
					color: #E8912B;
					font-size: 1.2rem;
					line-height: 2rem;
					text-align: right;

					.progress-excellent { color: #02C39A; }
				}
				.k-card-measurement-icon {
					height: 40px;
					width: 40px;
					margin-bottom: 18px;
				}
				.k-card-measurement-curVal {
					font-size: 3.2rem;
					font-weight: 300;
					line-height: 3.3rem;
					
					span {
						color: var(--app-primary-txt);
						font-size: 1.2rem;
						margin-left: 5px;
					}
				}
				.k-card-measurement-name {
					font-size: 1.65rem;
					line-height: 2rem;
					&.small {
						font-size: 1.45rem;
					}
				}

				&.msmt-alarm {
					color: #fff;
					.k-card-measurement-progress {
						color: #fff;
						.progress-excellent { color: #fff; }
					}
				}
				&.msmt-warning {background: var(--score-yellow-color);}
				&.msmt-critical {background: var(--score-red-color);}
			}
		}
	}
</style>