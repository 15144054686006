<template lang="pug">
    div.progressIndicator(v-bind:id="id" v-bind:style="width" v-bind:class="cssClass")
        svg(viewBox="0 0 36 36")
            path(d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" fill="none"  stroke-width="4" :stroke="cs" style="stroke-opacity: .09;")
            path(d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831",fill="none", v-bind:stroke="cs", stroke-width="4",v-bind:stroke-dasharray="value+',100'" stroke-linecap="round")
            text(v-bind:x="textwidth" y="23.5" fill="var(--app-primary-txt)" font-size="14px" font-weight="300" v-show="!hideText===true") {{val}}
</template>

<script>
export default {
    name: 'DonutIndicator',
    props: {
        id: String,
        cssClass: String,
        value: Number,
        size: Number,
        hideText: Boolean
    },
    data(){
        let width = 'width:'+this.size+'px;height:'+this.size+'px;'
        return {
            width,
        }
    },
    computed:{
        cs(){
            let themes = this.$store.state.themes;
            let cs = this.value < 80 ? '#FDE22B' : '#2196F3';
            if(themes){
                if(this.value >= 80 ){
                    cs = themes.ex
                } else if(this.value >= 50 && this.value < 80){
                    cs = themes.mo;
                } else {
                    cs = themes.poor;
                }
            }
            return cs
        },
        val(){
            return isNaN(this.value) ? '' : this.value
        },
        textwidth(){
            return this.value.toString().length === 1 ? '14' : this.value.toString().length === 2 ? '10' : '6';
        }
    }
}
</script>