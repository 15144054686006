<template lang="pug">
    #heatMaps
        main(v-if="site")
            Navbar(tab="heatmaps" :siteId="siteId" :site="site")
            main(v-if="site")
                v-container(v-if="ready")
                    v-row.top-header
                        .col.s12.site-info
                            .site-image(:style='"background-image:url(" + (site.logo ? site.logo.url.replace("http", "https") : "") + ")"')
                            //- .site-progress
                            //- 	a.modal-trigger(v-if="site.score" href="#modal-score-breakdown" style="color:var(--app-tertiary-txt)")
                            //- 		DonutIndicator(:key="site.objectId+'-pt-donut'" :id="site.objectId +'-scoreOA'" :cssClass="'main-donut'" :value="Math.round(site.scoreOA.curScore)" :size="60")
                            //- 	DonutIndicator(v-else :key="site.objectId+'-pt-donut'" :id="site.objectId +'-scoreOA'" :cssClass="'main-donut'" :value="Math.round(site.scoreOA.curScore)" :size="60")
                            .site-title {{site.name}} Heat Map 
                    v-row
                        v-col(:cols="12" :sm="12" :md="2")
                            v-select.haq-text-field(:items="types" v-model="type" disabled)
                        v-col(:cols="12" :sm="12" :md="2")
                            v-select.haq-text-field(:items="timePeriodOptions" v-model="timePeriod" item-text="text" item-value="value" @change="loadHeadMap")
                        v-col(:cols="12" :sm="12" :md="2")
                            v-select.haq-text-field(:items="measurementTypes" v-model="measurementType" item-text="text" item-value="value" @change="loadHeadMap")
                    v-row.mt-4(v-if="heatMapLoading")
                        v-col(:cols="12").d-flex.justify-center
                            v-progress-circular(indeterminate color="primary")
                    v-row.mt-4
                        v-col(:cols="12" v-if="chartOptions!=null")
                            HeatMapChart(:HeatMapObject="chartOptions" :isAlert="type=='Alerts'" :zones="zones" :timePeriod="timePeriod")


</template>
<style lang="scss" scoped>
    .top-header {
		margin-top: 40px;
		margin-bottom: 0;
		.site-info {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			.site-image {
				border-radius: 20px;
				width: 120px;
				height: 80px;
				min-width: 120px;
				min-height: 80px;
				background-size: cover;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				vertical-align: top;
				margin-right: 2rem;
			}
			.site-progress {
				margin-right: 1.5rem;
			}			
			.site-title {
				color: var(--app-primary-txt);
				font-size: 28px;
				.siteLink {
					color: var(--app-primary-txt);
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
</style>
<script>
import { useGetSite } from '../assets/js/hawken-query'
import Navbar from '@/components/Navbar.vue';
import { HTTPClient } from '../assets/js/http'
import HeatMapChart from '../components/util/HeatMapChart.vue';

import moment from 'moment';
let client = new HTTPClient();
export default {
    name: "HeatMap",
    components: {
        Navbar,
        HeatMapChart
    },
    data(){
        return {
            siteId: this.$route.params.siteId,
            site: null,
            heatMapLoading: false,
            zones: [],
            type: "Alerts",
            chartOptions: null,
            types: ["Alerts", "Measurements"],
            measurementTypes: [],
            measurementType: 'tamperAlert',
            timePeriodOptions: [
                {
                    text: "Today",
                    value: "today"
                },
                {
                    text: "This Week",
                    value: "week"
                },
                {
                    text: "This Month",
                    value: "month"
                },
                // {
                //     text: "Custom",
                //     value: "custom",
                //     disabled: true
                // }
                // {
                //     text: "Custom",
                //     value: "custom"
                // }
            ],
            timePeriod: "today",
            ready: false,
        }
    },
    methods: {
        async loadSite(){
            try {
                this.site = await useGetSite(this.siteId);
                let zoneRes = await client.robustQuery("/api/zones", {
                    siteId: this.siteId,
                    withMeasurements: false
                })

                let measurementTypesRes = await client.robustQuery(`/api/sites/${this.siteId}/heatmap/measurements`)
                this.measurementTypes = this.generateMeasurementTypeOptions(measurementTypesRes.results);
                this.measurementType = this.measurementTypes[0].value;
                // console.log("measurementTypes", this.measurementTypes)
                this.zones = zoneRes.results;
                this.ready = true;
                this.loadHeadMap();
            } catch (error) {
                console.error(error);
                window.M.toast({html: "Error loading site"})
            }
        },
        async loadHeadMap(){
            this.chartOptions = null;
            try {
               this.heatMapLoading = true;
               let heatmapObject;
               heatmapObject = await client.robustQuery(`/api/sites/${this.siteId}/heatmap/alerts`, {
                    type: this.type.toLowerCase(),
                    measurementType: this.measurementType,
                    timePeriod: this.timePeriod,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                });
                this.chartOptions = heatmapObject.results;
                this.heatMapObjectAvailable = true;
            } catch (error) {
                console.error(error);
                window.M.toast({html: "Error loading Heatmap"})
            } finally {
                this.heatMapLoading = false;
            }
        },
        generateMeasurementTypeOptions(types){
            let options = []
            let typeObject = {
                "co2" : "CO2",
                "carbonDioxide" : "CO2",
                "carbonMonoxide" : "CO",
                "spaceTemperature" : "Temperature",
                "relativeHumidity" : "Relative Humidity",
                "humidity" : "Relative Humidity",
                "scoreOA": "Overall Score",
                "scoreAQ": "Air Quality Score",
                "co2sensor": "CO2",
                "pm1": "PM1",
                "pm2.5": "PM2.5",
                "pm10": "PM10",
                "pm1sensor": "PM1",
                "pm2.5sensor": "PM2.5",
                "pm10sensor": "PM10",
                "voc": "VOC",
                "vocsensor": "VOC",
                "temperature": "Temperature",
                "nitrate": "Nitrate",
                "nitrogenDioxide": "Nitrogen Dioxide",
                "ozone": "Ozone",
                "airPressure": "Air Pressure",
                "lightLevel": "Light Level",
                "soundLevel": "Sound Level",
                "gunshotAlert": "Gunshot Alert",
                "helpAlert": "Help Alert",
                "motionAlert": "Motion Alert",
                "thcAlert": "THC Alert",
                "tamperAlert": "Tamper Alert",
                "aggressionAlert": "Aggression Alert",
                "vapeAlert": "Vape Alert",
                "vapeMaskingAlert": "Vape Masking Alert",
            }
            let access = types;
            types = types.filter(t => access.includes(t));
        
            for(let t of types){
                if(typeObject[t]){
                    options.push({
                        text: typeObject[t],
                        value: t
                    })
                }
            }
            return options.sort((a,b) => a.text.localeCompare(b.text, undefined, {numeric: true, sensitivity: 'base'}))
        },
    },
    computed:{
        startDate(){
            if(this.timePeriod == "custom"){
                return moment(this.customStartDate).valueOf()
            }
            else {
                if(this.timePeriod == "today"){
                    return moment().startOf('day').valueOf()
                } else if (this.timePeriod == "week"){
                    return moment().startOf('week').valueOf()
                } else {
                    return moment().startOf("month").valueOf()
                }
            }
        },
        endDate(){
             if(this.timePeriod == "custom"){
                return moment(this.customEndDate).valueOf()
            }
            else {
               return moment().endOf('hour').valueOf();
            }
        }
    },
    mounted(){
        this.loadSite()
    }
}
</script>