<template lang="pug">
    #settingsPage
        Navbar(tab="")
        v-container.settings
            v-row
                v-col
                    .desktop-tabsss.d-none.d-sm-flex
                        v-tabs(v-model="tab" vertical)
                            h2.settings-title Settings
                            v-tab
                                v-icon(left) mdi-account
                                | Account
                            v-tab
                                v-icon(left) mdi-lock
                                | Security & Login
                            v-tab-item
                                AccountSettings()
                            v-tab-item
                                PasswordSettings()
                    
                    .mobile-tabsss.d-sm-none
                        h2.settings-title Settings
                        v-tabs(v-model="tab")
                            v-tab
                                v-icon(left) mdi-account
                                | Account
                            v-tab
                                v-icon(left) mdi-lock
                                | Security & Login
                            v-tab-item
                                AccountSettings()
                            v-tab-item
                                PasswordSettings()

</template>
<script>
import Navbar from '@/components/Navbar.vue';
import AccountSettings from '@/components/settings/Account.vue';
import PasswordSettings from '@/components/settings/Password.vue';

export default {
    name: 'Settings',
    components: {
        Navbar,
        AccountSettings,
        PasswordSettings,
    },
    data() {
        return {
            // tab: this.$route.query.tab,
            tab: null,
        };
    },
};
</script>
<style lang="sass">
    #settingsPage
        .v-tabs
            background: transparent
            .v-tabs-bar
                background: transparent
                margin-right: 15px
                .v-tab
                    justify-content: left
                    text-align: left
            .v-tabs-items
                background: var(--app-secondary-bg)
                border-radius: 20px
                box-shadow: var(--box-shadow)
                padding: 20px
        .mobile-tabsss
            .v-tabs-bar
                margin-bottom: 15px

</style>
<style lang="sass" scoped>
    #settingsPage
        .settings
            margin-top: 50px
            .settings-title
                color: var(--app-primary-txt)
                font-size: 28px
                margin: 0 0 15px
            

</style>
