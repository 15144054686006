import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Signup from '../views/Signup.vue'
import Login from '../views/Login.vue'
import LoginGoogle from '../views/LoginGoogle.vue'
import LoginGoogleInit from '../views/LoginGoogleInit.vue'
import store from '../store/index'
import Site from '../views/Site.vue'
import Onboarding from '../views/Onboarding.vue'
import Onboarding2 from '../views/Onboarding2.vue'
import Onboarding3 from '../views/Onboarding3.vue'
import Kiosks from '../views/Kiosks'
import Devices from '../views/Devices'
import Kiosk from '../views/Kiosk.vue'
import KioskBranded from '../views/KioskBranded.vue'
import KioskPublic from '../views/KioskPublic.vue'
import KioskDemo from '../views/KioskDemo.vue'
import Status from '../views/Status.vue'
import KitOnboarding from '../views/kitOnboarding/KitOnboarding'
import DeviceOnboarding from '../views/kitOnboarding/DeviceOnboarding'
import CommanderLogin from '../views/CommanderLogin'
import AcceptInvite from '../views/AcceptInvite'
import Settings from '../views/Settings'
import Reports from '../views/Reports'
import HeatMap from '../views/HeatMaps'
// Site 

Vue.use(VueRouter)

  const routes = [
  {
    path: '/status/cps',
    name: 'Status',
    component: Status,
    meta: { 
      requiresAuth: false 
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId',
    name: 'Project',
    component: Home,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/sites/:siteId',
    name: 'Site',
    component: Site,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/sites/:siteId/floors',
    name: "FloorPlan",
    component: () => import(/* webpackChunkName: "floorplan" */ '../views/FloorPlan.vue'),
    meta: { 
        requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/sites/:siteId/heatmap',
    name: "Heatmap",
    component: () => import(/* webpackChunkName: "heatmap" */ '../views/HeatMaps.vue'),
    meta: {
        requiresAuth: true
    }
  },
  {
    path: "/projects/:projectId/sites/:siteId/alerts",
    name: "AlertsPage",
    component: () => import(/* webpackChunkName: "alerts" */ '../views/AlertsPage.vue'),
    meta: {
        requiresAuth: true
    }
  },
  {
    path: '/sites',
    // name: 'Site',
    component: Site,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/sites/:siteId/zones/:zoneId',
    name: 'Zone',
    component: Site,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: Signup,
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/acceptinvite',
    name: 'AcceptInvite',
    component: AcceptInvite,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/login/google',
    name: 'Google Login',
    component: LoginGoogle,
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/login/cps',
    name: 'Google Login Init',
    component: LoginGoogleInit,
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: Onboarding,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/onboarding2',
    name: 'Onboarding2',
    component: Onboarding2,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/onboarding3',
    name: 'Onboarding3',
    component: Onboarding3,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/kiosk',
    name: 'Kiosk',
    component: Kiosk,
    meta: {
      specialAuth: true // Special Auth is for shareable kiosk where if a kiosk is included it bypasses logged in state provided it has a kiosk id
    }
  },
  {
    path: '/kioskbranded',
    name: 'Kiosk Branded',
    component: KioskBranded,
    meta: {
      specialAuth: true,
    }
  },
  {
    path: '/kiosks/:id',
    name: "Kiosk Public",
    component: KioskPublic,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/kiosks',
    name: "Kiosks",
    component: Kiosks,
    meta: {
      requiresAuth: true 
    }
  },
  {
    path: '/devices',
    name: "Devices",
    component: Devices,
    meta: {
      requiresAuth: true 
    }
  },
  {
    path: '/kioskdemo',
    name: 'Kiosk Demo',
    component: KioskDemo,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/konboarding/:id',
    name: 'Kit Onboarding',
    component: KitOnboarding,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/do/:id',
    name: 'Device Onboarding',
    component: DeviceOnboarding,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/commanderLogin',
    name: 'CommanderLogin',
    component: CommanderLogin,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true
    }
  },
  {
      path: "/projects/:projectId/sites/:siteId/reports",
      name: "Reports",
      meta: {
          requiresAuth: true
      },
      component: Reports
  },
  {
    path: '/projects/:projectId/sites/:siteId/schedules',
    meta: {
        requiresAuth: true
    },
    name: "SchedulesPage",
    component: () => import(/* webpackChunkName: "schedules" */ '../views/SchedulesPage.vue'),
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
});


router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
    } else {
      next({name: 'Login', query: {from: window.location.pathname}}) 
    }
  } else {
    next() 
  }

  if(to.matched.some(record => record.meta.specialAuth)) {
    if (store.getters.isLoggedIn) {
      next()
    } else {
      if(to.query.kioskId){
        next();
      } else {
        next({name: 'Login', query: {from: window.location.pathname}}) 
      }
    }
  } else {
    next() 
  }
})

export default router