<template lang="pug">
    .parentWindow
        .contents
            .infoWindow
                img(:src="site.logo.url").siteLogo
                .title 
                    span.site-title {{site.name}}
                    .alert-indicator
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_4683_23986)">
                            <path d="M3.75039 14.25H14.2504C14.4951 14.2483 14.7357 14.1867 14.9512 14.0707C15.1666 13.9546 15.3504 13.7876 15.4865 13.5842C15.6226 13.3808 15.7069 13.1472 15.732 12.9037C15.757 12.6603 15.7222 12.4144 15.6304 12.1875L10.3054 3.00001C10.1757 2.76556 9.98552 2.57013 9.7547 2.43405C9.52389 2.29797 9.26083 2.2262 8.99289 2.2262C8.72494 2.2262 8.46189 2.29797 8.23107 2.43405C8.00026 2.57013 7.8101 2.76556 7.68039 3.00001L2.35539 12.1875C2.26534 12.4092 2.22964 12.6492 2.25125 12.8875C2.27286 13.1258 2.35117 13.3555 2.47963 13.5573C2.60808 13.7592 2.78297 13.9274 2.98968 14.0479C3.19639 14.1684 3.42893 14.2377 3.66789 14.25" stroke="#021449" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M9 6V10" stroke="#021449" stroke-width="1.5"/>
                            <path d="M9 12.5V11" stroke="#021449" stroke-width="1.5"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_4683_23986">
                            <rect width="18" height="18" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                        span.alert-count {{site.activeAlertsCount||0}}
            .actionWindow
                a(:href="navLink")
                    .info-button.see-more 
                        | See More
                .info-button.close Close

</template>
<style lang="scss">
    .parentWindow {
        width: 408px;
        height: 100%;
        background: #4E7CFF;
        border-radius: 8px;
        padding: 16px 16px 0px 16px;
        .contents {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            .infoWindow {
                height: 60%;
                display: flex;
                gap: 16px;
                position: relative;
                border-bottom:  1px solid rgba(255, 255, 255, 0.3);
                .siteLogo {
                    width:  130.21px;
                    height: 88px;
                    border-radius: 12px;
                }
                .title {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    display: flex;
                    flex-direction: column;
                    line-height: 32px;
                    height: 100%;
                    margin-left: 10px;
                    .site-title {
                        color: #FFFFFF;
                        height: 58px;
                        line-height: 29px;
                        overflow-y: hidden;
                    }
                    .alert-indicator {
                        display: flex;
                        padding: 0 8px;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-evenly;
                        width: 64px;
                        height: 30px;
                        background: #FDE22B;
                        border-radius: 30px;
                        svg {
                            max-height: 18px;
                        }
                        .alert-count {
                            font-size: 16px;
                            color: #021449;
                        }
                    }
                }
            }
            .actionWindow {
                height: 40%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .info-button {
                    width: 180px;
                    cursor: pointer;
                    height: 40px;
                    border: 1px solid #FFFFFF;
                    border-radius: 12px;
                    font-weight: 400;
                    font-size: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &.see-more {
                        background: white;
                        a {
                            color: #021449 !important;
                        }
                    }
                    &.close {
                        color: white;
                    }
                }
              
            }
        }
    }
</style>
<script>
export default {
    name: "AlarmInfoWindow",
    props: {
        site: Object
    },
    data(){
        return {
            navLink: `/projects/${this.site.projectId}/sites/${this.site.objectId}`
        }
    },
    mounted(){
    }
}
</script>