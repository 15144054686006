<template lang="pug">
    #floorPlanPage
        div(v-if="ready")
            v-row(style="width:100%").mb-2
                v-col(:cols="12" :md="3" :sm="12")
                    .vuetify
                        v-select.haq-text-field(v-model="selectedFloorId" :items="floors" item-text="name" item-value="objectId" label="Select Floor" hide-details v-on:change="changeFloor")
                v-col(:cols="12" :md="3" :sm="12")
                    .vuetify
                        v-select.haq-text-field(v-model="selectedMeasurement" :items="measurementOptions" item-text="name" item-value="type" label="Select Measurement" hide-details)
            v-row
                v-col(:cols="12")
                    .aqcard.no-gradient
                        .aqcard-header(style="padding-left:1.5rem")
                            .aqcard-title
                                span {{currentFloor.name}}
                        .aqcard-body(style="overflow:hidden" ref="canvasContainer").d-flex.justify-center.floorPlanCard
                            canvas(ref="floorPlanCanvas")

</template>
<style lang="sass" scoped>
    .canvas-container
        overflow: hidden !important
    canvas
        width: 100%
        overflow: hidden
    .floorPlanCard
        background-color: var(--app-primary-bg)
        padding: 0
        margin: 1.5rem
        margin-top: 0
        width: calc(100% - 3rem)
    .no-flex
        display: block
    .v-app
        background: transparent
        .v-application--wrap
            display: block
    .modal-trigger
        &:hover
            .main-donut
                background: rgba(255, 255, 255, 0.1)
                -webkit-box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.2)
                box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.2)
                border-radius: 50%
    .siteLink
        color: var(--app-primary-txt)
        font-size: 28px
        line-height: inherit
        &:hover
            text-decoration: underline
    .date-range
        color: var(--app-tertiary-txt)
        text-align: right
        opacity: 1
</style>
<script>
import {HTTPClient} from '../assets/js/http';
import Navbar from '@/components/Navbar.vue';
import DonutIndicator from '@/components/DonutIndicator.vue';
import { useGetSite } from '../assets/js/hawken-query';
import { icons } from "../components/floorplans/measurementIcons"
let client = new HTTPClient();
import { fabric } from 'fabric';
export default {
    name: "FloorPlanView",
    components: {
        Navbar,
        DonutIndicator,
    },
    data(){
        return {
            floors: [],
            currentFloor: null,
            currentZones : [],
            currentFloorMeasurements: [],
            selectedFloorId: null,
            selectedMeasurement: null,
            measurementOptions: [],
            canvas: null,
            canvasTextBoxes: [],
            canvasAvailable: false,
            canvasZones: [],
            canvasIcons: [],
            site: null,
            zoneId: null, // there is no zone concept in floor plan
            ready: false,
            siteId: this.$route.params.siteId,
            scoreHelp: {
				overallCurScore: null,
				measurements: null,
				key: null,
			},
        }
    },
    methods: {
        getDistance(longerLineLength, shorterLineLength) {
            const center = longerLineLength / 2; // Get the horizontal center of the longer line
            const start = center - shorterLineLength / 2; // Get the horizontal start of the centered line
            const distance = start; // The distance from the start of the longer line to the start of the centered line
            return distance;
        },
        measureTextDimensions(text, fontFamily, fontSize) {
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');
                ctx.font = fontSize + 'px ' + fontFamily;
                var width = ctx.measureText(text).width;
                var height = fontSize;  // assume one line of text
                let obj =  {
                    width: width,
                    height: height
                };
                canvas.remove();
                return obj;
        },
        getFontSizeAndText(text, width){
            let scale = width / 140;
            let fontSize = Math.min(16 * scale, 16);
            return fontSize;
        },
        async generateLayers(){
            try {
                // do something
                if(!Array.isArray(this.canvasZones) || this.canvasZones.length === 0) return;
                for(let icon of this.canvasIcons){
                    this.canvas.remove(icon);
                }
                for(let textBox of this.canvasTextBoxes){
                    this.canvas.remove(textBox);
                }
                for(let zone of this.canvasZones){
                    let { results } = await client.robustQuery(`/api/measurements?siteId=${this.siteId}&zoneId=${zone.id}&accessFilter=true&forFloorPlan=true`);
                    if(this.site.aqEnabled === false){
                         results = results.filter(function(m){
                            return m.type.indexOf('score') < 0
                         })
                    }
                    if(this.selectedMeasurement == null) {
                        this.selectedMeasurement = results[0].type
                        console.log(`Setting default measurement ${this.selectedMeasurement}`, results)
                    }
                    // console.log(results, ' zone canvas measurements');
                    if(results.length > 0){
                        let measurement = results.find(m => m.type === this.selectedMeasurement);
                        if(measurement == null) continue;
                        let color = "rgb(78,124,255, 0.9)"
                        if(measurement.curScore < 75){
                            color = "rgb(254,202,18, 0.9)"
                        }
                        if(measurement.curScore <= 50){
                            color = "rgb(250,95,95, 0.9)"
                        }
                        zone.set('fill', color)
                        let fontSize = this.getFontSizeAndText(zone.name, zone.width);
                        let textBox = new fabric.Textbox(zone.name, {
                            left: zone.left,
                            top: zone.top + (zone.height / 2) - (fontSize + (fontSize/2)),
                            width: zone.width,
                            height: zone.height / 2,
                            fontSize,
                            id: zone.id,
                            textAlign: 'center',
                            fill: 'white',
                            fontFamily: 'Roboto',
                            selectable: false,
                            hoverCursor: 'pointer',
                        });
                        // clicking should navigate to the zone
                        textBox.on('mouseup', (e) => {
                            this.$router.push({
                                name: 'Zone',
                                params: {
                                    zoneId: e.target.id
                                }
                            });
                            window.location.reload();
                        });
                        let displayValue = 0;
                        if(['scoreAQ', 'scoreOA'].includes(measurement.type)){
                            displayValue = measurement.curScore;
                        } else {
                            // console.log(measurement.childPoints)
                            displayValue = measurement.childPoints[0].curVal
                        }
                        // console.log(measurement, displayValue);
                        console.log('zone', zone, displayValue);

                        if(displayValue !== undefined){
                            // round to at most 1 decimal place
                            // if displayValue is a number, round it to 1 decimal place
                            if(!isNaN(displayValue)) displayValue = Math.round(displayValue * 10) / 10;
                            // if it is an msv or a boolean, use activeText/inactiveText, otherwise just use the value
                            if((measurement.kind === 'String' || measurement.kind === 'Number') && measurement.multiStateValues){
                                if(measurement.multiStateValues[displayValue]) displayValue = measurement.multiStateValues[displayValue];
                            }else if(measurement.kind === 'Bool' && measurement.activeText){
                                // coerce displayValue to a boolean
                                if(typeof displayValue === 'string') displayValue = displayValue.toLowerCase();
                                if(displayValue === 'true' || displayValue === '1' || displayValue === 1 || displayValue === true) displayValue = true;
                                else displayValue = false;
                                displayValue = displayValue ? measurement.activeText : measurement.inactiveText;
                            }

                            displayValue = displayValue.toString() + (measurement.unit || "");
                        }else{
                            displayValue = "N/A";
                        }
                        let fontSizeValueText = this.getFontSizeAndText(displayValue, zone.width);
                        let valueText = new fabric.Textbox(displayValue, {
                            left: zone.left,
                            top: zone.top + ((zone.height / 2) + (fontSize/2)),
                            width: zone.width,
                            height: zone.height / 2,
                            fontSize,
                            id: zone.id,
                            textAlign: 'center',
                            fill: 'white',
                            fontFamily: 'Roboto',
                            selectable: false,
                            hoverCursor: 'pointer',
                        });
                        // clicking should navigate to the zone
                        valueText.on('mouseup', (e) => {
                            this.$router.push({
                                name: 'Zone',
                                params: {
                                    zoneId: e.target.id,
                                }
                            });
                            window.location.reload();
                        });
                        const {width: valueTextWidth} = this.measureTextDimensions(displayValue, 'Roboto', fontSize);
                        var icon = new fabric.Path(icons[measurement.type], {
                            left: zone.left + 5,
                            top:  zone.top + 5,
                            width: fontSize,
                            height: fontSize,
                            id: zone.id,
                            selectable: false,
                            fill: 'white',
                            hoverCursor: 'pointer',
                        });
                        // clicking should navigate to the zone
                        icon.on('mouseup', (e) => {
                            this.$router.push({
                                name: 'Zone',
                                params: {
                                    zoneId: e.target.id,
                                }
                            });
                            window.location.reload();
                        });
                        this.canvasIcons.push(icon);
                        this.canvasTextBoxes.push(textBox);
                        this.canvasTextBoxes.push(valueText);
                        this.canvas.add(textBox);
                        this.canvas.add(valueText);
                        this.canvas.add(icon);

                    }
                }
                this.canvas.renderAll();
            } catch (error) {
                console.error(error);
            }
        },
        async generateManual(){
            try {
                // do something
                // let r1 = new fabric.Rect({
                //     left: 690,
                //     top: 171,
                //     width: 100,
                //     height: 100,
                //     fill: 'red',
                //     selectable: false,
                // })
                // this.canvas.add(r1);
                // console.log('r1', r1)
                // this.canvas.renderAll();

                let hbUanp1i6H0phzHoguKj6WR1 = {
                    "hbUanp1i6H0phzHoguKj6WR1": {
                        "name": "Boys Bathroom",
                        "x": 1024,
                        "y": 185,
                        "w": 187,
                        "h": 96
                    },
                    "idTJ3hxMQkWCSypjroIrz5vR": {
                        "name": "Girls Bathroom",
                        "x": 208,
                        "y": 171,
                        "w": 116,
                        "h": 110
                    },
                    "KvHMFbBvJCu1orPUt5RJr20w": {
                        "name": "TCL",
                        "x": 690,
                        "y": 171,
                        "w": 100,
                        "h": 100

                    }
                }
            } catch (error) {
                console.error(error);
            }
        },
        async loadCanvas(){
            this.canvas = null;
            // make it possible to drag the entire canvas
            var canvas = new fabric.Canvas(this.$refs.floorPlanCanvas, {
                // height: 700,
                height: 600,
                // width: 900,
                backgroundColor: "#031139",
                border: "1px solid #1D213C",
                selection: false,
                
                // make it draggable
                // use hand pointer
                controlsAboveOverlay: true,
                defaultCursor: 'normal',
                hoverCursor: 'grab',
                hasControls: true,
                hasBorders: false,
                hasRotatingPoint: false,

            });

            // use mouse events to drag and pan the canvas
            canvas.on('mouse:down', (e) => {
                canvas.isDragging = true;
                canvas.selection = false;
                canvas.lastPosX = e.e.clientX;
                canvas.lastPosY = e.e.clientY;
            });
            canvas.on('mouse:move', (e) => {
                if (canvas.isDragging) {
                    var mEvent = e.e;
                    canvas.viewportTransform[4] += mEvent.clientX - canvas.lastPosX;
                    canvas.viewportTransform[5] += mEvent.clientY - canvas.lastPosY;
                    canvas.requestRenderAll();
                    canvas.lastPosX = mEvent.clientX;
                    canvas.lastPosY = mEvent.clientY;
                }
            });
            canvas.on('mouse:up', (e) => {
                canvas.isDragging = false;
                canvas.selection = true;
            });
            canvas.on('mouse:wheel', (opt) => {
                var delta = opt.e.deltaY;
                var zoom = canvas.getZoom();
                zoom *= 0.999 ** delta;
                if (zoom > 20) zoom = 20;
                if (zoom < 0.01) zoom = 0.01;
                canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
                opt.e.preventDefault();
                opt.e.stopPropagation();
            });
            // canvas.on('object:moving', (e) => {
            //     var obj = e.target;
            //     // if object is too big ignore
            //     if(obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width){
            //         return;
            //     }
            //     obj.setCoords();
            //     // top-left  corner
            //     if(obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0){
            //         obj.top = Math.max(obj.top, obj.top-obj.getBoundingRect().top);
            //         obj.left = Math.max(obj.left, obj.left-obj.getBoundingRect().left);
            //     }
            //     // bot-right corner
            //     if(obj.getBoundingRect().top + obj.getBoundingRect().height > obj.canvas.height || obj.getBoundingRect().left + obj.getBoundingRect().width > obj.canvas.width){
            //         obj.top = Math.min(obj.top, obj.canvas.height-obj.getBoundingRect().height+obj.top-obj.getBoundingRect().top);
            //         obj.left = Math.min(obj.left, obj.canvas.width-obj.getBoundingRect().width+obj.left-obj.getBoundingRect().left);
            //     }
            var width =  this.$refs.canvasContainer.clientWidth - 0;
            // canvas.setWidth(width);
            canvas.setWidth(1286);
            fabric.Image.fromURL(this.currentFloor.floorPlan.url, async (img) => {
                // console.log('imgURL', this.currentFloor.floorPlan.url);
                img.scaleToWidth(width);
                img.scaleToHeight(600);
                canvas.add(img);
                canvas.centerObject(img);
                img.set({
                    flipX: false,
                    flipY: false,
                })
                img.selectable = false;
                // console.log(this.currentFloor.zoneData);
                for(let k in this.currentFloor.zoneData){
                    let zone = this.currentFloor.zoneData[k];
                    // console.log(
                    //     'Adding zone with name: ', zone.name,
                    // );
                    let width = zone.w;
                    let height = zone.h;
                    let rect = new fabric.Rect({
                        width,
                        height,
                        id: k,
                        name: zone.name,
                        fill: 'transparent',
                        left: zone.x,
                        top: zone.y,
                        selectable: false,
                        hoverCursor: 'pointer',
                    });
                    // clicking the rect should navigate to the zone
                    rect.on('mouseup', (e) => {
                        this.$router.push({
                            name: 'Zone',
                            params: {
                                zoneId: e.target.id,
                            }
                        });
                        window.location.reload();
                    });

                    // var path = new fabric.Path(icons.spaceTemperature, {
                    //     left: rect.left + (rect.width / 2) - 10,
                    //     top: rect.top + (rect.height  - 25),
                    //     selectable: false,
                    // });

                    canvas.add(rect);
                    this.canvasZones.push(rect);
                    // canvas.on('mouse:wheel', function(event) {
                    // // get the current zoom level of the canvas
                    //     var currentZoom = canvas.getZoom();

                    //     // calculate the new zoom level based on the mouse wheel delta
                    //     var delta = event.e.deltaY;
                    //     var newZoom = currentZoom + delta / 200;

                    //     // set the new zoom level of the canvas
                    //     canvas.zoomToPoint({ x: event.e.offsetX, y: event.e.offsetY }, newZoom);

                    //     // prevent the default mouse wheel behavior (e.g. scrolling the page)
                    //     event.e.preventDefault();
                    //     event.e.stopPropagation();
                    // });
                    
                }
                this.canvas = canvas;
                this.canvasAvailable = true;
                this.generateLayers();
                this.generateManual();
            });
          
        },
        getName(type, name){
            if(type === 'scoreAQ'){
                return 'Air Quality';
            } else if(type == 'scoreOA'){
                return 'Overall'
            } else {
                return name;
            }
        },
        async loadFloor(){
            // console.log('loadFloor for ', this.currentFloor.name);
            try {
                 this.measurementOptions = []
                 for(let k in this.currentFloor.zoneData){
                    let zone = this.currentFloor.zoneData[k];
                    let { results: measurements } = await client.robustQuery(`/api/measurements?siteId=${this.siteId}&zoneId=${k}&accessFilter=true`);
                    this.currentFloorMeasurements.push(...measurements);
                    measurements.forEach((el) => {
                        if(!this.measurementOptions.find((m) => m.type === el.type)){
                            if(this.site.aqEnabled == false){
                                if(el.type?.includes("score")){
                                    return;
                                }
                            }
                            this.measurementOptions.push({
                                type: el.type,
                                name: this.getName(el.type, el.name),
                            })
                        }
                    })
                }
            
                this.$nextTick(() => {
                    this.loadCanvas();
                });
            } catch (error) {
                console.error(error);
            }
        },
        async loadFloors(){
            try {
                this.site = await useGetSite(this.siteId);
                const { results: floors } = await client.robustQuery(`/api/sites/${this.siteId}/floors`);
                this.floors = floors;
                this.currentFloor = floors?.[0];
                this.selectedFloorId = this.currentFloor.objectId;
                this.ready = true;
                this.loadFloor();
            } catch (error) {
                console.error(error);
                window.M.toast({html: "Cannot load floor plan"});
            }
        },
        changeFloor(){
            this.canvas.forEachObject((obj) => {
                // console.log('removing', obj);
                this.canvas.remove(obj);
            });
            this.canvas.dispose();
            this.canvasIcons = [];
            this.canvasZones = [];
            this.currentZones = [];
            this.canvasAvailable = false;
            this.currentFloor = this.floors.find((f) => f.objectId === this.selectedFloorId);
            this.$nextTick(() => {
                this.loadFloor();
            });
        }
    },
    mounted(){
        this.loadFloors();
    },
    watch: {
        selectedMeasurement(){
            this.generateLayers();
        }
    }
}
</script>