<template lang="pug">
    #navbar.elevation-4
        //- DESKTOP NAVIGATION
        nav.desktop-nav.hide-on-med-and-down
            section
                router-link(to='/', :class="brandImageTheme").logo
                    img(:src="$store.state.logo" :class="$store.state.defaultLogo ? 'default-logo' : '' " )
                ul.navigation
                    li(v-if="siteId" :class="tab == 'monitor' ? 'active' : ''")
                        a(:href="`/projects/${projectId}/sites/${siteId}`") Monitor
                    li(v-if="siteId && projectId && projectId != 'pwNeghwfDaNcSlOApelIYmA6'" :class="tab == 'kiosks' ? 'active' : ''")
                        router-link(:to="{path: '/kiosks', query: {siteId}}") Kiosks
                    li(v-if="((userRole === 'admin' || userRole === 'superAdmin') && siteId)" :class="tab == 'devices' ? 'active' : ''")
                        router-link(:to="{path: '/devices', query: {siteId}}") Devices
                    li(v-if="siteId && projectId" :class="tab == 'reports' ? 'active' : ''")
                        router-link(:to="{path: `/projects/${projectId}/sites/${siteId}/reports`}", ) Reports
                    li(v-if="siteId" :class="tab == 'alerts' ? 'active' : ''")
                        router-link(:to="{path: `/projects/${projectId}/sites/${siteId}/alerts`}") Alerts
                    li(v-if="siteId && canBeScheduled" :class="tab == 'schedules' ? 'active' : ''")
                        router-link(:to="{path: `/projects/${projectId}/sites/${siteId}/schedules`}") Schedules
                    li(v-if="siteId" :class="tab == 'heatmaps' ? 'active' : ''")
                        router-link(:to="{path: `/projects/${projectId}/sites/${siteId}/heatmap`}")
                            div(class="heatmap-icon")
                                span Heat Map
                                span(data-badge)
                                    v-badge.ml-2
                                        span(slot="badge") NEW
            section.user-section
                .nav-icons
                    .nav-icon
                        v-icon.theme-toggle(@click="toggleTheme") mdi-invert-colors
                    .nav-icon(v-if="scoreHelp")
                        v-icon.score-help(@click="activeDialog='scorehelp'") mdi-help-circle-outline
                    .nav-icon(v-if="siteId")
                        .haq-alerts
                            //- .alerts-notification(v-if="notifications" :class="notifications.cssClass")
                            v-icon.alerts(@click="activeDialog='alerts'" :class="notifications.cssClass") mdi-bell
                .v-divide
                .user-info(v-if="$store.state.user")
                    .user-name {{firstName}} {{lastName}}
                    .user-role {{userRole}}
                v-menu(content-class="desktop-user-settings" offset-y transition="slide-y-transition" bottom)
                    template(v-slot:activator="{ on, attrs }")
                        .avatar-wrapper(v-bind="attrs" v-on="on")
                            img.avatar(:src="profilePhoto ? profilePhoto : '/images/ssprofile.png'")
                    v-list
                        v-list-item.modal-trigger(href="#modal-project-select") Change Project
                        v-list-item(v-if="site && (site.commanderLicenseId || site.project.commanderLicenseId)" @click="openInCommander()") Open in Commander
                        v-list-item(v-if="userRole === 'admin' || userRole === 'superAdmin'" @click="openAdminDashboard") Open Portfolio Manager
                        v-list-item.h-divide
                        v-list-item(to='/settings')
                            v-icon mdi-cog
                            span Settings
                        v-list-item(href="/logout")
                            v-icon mdi-logout
                            span Log Out
        //- MOBILE NAVIGATION
        nav.mobile-nav.hide-on-large-only
            section
                router-link(to='/', :class="brandImageTheme").logo
                    img(:src="$store.state.logo" :class="$store.state.defaultLogo ? 'default-logo' : '' " )
            section
                .nav-icons
                    .nav-icon(v-if="scoreHelp")
                        v-icon.score-help(@click="activeDialog='scorehelp'") mdi-help-circle-outline
                    .nav-icon(v-if="siteId")
                        .haq-alerts
                            //- .alerts-notification(v-if="notifications" :class="notifications.cssClass")
                            v-icon.alerts(@click="activeDialog='alerts'" :class="notifications.cssClass") mdi-bell
                    .nav-icon
                        v-icon.mobile-drawer-trigger(@click.stop="drawer = !drawer") mdi-menu
        //- MOBILE DRAWER
        v-navigation-drawer.mobile-drawer(v-model="drawer" absolute temporary)
            template(v-slot:prepend)
                v-list-item.user-profile(two-line)
                    v-list-item-avatar.user-img(v-if="$store.state.user")
                        img(:src="profilePhoto ? profilePhoto : '/images/ssprofile.png'")
                    v-list-item-content.user-info(v-if="$store.state.user")
                        v-list-item-title.user-name {{firstName}} {{lastName}}
                        v-list-item-subtitle.user-role {{userRole}}
                    v-btn(icon @click.stop="drawer = !drawer")
                        v-icon mdi-chevron-left
            v-divider
            v-list(v-if="siteId")
                v-list-item(:class="tab == 'monitor' ? 'active' : ''" link)
                    router-link(:to="{name: 'Site', params: {projectId, siteId}}") Monitor
                v-list-item(v-if="siteId && projectId && projectId != 'pwNeghwfDaNcSlOApelIYmA6'" :class="tab == 'kiosks' ? 'active' : ''" link)
                    router-link(:to="{path: '/kiosks', query: {siteId}}") Kiosks
                v-list-item(v-if="((userRole === 'admin' || userRole === 'superAdmin') && siteId)" :class="tab == 'devices' ? 'active' : ''")
                    router-link(:to="{path: '/devices', query: {siteId}}") Devices
                v-list-item(v-if="siteId && projectId" :class="tab == 'reports' ? 'active' : ''")
                    router-link(:to="{path: '/reports', query: {siteId}}") Reports
                v-list-item(v-if="siteId" :class="tab == 'alerts' ? 'active' : ''")
                    router-link(:to="{path: `/projects/${projectId}/sites/${siteId}/alerts`}") Alerts
                v-list-item(v-if="siteId" :class="tab == 'heatmaps' ? 'active' : ''")
                    router-link(:to="{path: `/projects/${projectId}/sites/${siteId}/heatmap`}")
                        div(class="heatmap-icon")
                            span Heat Map
                            span(data-badge)
                                v-badge.ml-2
                                    span(slot="badge") NEW
            .h-divide(v-if="siteId")
            v-list
                v-list-item.modal-trigger(href="#modal-project-select") Change Project
                v-list-item(v-if="site && (site.commanderLicenseId || site.project.commanderLicenseId)" @click="openInCommander()") Open in Commander
                v-list-item(v-if="userRole === 'admin' || userRole === 'superAdmin'" @click="openAdminDashboard") Open Portfolio Manager
            template(v-slot:append)
                .h-divide
                v-list
                    v-list-item.theme-toggle(@click="toggleTheme")
                        v-list-item-icon
                            v-icon mdi-invert-colors
                        v-list-item-content
                            v-list-item-title {{currentTheme ? 'Disable Dark Thene' : 'Enable Dark Theme'}}
                .h-divide
                v-list.user-settings
                    v-list-item(to='/settings')
                        v-list-item-icon
                            v-icon mdi-cog
                        v-list-item-content
                            v-list-item-title Settings
                    v-list-item(href="/logout")
                        v-list-item-icon
                            v-icon mdi-logout
                        v-list-item-content
                            v-list-item-title Log Out

        Alerts(v-if="activeDialog == 'alerts'" :openModal="activeDialog" v-on:closeModal="closeModal" :alerts="notifications ? notifications.alerts : null")
        ScoreHelp(v-if="activeDialog == 'scorehelp'" :openModal="activeDialog" v-on:closeModal="closeModal" :overallCurScore="scoreHelp.overallCurScore" :measurements="scoreHelp.measurements")
        ModalProjectSelect
</template>
<style lang="scss" scoped>
    .heatmap-icon {
        position: relative
    }
</style>
<script>
import {wlData} from '../assets/js/constants';
import Alerts from "@/components/modals/Alerts";
import ScoreHelp from "@/components/modals/ScoreHelp";
import ModalProjectSelect from "@/components/ModalProjectSelect";
import {HTTPClient} from '../assets/js/http';

let client = new HTTPClient();
let timeCheck = true;
let runThemeCheckOnce = false;

export default {
    name: "Navbar",
    components: {
        Alerts,
        ScoreHelp,
        ModalProjectSelect,
    },
    props: {
        siteId: String,
        zoneId: String,
        tab: String,
        site: Object,
        scoreHelp: Object,
    },
    data(){
        let {brandImageTheme} = wlData;
        return {
            projectId: this.$store.state.project.objectId || this.$route.params.projectId,
            // siteId: this.$route.params.siteId,
            // zoneId: this.$route.params.zoneId,
            brandImageTheme,
            drawer: false,
            navItems: [],
            activeDialog: null,
        };
    },
    methods: {
        closeModal() {
			this.activeDialog = null;
		},
        openInCommander(){
            if(this.site.commanderLicenseId)
                window.open('https://app.kmccommander.com/setlicense/' + this.site.commanderLicenseId, "_blank")
            else
               window.open('https://app.kmccommander.com/setlicense/' + this.site.project.commanderLicenseId, "_blank")
        },
        openAdminDashboard(){
            let subdomain = location.host.split('.')[0];
            let domain = location.host.split('.')[1];
            let beta = subdomain.indexOf("beta") > -1 ? true : false;
            location = beta ? `https://betaadmin.${domain}.com/` : `https://admin.${domain}.com/`;
        },
        toggleTheme(){
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark
            let theme = this.$vuetify.theme.dark ? 'dark' : 'light';
            this.setTheme(theme);
        },
        setTheme(theme,customTheme){
            try{
                if(customTheme)
                    this.$store.dispatch('setCustomTheme', [theme, customTheme]);
                else if(this.projectThemes)
                    this.$store.dispatch('setCustomTheme', [theme, this.projectThemes]);
                else
                    this.$store.dispatch('setDefaultTheme', theme);

                client.robustPost('/api/theme', {theme}).catch(function(error) {
                    console.error(error);
                    window.M.toast({html : 'Cannot Save theme'});
                });
            }catch(error){
                console.error(error);
            }
        },
        async fetchAlerts(){
			try {
                if(!this.siteId) return;
                this.$store.dispatch('fetchAlerts', this.siteId);
			} catch (error) {
				console.error(error);
			}
		},
    },
    mounted() {
        try{
            this.navItems = [
                {
                    title: "Monitor",
                    // icon: "mdi-view-dashboard",
                    link: '/projects/' + this.projectId + '/sites/'+ this.siteId,
                },
                {
                    title: "Projects",
                    link: "/projects"
                }
            ];
            let theme = this.$store.state.theme;
            let customTheme = this.projectThemes;
            this.setTheme(theme,customTheme);
            this.fetchAlerts();

            if(!timeCheck){
                timeCheck = setInterval(() => {
                    // check for time change of theme.
                    // this only runs if the project is CPS and has light and dark themes
                    try{
                        if(this.projectId == 'T1Z1nsOdp2'){
                            let themeKeys = Object.keys(this.projectThemes);
                            if(themeKeys.indexOf('light')<0 || themeKeys.indexOf('dark')<0) return;
                            let now = new Date();

                            if(now.getHours() == 6 && now.getMinutes() == 0 && now.getSeconds() == 0){
                                // 6am
                                if(theme != 'light'){
                                    this.setTheme('light');
                                }
                            }else if(now.getHours() == 18 && now.getMinutes() == 0 && now.getSeconds() == 0){
                                // 6pm
                                if(theme != 'dark'){
                                    this.setTheme('dark');
                                }
                            }
                            if(!runThemeCheckOnce){
                                // these should only be run on initialization, to set the theme correctly if the user is just loading the page mid day
                                if(now.getHours() >= 6 && now.getHours() < 18){
                                    // daytime
                                    if(theme != 'light'){
                                        this.setTheme('light');
                                    }
                                }else{
                                    // nighttime
                                    if(theme != 'dark'){
                                        this.setTheme('dark');
                                    }
                                }
                            }
                            runThemeCheckOnce = true;
                        }
                    }catch(e){
                        console.error(e);
                    }
                }, 1000);
            }
        }catch(e){
            console.error(e);
        }
    },
    computed: {
        userRole(){
            return this.$store.state.user?.role;
        },
        firstName(){
            return this.$store.state.user?.firstName;
        },
        lastName(){
            return this.$store.state.user?.lastName;
        },
        profilePhoto(){
            return this.$store.state.user?.profilePhoto?.url.replace('http://','https://');
        },
        projectThemes(){
            return this.$store.state.project?.themes;
        },
        currentTheme(){
            return this.$vuetify.theme.dark;
        },
        notifications() {
            return {
                alerts: this.$store.state.alerts,
                cssClass: this.$store.state.alertsCssClass
            }
        },
        canBeScheduled(){
            return this.$store.state.site?.canBeScheduled == true || this.site?.canBeScheduled == true;
        }
    }
};
</script>
<style lang="sass">
    .desktop-user-settings
        .v-list-item.h-divide
            min-height: 1px
            background: rgba(125, 133, 197, 0.2)
            height: 1px
            margin: 8px auto
            width: 80%
        .v-icon
            margin-right: 10px
</style>
<style lang="sass" scoped>
    // TOP NAVBAR
    ::placeholder
        color: var(--input-txt)
    .icon-text
        color: var(--app-primary-txt)

    #navbar
        display: flex
        justify-content: space-around
        background-color: var(--app-secondary-bg)
        box-shadow: 0px 4px 4px 0px #00000040
        color: var(--app-tertiary-txt)
        width: 100%

        @media (min-width: 1904px)
            .desktop-nav,
            .mobile-nav
                // max-width: 1340px !important
                margin: 0 auto

        // SHARED NAV ITEMS
        ul
            padding-left: 0
        .default-logo
            max-height: 40px
    
        .h-divide
            background: rgba(125, 133, 197, 0.2)
            height: 1px
            margin: 8px auto
            width: 90%
        .v-divide
            background: rgba(125, 133, 197, 0.2)
            height: 40px
            margin: 0 40px 0 20px
            transform: matrix(-1, 0, 0, 1, 0, 0)
            width: 2px

        .nav-icons
            display: flex
            align-items: center
            flex-direction: row
            height: 56px
            .nav-icon
                cursor: pointer
                color: var(--navbar-theme-toggle)
                margin-left: 30px
            .v-icon
                height: 24px
                line-height: 24px
                &:focus
                    background: transparent
            .haq-alerts
                height: 24px
                width: 24px
                position: relative
                .v-icon
                    position: absolute
                .msmt-warning
                    color: var(--score-yellow-color)
                .msmt-critical
                    color: var(--score-red-color)
                .msmt-info
                    color: var(--score-blue-color)

        .avatar-wrapper
            display: flex
            align-items: center
            justify-content: center
            border-radius: 50px
            max-height: 44px
            max-width: 44px            
            overflow: hidden
            margin-left: 8px     
            &:hover
                background: transparent
            .avatar
                max-width: 44px
                min-width: 100%

        .user-role
            text-transform: capitalize

        // DESKTOP NAVIGATION
        .desktop-nav
            box-shadow: none
            display: flex
            align-items: center
            justify-content: space-between
            height: 64px
            padding: 0 20px 0 20px
            // max-width: 1340px!important
            
            section
                display: flex
                align-items: center
                justify-content: space-between
                height: 64px
                .logo
                    margin-right: 40px
                    display: flex
                    align-items: center
                    img
                        height: 50px
                ul.navigation
                    li
                        height: 64px
                        padding: 0 20px
                        text-align: center
                        &:hover
                            background: rgba(0, 0, 0, 0.1)
                        a
                            color: var(--app-tertiary-txt)
                            font-size: 20px
                            height: 64px
                            padding: 0
                            opacity: 0.6
                            text-transform: none
                            &:hover
                                background: transparent
                    li.active
                        background: transparent
                        border-bottom: 4px solid
                        border-bottom-color: var(--app-tertiary-txt)
                        &:hover
                            background: rgba(0, 0, 0, 0.1)
                        a
                            color: var(--app-tertiary-txt)
                            opacity: 1
            section.user-section
                display: flex
                align-items: center
                justify-content: space-between
                .user-info
                    text-align: right
                    .user-name
                        color: var(--app-primary-txt)
                        font-size: 16px
                        line-height: 24px
                    .user-role
                        color: var(--navbar-link-inactive)
                        font-size: 12px
                        line-height: 20px
                        font-weight: 300
                .user-img
                    img
                        margin-left: 16px
                    .dropdown-content,
                    .v-list
                        background-color: var(--app-secondary-bg)
                        border-radius: 12px
                        min-width:180px
                        top: 70px !important
                        .v-list-item
                            &:hover
                                background: rgba(0, 0, 0, 0.2)
                            a
                                color: var(--app-primary-txt)

        // MOBILE NAVIGATION
        .mobile-nav
            box-shadow: none
            display: flex
            align-items: center
            justify-content: space-between
            height: 56px
            padding: 0 15px
            
            section
                display: flex
                .mobile-drawer-trigger
                    font-size: 2.2rem
                    &:focus
                        &:after
                            background: transparent
                .logo 
                    height: 40px
                    img
                        height: 40px
        .mobile-drawer
            background-color: var(--app-secondary-bg)
            display: flex
            flex-direction: column
            height: 100vh
            padding-bottom: 0
            a
                color: var(--app-tertiary-txt)
                cursor: pointer
                font-size: 16px
                font-weight: 400

            .user-profile
                .user-img,
                .user-info
                    display: inline-block
                    vertical-align: middle
                .user-img,
                .user-img img
                    width: 50px !important
                    height: 50px !important
                    border-radius: 25px

                .user-info
                    .user-name
                        font-size: 18px
                        line-height: 24px
                    .user-role
                        color: var(--navbar-link-inactive)
                        font-size: 14px
                        line-height: 20px
                        font-weight: 300
</style>