<template lang="pug">
    #step7
        p Mount the sensor with screws or the included adhesive strips.
        p For best results, the sensor should be placed about chest height on the wall, away from any vents, and out of direct sunlight.
        .stepImage(style="background-image: url(/images/kitSetup-lht65Step2a.png); margin-bottom:1.5em;")
        button.btn.waves-effect.white.haq-btn.blue-text(@click="previousStep") Back
        button.btn.waves-effect.haq-btn.haq-color.right(@click="nextStep") Next
</template>
<script>
export default {
    name: "Step7",
    data(){
        return {}
    },
    methods: {
        nextStep(){
            this.$emit('nextStep')
        },
        previousStep(){
            this.$emit('previousStep')
        }
    }
}
</script>
<style lang="sass" scoped>
    #step7
        .stepImage
            max-height: 400px
            height: 400px
            @media (max-width: 600px)
                max-height: 300px
</style>