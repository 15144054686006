<template>
  <div>
    <slot v-if="!error"></slot>
    <div v-else>
      An error occurred: {{ error.message }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: null
    }
  },
  errorCaptured(err, vm, info) {
    this.error = err;
    console.error(err, vm, info);
    return false;
  }
}
</script>