<template lang="pug">
    #loginPage
        .header-nav
            a(href="/")
                img(:src="wlLogo")
            a.contact-us.waves-effect.waves-light(:href='wlContact') Contact
        .login-cards
            .login-card.step-login(v-show="!passwordUpdateSuccess && !userVerified")
                .content
                    .title Welcome to {{wlName}} 
                    .subtitle Reset your password to begin.
                    br
                    .input-field
                        label(for='login-email') Email Address
                        input(name='login-email' :value='email' disabled)
                    .input-field
                        label(for='password') Password
                        input#password.validate(name='password' :type="togglePassword ? 'text' : 'password'" v-model="password" placeholder="Enter password")
                        span.field-icon(@click="togglePasswordInput('password')")
                            span.material-icons(v-if="togglePassword") visibility
                            span.material-icons(v-else="togglePassword") visibility_off
                        //- span.helper-text Password must be a minimum of 8 characters long
                    .input-field.if-pw-confirm
                        label(for='confirm-password') Confirm Password
                        input#confirm-password(name='confirm-password' :type="toggleConfirmPassword ? 'text' : 'password'" v-model="confirmPassword" placeholder="Re-enter password")
                        span.field-icon(@click="togglePasswordInput('confirmPassword')")
                            span.material-icons(v-if="toggleConfirmPassword") visibility
                            span.material-icons(v-else="toggleConfirmPassword") visibility_off
                        .error(v-if="errorText") {{errorText}}
                        .requirements Use 8 or more characters.
                    .input-field
                        br
                        label(for="agree")
                            input(type="checkbox" v-model="agree")#agree
                            span  I have read and accept the <a target="_blank" href="https://gethawken.com/privacy-policy">Privacy Policy</a> and <a target="_blank" href="https://gethawken.com/terms-of-service">Terms of Service</a>.
                    button.haq-btn-primary.waves-effect.waves-light(v-on:click="upsertUser" :disabled="!agree") Create Account
            .login-card.step-success(v-show="passwordUpdateSuccess && !userVerified")
                .content
                    .title Success!
                    span.material-icons check_circle
                    .message Now logging you in
                .progress
                    .indeterminate
            .login-card.step-user-verified(v-show="userVerified")
                .content
                    .title Account Verified
                    .message This account is already verified.
                    router-link(to='/login').haq-btn-primary.waves-effect.waves-light Login
                    //- a(href='/reset-password') Reset Password
</template>
<script>
// @ is an alias to /src
import {wlData} from '../assets/js/constants';
import {HTTPClient} from '../assets/js/http';
let client = new HTTPClient();

export default {
    name: 'Login',
    data() {
        return {
            email: this.$route.query.email,
            username: '',
            password: '',
            agree: false,
            confirmPassword: '',
            togglePassword: 0,
            toggleConfirmPassword: 0,
            passwordUpdateSuccess: false,
            userVerified: false,
            errorText: null,
            projectId: '',
            wlName: wlData.name ? wlData.name : "HawkenAQ",
            wlLogo: wlData.loginLogo ? wlData.loginLogo : "/images/hawkenAqBlue.png",
            wlContact: wlData.contactUrl||'https://gethawken.com/contact-hawkenaq'
        };
    },
    methods: {
        togglePasswordInput(toggle) {
            if(toggle == 'password')
                this.togglePassword == 0 ? this.togglePassword = 1 : this.togglePassword = 0;
            else if(toggle == 'confirmPassword')
                this.toggleConfirmPassword == 0 ? this.toggleConfirmPassword = 1 : this.toggleConfirmPassword = 0;
        },
        validatePassword(password){
            if(password.length >= 8)
                return true;
            return false;
        },
        async upsertUser() {
            try {
                let passwordsMatch = this.newPassword === this.confirmNewPassword;
                let isValid = this.validatePassword(this.password);
                
                if(!passwordsMatch) {
                    this.errorText = 'Passwords do not match. Please try again.';
                    return;
                }
                if(!isValid) {
                    this.errorText = 'Password is too short. Please try again.';
                    return;
                }
                if(isValid && passwordsMatch) {
                    this.errorText = '';
                    this.passwordUpdateSuccess = true;
                    let result = await client.robustPost('/users/signup',{email: this.email, password: this.password});
                    if(result.successMessage)
                        this.fetchProjects();
                }               
            }
            catch(error) {
                console.log(error)
            }
        },
        async fetchUserVerifiedStatus() {
            let result = await client.robustQuery('/users/verify',{email: this.email});
            this.userVerified = result.user.emailVerified;
        },
        async fetchProjects(){
            try {
                let projects = await client.robustQuery('/users/projectsWithAuth', {username: this.email, password: this.password});
                this.projectId = projects[0].objectId;
                this.authenticateAPI();
            } catch (error) {
                console.error(error);
                this.errorText = error.message||'Login failed';
            }
        },
        async authenticateAPI() {
            try {
                await this.$store.dispatch('login', {username: this.email, password: this.password, project: this.projectId});
                if(this.$route.query.from == "onboarding")
                    window.location.replace('/onboarding');
                else if (this.$route.query.from)
                    window.location.replace(this.$route.query.from);
                else
                    window.location.replace('/');
            } catch (error) {
                console.error(error);
                this.errorText = true;            
            }
        }
    },
    mounted() {
        this.fetchUserVerifiedStatus();
    }
};
</script>
<style lang="sass">
    #loginPage input.select-dropdown
        border: 1px solid #005B9A
        box-sizing: border-box
        border-radius: 12px
        color: #005B9A
        font: normal 400 16px/24px Roboto
        margin: 8px 0 24px
        padding: 8px 20px
</style>
<style lang="sass" scoped>
    #loginPage
        background: #fff url("../../public/images/bg.png") top right no-repeat
        background-size: cover
        min-height: 100vh
        
    .header-nav
        background: #fff
        box-shadow: 0px 100px 80px rgba(0, 91, 154, 0.12), 0px 41.7776px 33.4221px rgba(0, 91, 154, 0.0862625), 0px 22.3363px 17.869px rgba(0, 91, 154, 0.0715329), 0px 12.5216px 10.0172px rgba(0, 91, 154, 0.06), 0px 6.6501px 5.32008px rgba(0, 91, 154, 0.0484671), 0px 2.76726px 2.21381px rgba(0, 91, 154, 0.0337375)
        display: flex
        align-items: center
        justify-content: space-between
        height: 80px
        padding: 18px 36px
        
        @media (max-width: 600px)
            padding: 18px 15px

        img
            max-height: 44px
        a.contact-us
            border: 1px solid #005B9A
            border-radius: 12px
            color: #005B9A
            font: normal 400 16px/24px Roboto
            letter-spacing: 0.18px
            height: 40px
            line-height: 40px
            text-align: center
            width: 140px
            @media (max-width: 600px)
                font-size: 14px
                height: 36px
                line-height: 36px
                width: 90px
    .login-cards
        display: flex
        align-items: center
        min-height: calc(100vh - 80px)
        
        @media (max-width: 600px)
            align-items: flex-start

        .login-card
            background: #fff
            border-radius: 20px
            box-shadow: 0px 100px 80px rgba(0, 91, 154, 0.12), 0px 41.7776px 33.4221px rgba(0, 91, 154, 0.0862625), 0px 22.3363px 17.869px rgba(0, 91, 154, 0.0715329), 0px 12.5216px 10.0172px rgba(0, 91, 154, 0.06), 0px 6.6501px 5.32008px rgba(0, 91, 154, 0.0484671), 0px 2.76726px 2.21381px rgba(0, 91, 154, 0.0337375)
            color: #005B9A
            margin: 50px auto
            max-width: 548px
            width: 100%

            @media (max-width: 600px)
                // background: rgba(255,255,255,.8)
                margin: 50px 15px

            .content
                margin: 0 auto
                max-width: 360px
                width: 100%
                text-align: center
                padding: 40px
                @media (max-width: 600px)
                    padding: 40px 20px

                .title
                    font: normal 400 32px/46px Roboto
                    margin-bottom: 32px
                    @media (max-width: 600px)
                        font-size: 24px
                .subtitle
                    font: normal 400 16px/24px Roboto
                .input-field
                    margin: 0 0 16px
                    text-align: left
                    label
                        font: normal 400 14px/20px Roboto
                        color: #005B9A
                        position: static
                        margin-bottom: 8px
                    input
                        border: 1px solid #005B9A
                        box-sizing: border-box
                        box-shadow: none
                        border-radius: 12px
                        color: #005B9A
                        font: normal 400 16px/24px Roboto
                        height: 40px
                        margin: 0 
                        padding: 8px 20px
                        &:disabled
                            color: #acacac
                            border-color: #ccc
                    span.field-icon
                        position: absolute
                        right: 12px
                        top: 33px
                        cursor: pointer
                        z-index: 2
                        .material-icons
                            font-size: 20px
                    span.helper-text
                        color: #005B9A
                        font: normal 400 12px/20px Roboto
                    ::placeholder
                        color: #005B9A
                        font: normal 400 14px/20px Roboto
                        opacity: 0.4
                .if-pw-confirm
                    margin-bottom: 0
                .requirements
                    text-align: left
                    font: normal 400 12px/20px Roboto
                .error
                    background-color: transparent !important
                    color: #FF5050
                    font: normal 400 14px/20px Roboto
                    text-align: left
                .strike
                    display: block
                    text-align: center
                    overflow: hidden
                    white-space: nowrap
                    > span
                        color: #005B9A
                        font: normal 400 14px/20px Roboto
                        position: relative
                        display: inline-block
                        opacity: 0.5
                        &:before,
                        &:after
                            content: ""
                            position: absolute
                            top: 55%
                            width: 9999px
                            height: 1px
                            background: #005B9A
                            opacity: 0.5
                        &:before
                            right: 100%
                            margin-right: 8px
                        &:after
                            left: 100%
                            margin-left: 8px
        .step-success
            padding-bottom: 0
            overflow: hidden
            .title
            .material-icons
                color: #A9ECDE
                font-size: 48px
                margin-bottom: 32px
            .progress
                // background: #c8f7ed
                // background: #bcf7ea
                background: #fff
                margin: 0
                height: 8px
                overflow: visible
                .indeterminate
                    background: #A9ECDE
        .step-user-verified
            .message
                margin-bottom: 8px
        .haq-btn-primary
            background: #3456F7
            border: 0
            border-radius: 12px
            color: #fff
            margin: 24px 0
            padding: 10px
            height: 40px
            max-height: 360px
            width: 100%
            .disabled
                background: #B9E2FF
                color: #52B2F3
</style>