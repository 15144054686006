<template lang="pug">
v-menu(v-model="menu" ref="menu" :close-on-content-click="false" transition="scale-transition" offset-y :allowed-dates="allowedDates" min-width="auto")
    template(v-slot:activator="{on, attrs}")
        v-text-field(v-model="selectedDate" class="" v-bind="attrs" v-on="on" :label="label" ref="datebar" dense hide-details outlined)
    v-date-picker(v-model="selectedDate" @input="updateSelectedDate" class="haq-date-picker")
</template>
<style lang="sass">
    td, th
        padding: 5px !important
    .haq-date-picker
        .v-date-picker-table
            height: inherit
</style>
<script>
import moment from 'moment'
export default {
    name: "DatePicker",
    props: {
        date: Number,
        label: String,
        allowedDates: Array
    },
    data(){
        return {
            menu: false,
            selectedDate: moment(this.date).toISOString().substr(0, 10)
        }
    },
    methods: {
        updateSelectedDate(){
            console.log(this.selectedDate);
            this.menu = false;
            this.$emit("dateChange", this.selectedDate)
        }
    },
    mounted(){
        this.$refs.datebar.$el.querySelector("input")?.classList.add("browser-default")
    }
    // watch: {
    //     "$props.date": {
    //         handler(){
    //             console.log("Props Changed")
    //             this.selectedDate = moment(this.date).toISOString().substr(0, 10)
    //         }
    //     }
    // }
}
</script>