<template lang="pug">
    .tree(v-show="nodeData.enabled!== false")
        .row
            .col.s12.treeNodeSelector(v-bind:class="{selectorSelected : nodeData.selected && nodeData.type === 'measurement'}")
                span(:style="depthMargin")
                font-awesome-icon(:icon="icon.name" :style="icon.style")
                span(style="margin-left:20px" v-bind:class="{editableName: nodeData.type === 'zone'}" @click="selectorSelected")
                    span {{nodeData.name}}
                    span(v-if="nodeData.pointType").deviceChip
                        font-awesome-icon(icon="circle" :style="{color: '#3498db'}")
                        span  {{nodeData.pointType}}
                span(v-if="controls.edit").editButton
                    font-awesome-icon(:icon="controls.edit.icon" :style="controls.edit.style" @click="editMeasurement")
                span(v-if="controls.selfAdd").selfAddButton
                    font-awesome-icon(:icon="controls.selfAdd.icon" :style="controls.selfAdd.style" @click="selfAdd")
                span(v-if="controls.delete").deleteButton
                    font-awesome-icon(:icon="controls.delete.icon" :style="controls.delete.style" @click="nodeData.enabled = false")
        .aQModal(v-if="modalOpen")
            .modal-content
                .modal-body(style="padding:20px")
                    .row
                        .col.s12
                            h5 Edit Measurement
                    .row
                        .col.s12
                            .input-field
                                input(type="text" :id="`${nodeData.id}name`" v-model="nodeData.name").white-text
                                label(:for="`${nodeData.id}name`").white-text Name
                        .col.s12
                            h6 Config 
                                font-awesome-icon(:icon="controls.selfAdd.icon" :style="controls.selfAdd.style" @click="addConfig()")
                        .col.s12(v-if="nodeData.config" v-for="c in nodeData.config")
                            .input-field
                                label Config Name
                                input(type="text" v-model="c.name").white-text
                            .input-field
                                label Config Value
                                input(type="text" v-model="c.value").white-text
                            .input-field
                                select(v-model="c.kind").browser-default
                                    option(value="Number") Number
                                    option(value="String") String
                                    option(value="Bool") Boolean
                .modal-footer
                    .row
                        .col.s12
                            button.btn.waves-effect.waves-light(@click="modalOpen = false").red.modal-close Close

            
</template>
<style lang="scss" scoped>
    .aQModal {
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(45,50,90);
        background-color: rgba(0,0,0,0.3); /* Black w/ opacity */
    }
    .modal-content {
        background-color: #121527;
        margin: 15% auto; /* 15% from the top and centered */
        border: 1px solid rgb(45,50,90);
        width: 50%; /* Could be more or less, depending on screen size */
    }
    .modal-footer {
        background-color: #2d325a;
        border: 1px solid rgb(45,50,90);
        width: 100%; /* Could be more or less, depending on screen size */
        .modal-close {
            float: right;
            margin-left: 15px;
            margin-top: 15px;
        }
    }
    .treeNodeSelector {
        padding-top: 3px;
        color: #999;
        &:hover {
			background-color: #085691;
            color: #ffffff;
            .editButton, .deleteButton, .selfAddButton {
                display: inline;
            }
            .editName {
                bottom: -15px;
            }
        }
    }
    .editButton, .selfAddButton {
        display: none;
        float: right;
        margin-left: 20px;
    }
    .editableName {
        cursor: text;
    }
    .editName {
        padding:5px;
        size: 30px;
        border-radius: 50px;
    }
    .deleteButton {
        display: none;
        float: right;
    }
    .selectorSelected {
            background-color: #085691;
            color: #ffffff;
    }
     .expansionControl {
        margin-left: 10px;
        cursor: pointer;
        color: white;
    }
    .deviceChip {
        background-color:#95a5a6;
        color: #ffffff;
        padding: 0px 8px;
        display: inline-block;
        border-radius: 50px;
        margin-left: 10px;
    }
</style>
<script>
export default {
    name: 'MeasurementNode',
    props: {
        nodeData: Object,
        depth: Number,
        step: String
    },
   data() {
        let icon = {
            style: null,
            name: null
        }
        let controls = {editable: false}
        if(this.nodeData.type === 'zone'){
            icon.style = {color: '#2ecc71'}
            icon.name = "square"
        }
        if(this.nodeData.type === 'point'){
            icon.style = {color: '#3498db'}
            icon.name = "certificate"
        }
        if(this.nodeData.type === "site"){
            icon.style = {color: '#74b9ff'}
            icon.name = "globe"
        }
        if(this.nodeData.type === "measurement"){
            icon.style = {color: '#9b59b6'}
            icon.name = "ruler"
            controls.edit = {
                style: {color: '#f1c40f', size: "32px"},
                icon: 'edit'
            };
            controls.selfAdd = {
                 style: {color: '#f1c40f', size: "32px"},
                 icon: 'plus-circle'
            }
            controls.delete = {
                style: {color: '#e74c3c'},
                icon: 'trash'
            }
        }

        if(this.nodeData.type === "childMeasurement"){
            icon.style = {color: '#3498db'}
            icon.name = "ruler"
            
            controls.delete = {
                style: {color: '#e74c3c'},
                icon: 'trash'
            }
        }
        return {
            icon,
            controls,
            depthMargin: `margin-left:${25 * this.nodeData.depth}px`,
            modalOpen: false
        }
    },
    methods: {
        selectorSelected(){
            console.log('Selected Measurement');
            if(this.nodeData.type === "measurement"){
                this.$emit('selectorAdded', this.nodeData)
            }
        },
        selfAdd(){
            console.log('Adding Measurement to measurement');
            this.$emit('addMtoM', this.nodeData);
        },
        editMeasurement(){
            if(this.nodeData.type === "measurement"){
                this.controls.editable = !this.controls.editable
                if(this.controls.editable){
                    // this.controls.edit = {
                    //     icon: 'save',
                    //     style: {color: '#3498db'}
                    // }
                    this.modalOpen = true;
                } else {
                    // this.controls.edit = {
                    //     style: {color: '#f1c40f', size: "32px"},
                    //     icon: 'edit'
                    // }
                    this.modalOpen = false;
                }
            }
        },
        addConfig(){
            console.log('Adding Config')
            if(Array.isArray(this.nodeData.config)){
                this.nodeData.config.push({
                    name: "",
                    value: "",
                    kind: "Number"
                })
            } else {
                let obj = [
                    {
                        name: "",
                        value: "",
                        kind: "Number"
                    }
                ]
                this.nodeData.config = obj;
            }
            this.modalOpen = false;
            this.modalOpen = true;
        }
    },
    mounted(){
    }
}
</script>