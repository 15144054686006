<template lang="pug">
html
	
	body
		main 
		.container 
			.row 
				.col.s12 
					h4 FAFA Site Statuses 
						.row 
					#table-container
</template>

<script src="http://cdn.datatables.net/1.10.18/js/jquery.dataTables.min.js"></script>
<script>
/* global $ */
export default {
	data() {
		return {
			timer : null
		}
	},
	methods : {
		createTable() { 
        $.ajax({
            type: 'GET',
            url: 'https://app.kmccommander.com/fafa/site/status',
            success: function success(responseData) {
                let colData = responseData.sites[0];//["Site ID", "Site Name", "Site Status"];
                let rowData = responseData.sites.slice(1); //[['abc', 'McClennan', 'Not Begun'], ['def', 'CPS', 'HawkenAQ Installed']];
                let data = { "Cols": colData, "Rows": rowData };
                $('<select/>').addClass('select-wrapper');

                let table = $('<table/>').attr("id", "userquerytable").addClass("display").attr("cellspacing", "0").attr("width", "100%");
                let tr = $('<tr/>');
                table.append('<thead>').children('thead').append(tr);

                for (let i = 0; i < data.Cols.length; i++) {
                    tr.append('<td>' + data.Cols[i] + '</td>');
                }
                for (let r = 0; r < data.Rows.length; r++) {
                    tr = $('<tr/>');
                    table.append(tr).css('background-color', '#010e18');
                    for (let c = 0; c < data.Cols.length; c++) {
                        if (c == 2) {
                            let color;
                            if (data.Rows[r][c] == 'Site Complete') {
                                color = 'green';
                            } else if (data.Rows[r][c] == 'Pending Capital Improvements' || data.Rows[r][c] == 'Pending Site Work') {
                                color = 'yellow';
                            } else {
                                color = 'white';
                            }
                            tr.append('<td>' + data.Rows[r][c] + '<span class="dot" style="height:20px;width:20px;background-color:' + color + ';border-radius:50%;display:inline-block;float:right"></span></td>').css('background-color', '#010e18');
                        } else {
                            tr.append('<td>' + data.Rows[r][c] + '</td>').css('background-color', '#010e18');
                        }
                    }
                }
                $('#table-container').empty();
                $('#table-container').append(table);

                $('#userquerytable').DataTable({
                    responsive: true,
                    paging: false
                });
            }
        }).fail(function () {
            window.location = '/';
        });
		}
	},
	beforeDestroy() {
		clearInterval(this.timer);
	},
	destroy() {
		$('#userquerytable').DataTable().destroy();
	},
	mounted() {
		this.createTable();
		this.timer = setInterval(() => {
			this.createTable();
		},  60 * 5 * 1000)
	}
}
</script>
<style lang="scss">
	table td:last-child {
		text-align: left;
	}
	.dataTables_filter input {
		color: white;
	}
</style>