/**
 * @description get svg string.
 * @param {number} alarmCount 
 * @returns  {string} Svg string.
 */
export const getSVGString = (alarmCount) => {
    if(alarmCount === 0 || alarmCount == null){
        return `<svg width="32" height="35" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4 0C1.79086 0 0 1.79086 0 4V28C0 30.2091 1.79086 32 4 32H12L16 35L20 32H28C30.2091 32 32 30.2091 32 28V4C32 1.79086 30.2091 0 28 0H4Z" fill="#3456F7"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.25 25C7.25 24.5858 7.58579 24.25 8 24.25H24C24.4142 24.25 24.75 24.5858 24.75 25C24.75 25.4142 24.4142 25.75 24 25.75H8C7.58579 25.75 7.25 25.4142 7.25 25Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11 7.75C10.6685 7.75 10.3505 7.8817 10.1161 8.11612C9.8817 8.35054 9.75 8.66848 9.75 9V25C9.75 25.4142 9.41421 25.75 9 25.75C8.58579 25.75 8.25 25.4142 8.25 25V9C8.25 8.27065 8.53973 7.57118 9.05546 7.05546C9.57118 6.53973 10.2707 6.25 11 6.25H21C21.7293 6.25 22.4288 6.53973 22.9445 7.05546C23.4603 7.57118 23.75 8.27065 23.75 9V25C23.75 25.4142 23.4142 25.75 23 25.75C22.5858 25.75 22.25 25.4142 22.25 25V9C22.25 8.66848 22.1183 8.35054 21.8839 8.11612C21.6495 7.8817 21.3315 7.75 21 7.75H11Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13 12.5V13H14V12.5H13ZM12 12.2C12 11.8134 12.3134 11.5 12.7 11.5H14.3C14.6866 11.5 15 11.8134 15 12.2V13.3C15 13.6866 14.6866 14 14.3 14H12.7C12.3134 14 12 13.6866 12 13.3V12.2Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13 17V17.5H14V17H13ZM12 16.7C12 16.3134 12.3134 16 12.7 16H14.3C14.6866 16 15 16.3134 15 16.7V17.8C15 18.1866 14.6866 18.5 14.3 18.5H12.7C12.3134 18.5 12 18.1866 12 17.8V16.7Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 12.5V13H19V12.5H18ZM17 12.2C17 11.8134 17.3134 11.5 17.7 11.5H19.3C19.6866 11.5 20 11.8134 20 12.2V13.3C20 13.6866 19.6866 14 19.3 14H17.7C17.3134 14 17 13.6866 17 13.3V12.2Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 17V17.5H19V17H18ZM17 16.7C17 16.3134 17.3134 16 17.7 16H19.3C19.6866 16 20 16.3134 20 16.7V17.8C20 18.1866 19.6866 18.5 19.3 18.5H17.7C17.3134 18.5 17 18.1866 17 17.8V16.7Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3333 21.75C15.1786 21.75 15.0303 21.8115 14.9209 21.9209C14.8115 22.0303 14.75 22.1786 14.75 22.3333V25C14.75 25.4142 14.4142 25.75 14 25.75C13.5858 25.75 13.25 25.4142 13.25 25V22.3333C13.25 21.7808 13.4695 21.2509 13.8602 20.8602C14.2509 20.4695 14.7808 20.25 15.3333 20.25H16.6667C17.2192 20.25 17.7491 20.4695 18.1398 20.8602C18.5305 21.2509 18.75 21.7808 18.75 22.3333V25C18.75 25.4142 18.4142 25.75 18 25.75C17.5858 25.75 17.25 25.4142 17.25 25V22.3333C17.25 22.1786 17.1885 22.0303 17.0791 21.9209C16.9697 21.8115 16.8214 21.75 16.6667 21.75H15.3333Z" fill="white"/>
        </svg>
        `
    }
    return `<svg width="40" height="43" viewBox="0 0 40 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 8C1.79086 8 0 9.79086 0 12V36C0 38.2091 1.79086 40 4 40H12L16 43L20 40H28C30.2091 40 32 38.2091 32 36V12C32 9.79086 30.2091 8 28 8H4Z" fill="#FF5050"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.25 33C7.25 32.5858 7.58579 32.25 8 32.25H24C24.4142 32.25 24.75 32.5858 24.75 33C24.75 33.4142 24.4142 33.75 24 33.75H8C7.58579 33.75 7.25 33.4142 7.25 33Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 15.75C10.6685 15.75 10.3505 15.8817 10.1161 16.1161C9.8817 16.3505 9.75 16.6685 9.75 17V33C9.75 33.4142 9.41421 33.75 9 33.75C8.58579 33.75 8.25 33.4142 8.25 33V17C8.25 16.2707 8.53973 15.5712 9.05546 15.0555C9.57118 14.5397 10.2707 14.25 11 14.25H21C21.7293 14.25 22.4288 14.5397 22.9445 15.0555C23.4603 15.5712 23.75 16.2707 23.75 17V33C23.75 33.4142 23.4142 33.75 23 33.75C22.5858 33.75 22.25 33.4142 22.25 33V17C22.25 16.6685 22.1183 16.3505 21.8839 16.1161C21.6495 15.8817 21.3315 15.75 21 15.75H11Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13 20.5V21H14V20.5H13ZM12 20.2C12 19.8134 12.3134 19.5 12.7 19.5H14.3C14.6866 19.5 15 19.8134 15 20.2V21.3C15 21.6866 14.6866 22 14.3 22H12.7C12.3134 22 12 21.6866 12 21.3V20.2Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13 25V25.5H14V25H13ZM12 24.7C12 24.3134 12.3134 24 12.7 24H14.3C14.6866 24 15 24.3134 15 24.7V25.8C15 26.1866 14.6866 26.5 14.3 26.5H12.7C12.3134 26.5 12 26.1866 12 25.8V24.7Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18 20.5V21H19V20.5H18ZM17 20.2C17 19.8134 17.3134 19.5 17.7 19.5H19.3C19.6866 19.5 20 19.8134 20 20.2V21.3C20 21.6866 19.6866 22 19.3 22H17.7C17.3134 22 17 21.6866 17 21.3V20.2Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18 25V25.5H19V25H18ZM17 24.7C17 24.3134 17.3134 24 17.7 24H19.3C19.6866 24 20 24.3134 20 24.7V25.8C20 26.1866 19.6866 26.5 19.3 26.5H17.7C17.3134 26.5 17 26.1866 17 25.8V24.7Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3333 29.75C15.1786 29.75 15.0303 29.8115 14.9209 29.9209C14.8115 30.0303 14.75 30.1786 14.75 30.3333V33C14.75 33.4142 14.4142 33.75 14 33.75C13.5858 33.75 13.25 33.4142 13.25 33V30.3333C13.25 29.7808 13.4695 29.2509 13.8602 28.8602C14.2509 28.4695 14.7808 28.25 15.3333 28.25H16.6667C17.2192 28.25 17.7491 28.4695 18.1398 28.8602C18.5305 29.2509 18.75 29.7808 18.75 30.3333V33C18.75 33.4142 18.4142 33.75 18 33.75C17.5858 33.75 17.25 33.4142 17.25 33V30.3333C17.25 30.1786 17.1885 30.0303 17.0791 29.9209C16.9697 29.8115 16.8214 29.75 16.6667 29.75H15.3333Z" fill="white"/>
    <rect x="15" width="26" height="20" rx="8" fill="#FDE22B">
    </rect>
    <text x="27" y="14" text-anchor="middle" fill="black" font-size="14" font-family="Roboto">${alarmCount}</text>
    </svg>
    `


}