<template lang="pug">
    .section-editor(v-show="$store.state.editMode")
        v-icon(:size="iconSizeInPx" v-on:click.stop="openDialog").pencil-button mdi-pencil
        v-dialog(v-model="editDialog" max-width="600px" scrollable @click:outside="editDialog = false")
            v-card(style="background: var(--app-secondary-bg)")
                v-card-title
                    span(class="text-h5") Edit {{title}}
                v-card-text.mt-4
                    v-row
                        v-col
                            v-text-field.haq-text-field(v-model="textToEdit" :label="title")
                v-card-actions
                    v-spacer
                    v-btn.haq-btn.haq-btn-secondary(@click="editDialog = false").mr-1 Cancel
                    v-btn.haq-btn.haq-btn-primary(@click="save") Save
</template>
<style lang="scss" scoped>
    .section-editor {
        display: inline-block;
        padding: 0px;
        margin-left: 7px;
        text-align: right;
    }
    // color="var(--btn-primary-bg)" :disabled="disabledBtn"

</style>
<script>
import {HTTPClient} from '../assets/js/http';
let client = new HTTPClient();
export default {
    name: "SectionEditor",
    props: {
        text: String,
        title: String,
        iconSize: Number,
        measurementId: String,
    },
    data(){
        return {
            textToEdit: this.text,
            iconSizeInPx: this.iconSize ? String(this.iconSize) + 'px' : '24px',
            editDialog: false,
            disabledBtn: false
        }
    },
    methods: {
        openDialog(e){
            e.preventDefault();
            this.editDialog = true;
        },
        async save(){
            this.disabledBtn = true;
            try {
                if(this.measurementId != null){
                    let res = await client.robustPost("/api/measurement/" + this.measurementId, {name: this.textToEdit});
                    console.log(res);
                    window.M.toast({
                        html: `Updated ${this.title}`,
                    })

                    this.$emit("updateText", this.textToEdit);
                }
            } catch (error) {
                console.error(error);
                 window.M.toast({
                        html: "Error updating measurement. Please try again or contact support",
                    })
            } finally {
                this.disabledBtn = false;
                this.editDialog = false;
            }
        }
    }
}
</script>