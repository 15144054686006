<template lang="pug">
    v-card.haq-dialog-card
        v-card-title
            v-row
                v-col(:cols="12" :sm="12")
                    h5 Select Measurements
                v-col(:cols="12" :md="5")
                    v-text-field(v-model="searchQuery" append-icon="mdi-magnify" label="Search Measurements" dense hide-details outlined ref="searchBar")
        v-card-text(v-if="structuredMeasurements == null").d-flex.justify-center
            v-progress-circular(indeterminate color="primary")
        v-card-text(v-else)
            .optionsDiv
                .zoneDiv
                    .option
                        v-subheader.pa-0.ma-0 Filter by Zone
                    .option.pa-2.mt-2.show-hover(@click="toggleMultiselect")
                        v-icon mdi-checkbox-multiple-marked-outline
                        .optionTitle Multiselect by Type
                    .option.pa-2.mt-2.show-hover(v-if="showMultiselect" v-for="type in loadedPointTypes" :key="type" @click="selectedTypes[type] = !selectedTypes[type]; selectAllOfType()").my-0
                        v-checkbox(v-model="selectedTypes[type]" hide-details :label="type" @click="selectAllOfType()" @click.stop).ma-0
                    .option.pa-2.mt-2.show-hover(@click="setZone(siteId)")
                        v-icon mdi-domain
                        .optionTitle Site Measurements
                    .option.pa-2.mt-.show-hover(v-for="z in zones" :key="z.id" @click="setZone(z.id)" v-bind:class="{'active': selected == z.id}")
                        v-icon mdi-home-group
                        .optionTitle {{z.name}}
                .measurementDiv
                    .option
                        v-subheader.pa-0.mx-0.ml-4 Measurements
                    .option.pa-2.mt-2.show-hover(v-for="m,index in structuredMeasurements" :key="m.objectId" v-show="showThisMeasurement(m, getDisplayName(m))" :data-measurement-id="m.objectId" :data-measurement-index="index" @click="m.selected = !m.selected").my-0
                        v-checkbox(v-model="m.selected" hide-details :label="getDisplayName(m)" @click.stop).ma-0
        v-card-actions.mt-auto.ml-auto
            v-btn.haq-btn.haq-btn-secondary(@click="$emit('closeModal')").mr-4 Cancel
            v-btn.haq-btn.haq-btn-primary(@click="addMeasurements") Confirm
</template>
<script>
import { err_code } from 'redis/lib/utils';
import { useGetSite } from '../../assets/js/hawken-query';
import {HTTPClient} from "../../assets/js/http"
let client = new HTTPClient();
export default {
    name: "MultiMeasurementSelector",
    props: {
        preSelectedMeasurements: Array,
        source: String,
    },
    data(){
        return {
            zones: [],
            site: null,
            measurementForTopology: [],
            structuredMeasurements: null,
            selected: this.$route.query.siteId,
            siteId: this.$route.query.siteId || this.$route.params.siteId,
            searchQuery: "",
            selectedMeasurements: {},
            includeAll: {},
            loadedPointTypes: [], // New array to track all types of loaded points
            selectedTypes: {},
            showMultiselect: false
        }
    },
    methods: {
        setZone(e){
            this.selected = e;
        },
        toggleMultiselect() {
            this.showMultiselect = !this.showMultiselect;
        },
        selectAllOfType() {
            this.structuredMeasurements.forEach(m => {
                m.selected = this.selectedTypes[m.type];
            });
        },
        async loadstructuredMeasurements(){
            try {
                let measurements = [];
                let res;
                if(this.source == null){
                    res = await client.robustQuery(`/api/sites/reports/${this.siteId}/measurements`, {includeScores: true});
                    console.log("Source null ", res)
                } else if(this.source == "schedule"){
                    res = await client.robustQuery(`/api/sites/${this.siteId}/scheduleGroups/measurements`, {includeScores: true, structured: true});
                    console.log("Source schedule ", res)
                } else {
                    throw Error("Invalid source")
                }
                for(let z in res.results.zones){
                    let zone = res.results.zones[z];
                    if (zone.measurements) {
                        zone.measurements.sort((a, b) => 
                            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                        );
                        // Add types from zone measurements to loadedPointTypes
                        zone.measurements.forEach(m => {
                            if (!this.loadedPointTypes.includes(m.type)) {
                                this.loadedPointTypes.push(m.type);
                                this.selectedTypes[m.type] = false;
                            }
                        });
                    }
                    this.zones.push({
                        name: zone.name,
                        id: z,
                    })
                    measurements = measurements.concat(zone.measurements)
                }
                // Sort zones alphabetically by name (case-insensitive)
                this.zones.sort((a, b) => 
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                );
                // Sort loadedPointTypes alphabetically by name (case-insensitive)
                this.loadedPointTypes.sort((a, b) => 
                    a.toLowerCase().localeCompare(b.toLowerCase())
                );
                measurements = measurements.concat(res.results.site.measurements)
                // Add types from site measurements to loadedPointTypes
                res.results.site.measurements.forEach(m => {
                    if (!this.loadedPointTypes.includes(m.type)) {
                        this.loadedPointTypes.push(m.type);
                        this.selectedTypes[m.type] = false;
                    }
                });
                measurements.forEach((el) => {
                    this.preSelectedMeasurements.includes(el.objectId) ? el.selected = true : el.selected = false
                })
                this.structuredMeasurements = measurements;
                console.log('zones', this.zones)
            } catch (error) {
                console.error(error);
            }
        },
        addMeasurements(){
            let selectedMeasurements = this.structuredMeasurements.filter((el) => el.selected).map((el) => {
                return {
                    id: el.objectId,
                    type: el.type,
                }
            })
            this.$emit("addMeasurements", selectedMeasurements);
        }
    },
    mounted(){
        this.loadstructuredMeasurements()
        this.$refs.searchBar.$el.querySelector("input")?.classList.add("browser-default")
    },
    computed: {
        getDisplayName(){
            return (m) => {
                if(m.type == "scoreAQ"){
                    return "Air Quality Score"
                } else if(m.type == "scoreEQ"){
                    return "Equipment Score"
                } else if(m.type == "scoreOW"){
                    return "Occupant Wellness Score"
                } else if(m.type == "scoreOA"){
                    return "Overall Score"
                } else if(m.type == "scoreAF"){
                    return "Air Freshness Score"
                } else {
                    return m.name + " " + (m.unit ? `(${m.unit})` : "")
                }
            }
        },
        showThisMeasurement(){
            return (m, dn) => {
                let regex = new RegExp(this.searchQuery, "i")
                if(this.selected == this.siteId){
                    return m.zone == null && (regex.test(m.name) || regex.test(m.type) || regex.test(m.unit) || regex.test(dn))
                } 
                else {
                    return m.zone?.objectId === this.selected && (regex.test(m.name) || regex.test(m.type) || regex.test(m.unit) || regex.test(dn))
                }
            }
        }
        
    },
}
</script>
<style lang="sass" scoped>
</style>