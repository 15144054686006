<template lang="pug">
    .container
        .row.pie-card
            .col.s12.m7.pie
                highcharts(:options="chartOptions" v-if="chartOptions")
            .col.s12.m5.pie-info
                .hidden-btns.hide
                    button(v-for="c in children" :id="'pie-click-btn-' + c.objectId" @click="clickShowCard(c)") {{c.name}}
                
                .chart-description(v-if="noCard") This chart shows detailed information for every indicator. Click to see more.

                .show-card(v-else)
                    .k-card(:id="'card-' + clickedMeasurement.objectId" :class="'card-' + getKioskKind(clickedMeasurement.type)")
                        .k-card-measurement(:class="getKioskKind(clickedMeasurement.type)")
                            .k-card-measurement-progress
                                span.progress-excellent(v-if="clickedMeasurement.curScore >= 75") Excellent
                                span(v-else-if="clickedMeasurement.curScore < 75 && clickedMeasurement.curScore >= 50") Good
                                span(v-else) Poor
                            .k-card-measurement-icon
                                MeasurementIcons(:measurement="getKioskKind(clickedMeasurement.type)")
                            .k-card-measurement-curVal {{getDisplayVal(clickedMeasurement)}}
                                span {{clickedMeasurement.unit}}
                            //- .k-card-measurement-name {{clickedMeasurement.name}}
                            .k-card-measurement-name {{ getMeasurementDisName(clickedMeasurement) }}
                    .learn-more(v-if="clickedMeasurement.description")
                        button.waves-effect.waves-light.btn.btn-outline(@click="clickLearnMore(clickedMeasurement.objectId)") Learn more

        .row
            section.more-infos(v-for="c in children" :id="'hb-desc-' + c.objectId")
                .modal-card(v-if="c.curScore")
                    .card-header
                        h3 {{c.name}} 
                            span.hc-unit(v-if="c.unit") {{c.unit}}
                    .card-body(v-if="c.description")
                        p.hc-description {{c.description}}
                            .hc-more-info(v-if="c.threshold")
                                table
                                    thead
                                        tr
                                            th Threshold
                                            th Range
                                    tbody
                                        tr
                                            td Excellent
                                            td {{c.threshold.Excellent}}
                                        tr
                                            td Good
                                            td {{c.threshold.Good}}
                                        tr
                                            td Poor
                                            td {{c.threshold.Poor}}         
                                .hc-desc-more-info(v-html="c.extendedDescription")
</template>
<script>
import {Chart} from 'highcharts-vue'
import {ChartOptions} from '@/assets/js/chart.pie.theme.js'
import MeasurementIcons from '@/components/MeasurementIcons.vue';
let theme = new ChartOptions();

export default {
    name: 'ScoreBreakdownChart',
    components: {
        highcharts: Chart,
        MeasurementIcons,
    },
    props: {
        total: Number,
        children: Array,
    },
    data() {
        return {
            chartOptions: null,
            clickedMeasurement: null,
            clickedMeasurementName: null,
            noCard: true,
        }
    },
    methods: {
        clickShowCard(measurement) {
            // eslint-disable-next-line no-undef
            $('.more-infos').hide()

            if(this.clickedMeasurement?.objectId == measurement.objectId)
                return this.noCard = this.noCard === true ? false : true;
                        
            this.clickedMeasurement = this.children.find(c => c.objectId.includes(measurement.objectId));
            this.noCard = false;

            setTimeout(function() {
                document.querySelector('.k-card').scrollIntoView({ behavior: 'smooth'});
            }, 250);
        },
        clickLearnMore(objectId){
            let e = "#hb-desc-" + objectId;
            // eslint-disable-next-line no-undef
			$('.more-infos').not(e).hide()
            // eslint-disable-next-line no-undef
			$(e).toggle(0,function() {
				// eslint-disable-next-line no-undef
				$(this).get(0).scrollIntoView({
					behavior: "smooth",
					block: "start"
				});
			})
        },
        getKioskKind(kind) {
            let k = kind.toLowerCase();
            if(k.includes('airquality') || k.includes('aq') || k.includes('scoreow'))
                return 'airquality';
            if(['co2', 'carbonmonoxide', 'ozone'].includes(k))
				return 'co2';
			if(['pollutant', 'pm2.5', 'pm10'].includes(k))
				return 'pollutant'
            else
                return k.toLowerCase();
        },
        getDisplayVal(m){
            if(m.type.includes("score"))
                return Math.round(m.curScore)
            if (m.curVal)
                return m.curVal
            if (m.curScore)
                return Math.round(m.curScore)
            return 0;
        },
        loadChartOptions() {
            let dataset = [];
            let scoreSum = 0;
            let sliceColor;

            this.children.forEach(c => {
                if (c.curScore >= 80) sliceColor = 'var(--score-blue-color)';
                else if (c.curScore < 80 && c.curScore >= 60) sliceColor = 'var(--score-yellow-color)';
                else sliceColor = 'var(--score-red-color)';

                if (c.curScore) {
                    dataset.push({
                        objectId: c.objectId,
                        name: c.name,
                        color: sliceColor,
                        type: c.type,
                        y: c.curScore,
                        click: 'this.clickShowCard(c)'
                    });
                    scoreSum += c.curScore;
                }
            });
            let pieRemainder = ((scoreSum*100)/this.total)-scoreSum;
            // let pieRemainder2 = (scoreSum/(this.total/100))-scoreSum;

            dataset.push({
                name: '',
                y: pieRemainder,
                color: 'var(--card-primary-bg)',
                events : {
                    click(e){
                        e.preventDefault();
                    }
                }
            });
            this.chartOptions = theme.getChartOptions(this.total, dataset);
        }
    },
    mounted() {
      this.loadChartOptions();
    },
    computed: {
        getMeasurementDisName(){
			let scoreNames = {
				scoreAQ: "Current Air Quality",
				scoreOW: "Occupant Comfort",
				scoreOA: "Overall"
			}
			return (m) => {
				if(scoreNames[m.type]){
					return scoreNames[m.type]
				} else {
					return m.name
				}
			}
		}
    }
}
</script>
<style lang="sass">
    .pie-card .pie
        .highcharts-root
            .highcharts-background
                fill: none
            .highcharts-title tspan
                fill: var(--app-primary-txt)
                font-family: 'Roboto'
                font-size: 3rem

                @media only screen and (min-width: 601px)
                    font-size: 4rem
                @media only screen and (min-width: 992px)
                    font-size: 4.5rem

            .highcharts-point
                stroke: var(--card-primary-bg)
                stroke-width: 2
        .highcharts-data-label span
            color: var(--app-primary-txt) !important
            font-size: 18px !important
            .svg-wrp
                position: relative
                top: -15px
</style>
<style lang="sass" scoped>
    $airquality: var(--airquality)
    $co2: var(--co)
    $humidity: var(--rh)
    $pollutant: var(--plt)
    $temperature: var(--spt)
    $ventilation: var(--vt)
    $infectiontransmissibility: var(--airquality)

    .container
        width: 100%

    .pie-card       
        .pie-info
            .show-card             
                display: flex
                flex-flow: column
                align-items: center
                .k-card 
                    font-weight: 400
                    margin-bottom: 32px
                    height: 226px
                    max-width: 315px
                    width: 100%
                    .k-card-measurement
                        background: var(--kiosk-card-bg)
                        border-radius: 20px
                        max-width: 315px
                        width: 100%
                        min-height: 226px
                        padding: 16px 24px 0 32px
                        box-shadow: var(--box-shadow)

                        &.spacetemperature
                            background: var(--spt2)
                        &.relativehumidity
                            background: var(--rh2)
                        &.airquality
                            background: var(--airquality2)
                        &.ventilation
                            background: var(--vt2)
                        &.co2
                            background: var(--co2)
                        &.pollutant
                            background: var(--plt2)
                        &.pm10
                            background: var(--plt2)
                        &.timeincomfortzone
                            background: var(--rh2)
                        &.infectiontransmissibility
                            background: var(--spt2)
                        &.slide-infectiontransmissibility
                            background: var(--spt2)

                        .k-card-measurement-progress
                            color: #E8912B
                            font-size: 1.2rem
                            line-height: 2rem
                            text-align: right
                            .progress-excellent
                                color: #02C39A
                        .k-card-measurement-icon
                            height: 40px
                            width: 40px
                            margin-bottom: 18px
                        .k-card-measurement-curVal
                            font-size: 3.2rem
                            font-weight: 300
                            line-height: 3.3rem
                            span
                                color: var(--app-primary-txt)
                                font-size: 1.2rem
                                margin-left: 5px
                        .k-card-measurement-name
                            font-size: 1.65rem
                            line-height: 2rem
                .learn-more
                    text-align: center
                    .btn
                        color: var(--app-primary-txt)
                        border-radius: 12px
                        font: normal normal 16px/24px Roboto
                        letter-spacing: 0.18px
                        height: 40px
                        width: 180px
    .more-infos
        display: none
        .modal-card
            .card-header
                h3
                    font: normal 400 28px/30px 'Roboto', sans-serif
                    letter-spacing: 0.18px
                    margin: 0
                    .hc-unit
                        color: #ffffffb3
                        font: normal 300 14px/24px 'Roboto', sans-serif
                        letter-spacing: 0.5px
            .card-body
                .hc-description
                    margin-top: 10px
                .hc-more-info-wrap
                    .hc-more-info
                        .hc-desc-more-info
                            p
                                margin: 0
                            p.mi-title
                                margin-top: 1em
                            ul
                                margin: 0
                                padding-left: 20px
                    a
                        cursor: pointer
                        font: normal 300 16px/24px 'Roboto', sans-serif
                        letter-spacing: 0.18px
                        margin-top: 10px
</style>