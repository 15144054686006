/**
 * This Library will be used to cache common HTTP Requests
 */
import {HTTPClient} from "./http"

class QueryClient {
    constructor(){
        this.queries = {
            'sample-request': {
                url: "/sample",
                query: {},
                results: null
            }
        }
        this.client = new HTTPClient();
    }
    /**
     * @description Return a usable query
     * @param {string} key 
     * @param {string} url 
     * @param {object} query 
     */
    async useQuery(key, url, query){
        let requiresNetworkFetch = false;
        if(this.queries[key] == null) requiresNetworkFetch = true;
        if(this.queries[key]?.url != url) requiresNetworkFetch = true;
        if(this.queries[key]?.query && query){
            if(JSON.stringify(this.queries[key]?.query) != JSON.stringify(query)){
                // console.log(this.queries[key]?.query, query);
                requiresNetworkFetch = true;
            }
        }
        if(requiresNetworkFetch){
            // console.log('Caching Network request to ' + url)
            let res = await this.client.robustQuery(url, query)
            this.queries[key] = {
                url, query, results: res
            }
            return this.queries[key].results
        } else {
            // console.log("Returning Cached Network Request " + url);
            return this.queries[key].results
        }
    }
}

// Cache the Class
const Query = new QueryClient()
window.CachedQueries = Query

/**
 * 
 * @param {*} siteId
 * @returns {Promise<object>}
 */
export async function useGetSite(siteId){
    let {site} = await Query.useQuery(
        `get-site-${siteId}`,
        "/api/site",
        {siteId}
    )
    // console.log(site)
    return site
}