<template lang="pug">
	#homePage
		Navbar(tab="home" :scoreHelp="scoreHelp")
		main(v-if="ready")
			v-container(v-show="sites.length > 0 && mapViewMode == true").container-lg
				v-row(align="center").align-center.my-4
					v-col.col-12.col-md-8
						h4 {{project.name}}
						div(v-if="projectId&&projectId=='pwNeghwfDaNcSlOApelIYmA6'" style="margin-bottom:6px;")
							a.modal-trigger(href="#modal-airnow" style="color:var(--app-tertiary-txt)")
								span EPA AirNow AQI
					v-col.col-12.col-md-4.d-flex.justify-end
						v-switch(label="Map View" v-model="mapViewMode" color="primary" dense)
				v-row
					v-col(:cols="12")
						.aqcard(style="background-color: var(--app-secondary-bg);").no-gradient
							.aqcard-header(style='padding-left:1.5rem;')
								.aqcard-title
									<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M0.738041 16.7252C-0.0338883 18.0585 0.92823 19.7273 2.46889 19.7273H19.5311C21.0718 19.7273 22.0339 18.0585 21.262 16.7252L12.7309 1.98965C11.9605 0.659083 10.0395 0.659084 9.26915 1.98965L0.738041 16.7252ZM12.0909 16.4545H9.90909V14.2727H12.0909V16.4545ZM12.0909 12.0909H9.90909V7.72727H12.0909V12.0909Z" fill="#FDE22B"/>
									</svg>
									span.ml-4 {{totalActiveAlertCount}} Active Alerts
							.aqcard-body(style='margin: 1.5rem;padding:0;margin-top:0;')
								div(ref="ProjectMapSelector" style="height: calc(100vh - 350px); width: 100%;")
			v-container(v-if="sites.length > 0 && mapViewMode == false")
				v-row(align="center" style="margin-top:1rem;").align-center
					v-col.col-12(v-bind:class='{"col-md-4": hasLatLong, "col-md-9": !hasLatLong}')
						h4 {{project.name}}
						div(v-if="projectId&&projectId=='pwNeghwfDaNcSlOApelIYmA6'" style="margin-bottom:6px;")
							a.modal-trigger(href="#modal-airnow" style="color:var(--app-tertiary-txt)")
								span EPA AirNow AQI
					v-col.col-12.col-md-5.d-flex.justify-end(v-if="hasLatLong")
						v-switch(label="Map View" v-model="mapViewMode" color="primary" dense)
					v-col.col-12.col-md-3.d-flex.justify-end
						v-text-field.haq-search(v-model="searchTerm" append-icon="mdi-magnify" label="Search Sites" dense outlined hide-details single-line)
						//- .input-field.darken.right
						//- 	input#searchbar.white-text(name='searchbar', type='text', placeholder='Filter Sites' v-model="searchTerm")
						//- 	//- label(for='searchbar')
						//- 	i.material-icons(style="color:var(--app-tertiary-txt)") search
				v-row
					v-col.col-sm-12
						.material-table.aq-sitecard.elevation-8(v-for="site in sites" :key="site.objectId" v-show="matchSite(site)")
							router-link(class="aq-sitecard-header waves-effect waves-light" v-bind:to="'/projects/'+projectId+'/sites/' + site.objectId" @click="setSelectedSite(site)")
								.aq-sitecard-image(:style='"background-image:url(" + (site.logo ? site.logo.url.replace("http", "https") : "") + ")"')
								//- .aq-sitecard-separator.hide-on-small-only
								.aq-sitecard-progress(v-if="site.aqEnabled !== false && site.scoreOA&&site.scoreOA.curScore")
									DonutIndicator(v-if="site.scoreOA&&site.scoreOA.curScore" v-bind:id="site.objectId +'-scoreOA'", v-bind:value="Math.round(site.scoreOA.curScore)", v-bind:size="45")
								.aq-sitecard-title(v-show="!site.address") {{site.name}}
								.aq-sitecard-title-address(v-show="site.address")
									.siteName {{site.name}}
									.siteAddress {{site.address}}
								.aq-sitecard-icon.hide-on-small-only
									i.material-icons(style="color:var(--app-secondary-txt)") chevron_right
			v-container(v-if="sites == null || sites.length == 0")
				v-row
					v-col
						.empty-card There are no sites for this project.
		footer
			.container
				.row
					.col.s12
						span.powered(v-if="whitelabel") {{whitelabel}}
		ModalWelcome(:logo="whitelabel")
		AirNowModal(v-if="true")
</template>
<script>
import Navbar from '@/components/Navbar.vue';
import DonutIndicator from '@/components/DonutIndicator.vue';
import ModalWelcome from '@/components/NewUserOnboarding/ModalWelcome.vue';
import AirNowModal from '@/components/AirNowModal';
import {HTTPClient} from '../assets/js/http';
import {wlData} from '../assets/js/constants';
import { getSVGString } from '../assets/js/mapSvgIcons';
import AlarmInfoWindow from '@/components/maps/AlarmInfoWindow.vue';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import Vue from 'vue';
let client = new HTTPClient();

export default {
	name: "Home",
	components: {
		Navbar,
		DonutIndicator,
		ModalWelcome,
		AirNowModal,
		AlarmInfoWindow
	},
	data() {
		return {
			ready: false,
			totalActiveAlertCount: 0,
			sites: [],
			searchTerm: '',
			mapViewMode: false,
			hasLatLong: false,
			whitelabel: this.$store.state.project?.whitelabel?wlData.poweredByFooter:'',
			projectId: '',
			project: null,
			scoreHelp: {
				overallCurScore: null,
				measurements: null,
				key: null,
			},
		}
	},
	methods: {
		setSelectedSite(site){
			this.$store.commit('setSite', site);
		},
		startOnboardingNewUser(){
			// fetch the ModalWelcome
			let modal = document.getElementById("modal-onboard-new-user");
			let instance = M.Modal.getInstance(modal);
			instance.open();
		},
		getCenterPoint(coords) {
			var numCoords = coords.length;
			var X = 0;
			var Y = 0;
			var Z = 0;

			for (var i = 0; i < numCoords; i++) {
				var lat = coords[i][0] * Math.PI / 180;
				var lon = coords[i][1] * Math.PI / 180;

				var x = Math.cos(lat) * Math.cos(lon);
				var y = Math.cos(lat) * Math.sin(lon);
				var z = Math.sin(lat);

				X += x;
				Y += y;
				Z += z;
			}

			X /= numCoords;
			Y /= numCoords;
			Z /= numCoords;

			var LON = Math.atan2(Y, X);
			var hyp = Math.sqrt(X * X + Y * Y);
			var LAT = Math.atan2(Z, hyp);

			var centerLat = LON * 180 / Math.PI;
			var centerLon = LAT * 180 / Math.PI;

			return [centerLat, centerLon];
		},
		async initMap(){
            try{
                console.log("initMap");
                // if this.$refs.ProjectMapSelector is not set, then return
                if(!this.$refs.ProjectMapSelector) return;
                const InfoWindow = Vue.extend(AlarmInfoWindow);
                const sitesWithLocation = this.sites.filter(site => site.location?.latitude && site.location?.longitude);
                const center = this.getCenterPoint(sitesWithLocation.map(site => [site.location.latitude, site.location.longitude]));
                // console.log(center)
                const map = new google.maps.Map(this.$refs.ProjectMapSelector, {
                        zoom: 11,
                        mapTypeControl: false,
                        streetViewControl: false,
                        center: {
                        lat: center[1],
                        lng: center[0]
                    }
                });

                // group the icons if they are too close to each other
                // const markerCluster = new MarkerClusterer(map, [], {
                // 	imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
                // 	zoomOnClick: false,
                // 	averageCenter: true,
                // 	maxZoom: 15
                // });
                // make the cluster collapse soon
                const markerCluster = new MarkerClusterer({map, markers: []});

                // close other infoWindow when open new one
                let openInfoWindow = null;

                sitesWithLocation.forEach(site => {
                    this.totalActiveAlertCount += site.activeAlertsCount || 0;
                    site.projectId = this.projectId;
                    const marker = new google.maps.Marker({
                        position: {
                            lat: site.location.latitude,
                            lng: site.location.longitude
                        },
                        map: map,
                        title: site.name,
                        icon: {
                            url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(
                                getSVGString(
                                    site.activeAlertsCount || 0
                                )
                            ),
                            size: new google.maps.Size(40, 40),
                            scaledSize: new google.maps.Size(40, 40),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(20, 40)
                        },
                        optimized: true
                    });

                    var infoWindowInstance = new InfoWindow({
                        propsData : {
                            site
                        },
                        events: {
                            'close': () => {
                                infoWindow.close();
                            }
                        }
                    });
                    infoWindowInstance.$mount();
                    const infoWindow = new google.maps.InfoWindow({
                        content: infoWindowInstance.$el,
                        maxWidth: "408px",
                        maxHeight: "162px",
                        background: "#4E7CFF"
                    });
                    infoWindow.addListener('domready', () => {
                        const infoWindowContent = infoWindow.getContent().querySelector('div');
                        infoWindowContent.style.height = '162px';
                        const parentNode = infoWindow.getContent().parentNode;
                        console.log(parentNode)
                        parentNode.style.overflow = "hidden"
                        parentNode.parentNode.style.padding = "0px"
                        let defaultCloseButton = parentNode.parentNode.querySelector("button");
                        defaultCloseButton.style.display = "none";
                        const closeBtn = infoWindowContent.querySelector(".info-button.close");
                        closeBtn.addEventListener("click", () => {
                            infoWindow.close();
                        })
                    });
                    
                    marker.addListener('click', () => {
                        if (openInfoWindow) {
                            openInfoWindow.close();
                        }
                        openInfoWindow = infoWindow;
                        infoWindow.open(map, marker);
                    });
                    markerCluster.addMarker(marker);
                });
            } catch (error) {
                console.error(error);
            }
        }
	},
    created(){
        window.initMap = this.initMap;
        console.log('setting window.initMap');
    },
	mounted() {
		let mod = document.querySelectorAll('.modal');
		// console.log('mod', mod);
		window.M.Modal.init(mod, {dismissible: false});
		let loadSites = async () => {
			try {
				let res = await client.robustQuery('/api');
				this.sites = res.sites;
				this.$store.commit('setProject', res.project);
				this.project = res.project;
				this.projectId = res.project.objectId;
				this.mapViewMode = res.project.mapView=== true;
				console.log('mapViewMode', this.mapViewMode);
				if (!this.whitelabel && res.project.whitelabel)
					this.whitelabel = res.project.whitelabel;

				let user = this.$store.state.user;
				if(this.sites)
					this.ready = true;
				if(this.sites.find(site => site.location?.latitude && site.location?.longitude)){
					this.mapViewMode = true;
					this.hasLatLong = true;
					this.$nextTick(() => {
						this.initMap();
					})
				}
				
				if(!user?.onboardingComplete || !user.onboardingComplete?.modals)
					this.startOnboardingNewUser();

                console.log('sites', this.sites);
			} catch (error) {
				console.error(error);
				window.M.toast({html: 'Cannot Load Sites'})
			}
		}
		loadSites();
	},
	computed: {
		matchSite(){
			return (site) => {
				let searchRegex = new RegExp(this.searchTerm, "i");
				if(searchRegex.test(site.name)){
					return true
				} else if(site.address && searchRegex.test(site.address)){
					return true
				} else if(site.shortName && searchRegex.test(site.shortName)){
					return true
				} else {
					return false
				}
			}
		}
	}
}
</script>
<style lang="scss" scoped>
	#searchbar {
		// margin:0;
		// width:260px;
		// &:focus {
		//     border: 0;
		//     box-shadow: none;
			
		//     &::placeholder {
		//         color: #ccc;
		//     }
		// }
	}
	.siteName {
		font-size: 18px;
		margin-top: 18px
	}
	.siteAddress {
		font-size: 14px;
		line-height: 1.05;
	}
	.aq-sitecard {
		background: var(--app-tertiary-bg);
		border-radius: 20px;
		// box-shadow: var(--box-shadow);
		height: 80px;
		margin: 0.5rem 0 1rem 0;
		overflow: hidden;
		position: relative;
		transition: box-shadow .25s;

		.aq-sitecard-header {
			align-items: center;
			display: flex;
			height: 80px;
			&:hover {
				background: rgba(0, 0, 0, 0.1);
			}
		
			.aq-sitecard-image {
				background-size: cover;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				height: 80px;
			}
			.aq-sitecard-separator {
				background: rgba(125, 133, 197, 0.2);
				height: 24px;
				width: 2px;
			}
			.aq-sitecard-progress {
				margin: auto 20px;
				min-width: 45px;
				width: 45px;
			}
			.aq-sitecard-title,
			.aq-sitecard-title-address {
				color: var(--app-primary-txt);
				flex-grow: 4;
				margin-right: 35px;
				overflow: hidden;
				white-space: nowrap;
			}
			.aq-sitecard-title {
				font-size: 20px;
				text-overflow: ellipsis;
			}
			.aq-sitecard-title-address {
				.siteName,
				.siteAddress {
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.siteName {
					margin: 0;
				}
			}
			.aq-sitecard-icon {
				margin-right: 2.5rem;
				i {
					font-size: 32px;
					line-height: 80px;
				}
			}
		}
	}
	@media only screen and (min-width: 601px) {
		.aq-sitecard {
			.aq-sitecard-image {
				margin-right: 24.88px;
				min-width: 120px;
				width: 120px;
			}
		}
	}

	@media only screen and (max-width: 600px) {
		.aq-sitecard {
			.aq-sitecard-image {
				margin-right: 0;
				min-width: 80px;
				width: 80px;
			}
			.aq-sitecard-progress {
				margin: inherit 16px;
			}
		}
	}
</style>