<template lang='pug'>
    #modal-score-breakdown.haq-modal.modal
        .modal-content
            .modal-header
                h2 Overview
                i.material-icons.modal-close close
            .modal-body(v-if="scores.length > 0")
                .wrp(v-for='s in scores')
                    .pies(v-if="s.children.length > 0")
                        PieChartHelpBreakdown(:total="Math.round(overallCurScore)" :children="s.children")
                    .no-pies(v-else) There are no measurements.
            .modal-body(v-else) There are no measurements.
</template>
<script>
import {HTTPClient} from '@/assets/js/http';
import DonutIndicator from '@/components/DonutIndicator.vue';
import PieChartHelpBreakdown from '@/components/help/scoreBreakdown/ScoreBreakdownChart.vue';

let client = new HTTPClient();

export default {
    name: 'ScoreBreakdownModal',
    components: {
        DonutIndicator,
        PieChartHelpBreakdown,
    },
    data() {
        return {
            scoreTypes: ['scoreAF','scoreAQ','scoreOW'],
            scores: [],
            thresholds: [],
        };
    },
    props: {
        overallCurScore: Number,
        measurements: Array
    },
    methods: {
        buildScore(measurement,pt,allPoints) {
            if(measurement.curScore && !pt.curScore)
                pt['curScore'] = measurement.curScore;
            
            let children = [];
            let pieTotal = Math.round(this.overallCurScore);
            let pieSlices = measurement.childMeasurements.length;

            measurement.childMeasurements.forEach(cm => {
                if(this.scoreTypes.includes(cm.type) && cm.curScore) {
                    let percentage = pieTotal/pieSlices;
                    cm['percentage'] = percentage;
                    children.push(cm);

                    let t = {
                        'name' : cm.name,
                        'threshold' : cm.threshold,
                    }
                    this.thresholds.push(t);
                }
                else if (!this.scoreTypes.includes(cm.type)){
                    let cpt = allPoints.results.find(p => p.type.includes(cm.type));
                    if (cpt && cpt.description) {
                        if(cm.type.length > 0)
                            cpt['type'] = cpt.type[0]
                        if(cm.unit)
                            cpt['unit'] = cm.unit
                        if(cm.curVal)
                            cpt['curVal'] = cm.curVal
                        if(cm.curScore) {
                            cpt['curScore'] = cm.curScore

                            let pieSlice = Math.round(cm.curScore);
                            let percentage = (pieSlice*pieTotal)/100;
                            // let percentage = 360 * (pieSlice/pieTotal);
                            cpt['percentage'] = percentage;
                        }
                        children.push(cpt);

                        let t = {
                            'name' : cpt.name,
                            'threshold' : cpt.threshold,
                        }
                        this.thresholds.push(t);
                    }
                }
            });
            
            let score = {
                'title' : pt.name,
                'curScore' : measurement.curScore,
                children,
                'scoreOrder' : pt.scoreOrder,
            };
            return score;
        },
        async fetchPointTypes(){
            try {
                if (this.measurements) {
                    let ptRes = await client.robustQuery('/api/pointtypes');
                    this.measurements.forEach(m => {
                        let pt = ptRes.results.find(p => p.type.includes(m.type));
                        if(this.scoreTypes.includes(m.type) && pt && m.childMeasurements)
                            this.scores.push(this.buildScore(m, pt, ptRes));
                    });
                    this.scores.sort(function(a,b){ return a.scoreOrder - b.scoreOrder; });
                }
            }
            catch (error) {
                console.error(error);
                window.M.toast({html: 'Cannot Retrieve Point Types'});
            }
            finally {
                let tb = document.querySelectorAll('.tabs');
                window.M.Tabs.init(tb);
            }
        },
    },
    mounted() {
        this.fetchPointTypes();
    },
};
</script>
<style lang="sass" scoped>
#modal-score-breakdown
    @media only screen and (max-width: 992px)
        width: 95%

    @media only screen and (max-width: 601px)
        overflow-y: scroll
        max-height: 80%
    
    .modal-header
        display: flex
        justify-content: space-between
        .modal-close
            font-size: 38px
</style>