<template lang="pug">
    #step1(v-if="sites")
        .login-card.step-project(v-show="step == 1")
            .content(data-step='1')
                //- .title Select a Site
                .input-group
                    .vuetify(data-app)
                        v-select#selectProject2.haq-text-field(v-model="siteId" :items="sites" item-text="name" item-value="objectId" label="Select a Site" hide-details
                            :menu-props="{ bottom: true, offsetY: true, closeOnClick: true, closeOnContentClick: true, }")
                button#chooseSiteBtn.secondaryBtn.btn.haq-btn.waves-effect.brandColor(v-on:click="selectSite" :disabled="!siteId") Confirm
                .strike
                    span or
                button#createSiteBtn.secondaryBtn.btn.haq-btn.waves-effect.brandColor(v-on:click="moveToCreateSiteUI") Create New Site
        .login-card.step-login(v-show="step == 2")
            .content(data-step='2')
                //- .title Create Your Site
                .input-group
                    label(for='login-pname') Site Name
                    input#login-pname.haq-text-field(name='login-pname', type='text' v-model="mySiteName" placeholder="Enter a Site Name")
                //- .error(v-if="errorText") {{errorText}}
                button#confirmCreateSite.secondaryBtn.btn.haq-btn.waves-effect.waves-light.brandColor(v-on:click="nextStep" :disabled="!mySiteName") Create New Site
                .strike
                    span or
                button.secondaryBtn.btn.haq-btn.waves-effect.brandColor(v-on:click="moveToSelectSiteUI") Select a Site
        //- p What would you like to name your site?
        //- .row
        //-     .col.s12.input-field-outlined
        //-         input#sitename(placeholder="123 Main St" type="text" name="sitename" v-on:keyup="setSiteName" :value="mySiteName")
        //-         label(for="sitename") Site Name
                //- span.helper-text#sitenamehelper.red-text asdf
        //- button#nextbtn.btn.waves-effect.haq-btn.brandColor.right(@click="nextStep" :disabled="!mySiteName") Next
    .loading(v-else)
        span Loading Sites...
</template>
<style lang="sass" scoped>
    .secondaryBtn
        background: #3456F7
        border: 0
        border-radius: 12px
        color: #fff
        margin: 24px 0
        // padding: 10px
        // height: 40px
        max-height: 360px
        width: 100%
        .disabled
            background: #B9E2FF
            color: #52B2F3
    .haq-text-field, #login-pname
        border: 1px solid #005B9A
        box-sizing: border-box
        border-radius: 12px
        margin: 8px 0 8px
    .v-select__slot
        background: #fff
        font: normal 400 16px/24px Roboto
        label
            color: #005B9A !important
            line-height: 38px !important
        .v-select__selections
            color: #005B9A !important
        .v-input__append-inner .v-icon
            color: #005B9A !important
    .input-group
        text-align: left
        label
            font: normal 400 14px/20px Roboto
            color: #005B9A
        input
            border: 1px solid #005B9A
            box-sizing: border-box
            border-radius: 12px
            color: #005B9A
            font: normal 400 16px/24px Roboto
            margin: 8px 0 24px
            padding: 8px 20px
        ::placeholder
            color: #005B9A
            opacity: 0.4
    .strike
        display: block
        text-align: center
        overflow: hidden
        white-space: nowrap
        > span
            color: #005B9A
            font: normal 400 14px/20px Roboto
            position: relative
            display: inline-block
            opacity: 0.5
            &:before,
            &:after
                content: ""
                position: absolute
                top: 55%
                width: 9999px
                height: 1px
                background: #005B9A
                opacity: 0.5
            &:before
                right: 100%
                margin-right: 8px
            &:after
                left: 100%
                margin-left: 8px
</style>
<script>
import {HTTPClient} from '../../../assets/js/http';
let client = new HTTPClient();

export default {
    name: "Step1",
    props: ['siteName', 'kit'],
    data(){
        return {
            mySiteName: this.siteName,
            step: 1,
            siteId: '',
            sites: null,
            pname: '',
            errorText: null,
        }
    },
    methods: {
        async moveToCreateSiteUI(){
            this.step = 2;
            this.mySiteName = '';
        },
        async moveToSelectSiteUI(){
            this.step = 1;
        },
        async selectSite(){
            try{
                for(let s of this.sites){
                    if(s.objectId == this.siteId){
                        this.$emit('setSiteName', s.name);
                        this.$emit('setSite', s);
                        this.$emit('nextStep');
                        break;
                    }
                }
            }catch(e){
                window.M.toast({html: e.message});
                console.error(e);
            }
        },
        async nextStep(){
            try{
                $('#nextbtn').addClass('disabled');
                $('#confirmCreateSite').addClass('disabled');
                // make sure sitename exists
                this.$emit('setSiteName', this.$data.mySiteName);
                let results = await client.robustPost('/api/sites', {
                    name: this.$data.mySiteName,
                    productId: '7W610cxPyk61GrYQ3pfJzvpn'
                });
                // let results = JSON.parse('{"successMessage":"Success","results":{"name":"Test Site","product":{"objectId":"1nZXAA73qu"},"project":{"objectId":"AqeRT1fAGW"},"id":"xh7diBsGcp","createdAt":"2022-01-05T22:07:01.679Z","updatedAt":"2022-01-05T22:07:01.679Z","uuid":"ba6849eb-9e56-46d4-80de-71bc3dd5f4af"}}');
                // console.log(JSON.stringify(results));
                if(results.results){
                    this.$emit('setSite', results.results);
                    this.$emit('nextStep');
                }
            }catch(e){
                window.M.toast({html: e.message});
                console.error(e);
            }finally{
                $('#nextbtn').removeClass('disabled');
                $('#confirmCreateSite').removeClass('disabled');
            }
        },
        previousStep(){
            this.$emit('previousStep');
        },
        setSiteName(data){
            // console.log('Step1 setSiteName', data);
            this.mySiteName = $('#sitename').val();
        }
    },
    async mounted(){
        // if(this.siteName)
            window.M.updateTextFields();
            let sites = await this.$store.dispatch('getSites');
            this.sites = sites;
            // console.log('sites', sites);

        // $('#sitename').on('keyup', function(){
        //     console.log('change', $('#sitename').val());
        //     // this.$emit('setSiteName', $('#sitename').val());
        // });
    }
}
</script>