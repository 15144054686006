<template>
    <div id="onboarding3">
        <Navbar />
        <div class="container" style="min-width:85%">
            <div class="row">
            <div class="row detailsRow">
                <h5 class="center">
                    <button @click="backToPointTypes" class="btn waves-effect waves-light left">
                    Select Point Types (Step 2/3)
                    </button>
                    Onboarding (Step 3/3) Create Measurements
                </h5>
            </div>
            <div class="row">
                <div class="col s6 m4 input-field">
                   <select ref="unitSelect" v-model="units" id="measurementUnits" class="white-text">
                       <option value="imperial">Imperial (°F,Kg)</option>
                       <option value="metric">Metric (°C,lbs)</option>
                   </select>
                   <label for="measurementUnits">Units</label>
                </div>
            </div>
            <div class="row">
                <div class="col s12 m6">
                    <h6 style="padding-bottom:10px;border-bottom:1px solid;margin-bottom:25px">Select Points to add to measurements</h6>
                    <div class="commanderTopology">
                           <TreeNode v-for="node in aQTopology" :key="node.id" :nodeData="node" step="measurementSelection" :depth="0" @selecteeAdded="selecteeAdded" />
                    </div>
                </div>
                <div class="col s12 m6" style="padding-bottom:25px">
                    <h6 v-if="selecteeErrorText" style="color:#d35400;padding-bottom:10px;border-bottom:1px solid white;margin-bottom:25px">{{selecteeErrorText}}</h6>
                     <button class="btn waves-effect waves-light" style="background-color:#d35400" @click="createEquipmentMeasurement">Create Equipment Measurement</button>
                     <div class="pointTopology">
                         <MeasurementNode v-for="node in zoneTopology" :key="node.id" :nodeData="node" v-on:selectorAdded="selectorAdded" v-on:addMtoM="addMtoM"/>
                     </div>
                </div>
            </div>
            <div class="row">
                <div class="col s12">
                   <h5 class="center">
                        <button class="btn waves-effect waves-light" @click="onboardSite">
                        Create Measurements
                    </button>
                   </h5>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>
<style lang="scss">
    .commanderTopology, .pointTopology {
		padding: 20px;
		overflow-y: scroll;
		overflow-x: hidden;
		max-height: 60vh;
	}
    .select-dropdown{
        color: #ffffff;
    }
</style>
<script>
import Navbar from '@/components/Navbar.vue';
import MeasurementNode from '@/components/MeasurementNode.vue'
import TreeNode from '@/components/TreeNode.vue'
import {measurements} from '../assets/js/constants'
import { HTTPClient } from "../assets/js/http";
import { v4 } from 'uuid';
let client = new HTTPClient();
export default {
    name: "Onboarding3",
    components: {
        Navbar,
        TreeNode,
        MeasurementNode
    },
    data(){
       
        let siteId = this.$route.query.siteId;
        console.log('pointTopology' + siteId)
        let aQTopology = localStorage.getItem('pointTopology' + siteId);
        aQTopology = JSON.parse(aQTopology);
        let ma = JSON.parse(JSON.stringify(aQTopology));
        let zoneTopology = ma.filter((el) => el.type !== "point");
        return {
            aQTopology,
            zoneTopology,
            selector: null,
            selectee: null,
            selecteeErrorText: 'Add/Edit Measurement',
            siteId,
            units: "imperial"
        }
    },
    methods: {
        backToPointTypes(){
            this.$router.push({name: 'Onboarding2', query: {siteId: this.$route.query.siteId}});
        },
        async onboardSite(event){
            // event.target.disabled = true;
            let payload = {};
            payload.topology = this.cloneObj(this.zoneTopology);
            payload.siteId = this.siteId;
            payload.units = this.units;
            try {
                let res = await client.robustPost('/api/onboarding/measurements/', payload);
                window.M.toast({html: res.msg});
            } catch (error) {
                console.error(error);
                window.M.toast({html: "Cannot Save Measurements"});
            }
        },
        cloneObj(obj) {
            return JSON.parse(JSON.stringify(obj))
        },
        selecteeAdded(node){
            if(!this.selector){
				this.selecteeErrorText = "Please select a node";
			} else {
                console.log(node);
                if(this.selector.accepts.indexOf(node.pointType) > - 1){
                    this.selecteeErrorText = '';
                    let p = this.zoneTopology.find((el) => el.id === this.selector.id);
                    let index = this.zoneTopology.indexOf(p) + 1;
                    let clone = this.cloneObj(node);
                    clone.selected = false;
                    clone.id = v4();
                    clone.depth = this.selector.depth + 1;
                    clone.parentId = this.selector.id;
                    this.zoneTopology.splice(index, 0, clone);

                } else {
                    this.selecteeErrorText = "Cannot Add " + node.pointType + " to " + this.selector.measurementType
                }
				
			}
        },
        addMtoM(node){
            console.log('Add m to m');
            if(!this.selector){
				this.selecteeErrorText = "Please select a node";
			} else {
                console.log(node);
                if(node.type === "measurement") {
                    this.selecteeErrorText = '';
                    let p = this.zoneTopology.find((el) => el.id === this.selector.id);
                    p.realTime = false;
                    let index = this.zoneTopology.indexOf(p) + 1;
                    let clone = this.cloneObj(node);
                    clone.selected = false;
                    clone.depth = this.selector.depth + 1;
                    clone.parent = this.selector.id;
                    clone.id = v4();
                    let parent = this.zoneTopology.find((el) => node.parent === el.id);
                    clone.name = clone.name + " - " + parent.name
                    this.zoneTopology.splice(index, 0, clone);

                } else {
                    this.selecteeErrorText = "Cannot Add " + node.pointType + " to " + this.selector.measurementType
                }
				
			}
        },
        selectorAdded(node){
            console.log(`Selctor:  ${node.name}`)
			if(!this.selector){
				this.selector = node;
				this.$set(node, 'selected', true)
			} else {
				this.selector.selected = false;
				this.$set(this.selector, 'selected', false)
                this.selector = node;
                
				this.$set(node, 'selected', true)
				this.selector.selected = true;
			}
        },
        createEquipmentMeasurement(){

        },
        assingMeasurements(){
            // let unitSelect = this.$refs.unitSelect;
            // window.M.FormSelect.init(unitSelect);
             for(let z of this.zoneTopology){
                if(z.type === "zone" || z.type === "site"){
                    let index = this.zoneTopology.indexOf(z) + 1;
                    let zonePoints = this.aQTopology.filter(function(el){
                        return el.parent == z.id && el.type === "point"
                    });
                    if(zonePoints.length > 0){
                        console.log(zonePoints)
                        let mst = measurements.matchMeasurements(zonePoints);
                        for(let m of mst){
                            m = JSON.parse(JSON.stringify(m));
                            let measurement = m.m
                            let points = m.points;
                            measurement.id = v4();
                            measurement.parents = z.parents.concat(z.id);
                            measurement.parent = z.id;
                            measurement.depth = z.depth + 1;
                            this.zoneTopology.splice(index, 0, measurement);
                            for(let p of points){
                                p.zoneParent = z.id;
                                p.measurementParent = measurement.id;
                                p.id = v4();
                                p.depth = measurement.depth + 1;
                                this.zoneTopology.splice(index + 1, 0, p);
                            }
                        }
                    }
                }
            }
        },
        assingNonRealTimeMeasurements(){
            let topologyClone = this.cloneObj(this.zoneTopology);
            for(let z of this.zoneTopology){
                if(z.type === "zone"){
                    let childZones = this.zoneTopology.filter(function(el) {
                        return el.type === "zone" && el.parent == z.id
                    });
                    let childZonesIds = childZones.map((el) => el.id);
                    let childMeasurements = this.zoneTopology.filter(function(el) {
                        return el.type === "measurement" && childZonesIds.indexOf(el.parent) > -1 && el.measurementType!== "equipment"
                    });
                    if(childMeasurements.length > 0){
                        let mta = {};
                        for(let cm of childMeasurements){
                            if(!mta[cm.measurementType]){
                                mta[cm.measurementType] = {
                                    id: v4(),
                                    type: "measurement",
                                    measurementType: cm.measurementType,
                                    parent: z.id,
                                    kind: cm.kind,
                                    depth: z.depth,
                                    realTime: false,
                                    accepts: cm.accepts,
                                    name: cm.name,
                                    childMeasurements: [cm]
                                }
                            } else {
                                mta[cm.measurementType].childMeasurements.push(cm)
                            }
                        }
                        console.log(mta);
                        for(let nonRealTime in mta){
                            let index = this.zoneTopology.indexOf(z);
                            console.log(index)
                            this.zoneTopology.splice(index + 1, 0, mta[nonRealTime]);
                            for(let c of mta[nonRealTime].childMeasurements){
                                let originalZone = this.zoneTopology.find((el) => el.id == c.parent);
                                let cm = this.cloneObj(c);
                                cm.id = v4();
                                cm.parent = mta[nonRealTime].id;
                                cm.depth = mta[nonRealTime].depth + 1;
                                cm.name = cm.name + " " + originalZone.name
                                cm.parseId = cm.parseId || null;
                                cm.type = "childMeasurement";
                                cm.originalMeasurement = c.id;
                                cm.parent = originalZone.id;
                                this.zoneTopology.splice(index + 2, 0, cm);
                            }
                        }
                    }
                }
            }
        }
    },
    mounted(){
        this.assingMeasurements();
        // this.assingNonRealTimeMeasurements();
    }
}
</script>