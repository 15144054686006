<template lang="pug">
	.row(style='margin-top:10px;margin-bottom:10px;')
		.col.s12
			.card.material-table.aqcard.elevation-8
				.aqcard-header.waves-effect.waves-light(style='padding-left:1.5rem;' v-on:click="expand").expandable
					.aqcard-progress
						DonutIndicator(:id="type+'graph'" :value="aqCardIndicatorScore", :size="40" v-if="showDonutIndicator")
						<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
							<path d="M25.1201 20.33C25.1201 21.6156 24.7389 22.8723 24.0247 23.9412C23.3104 25.0101 22.2953 25.8432 21.1076 26.3352C19.9198 26.8272 18.6129 26.9559 17.352 26.7051C16.0912 26.4543 14.933 25.8352 14.0239 24.9262C13.1149 24.0172 12.4958 22.859 12.245 21.5981C11.9942 20.3372 12.1229 19.0303 12.6149 17.8426C13.1069 16.6548 13.94 15.6397 15.0089 14.9254C16.0778 14.2112 17.3345 13.83 18.6201 13.83" :stroke="equipmentGearColor" stroke-width="1.5"/>
							<path d="M21.1602 6.66C20.323 6.50011 19.4726 6.41976 18.6202 6.42C17.4316 6.42541 16.2486 6.58337 15.1002 6.89V9.54C14.5434 9.72416 14.0017 9.95152 13.4802 10.22L11.6002 8.33C9.53318 9.54138 7.81161 11.263 6.60023 13.33L8.50023 15.2C8.23179 15.7215 8.00443 16.2631 7.82023 16.82H5.17023C4.55524 19.1128 4.55524 21.5272 5.17023 23.82H7.83023C8.01439 24.3769 8.24175 24.9185 8.51023 25.44L6.62023 27.35C7.83161 29.417 9.55318 31.1386 11.6202 32.35L13.5002 30.47C14.0217 30.7384 14.5634 30.9658 15.1202 31.15V33.81C17.413 34.425 19.8274 34.425 22.1202 33.81V31.12C22.6771 30.9358 23.2187 30.7084 23.7402 30.44L25.6202 32.32C27.6873 31.1086 29.4088 29.387 30.6202 27.32L28.7402 25.44C29.0087 24.9185 29.2361 24.3769 29.4202 23.82H32.0802C32.3869 22.6716 32.5448 21.4886 32.5502 20.3C32.5487 19.7987 32.5187 19.2979 32.4602 18.8" :stroke="equipmentGearColor" stroke-width="1.5"/>
							<path d="M27.0799 20.85C31.5755 20.85 35.2199 17.2056 35.2199 12.71C35.2199 8.2144 31.5755 4.57 27.0799 4.57C22.5843 4.57 18.9399 8.2144 18.9399 12.71C18.9399 17.2056 22.5843 20.85 27.0799 20.85Z" :stroke="equipmentGearColor" stroke-width="1.5"/>
							<path d="M24.5498 11.89L26.8398 14.25L30.0098 10.63" :stroke="equipmentGearColor" stroke-width="1.5"/>
						</svg>
					.aqcard-title
						span.mr-1 {{title}}
						SectionEditor(:text="title" title="Section Name" :measurementId="measurementObjectId" :iconSize="20" v-on:updateText="updateText")
					.aqcard-icon
						i.material-icons {{expanded.icon}}
				div
					.aqcard-body(v-if="expanded.status")
						.aqcard-bodyborder
						.aqcard-bodycontent
							//- BUTTON
							AQMeasurement(v-for="(m,index) in measurements", :m="m", v-if="m.ready", :key="m.uuid", :data-measurement-id="m.uuid", :zoneTracker="zoneTracker", :score="score", :tpCustomStart="tpCustomStart", :tpCustomEnd="tpCustomEnd", :timePeriod="timePeriod", v-on:aqCardZoneChange="aqCardZoneChange")
							
							//- LARGE GRAPH
							.aqcard-measurement-large.hide-on-small-only(v-for="(m,index) in measurements", :key="m.objectId", :id="'lrg-grph-' + m.__ob__.dep.id", v-if="m.ready && m.chartData", :data-measurement-id="m.uuid")
								.aqcard-measurement-info
									.aqcard-measurement-title(v-if="scoreTypes.includes(m.type)") {{ topLevelScore(m) }}
									.aqcard-measurement-title(v-else) {{m.name}}
									.aqcard-measurement-value(v-if="scoreTypes.includes(m.type)") {{Math.round(m.curScore)}}
									.aqcard-measurement-value(v-else-if="!isNaN(m.curVal)")  {{measurementDisplayValue(m) }}
									.aqcard-measurement-value.txt-resize(v-else) {{measurementDisplayValue(m) }}
									.aqcard-measurement-unit {{m.unit}}
								.aqcard-measurement-chart
									AreaChartLarge(:dataArray="m.chartData.data", :colorMode="m.chartData.colorMode", :units="m.unit", :range="m.chartData.range",:enumeration="m.chartData.enumeration", :multiStateValues="m.chartData.multiStateValues", height="250px")
</template>
<script>
import DonutIndicator from './DonutIndicator'
import AreaChart from './AreaChart'
import AreaChartLarge from './AreaChartLarge'
import MeasurementIcons from '@/components/MeasurementIcons.vue';
import AQMeasurement from '@/components/AQMeasurement.vue';
import {HTTPClient} from '../assets/js/http'
import moment from 'moment';
let client = new HTTPClient();
import SectionEditor from './SectionEditor'

export default {
	name: 'AqCard',
	components: {
		DonutIndicator,
		AreaChart,
		AreaChartLarge,
		MeasurementIcons,
        SectionEditor,
        AQMeasurement
	},
	props: {
		title: String,
		measurements: Array,
		score: Number,
		type: String,
		timePeriod: String,
		tpCustomStart: Number,
		tpCustomEnd: Number,
		zoneTracker: Object,
		measurementId: String,
		isEquipment: Boolean,
        measurementObjectId: String,
	},
	data() {
		let startTime;
		let endTime;

		// Should handle start time of clien
		switch(this.timePeriod){
			case "custom": {
				startTime = this.tpCustomStart
				endTime = this.tpCustomEnd
				break;
			}
			case "week": {
				startTime = moment().startOf('week').valueOf()
				break;
			}
			case "day": {
				startTime = moment().startOf('day').valueOf()
				break;
			}
			case "month": {
				startTime = moment().startOf('month').valueOf()
				break;
			}
			default: {
				startTime = moment().startOf('week').valueOf() 
				break;
			}
		}

		return {
			siteId: this.$route.params.siteId,
			zoneId: this.$route.params.zoneId,
			expanded: {
				status: false,
				icon: 'keyboard_arrow_down',
				sid: this.$route.params.siteId
			},
			startTime,
			endTime,
			aqCardIndicatorScore: Math.round(this.score),
			scoreTypes: ['scoreOA', 'scoreAQ', 'scoreOW', 'scoreAF'],
		}
	},
	mounted() {
		if(this.measurements)
			this.loadTrendCharts();
		
		console.log("aqcard times", this.startTime, this.endTime)
		
		// this.measurements.forEach(m =>{
		// 	if (m.writable)
		// 		console.log("this.measurements",m)
		// })
		
	},
	methods: {
		updateText(t){
			this.title = t
			this.$emit('updateMeasurementText', t)
		},
        aqCardZoneChange(zoneId){
            this.$emit("aqCardZoneChange", zoneId);
        },
		rewriteValue(measurementId){
			let displayVal = '.new-val-displaying-' + measurementId;
			let editVal = '.new-val-editing-' + measurementId;
			let savedVal = '.new-val-saved-' + measurementId;
			let saveFailedVal = '.new-val-failed-' + measurementId;
			$(displayVal).hide(); // eslint-disable-line no-undef
			$(savedVal).hide(); // eslint-disable-line no-undef
			$(saveFailedVal).hide(); // eslint-disable-line no-undef
			$(editVal).show(); // eslint-disable-line no-undef
            this.data.editingCurVals[measurementId] = false;
		},
		cancelWritableValue(measurementId) {
			let displayVal = '.new-val-displaying-' + measurementId;
			let editVal = '.new-val-editing-' + measurementId;
			$(editVal).hide(); // eslint-disable-line no-undef
			$(displayVal).show(); // eslint-disable-line no-undef
		},
		saveSuccess(savingVal,savedVal) {
			$(savingVal).hide(); // eslint-disable-line no-undef
			$(savedVal).show(); // eslint-disable-line no-undef
			
			setTimeout(function() {
				$(savedVal).hide(); // eslint-disable-line no-undef
			}, 3000);
		},
		saveFailed(savingVal,saveFailedVal) {
			$(savingVal).hide(); // eslint-disable-line no-undef
			$(saveFailedVal).show(); // eslint-disable-line no-undef
			
			setTimeout(function() {
				$(saveFailedVal).hide(); // eslint-disable-line no-undef
			}, 3000);
		},
		refreshMeasurement(measurementId,point) {
			console.log("refreshing",measurementId)
			console.log("refreshing",point)
			this.loadTrendCharts();
		},
		expand(){
			// console.log(fromIcon)
			// if(this.$store.state.editMode && fromIcon != "icon"){
			// 	return null;
			// }
			if(this.expanded.status){
				this.expanded.status = false;
				this.expanded.icon = 'keyboard_arrow_down'
			} else {
				this.expanded.status = true;
				this.expanded.icon = 'keyboard_arrow_up'
			}
		},
		toggleLrgGraph(id) {
			let g = '#lrg-grph-' + id
			// eslint-disable-next-line no-undef
			$('.aqcard-measurement-large').not(g).hide()
			// eslint-disable-next-line no-undef
			$(g).toggle(0,function() {
				// eslint-disable-next-line no-undef
				$(this).get(0).scrollIntoView({
					behavior: "smooth",
					block: "end"
				});
			})
			
		},
		navigateToLink(m){
			if(this.scoreTypes.includes(m.type))
				this.$emit("aqCardZoneChange", m.zone.objectId);
		},
		async loadTrendCharts(){
			let themes = this.$store.state.themes || {};
			try {
				for(let m of this.measurements){
					let chartData = await this.$store.dispatch('getTrendData', {
						siteId: this.siteId,
						pointId: m.uuid,
						version: 'highcharts',
						startTime: this.startTime,
						endTime: this.endTime,
						type: m.type
					})
					if(chartData.data.length > 0){
						m.chartData = chartData
						if(m.activeText){
							let activeText = m.activeText;
							let inactiveText = m.inactiveText;
							m.chartData.enumeration = {
								activeText: m.activeText,
								inactiveText: m.inactiveText
							}
							if(m.kind == "Bool"){
								if(!isNaN(chartData.data[0][1])){
									m.chartData.data = chartData.data.map((el) => {
										return [el[0], el[1] == 1 ? activeText : inactiveText ]
									})
								}
							}
						}
						if(m.multiStateValues){
							m.chartData.multiStateValues = m.multiStateValues;
						}
						if(m.curScore > 80){
							m.chartData.colorMode = themes.ex || "#4e7cff";
						} else if(m.curScore >= 50 && m.curScore < 80) {
							m.chartData.colorMode = themes.mo || "#feca12"
						} else {
							// console.log(`Score less than 50 `);
							m.chartData.colorMode = themes.poor || "#fa5f5f"
							// console.log(`Score less than 50 ` + m.chartData.colorMode);
						}
					}
					m.ready = true
					this.expanded.status = false;
					this.expanded.status = true;
					
				}
			} catch (error) {
				console.error(error);
			}
		},
		async aqCardIndicatorScoreLatest(){
			try {
				if(this.measurementId == null){
					return null;
				}
				if(this.$store.state.TrendData[this.measurementId]?.data){
					this.aqCardIndicatorScore = Math.round(
						this.$store.state.TrendData[this.measurementId].data[this.$store.state.TrendData[this.measurementId].data.length - 1][1]
					)
				} else {
					let measurementIds = JSON.stringify([this.measurementId]);
					let res = await client.robustQuery(`/api/site/${this.siteId}/latest`, {measurementIds});
					if(res.results?.[this.measurementId]?.curScore?.val){
						this.aqCardIndicatorScore = Math.round(res.results[this.measurementId].curScore.val)
					}
				}
			} catch (error) {
				console.error(error);
				this.aqCardIndicatorScoreLatest = Math.round(this.score)
			}
		}
	},
	computed: {
		topLevelScore(){
			return (m) => {
				if(m.zone){
					let zt = this.zoneTracker[m.zone.objectId]
					if(zt)
						return zt;
					else
						return m.name;
				}
				return m.name
			}
		},
		measurementDisplayValue(){
			return (m) => {
				let field = this.scoreTypes.includes(m.type) ? "curScore" : "curVal";
				if(/Bool/i.test(m.kind)){
					// console.log(m)
					if(m.curVal == "true" || Number(m.curVal) == 1){
						return m.activeText || "True"
					} else {
						return m.inactiveText || "False"
					}
				}
				if(isNaN(Number(m.curVal))) return m.curVal
				if(m.chartData?.data.length >= 1){
					let lastVal = m.chartData.data[m.chartData.data.length - 1][1]
					return !isNaN(lastVal) ? Math.round(lastVal * 100)/100 : lastVal
				}
				return !isNaN(m[field]) ? Math.round(m[field] * 100)/100 : m[field]
			}
		},
		equipmentGearColor(){
			if(this.$store.state.theme!= null && this.$store.state.theme == "light"){
				return "#5E50FF";
			} else {
				return "#ffffff"
			}
		},
        showDonutIndicator(){
            if(this.$store.state.site == null){
                console.info("Donut Indicator::Site is null")
                return false;
            }
            if(this.$store.state.site.aqEnabled == false) {
                console.info("Donut Indicator::Site AQ is disabled")
                return false;
            }
            return this.isEquipment != true && this.aqCardIndicatorScore;
        }
	},
	watch : {
		'$props.timePeriod': {
			handler(){
				let startTime, endTime = null
				switch(this.timePeriod){
					case "custom": {
						startTime = this.tpCustomStart
						endTime = this.tpCustomEnd
						break;
					}
					case "week": {
						startTime = moment().startOf('week').valueOf()
						break;
					}
					case "day": {
						startTime = moment().startOf('day').valueOf()
						break;
					}
					case "month": {
						startTime = moment().startOf('month').valueOf()
						break;
					}
					default: {
						startTime = moment().startOf('week').valueOf() 
						break;
					}
				}
				this.startTime = startTime;
				this.endTime = endTime;
				this.loadTrendCharts();
			},
			immediate: false
		},
		"$store.state.daemonFetchTime" : {
			handler(){
				this.loadTrendCharts()
				this.aqCardIndicatorScoreLatest();
			},
			immediate: false
		}
	}
}
</script>
<style lang="scss">
	.material-tooltip {
		background-color: var(--bg-tooltip);
		border-radius: 8px;
		padding: 16px;
	}
	// .txt-resize {
	// 	// font-size: MAX(MIN(6vw,18px,)32px) !important;
	// }
</style>
<style lang="scss" scoped>
	.cls-1 {
		fill:none;
		stroke: var(--app-primary-txt);
		stroke-linecap:round;
		stroke-linejoin:round;
		stroke-width:2px;
	}
	.aqcard-navigate-wrap {
		cursor: auto;
        min-height: 113px;
		color: var(--app-tertiary-txt);
	}
	.aqcard-measurement-graph {
		cursor: pointer;
	}
	.top-lvl-aqcm {
		&:hover {
			background: rgba(0, 0, 0, 0.1);
		}
		.aqcard-navigate-wrap {
			cursor: pointer;
			&:hover {
				.aqcard-measurement-title {
					text-decoration: underline;
				}
			}

			@media only screen and (max-width: 601px) {
				.aqcard-measurement-title {
					text-decoration: underline;
				}
			}
		}
	}
	.writable-status-icons {
		.new-val-saving,
		.new-val-saved,
		.new-val-failed { display: none; }
		.new-val-saving { max-width: 24px; }
		.new-val-saved { color: #A9ECDE; }
		.new-val-failed { color: red; }
	}
	
	.writable {
		.writable-val {
			background: rgba(0,151,255,.4);
			border-radius: 12px;
			box-shadow: 0px 4px 4px 0px #00000040;
			cursor: pointer;
			padding: 0 12px;
            font-size: 28px;
		}
		.new-val-displaying {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		.new-val-editing {
			display: none;

			.ev-num {
				background-color: var(--input-bg);
				border: 0;
				border-radius: 12px;
				box-sizing: border-box;
				color: var(--input-txt);
				padding: 5px;
				input {
					border: 0;
					margin: 0;
				}
			}
			.edit-val {
			margin: 0;
			padding: 0;
			}
			.new-val-edit-btns {
				margin-top: 8px;
				.edit-val-cancel {
					margin-right: 8px;
				}
				.edit-val-cancel,
				.edit-val-save {
					font-size: 12px;
					padding: 0 8px !important;
				}
				.haq-btn-primary {
					background: var(--btn-primary-bg)!important;
					border: 1px solid var(--btn-primary-border);
					color: var(--btn-primary-txt)!important;
				}
				.haq-btn-secondary {
					background: var(--btn-secondary-bg)!important;
					border: 1px solid var(--btn-secondary-border);
					color: var(--btn-secondary-txt)!important;
				}
			}
		}
		
	}
</style>