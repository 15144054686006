<template>
	<div id="onboarding">
		<Navbar />
		<div class="container" style="min-width:85%">
			<div class="row" v-if="siteSelectionMode">
				<div class="col s12 center">
					<SiteSelector/>
				</div>
			</div>
            <div class="row detailsRow" v-if="!siteSelectionMode">
					<h5 class="center">
						Onboarding (Step 1/3) Create Topology
					</h5>
					<button class="btn waves-effect waves-light right" @click="refreshData()">
						Refresh
						<font-awesome-icon icon="redo"></font-awesome-icon>
					</button>
				</div>
			<div class="row" v-if="!siteSelectionMode">
				<div class="col s12 m6" v-if="commanderTopology">
					<h6>Commander Topology</h6>
					<div class="input-field">
						<label for="commanderTopologySearch">Search</label>
						<input type="text" v-model="searchQuery" id="commanderTopologySearch" style="color:white"
						@keyup="searchPoints"
						>
					</div>
					<div class="input-field">
						<button class="btn waves-effect waves-light" @click="pointBasedTopology = !pointBasedTopology">
							{{pointBasedTopology ? 'Sort By Device' : 'Sort By Points'}}
						</button>
					</div>
					<div class="row" v-if="selecteeErrorText">
						<div class="col s12">
							<span class="red-text">
								{{selecteeErrorText}}
							</span>
						</div>
					</div>
					<div class="commanderTopology" v-if="pointBasedTopology">
						<TreeNode v-for="node in commanderTopology" :key="node.id" :nodeData="node"
						@selecteeAdded="selecteeAdded" @expandCollapseTreeNode="expandCollapseTreeNode"
						/>
					</div>
					<div class="commanderTopology" v-if="!pointBasedTopology">
						<TreeNode v-for="node in commanderTopologyByDevice" :key="node.id" :nodeData="node"
						@selecteeAdded="selecteeAdded" @expandCollapseTreeNode="expandCollapseTreeNode"
						/>
					</div>
				</div>
				<div class="col s12 m6" v-if="zoneTopology ">
					<h6>AQ Topology</h6>
					<div class="input-field">
						<button class="btn waves-effect waves-light" @click="createZonesFromDevices">
							Create Zones from Devices
						</button>
						<button class="btn waves-effect waves-light green" @click="addNewZone" style="margin-left:25px">
							Add Zone
						</button>
						<button class="btn waves-effect waves-light" @click="generateDefaultPoints" style="margin-left:25px">
							Default Points
						</button>
					</div>
					<div class="aQTopology">
						<TreeNodeSelector v-for="node in zoneTopology" :key="node.id" :nodeData="node" v-on:selectorAdded="selectorAdded" v-on:relegateZone="relegateZone" v-on:removePointFromZone="removePointFromZone" v-on:removeChildren="removeChildren"/>
					</div>
					<div class="input-field">
						<button class="btn primary waves-effect waves-light" @click="selectPointTypes">
							Select Point Types
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
	.commanderTopology, .aQTopology {
		padding: 20px;
		overflow-y: scroll;
		overflow-x: hidden;
		max-height: 60vh
	}
</style>
<script>
import Navbar from '@/components/Navbar.vue';
import TreeNode from '@/components/TreeNode.vue'
import TreeNodeSelector from '@/components/TreeNodeSelector.vue'
import SiteSelector from '../components/SiteSeletctor.vue';
import { HTTPClient } from "../assets/js/http";
import { v4 as uuidv4 } from 'uuid';
let client = new HTTPClient();

export default {
	name: "Onboarding",
	components: {
		Navbar,
		SiteSelector,
		TreeNode,
		TreeNodeSelector
	},
	data() {
		return {
			siteSelectionMode: false,
			searchQuery: '',
			siteId: null,
			commanderTopology: null,
			commanderTopologyByDevice: null,
			zoneTopology: null,
			selector: null,
			selectee: null,
			selecteeErrorText: null,
			pointBasedTopology: false
		}
	},
	methods: {
		async fetchTopology(){
			try {
				console.log(`Fetching commanderTopology${this.siteId}`);
				let stored = localStorage.getItem(`commanderTopology${this.siteId}`)
				if(!stored){
					let res = await client.robustQuery('/api/onboarding/' + this.siteId);
					let flatArray = [];
					this.getDepthIndexedArray(this.cloneArray(res), flatArray, 0);
					this.commanderTopology = flatArray;
					localStorage.setItem(`commanderTopology${this.siteId}`, JSON.stringify(res));
					let zoneFlatArray = [];
					this.getDepthIndexedArray(this.cloneArray(res), zoneFlatArray, 0);
					this.zoneTopology = [];
						for(let z of zoneFlatArray){
							if(z.type == "point"){
								if(z.pointOfInterest){
									z.parseId = z.id;
									z.id = uuidv4();
									this.zoneTopology.push(z);
								}
							} else {
								this.zoneTopology.push(z);
							}
						}
				} else {
					let flatArray = [];
					this.getDepthIndexedArray(JSON.parse(stored), flatArray, 0);
					this.commanderTopology = flatArray;
					let zoneStored = localStorage.getItem(`zoneTopology${this.siteId}`);
					// eslint-disable-next-line no-constant-condition
					if(zoneStored){
						this.zoneTopology = JSON.parse(zoneStored);
					} else {
						console.log('Regenerating Zone Topology')
						let zoneFlatArray = [];
						this.getDepthIndexedArray(JSON.parse(stored), zoneFlatArray, 0);
						this.zoneTopology = [];
						for(let z of zoneFlatArray){
							if(z.type == "point"){
								if(z.pointOfInterest){
									z.parseId = z.id;
									z.id = uuidv4();
									this.zoneTopology.push(z);
								}
							} else {
								this.zoneTopology.push(z);
							}
						}
					}
					
				}
				this.getTopologyByDevice()
			} catch (error) {
				console.error(error);
				window.M.toast({html: 'Cannot Load Topology'});
			}
		},
		getDepthIndexedArray(array, flatArray, depth, parents){
			for(let f of array){
				let children = f.children || null;
				if(!depth){
					depth = 0;
				}
				delete f.children;
				if(!parents){
					parents = []
				}
				f.depth = depth;
				f.parents = parents;
				f.visible = true
				if(children){
					f.children = true;
					f.expanded = true;
					flatArray.push(f);
					let fpa = Array.prototype.concat(f.parents, [f.id]);
					this.getDepthIndexedArray(children, flatArray, depth + 1, fpa);
				} else {
					f.children = false;
					flatArray.push(f);
				}

			}
		},
		cloneArray(array){
			return JSON.parse(JSON.stringify(array));
		},
		expandCollapseTreeNode(node){
			if(this.pointBasedTopology){
				for(let el of this.commanderTopology){
					if(el.type!== "zone" && el.type!== "device" && el.parent === node.id){
						this.$set(el, 'visible', !el.visible);
					}
				}
				this.$set(node, 'expanded', !node.expanded);
			} else {
				for(let el of this.commanderTopologyByDevice){
					if(el.type!== "zone" && el.type!== "device" && el.parent === node.id){
						this.$set(el, 'visible', !el.visible);
					}
				}
				this.$set(node, 'expanded', !node.expanded);
			}
		},
		selecteeAdded(node){
			if(!this.selector){
				this.selecteeErrorText = "Please select a node";
			} else {
				let childElements = this.zoneTopology.filter((el) => el.parent == this.selector.id).map(function(el){
					return el['parseId'];
				})
				if(childElements.indexOf(node.id) < 0){
					this.selecteeErrorText = null;
					let index = this.zoneTopology.indexOf(this.selector);
					let clone = this.cloneArray(node);
					clone.parent = this.selector.id;
					clone.parents = this.selector.parents.concat(this.selector.id);
					clone.parseId = clone.id;
					clone.id = uuidv4();
					clone.depth = this.selector.depth + 1;
					this.zoneTopology.splice(index + 1, 0, clone);
				} else {
					this.selecteeErrorText = `${node.name} already exists in ${this.selector.name}`;
				}

			}
		},
		selectPointTypes(){
			if(this.selector){
				this.selector.selected = false;
				this.$set(this.selector, 'selected', false)
			}
			let zonesWithPoints = [];
			let zoneClone = this.cloneArray(this.zoneTopology);
			for(let z in zoneClone){
				if(zoneClone[z].type !== "point"){
					let hasPoint = zoneClone.find((el) => el.type === "point" && el.parents.indexOf(zoneClone[z].id) >  -1);
					if(hasPoint){
						zonesWithPoints.push(zoneClone[z]);
					}
				} else {
					zonesWithPoints.push(zoneClone[z]);
				}
			}
			console.log(zonesWithPoints);
			localStorage.setItem(`zoneTopology${this.siteId}`, JSON.stringify(this.zoneTopology));
			localStorage.setItem(`aQTopology${this.siteId}`, JSON.stringify(zonesWithPoints));
			this.$router.push({name: 'Onboarding2', query: {siteId: this.$route.query.siteId}});
		},
		selectorAdded(node){
			console.log(`Selctor:  ${node.name}`)
			if(!this.selector){
				this.selector = node;
				this.$set(node, 'selected', true)
			} else {
				this.selector.selected = false;
				this.$set(this.selector, 'selected', false)
				this.selector = node;
				this.$set(node, 'selected', true)
				this.selector.selected = true
			}
			
		},
		getPointsByDevice(){
			let topology = this.cloneArray(this.commanderTopology);
			let devices = {};
			for(let t of topology){
				if(t.type === "point"){
					if(devices[t.deviceId]){
						devices[t.deviceId].points.push(t);
					} else {
						devices[t.deviceId] = {
							name: t.deviceName,
							id: t.deviceId,
							points: [t],
							parent: t.parent
						}
					}
				}
			}
			return devices;
		},
		createZonesFromDevices(event){
			event.target.disabled = true;
			let devices = this.getPointsByDevice();
			for(let d in devices){
				let device = devices[d];
				if(device.parent){
					let pZ = this.zoneTopology.find(((el) => el.id == device.parent));
					let index = this.zoneTopology.indexOf(pZ);
					let parents = this.cloneArray(pZ.parents);
					parents.push(pZ.id);
					let newZone = {
						id: uuidv4(),
						existing: false,
						name: device.name,
						parent: pZ.id,
						parents,
						depth: pZ.depth + 1,
						type: "zone",
						originalDeviceRef: device.id,
						visible: true
					}
					this.zoneTopology.splice(index + 1, 0, newZone)
				}
			}
		},
		getTopologyByDevice(){
			let devices = this.getPointsByDevice();
			let topology = this.cloneArray(this.zoneTopology);
			for(let d in devices){
				let device = devices[d];
				if(device.parent){
					let pZ = topology.find(((el) => el.id == device.parent));
					let index = topology.indexOf(pZ) + 1;
					let deviceView = {
						id: uuidv4(),
						existing: false,
						visible: true,
						parent: pZ.id,
						parents: pZ.parents.concat(pZ.id),
						children: true,
						expanded: true,
						name: device.name,
						depth: pZ.depth + 1,
						type: "device"
					}
					topology.splice(index, 0 , deviceView);
					for(let p of device.points){
						let point = this.commanderTopology.find((el) => el.id === p.id);
						point = this.cloneArray(point)
						point.parents.push(deviceView.id);
						point.parent = deviceView.id;
						point.depth = point.depth + 1;
						point.visible = true;
						point.type = "point";
						topology.splice(index + 1, 0, point);

					}
				}
			}
			this.commanderTopologyByDevice = topology;
		},
		refreshData(){
			localStorage.removeItem(`commanderTopology${this.siteId}`);
			localStorage.removeItem(`zoneTopology${this.siteId}`);
			this.commanderTopology = null;
			this.zoneTopology = null;
			window.location.reload();
			// this.fetchTopology();
		},
		searchInTreeNode(empty){
			let regex = empty ? new RegExp("") : new RegExp(this.searchQuery, "i");
			if(this.pointBasedTopology){
				for(let el of this.commanderTopology){
					if(el.type === "point"){
						if(regex.test(el.name)){
							this.$set(el, 'visible', true);
						} else {
							this.$set(el, 'visible', false);
						}
					}
				}
			} else {
				for(let el of this.commanderTopologyByDevice){
					if(el.type === "point"){
						if(regex.test(el.name)){
							this.$set(el, 'visible', true);
						} else {
							this.$set(el, 'visible', false);
						}
					}
				}
			}
		},
		searchPoints(){
			if(this.searchQuery.length >= 2){
				setTimeout(this.searchInTreeNode, 200)
			} else {
				setTimeout(this.searchInTreeNode(true), 200)
			}
		},
		addNewZone(){
			let newZone = {
				id: uuidv4(),
				existing: false,
				name: "New Zone",
				parent: null,
				parents: [],
				depth: 0,
				type: "zone",
				visible: true
			}
			this.zoneTopology.push(newZone);
		},
		relegateZone(node){
			console.log(this.cloneArray(node));
			for(let z of this.zoneTopology){
				if(z.id === node.id){
					z.depth = z.depth - 1;
					z.parents.splice(z.parents.length - 1, 1);
					z.parent = z.parents.length > 0  ? z.parents[z.parents.length - 1] : null;
					this.$set(z, 'depth', z.depth);
				}
				let childIndex = z.parents.indexOf(node.id);
				if(childIndex > -1){
					z.depth = z.depth - 1;
					z.parents.splice(childIndex, 1);
					this.$set(z, 'depth', z.depth);
				}
			}
		},
		removePointFromZone(node){
			console.log(node);
			let index = this.zoneTopology.indexOf(node);
			this.zoneTopology.splice(index, 1);
		},
		generateDefaultPoints() {
			console.log('Generating Default Points');
			for(let dz of this.zoneTopology){
				if(dz.originalDeviceRef){
					let points = this.commanderTopology.filter((el) => el.deviceId === dz.originalDeviceRef);
					let regexTests = [/(?=.*temp)(?!.*setpoint)(.+)/ig, /humid/ig, /co2/ig, /filter alarm/ig];
					for(let p of points){
						for(let r of regexTests){
							if(r.test(p.name)){
								let np = this.cloneArray(p);
								console.log(np);
								np.parseId = np.id
								np.id = uuidv4();
								np.name = np.name.replace(np.type, "");
								np.parent = dz.id;
								np.parents = this.cloneArray(dz.parents).concat(dz.id);
								np.depth = dz.depth + 1;
								let index = this.zoneTopology.indexOf(dz);
								this.zoneTopology.splice(index+1, 0, np);
							}
						}
					}
				}
			}
		},
		removeChildren(id){
			for(let z in this.zoneTopology){
				let index = Number(z);
				if(this.zoneTopology[z].parents.includes(id)){
					this.zoneTopology.splice(index, 1);
				}
			}
		}
	},
	mounted() {
		if(this.$route.query.siteId){
			this.siteSelectionMode = false;
			this.siteId = this.$route.query.siteId;
			this.fetchTopology();
		} else {
			this.siteSelectionMode = true
		}
	}
};
</script>
