import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faSquare, faCertificate, faCircle, faRuler, faRedo, faGlobe, faEdit, faTrash, faSave, faTablet, faPlusCircle, faArrowCircleLeft} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import vuetify from './plugins/vuetify'
import './registerServiceWorker'

library.add(faSquare, faCertificate, faCircle, faRuler, faRedo, faGlobe, faEdit, faTrash, faSave, faTablet, faPlusCircle, faArrowCircleLeft);

Vue.component('font-awesome-icon', FontAwesomeIcon);


// Custom Directives

Vue.directive('click-outside', {
  bind (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
        try{
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        }catch(e){
            console.error(e);
        }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

Vue.config.productionTip = false;

// new Vue({
//   router,
//   store,
//   vuetify,
//   render: h => h(App)
// }).$mount('#app');

document.addEventListener('DOMContentLoaded', () => {
    new Vue({
        router,
        store,
        vuetify,
        render: h => h(App),
        mounted() {
            console.log('mounted');
            console.log('App element:', document.getElementById('app'));
            this.$nextTick(() => {
                if (window.M && typeof window.M.AutoInit === 'function') {
                    if (document.body && document.body.querySelectorAll) {
                        window.M.AutoInit();
                    } else {
                        const initInterval = setInterval(() => {
                            if (document.body && document.body.querySelectorAll) {
                                window.M.AutoInit();
                                clearInterval(initInterval);
                            }
                        }, 100);
                    }
                }
            });
        }
    }).$mount('#app');
});
