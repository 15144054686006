<template lang="pug">
    #loginPage
        .header-nav
            a(href="/")
                img(:src="wlLogo")
            a.contact-us.waves-effect.waves-light(:href='wlContact') Contact
        .login-cards
            .login-card.step-login(v-show="projects.length < 1")
                .content(data-step='0')
                    .title Welcome to {{wlName}} 
                    .input-group
                        label(for='login-firstname') First Name
                        input#login-firstname(name='login-firstname', type='text' v-model="firstName" placeholder="Enter your first name")
                    .input-group
                        label(for='login-lastname') Last Name
                        input#login-lastname(name='login-lastname', type='text' v-model="lastName" placeholder="Enter your last name")
                    .input-group
                        label(for='login-email') Email Address
                        input#login-email(name='login-email', type='email' v-model="username" placeholder="Enter your email address")
                    .error(v-if="errorText") {{errorText}}
                    button#signupBtn.waves-effect.waves-light(v-on:click="fetchProjects") Sign Up
            .login-card.step-project(v-show="projects.length >= 1")
                .content(data-step='0')
                    .title Select a Project
                    .input-group
                        .vuetify(data-app)
                            v-select#selectProject2.haq-text-field(v-model="projectId" :items="projects" item-text="name" item-value="objectId" label="Select a Project" hide-details
                                :menu-props="{ bottom: true, offsetY: true, closeOnClick: true, closeOnContentClick: true, }")
                    button#chooseProjectBtn.waves-effect.waves-light(v-on:click="authenticateAPI") Select
</template>
<script>
// @ is an alias to /src
import {wlData} from '../assets/js/constants';
import {HTTPClient} from '../assets/js/http';
let client = new HTTPClient();

export default {
    name: 'Signup',
    mounted(){
        if(this.autologin)
            $('#signupBtn').trigger('click');
    },
    data() {
        return {
            username: this.$route.query.username||'',
            password: this.$route.query.password||'',
            autologin: (this.$route.query.al=='true')||false,
            errorText: null,
            projectId: '',
            projects: [],
            onboardNewUser: false,
            wlName: wlData.name ? wlData.name : "HawkenAQ",
            wlLogo: wlData.loginLogo ? wlData.loginLogo : "/images/hawkenAqBlue.png",
            wlContact: wlData.contactUrl||'https://gethawken.com/contact-hawkenaq'
        };
    },
    methods: {
        async fetchProjects(event){
            try {
                event.target.disabled = true;
                let username = this.username.toLowerCase().trim();
                let password = this.password;

                if (!username || !password){
                    window.M.toast({html: 'Enter your Email and/or Password'});
                    event.target.disabled = false;
                }
                else {
                    let projects = await client.robustQuery('/users/projectsWithAuth', {username, password});
                    
                    if (projects.length > 1) {
                        this.projects = projects;
                        this.$nextTick(() => {
                            let elems = document.querySelector("#selectProject");
                            window.M.FormSelect.init(elems,{});
                        })
                        event.target.disabled = false;
                    }
                    else if (projects.length == 1) {
                        this.projectId = projects[0].objectId;
                        this.authenticateAPI(event);
                    }
                    else {
                        window.M.toast({html: 'There are no currently no projects assigned to your account.'});
                        event.target.disabled = false;
                    }
                }
            } catch (error) {
                console.error(error);
                event.target.disabled = false;
                this.errorText = error.message||'Login failed';
            }
        },
        async authenticateAPI(event) {
            event.target.disabled = true;
            try {
                let username = this.username.toLowerCase().trim();
                let password = this.password;
                let project = this.projectId;

                if (!project){
                    window.M.toast({html: 'Select a Project'});
                    event.target.disabled = false;
                }
                else {
                    await this.$store.dispatch('login', {username, password, project});
                    
                    if(this.$route.query.from == "onboarding")
                        window.location.replace('/onboarding');
                    else if (this.$route.query.from) //used to return to Admin
                        window.location.replace(this.$route.query.from);
                    else
                        window.location.replace('/');
                }
            } catch (error) {
                console.error(error);
                event.target.disabled = false;
                this.errorText = true;
            }
        }
    }
};
</script>
<style lang="sass">
    #loginPage .haq-text-field
        border: 1px solid #005B9A
        box-sizing: border-box
        border-radius: 12px
        margin: 8px 0 24px
        .v-select__slot
            background: #fff
            font: normal 400 16px/24px Roboto
            label
                color: #005B9A !important
                line-height: 38px !important
            .v-select__selections
                color: #005B9A !important
            .v-input__append-inner .v-icon
                color: #005B9A !important
</style>
<style lang="sass" scoped>
    #loginPage
        background: #fff url("../../public/images/bg.png") top right no-repeat
        background-size: cover
        min-height: 100vh
        
    .header-nav
        background: #fff
        box-shadow: 0px 100px 80px rgba(0, 91, 154, 0.12), 0px 41.7776px 33.4221px rgba(0, 91, 154, 0.0862625), 0px 22.3363px 17.869px rgba(0, 91, 154, 0.0715329), 0px 12.5216px 10.0172px rgba(0, 91, 154, 0.06), 0px 6.6501px 5.32008px rgba(0, 91, 154, 0.0484671), 0px 2.76726px 2.21381px rgba(0, 91, 154, 0.0337375)
        display: flex
        align-items: center
        justify-content: space-between
        height: 80px
        padding: 18px 36px
        
        @media (max-width: 600px)
            padding: 18px 15px

        img
            max-height: 52px
        a.contact-us
            border: 1px solid #005B9A
            border-radius: 12px
            color: #005B9A
            font: normal 400 16px/24px Roboto
            letter-spacing: 0.18px
            height: 40px
            line-height: 40px
            text-align: center
            width: 140px
            @media (max-width: 600px)
                font-size: 14px
                height: 36px
                line-height: 36px
                width: 90px
    .login-cards
        display: flex
        align-items: center
        min-height: calc(100vh - 80px)
        
        @media (max-width: 600px)
            align-items: flex-start

        .login-card
            background: #fff
            border-radius: 20px
            box-shadow: 0px 100px 80px rgba(0, 91, 154, 0.12), 0px 41.7776px 33.4221px rgba(0, 91, 154, 0.0862625), 0px 22.3363px 17.869px rgba(0, 91, 154, 0.0715329), 0px 12.5216px 10.0172px rgba(0, 91, 154, 0.06), 0px 6.6501px 5.32008px rgba(0, 91, 154, 0.0484671), 0px 2.76726px 2.21381px rgba(0, 91, 154, 0.0337375)
            color: #005B9A
            margin: 50px auto
            max-height: 604px
            max-width: 548px
            padding: 40px
            width: 100%

            @media (max-width: 600px)
                // background: rgba(255,255,255,.8)
                margin: 50px 15px
                padding: 40px 20px

            .content
                margin: 0 auto
                max-width: 360px
                width: 100%
                text-align: center

                .title
                    // font: normal 400 32px/46px Roboto
                    margin-bottom: 32px
                    @media (max-width: 600px)
                        font-size: 24px
                .input-group
                    text-align: left
                    label
                        font: normal 400 14px/20px Roboto
                        color: #005B9A
                    input
                        border: 1px solid #005B9A
                        box-sizing: border-box
                        border-radius: 12px
                        color: #005B9A
                        font: normal 400 16px/24px Roboto
                        margin: 8px 0 24px
                        padding: 8px 20px
                    ::placeholder
                        color: #005B9A
                        opacity: 0.4
                .error
                    background-color: transparent !important
                    color: #FF5050
                    font: normal 400 14px/20px Roboto
                    text-align: left
                .strike
                    display: block
                    text-align: center
                    overflow: hidden
                    white-space: nowrap
                    > span
                        color: #005B9A
                        font: normal 400 14px/20px Roboto
                        position: relative
                        display: inline-block
                        opacity: 0.5
                        &:before,
                        &:after
                            content: ""
                            position: absolute
                            top: 55%
                            width: 9999px
                            height: 1px
                            background: #005B9A
                            opacity: 0.5
                        &:before
                            right: 100%
                            margin-right: 8px
                        &:after
                            left: 100%
                            margin-left: 8px
        #signupBtn,
        #chooseProjectBtn
            background: #3456F7
            border: 0
            border-radius: 12px
            color: #fff
            margin: 24px 0
            padding: 10px
            max-height: 360px
            width: 100%
            .disabled
                background: #B9E2FF
                color: #52B2F3
        
        #googleBtn
            background: #fff
            border: 1px solid #005B9A
            border-radius: 12px
            color: #005B9A
            font: normal 400 14px/20px 'Roboto'
            padding: 10px
            height: 40px
            max-height: 360px
            margin: 24px 0
            width: 100%
</style>