<template lang="pug">
	#kioskSharingPage
		Navbar(tab="devices" :siteId="siteId" :site="site")
		main(v-if="site")
			.container
				v-row.top-header
					.col.s12.site-info
						.site-image(:style='"background-image:url(" + (site.logo ? site.logo.url.replace("http", "https") : "") + ")"')
						.site-title {{site.name}} Devices 
							//- router-link(:to="{name: 'Site', params: {siteId: site.objectId}}").siteLink {{site.name}} 
							//- | Kiosks
				v-row
					v-col
						v-data-table.elevation-8.haq-data-table(v-model="devices" show-select item-key="objectId" :search="search" :headers="dtDevicesHeaders" :items="dtDevices" :items-per-page="10")
							template(v-slot:top)
								v-row
									v-col(sm="5").mb-5
										v-text-field.haq-search(append-icon="mdi-magnify" v-model="search" label="Search Devices" dense hide-details single-line filled outlined).my-2
							template(v-slot:item.edit="{ item }")
								v-icon(small class="mr-2" @click="openEditModal(item)") mdi-dots-vertical
							template(v-slot:item.rssi="{ item }")
								v-tooltip(top)
									template(v-slot:activator="{on,attrs}")
										v-icon(v-if="item.rssi <= 0 && item.rssi >= -60" class="mr-2" @click="openEditModal(item)" v-bind="attrs" v-on="on") mdi-signal-cellular-3
										v-icon(v-else-if="item.rssi <= -60 && item.rssi >= -85" class="mr-2" @click="openEditModal(item)" v-bind="attrs" v-on="on") mdi-signal-cellular-2
										v-icon(v-else-if="item.rssi <= -85" class="mr-2" @click="openEditModal(item)" v-bind="attrs" v-on="on") mdi-signal-cellular-1
										v-icon(v-else class="mr-2" @click="openEditModal(item)" v-bind="attrs" v-on="on") mdi-signal-cellular-outline
									span {{item.rssi}}
		DeviceModal(v-if="currentDialog == 'edit'" :action="currentDialog" :device="dtDevices[deviceIndex]" v-on:closeDeviceModal="closeDeviceModal")
</template>
<script>
import Navbar from '@/components/Navbar.vue';
import DonutIndicator from '@/components/DonutIndicator.vue';
import {HTTPClient} from '../assets/js/http';
import {useGetSite} from '../assets/js/hawken-query'
import DeviceModal from '@/components/modals/Device.vue'
let client = new HTTPClient();

export default {
	name: 'Devices',
	components: {
		Navbar,
		DonutIndicator,
		DeviceModal,
	},
	data(){
		return {
			projectId: this.$store.state.project.objectId,
			siteId: this.$route.params.siteId ? this.$route.params.siteId : this.$route.query.siteId,
			site: null,
			search: "",
			devices: [],
			searchDevices: '',
			dtDevicesHeaders: [
				{text: 'Device Name', value: 'deviceName'},
				{text: 'Model Name', value: 'name'},
				{text: 'Serial Number', value: 'serialNum'},
				{text: 'RSSI', value: 'rssi'},
				{text: 'Zone', value: 'zone'},
				{text: 'Connected', value: 'connected'},
				{text: 'Last Heard From', value: 'lastTrendData'},
				{text: '', value: 'edit'},
			],
			dtDevices: [],
			deviceIndex: 0,
			currentDialog: null,
		};
	},
	methods: {
		async getSite() {
			try {
				this.site = await useGetSite(this.siteId)
			} catch (error) {
				window.M.toast({html: "Error in fetching site"})
			}
		},
		async getDevices() {
			let devices = await client.robustQuery(`/api/sites/${this.siteId}/devices`);
			this.dtDevices = devices.results.map(x => {
				return {
					serialNum: x.serialNumber,
					name: x.deviceType.friendlyModelName,
					rssi: x.rssi,
					zone: x.zone?.name,
					zoneId: x.zone?.objectId,
					// device is connected if lastHeardFrom within the hour
					connected: x.lastHeardFrom ? (new Date() - new Date(x.lastHeardFrom)) < 3600000 : false,
					// connected: x.zone?.connected ? 'True' : 'False',
					lastTrendData: x.lastHeardFrom ? new Date(x.lastHeardFrom).toTimeString() : x.lastHeardFrom,
					deviceName: x.name,
					objectId: x.objectId
				}
			});
		},
		openEditModal(device) {
			this.currentDialog = 'edit';
			this.deviceIndex = this.dtDevices.indexOf(device)
		},
		closeDeviceModal() {
			this.currentDialog = null;
			this.deviceIndex = 0;
		},
		
	},
	computed: {
		showDevice(){
			return (d) => {
				let regex = new RegExp(this.search, 'i');
				return regex.test(d.deviceName) || regex.test(d.name)
			}
		}	
	},
	mounted() {		
		this.getSite();
		this.getDevices();
	},
	watch: {
		
	}
};
</script>
<style lang="scss" scoped>
	.container-lg {
		@media only screen and (min-width: 993px) {
			width: 100%;
		}
	}
	
	.top-header {
		margin-top: 40px;
		margin-bottom: 0;
		.site-info {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			.site-image {
				border-radius: 20px;
				width: 120px;
				height: 80px;
				min-width: 120px;
				min-height: 80px;
				background-size: cover;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				vertical-align: top;
				margin-right: 2rem;
			}
			.site-progress {
				margin-right: 1.5rem;
			}			
			.site-title {
				color: var(--app-primary-txt);
				font-size: 28px;
				.siteLink {
					color: var(--app-primary-txt);
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
</style>