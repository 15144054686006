<template lang="pug">
    #step5
        p Which room are you placing this sensor in?
        .row
            .col.s12.input-field-outlined
                input#zonename(placeholder="Conference Room" type="text" name="zonename" v-on:keyup="setZoneName" :value="myZoneName")
                label(for="zonename") Room Name
                //- span.helper-text#sitenamehelper.red-text asdf
        button.btn.waves-effect.white.haq-btn.blue-text(@click="previousStep") Back
        button#nextbtn.btn.waves-effect.haq-btn.brandColor.right(@click="nextStep" :disabled="!myZoneName") Next
</template>
<script>
import {HTTPClient} from '../../../assets/js/http';
let client = new HTTPClient();

export default {
    name: "Step5",
    props: ['data', 'kit', 'site'],
    data(){
        return {
            myZoneName: null,
            zoneCreated: false,
            zoneId: null
        }
    },
    methods: {
        async nextStep(){
            try{
                $('#nextbtn').addClass('disabled');
                console.log({
                    name: this.$data.myZoneName
                });
                let zresults = null;
                if(!this.$data.zoneCreated){
                    zresults = await client.robustPost('/api/sites/'+(this.site.id||this.site.objectId)+'/zones', {
                        name: this.$data.myZoneName
                    });
                    this.$data.zoneCreated = true;
                    this.$data.zoneId = zresults.results.objectId;
                }
                let results = await client.robustPost('/api/devices/'+this.data.objectId+'/onboard', {
                    name: this.$data.myZoneName+' '+this.data.deviceType.friendlyModelName,
                    zoneId: this.$data.zoneId
                });
                // results = JSON.parse('{"successMessage":"Success","results":{"name":"Test Site","product":{"objectId":"1nZXAA73qu"},"project":{"objectId":"AqeRT1fAGW"},"id":"xh7diBsGcp","createdAt":"2022-01-05T22:07:01.679Z","updatedAt":"2022-01-05T22:07:01.679Z","uuid":"ba6849eb-9e56-46d4-80de-71bc3dd5f4af"}}');
                // console.log(JSON.stringify(results));
                if(results.results){
                    this.$emit('nextStep');
                }
            }catch(e){
                window.M.toast({html: e.message});
                console.error(e);
            }finally{
                $('#nextbtn').removeClass('disabled');
            }
        },
        previousStep(){
            this.$emit('previousStep');
        },
        setZoneName(data){
            // console.log('Step1 setSiteName', data);
            this.myZoneName = $('#zonename').val();
        }
    },
    mounted(){
        // if(this.siteName)
            window.M.updateTextFields();
    }
}
</script>