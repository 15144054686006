<template lang="pug">
	.modal-share-kiosk
		.modal-content
			.modal-header
				h5 Share with
			.modal-divider
			.modal-body
				ul.tabs
					li.tab.col.s3
						a(href="#email-slide") Email 
					li.tab.col.s3
						a(href="#link-slide") Link 
					li.tab.col.s3
						a(href="#qr-slide") QR Code 
			.modal-slides
				#email-slide.modal-slide.col.s12
					//-p Share with email(s) 
					.row
						.input-field.col.s12
							#link-text.chips.chips-placeholder.white-text
								input.email-chip(placeholder="Share with email" @keyup.enter="share = true")
							//-input.validate.white-text#link-text(placeholder="Share with email" v-model="email")
							label.active.p-txt(for="link-text") Send code to email 
					.row
						.col.s12
							.share-btns.float-right
								v-btn.haq-btn.haq-btn-secondary(text @click='close()') Cancel
								v-btn.haq-btn.haq-btn-primary(text @click="sendEmail") Share

				#link-slide.modal-slide.col.s12
					.row
						.input-field.col.s12
							input.validate.p-txt#link-text(disabled :value="url" ref="link")
							label.active.p-txt(for="link-text") Copy link
					.row
						.col.s12
							.share-btns.float-right
								v-btn.haq-btn.haq-btn-secondary(text @click='close()') Cancel
								v-btn.haq-btn.haq-btn-primary(text @click="copy") Copy 

				#qr-slide.modal-slide.col.s12
					.row(v-if="kiosk.qrCode && kiosk.qrCode.url")
						img.center(:src="kiosk.qrCode ? kiosk.qrCode.url : ''")
					a.row.center.download(v-if="kiosk.qrCode && kiosk.qrCode.url" download="QRCode.png" target="_blank" :href="kiosk.qrCode ? kiosk.qrCode.url : ''")
						.col(style="padding-right:0px")
							i.material-icons file_download 
						.col
							span Download Image
					a.row.center.p-txt(v-else) No QR code for this kiosk
					.row
						.col.s12
							.share-btns.float-right
								v-btn.haq-btn.haq-btn-secondary(text @click='close()') Cancel
								
</template>
<script>
import {HTTPClient} from '@/assets/js/http';
let client = new HTTPClient();

export default {
	name: 'ModalShareKiosk',
	props: {
		site: Object,
		kiosk: Object,
	},
	data() {
		return {
			siteId: this.$route.query.siteId,
			url: this.kiosk?.url || 'https://app.hawkenaq.com/kioskbranded?siteId=' + this.$route.query.siteId,
			email: '',
			emails: [],
			kioskName: this.kiosk.name ? this.kiosk.name : this.kiosk?.zone?.name || this.kiosk?.site?.name || '',
			share: false, 
		};
	},
	methods: {
		async copy() {
			await navigator.clipboard.writeText(this.url);
			window.M.toast({html: 'Copied!'})
		},
		close() {
			this.$emit('closeShareModal')
		},
		placeholderUpdate() {
			let chipInstance = window.M.Chips.getInstance($('.chips'))
			console.log('chipInstance', chipInstance)
			this.emails = chipInstance.chipsData.map(a => a.tag);
		},
		sendEmail() {
			let chipInstance = window.M.Chips.getInstance($('.chips'))
			console.log('chipInstance', chipInstance)
			let emails = chipInstance.chipsData.map(a => a.tag);
			console.log(emails)
			if (emails.length <= 0) {
				window.M.toast({html: 'At least one email must be entered to share'})
				return;
			}
			client.robustPost('/api/kiosk/share', {emails, kiosk: this.kiosk});
			window.M.toast({html: this.kioskName + ' Kiosk Shared'})
			this.close();
		}
	},
	updated() {
		let tb = document.querySelectorAll('.tabs');
		window.M.Tabs.init(tb);
		var elems = document.querySelectorAll('.chips');
		window.M.Chips.init(elems);
	},
	mounted() {
		console.log('this.kiosk', this.kiosk);
		console.log('this.site', this.site)
		let tb = document.querySelectorAll('.tabs');
		window.M.Tabs.init(tb);
		var elems = document.querySelectorAll('.chips');
		window.M.Chips.init(elems);
	},
	watch: {
		'$props.kiosk':  {
			handler() {
				this.url = this.kiosk?.url || 'https://app.hawkenaq.com/kioskbranded?siteId=' + this.$route.query.siteId
			}
		}
	}
};
</script>
<style lang="scss" scoped>
	.modal-share-kiosk {
		position: fixed; /* Stay in place */
		z-index: 1; /* Sit on top */
		left: 0;
		right: 0;
		transform: scaleX(1) scaleY(1);
		top: 10%;
		border-radius: 8px;
		width: 35%; /* Full width */
		margin: auto;
		background-color: var(--card-primary-bg); /* Fallback color */
		z-index: 1006;
		animation: scaleUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
	}
	#link-text {
		margin-left: 10px !important;
		width: 95%
	}
	.modal-content {
		z-index: 1005;
		animation: scaleBack .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
	}
	.modal-header {
		padding-left: 10px;
	}
	.modal-slide {
		margin-top: 10px;
	}
	.center {
		text-align: center;
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 40%;
	}	
	label {
		margin-left: 10px
	}
	div::placeholder {
		color: var(--app-primary-txt)
	}	
	input::placeholder {
		color: var(--app-primary-txt)
	}	
	.email-chip {
		width: unset !important
	}	
	.tabs {
		background: transparent;
		height: 55px;
		padding-left: 10px;
		overflow-x: hidden;
		li.tab {
			height: 55px;
			line-height: 55px;
			width: 33%;
			a {
				color: var(--app-tertiary-txt);
				opacity: 0.6;
				&.active {
					color: var(--app-tertiary-txt);
					opacity: 1;
					border-bottom: 2px solid #ccc;
					border-bottom-width: 3px;
				}
			}
		}
		.indicator {
			display: none;
		}
	} 
	.download {
		cursor: pointer;
	}

	.share-btns {
		.haq-btn {
			border-radius: 12px !important;
			box-sizing: border-box;
			font: normal normal 1rem/20px Roboto !important;
			margin: 0px 10px;
			padding: 20px 16px !important;
			text-align: center;
			text-transform: none !important;
		}
		.haq-btn-primary {
			background: var(--btn-primary-bg)!important;
			border: 1px solid var(--btn-primary-border);
			color: var(--btn-primary-txt)!important;
		}
		.haq-btn-secondary {
			background: var(--btn-secondary-bg)!important;
			border: 1px solid var(--btn-secondary-border);
			color: var(--btn-secondary-txt)!important;
		}
	}
</style>
<style lang="scss">
	.chip {
		border-radius: 8px;
		background: #7d85c5;
		color: var(--app-primary-txt);
		i.close {
			position: unset
		}
	}
	.input {
		color: var(--app-primary-txt) !important;
	}
	.p-txt {
		color: var(--app-primary-txt) !important;
	}
</style>