<template lang="pug">
    v-dialog(v-model="dialog" content-class="haq-dialog" max-width="65%" @input="closeModal" scrollable)
        v-card#alert-dialog.haq-dialog-card(v-if="site")
            v-card-title.haq-dialog-card-header
                //- v-icon.alerts-icon mdi-bell-outline
                .haq-dialog-card-title 
                    span Active Alerts
                    span.ml-2 
                        a(:href="'/projects/'+site.project.objectId+'/sites/'+site.objectId+'/alerts'")
                            v-btn.haq-btn-primary.waves-effect.waves-light(@click="closeModal") See All Alerts
                v-spacer
                v-icon.close-icon(text @click="closeModal") mdi-close
            v-card-text
                .alerts(v-if="alerts && alerts.length > 0 && site")
                    .alert(v-for="(n,index) in pages" :key="n.objectId")
                        .alert-header
                            v-icon.warning-icon(:class="(n.alertConfig.alertType == 'warning' ? 'alert-warning' : 'alert-critical')") mdi-alert
                            //- v-icon.warning-icon(:class="(n.alertType == 'warning' ? 'alert-warning' : 'alert-critical')") mdi-alert-octagram
                            .alert-title 
                                a(:href="'/projects/'+site.project.objectId+'/sites/'+site.objectId+'/zones/'+n.measurement.zone.objectId") {{n.measurement.zone.name}} - {{n.measurement.name}}
                            v-spacer
                            //- v-icon.dismiss-alert(text @click="dismissAler") mdi-close
                        .alert-message {{n.alertConfig.message}}
                            //- a(:href="'/projects/'+site.project.objectId+'/sites/'+site.objectId+'/zones/'+n.measurement.zone.objectId") {{n.measurement.zone.name}} - {{n.measurement.name}}
                        //- .alert-notes {{n.notes}}
                .no-alerts(v-else) You have no active alerts.
            v-card-actions(v-if="alerts && alerts.length > 0")
                v-pagination.haq-alerts-paging(v-model="currentPage" :length="Math.ceil(alerts.length/pageMax)" :total-visible="7" color="notprimary")

                                
</template>
<script>
import { useGetSite } from '../../assets/js/hawken-query'
// import {HTTPClient} from '../../assets/js/http'
// let client = new HTTPClient();

export default {
    name: 'Alerts',
    props: {
        openModal: String,
        alerts: Array,
    },
    data() {
        return {
            dialog: this.openModal == 'alerts' ? true : false,
            currentPage: 1,
            pageMax: 5,
            // siteIf from router params
            siteId: this.$route.params.siteId,
            site: null,
        }
    },
    methods: {
        closeModal() {
            this.dialog = false;
			this.$emit("closeModal")
		},
        async loadAlerts(){
            try{
                this.site = await useGetSite(this.siteId);
            }catch(e){
                console.error(e)
            }
        }
    },
    computed: {
        pages () {
            if(!this.alerts)
                return []
            return this.alerts.slice((this.currentPage - 1) * this.pageMax, this.currentPage * this.pageMax)
        }
    },
    mounted(){
        this.loadAlerts()
    }
}
</script>
<style lang="sass">
    #alert-dialog
        .haq-dialog-card-header
            padding-bottom: 34px !important
            .alerts-icon
                font-size: 34px !important
                margin-right: 15px
            .haq-dialog-card-title
                font: 400 34px/46px 'Roboto'

    .alerts
        display: flex
        flex-direction: column
        gap: 16px
        width: 100%
        .alert
            background: var(--app-secondary-bg)
            border-radius: 12px
            display: block
            padding: 16px
            .alert-header
                display: flex
                flex-direction: row
                justify-content: left
                margin-bottom: 5px
                .warning-icon
                    margin-right: 10px
                    &.alert-warning
                        color: var(--score-yellow-color)
                    &.alert-critical
                        color: var(--score-red-color)
                .alert-title
                    color: var(--app-primary-txt)
                    font: 400 20px/24px 'Roboto'
                    letter-spacing: 0.18px
            .alert-message
                color: #fff
                font: 400 16px/24px 'Roboto'
    .no-alerts
        font: 400 18px/24px 'Roboto'
        margin: 50px auto
        text-align: center
        width: 100%
    .haq-alerts-paging
        box-shadow: none
        height: auto
        line-height: 24px
        width: 100%
        i
            height: auto
        .v-pagination__navigation
            background: transparent !important
            box-shadow: none
            color: var(--app-primary-txt)
        .v-pagination__item
            background: transparent !important
            box-shadow: none
            color: var(--app-primary-txt)
        .v-pagination__item--active
            background: transparent
            border: 1px solid var(--app-primary-txt)

    .haq-btn-primary
        background: #3456F7
        border-radius: 12px
        color: #fff
        .disabled
            background: #B9E2FF
            color: #52B2F3
</style>