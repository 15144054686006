import HighCharts from 'highcharts';
import moment from 'moment'

let theme = {
    colors: ['#2196F3', '#FDE22B', '#d7351f', '#7798BF', '#aaeeee', '#ff0066',
        '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'
    ],
    chart: {
        margin: 0,
        height: '71px',
        backgroundColor: {
            linearGradient: {
                x1: 0,
                y1: 0,
                x2: 1,
                y2: 1
            },
            stops: [
                [0, 'transparent'],
                [1, 'transparent']
            ]
        },
        style: {
            fontFamily: '\'Roboto\', sans-serif'
        },
        plotBorderColor: '#60606300'
    },
    title: {
        style: {
            color: '#E0E0E3',
            textTransform: 'uppercase',
            fontSize: '20px'
        }
    },
    subtitle: {
        style: {
            color: '#E0E0E3',
            textTransform: 'uppercase'
        }
    },
    xAxis: {
        gridLineColor: '#70707300',
        labels: {
            style: {
                color: '#00000000'
            }
        },
        lineColor: '#70707300',
        minorGridLineColor: '#50505300',
        tickColor: '#70707300',
        title: {
            style: {
                color: '#A0A0A300'
            }
        }
    },
    yAxis: {
        visible: false,
        gridLineColor: '#70707300',
        labels: {
            style: {
                color: '#E0E0E300'
            }
        },
        lineColor: '#70707300',
        minorGridLineColor: '#50505300',
        tickColor: '#70707300',
        tickWidth: 1,
        title: {
            style: {
                color: '#A0A0A300'
            }
        }
    },
    tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        style: {
            color: '#F0F0F0'
        }
    },
    plotOptions: {
        series: {
            dataLabels: {
                color: '#F0F0F300',
                style: {
                    fontSize: '13px'
                }
            },
            marker: {
                lineColor: '#33300'
            }
        },
        boxplot: {
            fillColor: '#505053'
        },
        candlestick: {
            lineColor: 'white'
        },
        errorbar: {
            color: 'white'
        }
    },
    legend: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        itemStyle: {
            color: '#E0E0E3'
        },
        itemHoverStyle: {
            color: '#FFF'
        },
        itemHiddenStyle: {
            color: '#606063'
        },
        title: {
            style: {
                color: '#C0C0C0'
            }
        }
    },
    credits: {
        style: {
            color: '#666'
        }
    },
    labels: {
        style: {
            color: '#70707300'
        }
    },
    drilldown: {
        activeAxisLabelStyle: {
            color: '#F0F0F3'
        },
        activeDataLabelStyle: {
            color: '#F0F0F3'
        }
    },
    navigation: {
        buttonOptions: {
            symbolStroke: '#DDDDDD',
            theme: {
                fill: '#505053'
            }
        }
    },
    // scroll charts
    rangeSelector: {
        buttonTheme: {
            fill: '#505053',
            stroke: '#000000',
            style: {
                color: '#CCC'
            },
            states: {
                hover: {
                    fill: '#707073',
                    stroke: '#000000',
                    style: {
                        color: 'white'
                    }
                },
                select: {
                    fill: '#000003',
                    stroke: '#000000',
                    style: {
                        color: 'white'
                    }
                }
            }
        },
        inputBoxBorderColor: '#505053',
        inputStyle: {
            backgroundColor: '#333',
            color: 'silver'
        },
        labelStyle: {
            color: 'silver'
        }
    },
    navigator: {
        handles: {
            backgroundColor: '#666',
            borderColor: '#AAA'
        },
        outlineColor: '#CCC',
        maskFill: 'rgba(255,255,255,0.1)',
        series: {
            color: '#7798BF',
            lineColor: '#A6C7ED'
        },
        xAxis: {
            gridLineColor: '#505053'
        }
    },
    scrollbar: {
        barBackgroundColor: '#808083',
        barBorderColor: '#808083',
        buttonArrowColor: '#CCC',
        buttonBackgroundColor: '#606063',
        buttonBorderColor: '#606063',
        rifleColor: '#FFF',
        trackBackgroundColor: '#404043',
        trackBorderColor: '#404043'
    },
    time: {
        useUTC: false
    }
};

export class ChartOptions {
    constructor() {
        this.theme = theme;
    }

   /**
     * @description Returns HighCharts Options
     * @param {Array} data 
     * @param {Number} colorMode 
     * @param {String} units 
     * @param {String} height 
     * @param {Array} range 
     * @param {Object} enumeration
     * @param {Array<String>} multiStateValues
     * @returns {Object}
     */
    getChartOptions(data, colorMode, units, range, enumeration, multiStateValues) {
        let options = JSON.parse(JSON.stringify(this.theme));
        options.chart.zoomType = 'x';
        options.title.text = '';
        options.subtitle.text = '';
        options.xAxis.type = 'datetime';
        options.tooltip.formatter = function() {
            return moment(this.x).format('dddd MMMM DD, h:mm a') + '<br />' + this.y + ` ${units ? units: ""}`
        }
        options.yAxis.max = range[1],
        options.yAxis.min = range[0];
        options.yAxis.title = {
            text: 'temperature'
        };
        options.legend.enabled = false;
        options.plotOptions.area = {
            shadow: {
                color: theme.colorMode,
                opacity: 0
            },
            lineColor: 'transparent',
            fillColor: {
                linearGradient: {
                    x1: 1,
                    y1: 0,
                    x2: 0,
                    y2: 0
                },
                stops: [
                    [0, HighCharts.color(colorMode).setOpacity(1).get('rgba')],
                    [1, HighCharts.color(colorMode).setOpacity(0/256).get('rgba')]
                ]
            },
            marker: {
                radius: 0,
                color: '#fff'
            },
            lineWidth: 2,
            states: {
                hover: {
                    lineWidth: 3
                }
            },
            threshold: null
        }

        if(enumeration){
            options.tooltip.formatter = function() {
                let text;
                if(this.y === 100){
                    text = enumeration.activeText
                } else {
                    text = enumeration.inactiveText
                }
                return moment(this.x).format('dddd MMMM DD, hh:mm a')+'<br/>' + 'Status: ' + text
            }
        }
        if(multiStateValues){
            options.tooltip.formatter = function() {
                let text;
                text = multiStateValues[this.y]
                return moment(this.x).format('dddd MMMM DD, hh:mm a') +'<br/>' + 'Status: ' + text
            }
            options.yAxis.labels = {
                formatter() {
                    return multiStateValues[this.value]
                }
            }
        }
        options.series = [{
            type: 'area',
            name: units,
            data
        }]
        return options;

    }
}