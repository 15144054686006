<template lang="pug">
        main(style='padding-left:0;position:fixed;top:0;left:0;bottom:0;right:0;background-color:#000;').valign-wrapper.login-bg
            .valign.center-align(style='width:100%;')
                div(style='position:absolute;top:8px;left:16px;')
                    span.main-icon(style='margin-right:12px;top:4px;')
                .container
                    .row(style='margin-bottom:0;')
                        .col.m6.push-m3.s12
                            .imageContainer(:style="imageStyle")
                    .row
                        .col.m6.push-m3.s12
                            .card.hoverable#setup.foregroundColor.brandColor(v-show="projects.length < 1")
                                .card-content(data-step='0')
                                    span.card-title.white-text Let's Get Started
                                    .row(style='margin-bottom:0;')
                                        .input-field.col.s12.l12
                                            a.waves-effect.waves-light.btn#googleBtn(href="/users/auth/google") Sign in with Google
                                        //- .input-field.col.s12.l12
                                        //-     button.white.waves-effect.waves-light.btn.btn-outline#loginBtn(v-on:click="fetchProjects") Login
                                        .input-field.col.s12(style='margin:0;' v-if="errorText")
                                            div.message.message-block.message-error.red.white-text {{errorText}}
                            .card.hoverable#setup.foregroundColor.brandColor(v-show="projects.length >= 1")
                                .card-content(data-step='0')
                                    span.card-title.white-text Select Project
                                    .row
                                        .input-field-outlined-light.col.s12.darken-2
                                            select#selectProject(v-model="projectId").browser-default.brandColor.white-text
                                                option(v-for="project in projects" :key="project.id" :value="project.objectId") {{project.name}}
                                        .input-field.col.s12(style='text-align:center;')
                                            button.white.waves-effect.waves-light.btn.btn-outline#chooseProjectBtn(v-on:click="authenticateAPI") Select
                                            
</template>

<script>
// @ is an alias to /src
import {wlData} from '../assets/js/constants';
import {HTTPClient} from '../assets/js/http';
let client = new HTTPClient();

export default {
  name: 'Login',
  data() {
      let imageStyle = 'background-image:url("'+wlData.loginLogo+'");'+
          'width:313px;height:100px;margin-left:auto;margin-right:auto;';
      return {
        username: '',
        password: '',
        errorText: null,
        projectId: '',
        projects: [],
        imageStyle
      };
  },
  methods: {
    async fetchProjects(event){
        try {
            event.target.disabled = true;
            let username = this.username;
            let password = this.password;
            let projects = await client.robustQuery('/users/projectsWithAuth', {username, password});
            this.projects = projects;
            event.target.disabled = false;
        } catch (error) {
            console.error(error);
            event.target.disabled = false;
            this.errorText = error.message||'Login failed';
        }
    },
    async authenticateAPI(event) {
        // console.log('Success', event);
        event.target.disabled = true;
        try {
            let username = this.username;
            let password = this.password;
            let project = this.projectId;
            if(!username || !password || !project){
                window.M.toast({html: 'Select Project'});
            }
            else {
                await this.$store.dispatch('login', {username, password, project});
                if(this.$route.query.from == "onboarding"){
                    window.location.replace('/onboarding');
                } else {
                    window.location.replace('/');
                }
            }
        } catch (error) {
            event.target.disabled = false;
            this.errorText = true;
            console.error(error);
        }
    }
  }
};
</script>

<style lang="scss" scoped>
    .login-bg {
        background-image: url("../../public/images/bg.png");
        background-repeat: no-repeat;
        background-size: cover;
    }
    [data-step] {
        background-color: #0097FF;
        background-repeat: no-repeat;
    }
    #selectProject {
        padding: 5px;
        border-radius: 10px;
        height: 3rem;
    }
    #googleBtn {
        min-width: 20px;
        background-image: url("../../public/images/google/vector/btn_google_dark_normal_ios.svg");
        background-repeat: no-repeat;
        background-size: 44px 44px;
        background-position: -2px -2px;
        background-color: #4285F4;
        text-transform: unset;
        padding: 0px;
        padding-left: 48px;
        padding-right: 10px;
        font-size: 16px;
        line-height: 40px;
        min-height: 40px;
        font-family: 'Roboto';
    }
</style>
