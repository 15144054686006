<template>
    <div id="onboarding2">
        <Navbar />
        <div class="container" style="min-width:85%">
            <div class="row">
            <div class="row detailsRow">
                <h5 class="center">
                    <button @click="backToOnboarding" class="btn waves-effect waves-light left">
                    Select Topology (Step 1/3)
                    </button>
                    Onboarding (Step 2/3) Select Point Types
                </h5>
            </div>
            <div class="row">
                <div class="col s12 m6">
                    <h6 style="padding-bottom:10px;border-bottom:1px solid;margin-bottom:25px">Select Points from Zone Topology</h6>
                    <div class="row">
                        <button class="btn waves-effect waves-light left" @click="guessPointTypes()">
                                Guess Point Types
                        </button>
                    </div>
                    <div class="commanderTopology">
                         <TreeNodeSelector v-for="node in aQTopology" :key="node.id" :nodeData="node" :depth="0"  v-on:selectorAdded="selectorAdded" step="pointTypeSelection"/>
                    </div>
                </div>
                <div class="col s12 m6" style="padding-bottom:25px">
                    <h6 style="padding-bottom:10px;border-bottom:1px solid;margin-bottom:25px">{{selecteeErrorText}}</h6>
                    <div class="pointTopology">
                        <div class="row" v-for="point in pointTopology" :key="point.pointType" @click="selecteeAdded(point)">
                        <div class="col s12 treeNodeSelector" v-bind:class="{selectorSelected: point.selected}">
                            <font-awesome-icon icon="circle" :style="{color: '#3498db'}"></font-awesome-icon>
                            <span style="margin-left:20px">{{point.name}}</span>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col s12">
                   <h5 class="center">
                        <button class="btn waves-effect waves-light" @click="createMeasurements">
                        Create Measurements
                    </button>
                   </h5>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<style lang="scss">
    .treeNodeSelector {
        padding-top: 3px;
        color: #999;
        &:hover {
			background-color: #085691;
            color: #ffffff;
            cursor: pointer;
		}
    }
    .selectorSelected {
            background-color: #085691;
            color: #ffffff;
    }
	.commanderTopology, .pointTopology {
		padding: 20px;
		overflow-y: scroll;
		overflow-x: hidden;
		max-height: 60vh;
	}
</style>
<script>
import Navbar from '@/components/Navbar.vue';
import TreeNodeSelector from '@/components/TreeNodeSelector.vue'
export default {
    name: "Onboarding2",
    components: {
        Navbar,
        TreeNodeSelector
    },
    data() {
        let pointTopology = [{"name":"Space Air Flow Sensor","type":"pointType","pointType":"airFlow"},{"name":"Damper Position Sensor","type":"pointType","pointType":"damperPositionStatus"},{"name":"Fan Off/On","type":"pointType","pointType":"fanStatus"},{"name":"Return Air Flow Sensor","type":"pointType","pointType":"returnAirFlow"},{"name":"Outside Air Flow Sensor","type":"pointType","pointType":"outsideAirFlow"},{"name":"Outside Air Temperature","type":"pointType","pointType":"outsideAirTemperature"},{"name":"CO2 Sensor","type":"pointType","pointType":"cO2Sensor"},{"name":"Humidity Sensor","type":"pointType","pointType":"humiditySensor"},{"name":"Space Temperature","type":"pointType","pointType":"spaceTemperature"},{"name":"Occupied Mode","type":"pointType","pointType":"occupiedMode"},{"name":"Motion Sensor","type":"pointType","pointType":"motionSensor"},{"name":"Active Pure","type":"pointType","pointType":"activePure"},{"name":"Static Pressure","type":"pointType","pointType":"staticPressure"},{"name":"VOC Sensor","type":"pointType","pointType":"vocSensor"},{"name":"Boiler Status","type":"pointType","pointType":"boilerStatus"},{"name":"Boiler Pressure","type":"pointType","pointType":"boilerPressure"},{"name":"Supply Fan Status","type":"pointType","pointType":"supplyFanStatus"},{"name":"Equipment","type":"pointType","pointType":"equipmentValue"}, {name: "Ion Level", type: "pointType", pointType: "ionLevel"}, {name: "Ionizer Status", type: "pointType", pointType: "ionizerStatus"},{name: "Differential Pressure", type: "pointType", pointType: "differentialPressure"},{name: "Carbon Monoxide Sensor", pointType: "coSensor"}, {name: "Ozone", pointType: "ozoneSensor"},{name: "PM 2.5", pointType: "pm2.5Sensor"}, {name: "PM 10", pointType: "pm10Sensor"}]

        pointTopology = pointTopology.sort(function(a, b){
            return a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})
        })
        return {
            aQTopology: null,
            pointTopology,
            selecteeErrorText: "Select Point type for - (Choose Point)",
            selector: null,
            selectee: null,
            siteId: this.$route.query.siteId
        }
    },
    methods: {
        backToOnboarding(){
            this.$router.push({name: "Onboarding",  query: {siteId: this.$route.query.siteId}})
        },
        selectorAdded(node){
            this.selecteeErrorText = `Select Point type for - (${node.name})`;
              if(this.selectee){
                    this.selectee.selected = false;
                    this.$set(this.selectee, 'selected', false)
                }
            if(!this.selector){
				this.selector = node;
				this.$set(node, 'selected', true)
			} else {
				this.selector.selected = false;
				this.$set(this.selector, 'selected', false)
				this.selector = node;
				this.$set(node, 'selected', true)
				this.selector.selected = true;
            }
            if(this.selector.pointType){
                let match = this.pointTopology.find((el) => el.pointType == this.selector.pointType);
                if(match){
                    this.selectee = match;
                    this.$set(match, 'selected', true)
                    this.selectee.selected = true;
                }
            }
        },
        selecteeAdded(node){
            console.log(`Selected ${node.pointType}`)
            if(!this.selector){
                this.selecteeErrorText = "Select Point type for - (Choose Point)"
            } else {
                 if(!this.selectee){
                    this.selectee = node;
                    this.$set(node, 'selected', true)
                } else {
                    this.selectee.selected = false;
                    this.$set(this.selectee, 'selected', false)
                    this.selectee = node;
                    this.$set(node, 'selected', true)
                    this.selectee.selected = true;
                }
                this.selector.pointType = node.pointType
                this.$set(this.selector, 'poinType', node.pointType);
            }
        },
        createMeasurements(){
            localStorage.setItem('pointTopology' + this.siteId, JSON.stringify(this.aQTopology))
            this.$router.push({name: 'Onboarding3', query: {siteId: this.$route.query.siteId}});
        },
        guessPointTypes() {
            console.log('Guessing Point Types')
            for(let e of this.aQTopology){
                if(e.type === "point"){
                    console.log(e.name)
                    let tempRegex = /temp/i;
                    let humRegex = /hum/ig;
                    let co2Regex = /co2/i;
                    let pm2 = /pm2.5/i;
                    let pm10 = /pm10/i;
                    let voc = /voc/i;
                    if(tempRegex.test(e.name)){
                        this.$set(e, 'pointType', 'spaceTemperature');
                        console.log(`Setting ${e.name} ${e.deviceName} as spaceTemperature`)
                    } else if(humRegex.test(e.name)){
                        this.$set(e, 'pointType', 'humiditySensor');
                    } else if(co2Regex.test(e.name)){
                        this.$set(e, 'pointType', 'cO2Sensor');
                    } else if(pm2.test(e.name)){
                        this.$set(e, 'pointType', 'pm2.5Sensor')
                    } else if(pm10.test(e.name)){
                        this.$set(e, 'pointType', 'pm10Sensor')
                    } else if(voc.test(e.name)){
                        this.$set(e, 'pointType', 'vocSensor')
                    }
                    else {
                         e.pointType = "equipmentValue";
                        this.$set(e, 'pointType', 'equipmentValue');
                    }
                }
            }
        }
    },
    mounted(){
        let t = localStorage.getItem(`aQTopology${this.siteId}`);
        if(!t){
            this.$router.push({name: 'Onboarding', query: {siteId: this.siteId}});
        } else{
            let pt = localStorage.getItem('pointTopology' + this.siteId);
            if(pt){
                pt = JSON.parse(pt);
                let ot = JSON.parse(t);
                for(let p of pt){
                    if(p.pointType){
                       for(let o in ot){
                           if(ot[o].id === p.id){
                               ot[o].pointType = p.pointType;
                               ot[o].name = ot[o].name + ' - ' + p.pointType;
                               break;
                           }
                       }
                    }
                }
                this.aQTopology = ot;
            } else {
                this.aQTopology = JSON.parse(t);
            }
        }
    }
}
</script>