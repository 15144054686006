<template lang="pug">
    #step1
        //- h2 Step0
        p HawkenAQ monitors your building's air quality 24/7 for elements proven critical to your health and productivity. We help you pinpoint and resolve common air quality issues before they can become a problem for you and your guests. 
        p Now you can trust the air you breathe.
        .center-align
            button.btn.waves-effect.haq-btn.haq-color(@click="nextStep" style="width:180px;margin-top:1.8vh;font-size:18px;") Get Started
</template>
<script>
export default {
    name: "Step0",
    data(){
        return {}
    },
    methods: {
        nextStep(){
            this.$emit('nextStep')
        },
        previousStep(){
            this.$emit('previousStep')
        }
    }
}
</script>