<template lang="pug">
    #step3
        p Connect the ethernet cable to the HawkenAQ Hub, then plug the other end into an empty port on your wall or Internet router. Now, connect the micro-USB cable to the HawkenAQ Hub and power adapter, then plug the power adapter into a wall socket.
        p After a few minutes, the lights on the Hub will start blinking. This means it has connected to HawkenAQ.
        .stepImage(style="background-image: url(/images/kitSetup2.png); margin-bottom:1.5em; max-height:170px;")
        button.btn.waves-effect.white.haq-btn.blue-text(@click="previousStep") Back
        button.btn.waves-effect.haq-btn.haq-color.right(@click="nextStep") Next
</template>
<script>
export default {
    name: "Step3",
    data(){
        return {}
    },
    methods: {
        nextStep(){
            this.$emit('nextStep')
        },
        previousStep(){
            this.$emit('previousStep')
        }
    }
}
</script>