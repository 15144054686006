<template>
  <v-app>
    <v-main>
        <ErrorBoundary>
            <router-view/>
        </ErrorBoundary>
    </v-main>
  </v-app>
</template>
<script>
import {wlData} from './assets/js/constants';
import ErrorBoundary from './components/ErrorBoundary.vue';
// set the page title and favicon
document.title = wlData.pageTitle||wlData.name;
export default {
  name: "App",
  components: {
    ErrorBoundary
  },
  async beforeCreate(){
    try {
      if(!LOGGING_ENABLED){
        console.log = () => {}
      }
      if(MODE_DEV_SERVER){
        console.warn('Webpack detected. Loading locals via /locals')
        await this.$store.dispatch('loadLocals');
        if (this.$store.state.theme === 'dark')
          this.$vuetify.theme.dark = true
        else
          this.$vuetify.theme.dark = false
      } else {
        this.$store.dispatch('localsThroughWindow')
        if (this.$store.state.theme === 'dark')
          this.$vuetify.theme.dark = true
        else
          this.$vuetify.theme.dark = false
      }
    } catch (error) {
      console.error(error);
    }
  }
}
</script>
<style lang="scss">
  @import './assets/sass/global.sass';
</style>