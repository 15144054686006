<template lang="pug">
    main#reportsPage.browser-default
        Navbar(tab="reports" :siteId="siteId" :site="site")
        main(v-if="site")
            .container
                v-row.top-header
                    .col.s12.site-info
                        .site-image(:style='"background-image:url(" + (site.logo ? site.logo.url.replace("http", "https") : "") + ")"')
                        //- .site-progress
                        //- 	a.modal-trigger(v-if="site.score" href="#modal-score-breakdown" style="color:var(--app-tertiary-txt)")
                        //- 		DonutIndicator(:key="site.objectId+'-pt-donut'" :id="site.objectId +'-scoreOA'" :cssClass="'main-donut'" :value="Math.round(site.scoreOA.curScore)" :size="60")
                        //- 	DonutIndicator(v-else :key="site.objectId+'-pt-donut'" :id="site.objectId +'-scoreOA'" :cssClass="'main-donut'" :value="Math.round(site.scoreOA.curScore)" :size="60")
                        .site-title {{site.name}} Reports 
                //- v-row.align-center
                    
                    //- v-col(:cols="12" :sm="12" :md="4")
                    //-     v-checkbox(v-model="includesScores" label="Include Scores?")
                v-row.align-center
                    v-col(:cols="12" :sm="12" :md="3").ma-0
                        //- v-text-field(label="Start Date" v-model="startDate").haq-text-field
                        DatePicker(:date="startDate" v-on:dateChange="updateStartDate" label="Start Date")
                    v-col(:cols="12" :sm="12" :md="3").ma-0
                        DatePicker(:date="endDate" v-on:dateChange="updateEndDate" label="End Date")
                    v-col(:cols="12" :sm="12" :md="6")
                        v-btn.mr-6.haq-btn.haq-btn-secondary.measurement-chips-btn(@click="selectMeasurementDialog = true" :disabled="disableButtons" ) 
                            span.mr-2 Select Measurements
                            v-chip(small) {{selectedMeasurements.length}}
                        v-btn.haq-btn.haq-btn-secondary(:disabled="disableButtons" @click="generateReport") Generate Report
                #reportsDiv(v-if="report")
                    v-row
                        v-col(:cols="12" :sm="12").d-flex
                            v-btn.haq-btn.haq-btn-secondary(@click="downloadCSV()") Download CSV
                    v-row(v-for="d,index in report.data" :key="index")
                        v-col(:cols="12" :sm="12")
                            v-card.measurement-report-card
                                v-card-title {{ getDisplayName(d) }}
                                v-card-text
                                    v-simple-table.measurement-report-table(:data-measurement-kind="d.measurementKind")
                                        thead(v-if="d.measurementKind === 'Bool'")
                                            tr
                                                th Time Range
                                                th Count
                                        tbody(v-if="d.measurementKind === 'Bool'")
                                            tr(v-for="i,intervalIdex in d.intervals" v-show="intervalIdex !== d.intervals.length - 1" :key="intervalIdex")
                                                td {{i.intervalName}}
                                                td  {{i.count}}
                                        template(v-slot:default v-if="d.measurementKind !== 'Bool'")
                                            thead
                                                tr
                                                    th Time Range
                                                    th Min Value
                                                    th Max Value
                                                    th Average Value
                                                    th(v-if="showScore") Min Score
                                                    th(v-if="showScore")  Max Score
                                                    th(v-if="showScore")  Average Score
                                            tbody
                                                tr(v-for="i,intervalIdex in d.intervals" v-show="intervalIdex !== d.intervals.length - 1" :key="intervalIdex")
                                                    td {{i.intervalName}}
                                                    td  {{i.minVal}}
                                                    td {{i.maxVal}}
                                                    td {{Math.round(i.aveVal * 100)/100}}
                                                    td(v-if="showScore")  {{Math.round(i.minScore * 100)/100}}
                                                    td(v-if="showScore")  {{Math.round(i.maxScore * 100)/100}}
                                                    td(v-if="showScore")  {{Math.round(i.aveScore * 100)/100}}
                        v-col(:cols="12" :sm="12")
                            ReportChart(:report="d")
                v-dialog(v-model="selectMeasurementDialog"  content-class="multiMeasurementSelectorModal" scrollable)
                    MultiMeasurementSelector(v-if="selectMeasurementDialog" :preSelectedMeasurements="selectedMeasurements" v-on:addMeasurements="addMeasurements"
                    v-on:closeModal="selectMeasurementDialog=false")
</template>

<style lang="scss" scoped>
    .measurement-report-card {
        background-color: var(--app-secondary-bg);
        .measurement-report-table {
             background-color: var(--app-tertiary-bg);
             height: 400px;
             overflow-x: hidden;
             overflow-y: auto;
        }
    }
    .top-header {
		margin-top: 40px;
		margin-bottom: 0;
		.site-info {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			.site-image {
				border-radius: 20px;
				width: 120px;
				height: 80px;
				min-width: 120px;
				min-height: 80px;
				background-size: cover;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				vertical-align: top;
				margin-right: 2rem;
			}
			.site-progress {
				margin-right: 1.5rem;
			}			
			.site-title {
				color: var(--app-primary-txt);
				font-size: 28px;
				.siteLink {
					color: var(--app-primary-txt);
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
</style>
<script>
import Navbar from '@/components/Navbar.vue';
import MultiMeasurementSelector from '@/components/util/MultiMeasurementSelector'
import DatePicker from '@/components/util/DatePicker.vue';
import { useGetSite } from '../assets/js/hawken-query'
import moment from 'moment-timezone'
import {HTTPClient} from '../assets/js/http'
import ReportChart from '@/components/reports/chart.vue';
let client = new HTTPClient();
export default {
    name: "Reports",
    components: {
        Navbar,
        DatePicker,
        MultiMeasurementSelector,
        ReportChart
    },
    data(){
        return {
            loadedAt: new Date().valueOf(),
            siteId: this.$route.params.siteId,
            site: null,
            timeperiodOptions: ["Daily", "Weekly", "Monthy"],
            timeperiod: "Daily",
            // report: JSON.parse(localStorage.getItem("report")),
            report: null,
            includesScores: true,
            selectedMeasurements: [],
            selectMeasurementDialog: false,
            disableButtons: false,
            startDate: moment().subtract(7, "days").valueOf(),
            endDate: moment().startOf("day").valueOf(),
        }
    },
    methods: {
        async loadOptions(){
            try {
                this.site = await useGetSite(this.siteId)
            } catch (error) {
                console.error(error)
            }
        },
        addMeasurements(m){
            this.selectedMeasurements = m;
            // console.log(m);
            this.selectMeasurementDialog = false
        },
        async generateReport(){
            console.time("Generate Reports")
            try {
                this.report = null;
                this.disableButtons = true
                let startTime = moment(this.startDate).startOf("day").valueOf();
                let endTime = moment(this.endDate).endOf('day').valueOf() - 1;
                if(this.selectedMeasurements.length  < 1){
                    console.error(`Please select measurements`)
                    window.M.toast({html: "Please select measurements"});
                    this.disableButtons = false;
                    return;
                }
                let res = await client.robustPost(`/api/sites/reports/${this.siteId}?startTime=${startTime}&endTime=${endTime}`, {
                    measurementIds: this.selectedMeasurements.map((el) => el.id)
                });
                this.report = res.results.report;
                localStorage.setItem("report", JSON.stringify(res.results.report));

            } catch (error) {
                console.error(error);
                window.M.toast({html: "Cannot Generate Report"})
            } finally {
                this.disableButtons = false
            }
            console.timeEnd("Generate Reports")
        },
        updateStartDate(e){
            this.startDate = moment(e, "YYYY-MM-DD").valueOf();
            // console.log(`Start Date is ${this.startDate}`)
        },
        updateEndDate(e){
            this.endDate = moment(e, "YYYY-MM-DD").valueOf();
            // console.log(`End Date is ${this.endDate}`)
        },
        downloadCSV(){
            const round100 = (num) => {
                return Math.round(num * 100)/100
            }
           try {
             if(this.report == null) throw new Error("No Report to Download")
            let csvContent = "data:text/csv;charset=utf-8,";
            let headers = ["Time Range", "Type", "Min Value", "Max Value", "Average Value"]
            if(this.showScore === true){
                headers.push("Min Score", "Max Score", "Average Score")
            }
            for(let d of this.report.data){
               let displayName = d.measurementName + ` (${d.measurementZone || "(Site)"})`
                csvContent += `Measurement,${displayName}\n\n`
                if(d.measurementKind == 'Bool'){
                    headers = ["Time Range", "Type", "Count"]
                }
                csvContent += headers.join(",") + "\n"
                for(let ii = 0; ii < d.intervals.length-1; ii++){
                    let i = d.intervals[ii];
                    let row = [i.intervalName, d.measurementType,round100(i.minVal), round100(i.maxVal), round100(i.aveVal)]
                    if(this.showScore == true){
                        row.push(round100(i.minScore), round100(i.maxScore), round100(i.aveScore))
                    }
                    if(d.measurementKind == 'Bool'){
                        row = [i.intervalName, d.measurementType, i.count]
                    }
                    csvContent += row.join(",") + "\n"
                }
                csvContent += "\n\n"
            }
            let encodedUri = encodeURI(csvContent);
            let link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `${this.site.name} Report - ${moment().format("mm/dd")}.csv`);
            document.body.appendChild(link); // Required for FF
            link.click();
           } catch (error) {
            console.error(error)
           }
        }
    },
    computed: {
        getDisplayName(){
          return (d) => {
               return d.measurementName + ` (${d.measurementZone || "(Site)"})`
          }
        },
        showScore(){
            return this.site?.aqEnabled === false ? false: true 
        }
    },
    mounted(){
        this.loadOptions()
    }
}
</script>