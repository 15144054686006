<template lang="pug">
  div(v-if="measurement === 'activepure'" :class="'icon-' + measurement")
    svg(:width="wsize" :height="hsize" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg")
      path(d="M5.88989 19.4498C5.93656 17.5931 6.34878 15.7639 7.10297 14.0667C7.85717 12.3696 8.93855 10.8377 10.2853 9.5588C11.632 8.27989 13.2176 7.27901 14.9514 6.61341C16.6853 5.94781 18.5333 5.63055 20.3899 5.67977" :stroke="color" stroke-width="1.5")
      path(d="M34.47 20.1802C34.4285 22.0381 34.0211 23.8696 33.2712 25.5699C32.5213 27.2702 31.4436 28.8061 30.0997 30.0896C28.7558 31.3732 27.1721 32.3792 25.4391 33.0502C23.7061 33.7212 21.8578 34.044 20 34.0002" :stroke="color" stroke-width="1.5")
      path(d="M17.6001 8.97023L20.8601 5.71023L17.6301 2.49023" :stroke="color" stroke-width="1.5")
      path(d="M22.4299 30.77L19.1699 34.04L22.4199 37.29" :stroke="color" stroke-width="1.5")
      path(d="M14 32.5802C12.3204 31.7857 10.814 30.668 9.56664 29.291C8.31931 27.914 7.3556 26.3046 6.73058 24.5549C6.10557 22.8053 5.8315 20.9496 5.92405 19.0939C6.0166 17.2383 6.47395 15.419 7.26996 13.7402" :stroke="color" stroke-width="1.5")
      path(d="M26.3999 7C29.79 8.60663 32.4034 11.4938 33.6654 15.0267C34.9274 18.5596 34.7348 22.4491 33.1299 25.84" :stroke="color" stroke-width="1.5")
      path(d="M20 24.0298V25.8798" :stroke="color" stroke-width="1.5")
      path(d="M20 14.1299V15.9699" :stroke="color" stroke-width="1.5")
      path(d="M24.03 20H25.88" :stroke="color" stroke-width="1.5")
      path(d="M14.1299 20H15.9699" :stroke="color" stroke-width="1.5")

  div(v-else-if="measurement === 'airquality'" :class="'icon-' + measurement")
    svg(:width="wsize" :height="hsize" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg")
      path(d="M36.0599 10.4101C34.2313 12.099 31.9057 13.1513 29.4299 13.4101C20.5999 14.5701 19.6699 7.5001 10.8299 8.6601C8.23815 8.92668 5.81136 10.0573 3.93994 11.8701" :stroke="color" stroke-width="1.5")
      path(d="M36.0599 28.3901C34.2313 30.079 31.9057 31.1313 29.4299 31.3901C20.5999 32.5501 19.6699 25.4801 10.8299 26.6401C8.23815 26.9067 5.81136 28.0373 3.93994 29.8501" :stroke="color" stroke-width="1.5")
      path(d="M36.06 19.56C34.2313 21.2489 31.9057 22.3012 29.43 22.56C20.6 23.72 19.67 16.65 10.83 17.81C8.2442 18.0733 5.82156 19.1965 3.94995 21" :stroke="color" stroke-width="1.5")

  div(v-else-if="measurement === 'carbonmonoxide'" :class="'icon-' + measurement")
    svg(:width="wsize" :height="hsize" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg")
      path(d="M34.5007 15.4995C35.1689 10.6465 31.7765 6.17067 26.9235 5.50245C22.0705 4.83422 17.5947 8.22663 16.9265 13.0796C16.2583 17.9326 19.6507 22.4084 24.5036 23.0766C29.3566 23.7449 33.8324 20.3524 34.5007 15.4995Z" :stroke="color" stroke-width="1.5")
      path(d="M16.674 29.8318C17.1109 26.7426 14.9608 23.8841 11.8715 23.4473C8.78234 23.0104 5.9239 25.1606 5.48704 28.2498C5.05019 31.339 7.20034 34.1974 10.2895 34.6343C13.3788 35.0711 16.2372 32.921 16.674 29.8318Z" :stroke="color" stroke-width="1.49977")
      path(d="M15.01 24.9899L18.61 21.3899L19.31 20.6899L15.01 24.9899Z" :stroke="color" stroke-width="1.5")

  div(v-else-if="measurement === 'co2'" :class="'icon-' + measurement")
    svg(:width="wsize" :height="hsize" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg")
      path(d="M32.1772 24.1265C34.8157 21.2257 34.6031 16.7353 31.7024 14.0968C28.8017 11.4582 24.3113 11.6708 21.6727 14.5715C19.0342 17.4722 19.2468 21.9627 22.1475 24.6012C25.0482 27.2397 29.5386 27.0272 32.1772 24.1265Z" :stroke="color" stroke-width="1.5")
      path(d="M15.53 11.1899L21.82 15.2999" :stroke="color" stroke-width="1.5")
      path(d="M15.6621 11.8734C17.4571 9.90005 17.3125 6.84529 15.3392 5.05035C13.3658 3.2554 10.3111 3.4 8.51614 5.3733C6.7212 7.34661 6.86579 10.4014 8.8391 12.1963C10.8124 13.9913 13.8672 13.8467 15.6621 11.8734Z" :stroke="color" stroke-width="1.5")
      path(d="M21.38 23.48L17.47 26.42" :stroke="color" stroke-width="1.5")
      path(d="M17.2103 32.7454C19.0076 30.7743 18.8668 27.7194 16.8957 25.922C14.9246 24.1247 11.8696 24.2655 10.0723 26.2367C8.27491 28.2078 8.41577 31.2627 10.3869 33.0601C12.358 34.8574 15.4129 34.7165 17.2103 32.7454Z" :stroke="color" stroke-width="1.5")

  div(v-else-if="measurement === 'infectiontransmissibility'" :class="'icon-' + measurement")
    svg(:width="wsize" :height="hsize" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg")
      path(d="M30.2645 21.4084C31.0449 15.7402 27.0827 10.5125 21.4145 9.73207C15.7463 8.95159 10.5186 12.9139 9.73813 18.5821C8.95765 24.2502 12.9199 29.4779 18.5881 30.2584C24.2563 31.0389 29.484 27.0766 30.2645 21.4084Z" :stroke="color" stroke-width="1.5")
      path(d="M20 5.1001V10.2501" :stroke="color" stroke-width="1.5")
      path(d="M16.8501 4.81006H23.1501" :stroke="color" stroke-width="1.5")
      path(d="M20 24.0298V25.8798" :stroke="color" stroke-width="1.5")
      path(d="M20 14.1299V15.9699" :stroke="color" stroke-width="1.5")
      path(d="M24.03 20H25.88" :stroke="color" stroke-width="1.5")
      path(d="M14.1299 20H15.9699" :stroke="color" stroke-width="1.5")
      path(d="M20 34.9V29.75" :stroke="color" stroke-width="1.5")
      path(d="M23.1501 35.1899H16.8501" :stroke="color" stroke-width="1.5")
      path(d="M5.1001 20H10.2501" :stroke="color" stroke-width="1.5")
      path(d="M4.81006 23.1501V16.8501" :stroke="color" stroke-width="1.5")
      path(d="M34.9 20H29.75" :stroke="color" stroke-width="1.5")
      path(d="M35.1899 16.8501V23.1501" :stroke="color" stroke-width="1.5")
      path(d="M9.45996 9.45996L13.1 13.1" :stroke="color" stroke-width="1.5")
      path(d="M7.03003 11.4898L11.49 7.02979" :stroke="color" stroke-width="1.5")
      path(d="M30.5399 30.5399L26.8999 26.8999" :stroke="color" stroke-width="1.5")
      path(d="M32.97 28.5098L28.51 32.9698" :stroke="color" stroke-width="1.5")
      path(d="M9.45996 30.5399L13.1 26.8999" :stroke="color" stroke-width="1.5")
      path(d="M11.49 32.9698L7.03003 28.5098" :stroke="color" stroke-width="1.5")
      path(d="M30.5399 9.45996L26.8999 13.1" :stroke="color" stroke-width="1.5")
      path(d="M28.51 7.02979L32.97 11.4898" :stroke="color" stroke-width="1.5")

  div(v-else-if="['ionization', 'ionizerstatus'].includes(measurement)")
    svg(:width="wsize" :height="hsize" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg")
      path(d="M19.6699 26.2802C23.1382 26.2802 25.9499 23.4686 25.9499 20.0002C25.9499 16.5319 23.1382 13.7202 19.6699 13.7202C16.2015 13.7202 13.3899 16.5319 13.3899 20.0002C13.3899 23.4686 16.2015 26.2802 19.6699 26.2802Z" :stroke="color" stroke-width="1.5")
      path(d="M5.85997 23.9999C5.7933 23.7799 5.7333 23.5566 5.67997 23.3299C4.93779 20.2385 5.24252 16.9881 6.54637 14.0885C7.85023 11.1889 10.0795 8.80396 12.8846 7.30762C15.6897 5.81127 18.9121 5.28814 22.0466 5.82024C25.181 6.35235 28.0503 7.90959 30.2044 10.2478C32.3586 12.5861 33.6759 15.5731 33.9498 18.7405C34.2237 21.908 33.4387 25.0768 31.7179 27.7501C29.9971 30.4234 27.4378 32.4501 24.4413 33.5124C21.4447 34.5747 18.1803 34.6125 15.16 33.6199" :stroke="color" stroke-width="1.5" stroke-dasharray="2.76 3.68")
      path(d="M10.3901 34.6899C12.7484 34.6899 14.6601 32.7782 14.6601 30.4199C14.6601 28.0616 12.7484 26.1499 10.3901 26.1499C8.03186 26.1499 6.12012 28.0616 6.12012 30.4199C6.12012 32.7782 8.03186 34.6899 10.3901 34.6899Z" :stroke="color" stroke-width="1.5")
      path(d="M22.2701 20.2202H16.8601" :stroke="color" stroke-width="1.5")
      path(d="M19.5701 17.5098V22.9198" :stroke="color" stroke-width="1.5")
      path(d="M12.5899 30.4199H8.18994" :stroke="color" stroke-width="1.5")

  //- not in the icon list, different icon from ionization
  div(v-else-if="measurement === 'ionlevel'" :class="'icon-' + measurement") 
    svg(:width="wsize" :height="hsize" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg")
      circle(id="Ellipse 87" cx="16.6843" cy="18.6316" r="6.15789" :stroke="color" stroke-width="1.6")
      circle(id="Ellipse 88" cx="6.26316" cy="7.26316" r="4.26316" :stroke="color" stroke-width="1.6")
      circle(id="Ellipse 89" cx="33.7368" cy="11.0526" r="4.26316" :stroke="color" stroke-width="1.6")
      circle(id="Ellipse 90" cx="11" cy="31.8947" r="4.26316" :stroke="color" stroke-width="1.6")
      path(id="Line 1" d="M21.4209 15.7895L30.421 12.4737" :stroke="color" stroke-width="1.6")
      line(id="Line 2" x1="9.19728" y1="10.0132" x2="12.9868" y2="13.8027" :stroke="color" stroke-width="1.6")
      line(id="Line 3" x1="12.6529" y1="28.2211" x2="14.5476" y2="24.4317" :stroke="color" stroke-width="1.6")

  div(v-else-if="measurement === 'ozone'" :class="'icon-' + measurement")
    svg(:width="wsize" :height="hsize" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg")
      path(d="M20.0099 18.1799C22.705 18.1799 24.8899 15.9951 24.8899 13.2999C24.8899 10.6048 22.705 8.41992 20.0099 8.41992C17.3147 8.41992 15.1299 10.6048 15.1299 13.2999C15.1299 15.9951 17.3147 18.1799 20.0099 18.1799Z" :stroke="color" stroke-width="1.5")
      path(d="M11.23 13.3C11.23 10.9714 12.155 8.73819 13.8016 7.09162C15.4482 5.44505 17.6814 4.52002 20.01 4.52002C22.3386 4.52002 24.5718 5.44505 26.2184 7.09162C27.8649 8.73819 28.79 10.9714 28.79 13.3" :stroke="color" stroke-width="1.5")
      path(d="M15.23 20.66L16.94 17.98" :stroke="color" stroke-width="1.5")
      path(d="M12.88 30.3C15.5751 30.3 17.76 28.1152 17.76 25.42C17.76 22.7249 15.5751 20.54 12.88 20.54C10.1849 20.54 8 22.7249 8 25.42C8 28.1152 10.1849 30.3 12.88 30.3Z" :stroke="color" stroke-width="1.5")
      path(d="M12.3301 33.86C10.4964 33.8573 8.71353 33.2575 7.25109 32.1513C5.78865 31.0451 4.72624 29.4928 4.22459 27.729C3.72293 25.9653 3.80931 24.0862 4.47066 22.3759C5.13201 20.6657 6.33235 19.2173 7.89011 18.25" :stroke="color" stroke-width="1.5")
      path(d="M23.03 17.98L24.74 20.66" :stroke="color" stroke-width="1.5")
      path(d="M27.1 30.3C29.7951 30.3 31.98 28.1152 31.98 25.42C31.98 22.7249 29.7951 20.54 27.1 20.54C24.4048 20.54 22.22 22.7249 22.22 25.42C22.22 28.1152 24.4048 30.3 27.1 30.3Z" :stroke="color" stroke-width="1.5")
      path(d="M31.8099 17.6001C33.5599 18.5268 34.9491 20.013 35.7556 21.8215C36.5621 23.63 36.7396 25.6566 36.2596 27.5778C35.7795 29.4989 34.6698 31.2039 33.1074 32.4206C31.545 33.6372 29.6201 34.2954 27.6399 34.2901" :stroke="color" stroke-width="1.5")

  div(v-else-if="measurement === 'pm10'" :class="'icon-' + measurement")
    svg(:width="wsize" :height="hsize" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg")
      path(d="M22.37 9.60986H16.97" :stroke="color" stroke-width="1.5")
      path(d="M19.6699 6.91016V12.3202" :stroke="color" stroke-width="1.5")
      path(d="M34.2701 9.62988H28.8601" :stroke="color" stroke-width="1.5")
      path(d="M31.5701 6.93018V12.3402" :stroke="color" stroke-width="1.5")
      path(d="M34.0999 21.7202H28.7" :stroke="color" stroke-width="1.5")
      path(d="M31.3999 19.0098V24.4198" :stroke="color" stroke-width="1.5")
      path(d="M13.4199 13.5801L8.71992 18.2701C4.99992 22.0001 5.54992 28.5701 9.10992 32.1301C12.6699 35.6901 19.2499 36.2301 22.9699 32.5101L27.6599 27.8201L21.3199 25.7601C21.1799 23.2301 21.0899 20.1801 21.0899 20.1801C21.0899 20.1801 17.9899 20.0701 15.4199 19.9401L13.4199 13.5801Z" :stroke="color" stroke-width="1.5")
      path(d="M28.2501 15.6499H22.8401" :stroke="color" stroke-width="1.5")
      path(d="M25.55 12.9502V18.3602" :stroke="color" stroke-width="1.5")
      path(d="M9.07009 32.0898L6.59009 34.6098" :stroke="color" stroke-width="1.5")

  div(v-else-if="['pm2.5', 'pollutant'].includes(measurement)")
    svg(:width="wsize" :height="hsize" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg")
      path(d="M19 34.7801C21.39 30.0001 28.56 30.5801 32.31 25.8301" :stroke="color" stroke-width="1.5")
      path(d="M12.3301 23.5C13.7667 22.4041 15.3358 21.4936 17.0001 20.79C18.8801 19.86 19.5201 18.79 19.1501 15.79" :stroke="color" stroke-width="1.5")
      path(d="M11.5599 36.5302C11.3312 34.5278 11.8434 32.5109 12.9999 30.8602C16.6799 25.5902 21.8499 27.0002 25.2899 21.4902C26.4381 19.7132 26.9297 17.5911 26.6799 15.4902" :stroke="color" stroke-width="1.5")
      path(d="M12.75 15.7C12.457 14.5466 12.4171 13.3435 12.6328 12.1732C12.8486 11.0029 13.315 9.89309 14 8.92C15.1445 7.15118 16.931 5.89572 18.9829 5.41818C21.0349 4.94063 23.1921 5.27829 25 6.36C26.1225 7.09758 27.0134 8.13761 27.57 9.36C29.3566 9.2535 31.1303 9.7232 32.63 10.7C34.3762 11.9126 35.6153 13.7251 36.1113 15.7924C36.6074 17.8596 36.3257 20.037 35.32 21.91" :stroke="color" stroke-width="1.5")
      path(d="M8.00005 10.71C6.25726 11.9255 5.02219 13.7394 4.52986 15.8064C4.03754 17.8734 4.32234 20.0493 5.33005 21.92" :stroke="color" stroke-width="1.5")

  div(v-else-if="measurement === 'relativehumidity'" :class="'icon-' + measurement")
    svg(:width="wsize" :height="hsize" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg")
      path(d="M20.1099 34.7799C18.7389 34.7799 17.3813 34.5099 16.1147 33.9852C14.8481 33.4605 13.6972 32.6915 12.7277 31.7221C11.7583 30.7526 10.9893 29.6017 10.4646 28.3351C9.93996 27.0685 9.66992 25.7109 9.66992 24.3399C9.80338 22.6506 10.2546 21.0017 10.9999 19.4799C12.8999 15.3899 20.0599 5.88989 20.0599 5.88989C20.0599 5.88989 27.2199 15.3899 29.1099 19.4799C29.8726 20.9983 30.3408 22.6473 30.4899 24.3399C30.49 27.1001 29.3969 29.748 27.4498 31.7045C25.5027 33.6609 22.8601 34.7667 20.0999 34.7799" :stroke="color" stroke-width="1.5")
      path(d="M23.24 20.8401L16.76 27.3201" :stroke="color" stroke-width="1.5")
      path(d="M23.5701 25.8601V28.0301" :stroke="color" stroke-width="1.5")
      path(d="M16.4299 20.28V22.45" :stroke="color" stroke-width="1.5")

  div(v-else-if="['temperature', 'spacetemperature'].includes(measurement)")
    svg(:width="wsize" :height="hsize" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg")
      path(d="M24.7801 19.7101V8.80009C24.8096 8.15464 24.708 7.50991 24.4814 6.90484C24.2547 6.29977 23.9078 5.74693 23.4615 5.27973C23.0151 4.81252 22.4787 4.44065 21.8847 4.18658C21.2906 3.93251 20.6512 3.80151 20.0051 3.80151C19.3589 3.80151 18.7195 3.93251 18.1255 4.18658C17.5314 4.44065 16.995 4.81252 16.5487 5.27973C16.1024 5.74693 15.7554 6.29977 15.5288 6.90484C15.3021 7.50991 15.2005 8.15464 15.2301 8.80009V19.7101C13.7076 20.7334 12.5541 22.2187 11.9396 23.9472C11.3251 25.6756 11.2822 27.5558 11.817 29.3105C12.3519 31.0652 13.4364 32.6017 14.9106 33.6934C16.3847 34.7852 18.1706 35.3745 20.0051 35.3745C21.8395 35.3745 23.6254 34.7852 25.0996 33.6934C26.5738 32.6017 27.6582 31.0652 28.1931 29.3105C28.728 27.5558 28.685 25.6756 28.0705 23.9472C27.4561 22.2187 26.3026 20.7334 24.7801 19.7101V19.7101Z" :stroke="color" stroke-width="1.5")
      path(d="M20.7874 30.9985C23.105 30.5627 24.6306 28.3306 24.1947 26.013C23.7589 23.6953 21.5268 22.1698 19.2091 22.6056C16.8915 23.0415 15.366 25.2736 15.8018 27.5912C16.2377 29.9089 18.4698 31.4344 20.7874 30.9985Z" :stroke="color" stroke-width="1.5")
      path(d="M20 13.6101V21.9401" :stroke="color" stroke-width="1.5")

  div(v-else-if="['timeinactivepure', 'ionizationtime'].includes(measurement)")
    svg(:width="wsize" :height="hsize" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg")
      path(d="M4.86011 19.83C4.90042 16.7738 5.8648 13.8013 7.62642 11.3036C9.38804 8.80586 11.8644 6.89979 14.7298 5.83608C17.5952 4.77238 20.7155 4.60085 23.6802 5.34405C26.6449 6.08726 29.3153 7.7104 31.3401 9.99999V5.15999" :stroke="color" stroke-width="1.5")
      path(d="M35.1399 20.1699C35.105 23.1991 34.1622 26.1482 32.4334 28.6358C30.7046 31.1235 28.2693 33.0353 25.4423 34.1241C22.6154 35.2128 19.5268 35.4285 16.5759 34.7432C13.6251 34.0579 10.9477 32.5031 8.88989 30.2799V35.1399" :stroke="color" stroke-width="1.5")
      path(d="M20.11 10.3202C22.0248 10.3401 23.8906 10.9275 25.4716 12.008C27.0525 13.0886 28.2774 14.6137 28.9913 16.3905C29.7053 18.1674 29.8762 20.116 29.4825 21.99C29.0888 23.864 28.1481 25.5791 26.7795 26.9184C25.4108 28.2576 23.6757 29.1609 21.7936 29.5138C19.9115 29.8668 17.967 29.6536 16.2061 28.9012C14.4452 28.1489 12.947 26.8912 11.901 25.2872C10.855 23.6832 10.3083 21.805 10.33 19.8902C10.3404 18.6187 10.602 17.3618 11.0998 16.1917C11.5976 15.0216 12.3217 13.9614 13.2305 13.0721C14.1393 12.1828 15.215 11.4818 16.3956 11.0096C17.5762 10.5373 18.8385 10.303 20.11 10.3202V10.3202Z" :stroke="color" stroke-width="1.5")
      path(d="M20.05 13.7002V20.0502H26.4" :stroke="color" stroke-width="1.5")

  div(v-else-if="measurement === 'timeincomfortzone'" :class="'icon-' + measurement")
    svg(:width="wsize" :height="hsize" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg")
      path(d="M20.36 10.3202C22.2748 10.3401 24.1406 10.9275 25.7216 12.008C27.3025 13.0886 28.5274 14.6137 29.2413 16.3905C29.9553 18.1674 30.1262 20.116 29.7325 21.99C29.3388 23.864 28.3981 25.5791 27.0295 26.9184C25.6608 28.2576 23.9257 29.1609 22.0436 29.5138C20.1615 29.8668 18.217 29.6536 16.4561 28.9012C14.6952 28.1489 13.197 26.8912 12.151 25.2872C11.105 23.6832 10.5583 21.805 10.58 19.8902C10.5904 18.6187 10.852 17.3618 11.3498 16.1917C11.8476 15.0216 12.5717 13.9614 13.4805 13.0721C14.3893 12.1828 15.465 11.4818 16.6456 11.0096C17.8262 10.5373 19.0885 10.303 20.36 10.3202V10.3202Z" :stroke="color" stroke-width="1.5")
      path(d="M30.87 31.1401C29.4138 32.5232 27.6994 33.606 25.8247 34.3264C23.95 35.0468 21.9517 35.3909 19.944 35.3389C17.9363 35.2869 15.9585 34.8398 14.1236 34.0233C12.2887 33.2068 10.6326 32.0368 9.25 30.5801" :stroke="color" stroke-width="1.5")
      path(d="M25.3799 5.47998C27.6603 6.28211 29.7169 7.61552 31.3799 9.36998" :stroke="color" stroke-width="1.5")
      path(d="M9.75 8.80986C11.6091 7.03771 13.8865 5.76486 16.37 5.10986" :stroke="color" stroke-width="1.5")
      path(d="M20.3101 13.7002V20.0502H26.6601" :stroke="color" stroke-width="1.5")

  div(v-else-if="['totalvocs', 'voc'].includes(measurement)")
    svg(:width="wsize" :height="hsize" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg")
      path(d="M6.57007 30.6001V32.9601" :stroke="color" stroke-width="1.5")
      path(d="M12.1899 5.72021V8.08021" :stroke="color" stroke-width="1.5")
      path(d="M26.6899 14.8301V17.1901" :stroke="color" stroke-width="1.5")
      path(d="M25.3601 8.32003C23.4473 8.3216 21.5767 8.88172 19.9779 9.93161C18.3791 10.9815 17.1217 12.4755 16.3601 14.23C15.5571 13.5946 14.6275 13.1383 13.6337 12.8917C12.6399 12.6451 11.6048 12.614 10.598 12.8003C9.5911 12.9867 8.63574 13.3862 7.796 13.9722C6.95626 14.5581 6.25156 15.3169 5.72919 16.1976C5.20682 17.0782 4.87887 18.0605 4.76733 19.0783C4.6558 20.0962 4.76326 21.1262 5.08252 22.0991C5.40178 23.072 5.92544 23.9654 6.61837 24.7192C7.3113 25.4731 8.15746 26.0701 9.10007 26.47C9.53256 28.0357 10.4314 29.4326 11.677 30.475C12.9227 31.5174 14.4561 32.156 16.0735 32.3058C17.6908 32.4556 19.3155 32.1095 20.7314 31.3136C22.1473 30.5177 23.2874 29.3096 24.0001 27.85C25.3391 28.052 26.7054 27.9748 28.0131 27.6233C29.3209 27.2718 30.5418 26.6537 31.5992 25.8077C32.6566 24.9617 33.5276 23.9062 34.1574 22.7075C34.7873 21.5087 35.1624 20.1927 35.2592 18.842C35.3561 17.4913 35.1725 16.1352 34.7201 14.8588C34.2676 13.5825 33.5561 12.4135 32.6302 11.4254C31.7043 10.4372 30.584 9.65127 29.3398 9.11686C28.0955 8.58246 26.7542 8.31117 25.4001 8.32003H25.3601Z" :stroke="color" stroke-width="1.5")

  div(v-else-if="measurement === 'ventilation'" :class="'icon-' + measurement")
    svg(:width="wsize" :height="hsize" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg")
      path(d="M16.86 13.1099V12.8299C16.9989 11.875 16.9989 10.9049 16.86 9.94994C16.7709 9.17054 16.4768 8.42866 16.0078 7.79981C15.5388 7.17096 14.9116 6.6776 14.19 6.36994C13.6982 6.1669 13.1695 6.06828 12.6376 6.08033C12.1057 6.09238 11.5821 6.21484 11.1 6.43995C10.1083 6.91227 9.23139 7.59473 8.52996 8.43994C4.52996 12.7999 6.29996 16.2099 6.91996 17.0299C7.65051 17.9558 8.63338 18.6504 9.74996 19.0299C11.75 19.8299 14.56 19.8399 16.11 20.0299" :stroke="color" stroke-width="1.5")
      path(d="M23.3901 27.66V27.94C23.2511 28.895 23.2511 29.865 23.3901 30.82C23.4719 31.5948 23.7562 32.3344 24.2143 32.9645C24.6725 33.5947 25.2883 34.0932 26.0001 34.41C26.4919 34.613 27.0205 34.7117 27.5525 34.6996C28.0844 34.6876 28.608 34.5651 29.0901 34.34C30.0817 33.8677 30.9587 33.1852 31.6601 32.34C35.6601 27.98 33.8901 24.57 33.2701 23.75C32.5396 22.8242 31.5567 22.1296 30.4401 21.75C28.4401 20.95 25.6301 20.94 24.0801 20.75" :stroke="color" stroke-width="1.5")
      path(d="M27.18 16.9H27.46C28.415 17.039 29.385 17.039 30.34 16.9C31.1194 16.8109 31.8613 16.5169 32.4901 16.0479C33.119 15.5789 33.6124 14.9516 33.92 14.23C34.123 13.7382 34.2217 13.2096 34.2096 12.6776C34.1976 12.1457 34.0751 11.6221 33.85 11.14C33.3777 10.1484 32.6952 9.27143 31.85 8.57C27.49 4.57 24.08 6.34 23.26 6.96C22.3342 7.69054 21.6396 8.67342 21.26 9.79C20.46 11.79 20.45 14.6 20.26 16.15" :stroke="color" stroke-width="1.5")
      path(d="M13.0599 23.8601H12.7799C11.8249 23.7211 10.8549 23.7211 9.8999 23.8601C9.12049 23.9492 8.37861 24.2432 7.74976 24.7122C7.12091 25.1812 6.62755 25.8084 6.3199 26.5301C6.11686 27.0219 6.01823 27.5505 6.03028 28.0824C6.04233 28.6144 6.16479 29.138 6.3899 29.6201C6.86222 30.6117 7.54468 31.4887 8.3899 32.1901C12.7499 36.1901 16.1599 34.4201 16.9799 33.8001C17.9057 33.0695 18.6003 32.0867 18.9799 30.9701C19.7799 28.9701 19.7899 26.1601 19.9799 24.6101" :stroke="color" stroke-width="1.5")
      path(d="M20.7908 24.1958C23.1085 23.76 24.634 21.5279 24.1981 19.2102C23.7623 16.8926 21.5302 15.3671 19.2126 15.8029C16.8949 16.2387 15.3694 18.4709 15.8052 20.7885C16.2411 23.1061 18.4732 24.6316 20.7908 24.1958Z" :stroke="color" stroke-width="1.5")

  //- There is no icon for the measurement
  div(v-else)
</template>

<script>
export default {
  name: "MeasurementIcons",
  props: {
    measurement: String,
    iconcolor: String,
    size: {
      type: Number,
      default: 40,
    },
  },
  data() {
    let m = this.measurement;
    let c = this.iconcolor;

    if (!c) {
      if(m === "activepure") c = "#FFA88C";
      else if(m === "airquality") c = "#2EFCFA";
      else if (m === "carbonmonoxide") c = "#FF50D8";
      else if(m === "co2") c = "#FFCA3A";
      else if (m === "infectiontransmissibility") c = "#02C39A";
      else if (["ionization", "ionizerstatus"].includes(m)) c = "#FF5050";
      else if(m === "ionlevel") c = "4E7CFF"; // same color as temp, space temp
      else if (m === "ozone") c = "#6F44FF";
      else if (m === "pm10") c = "#CC3AFF";
      else if (["pm2.5","pollutant"].includes(m)) c = "#4C3DFF";
      else if(m === "relativehumidity") c = "#9655FF";
      else if(["temperature", "spacetemperature"].includes(m)) c = "#4E7CFF";
      else if (["timeinactivepure","ionizationtime"].includes(m)) c = "#55FFC2";
      else if(m === "timeincomfortzone") c = "#D3FC2E";
      else if(["totalvocs","voc"].includes(m)) c = "#FF9F47";
      else if(m === "ventilation") c = "#7C90FF";
    }
    return {
      color: c,
      wsize: this.size,
      hsize: this.size,
    };
  },
};
</script>