<template lang="pug">
        main(style='padding-left:0;position:fixed;top:0;left:0;bottom:0;right:0;background-color:#1d2039;').valign-wrapper
            div 
</template>

<script>
// @ is an alias to /src
import {wlData} from '../assets/js/constants';
import {HTTPClient} from '../assets/js/http';
let client = new HTTPClient();

export default {
  name: 'LoginGoogle',
  data() {
      let imageStyle = 'background-image:url("'+wlData.loginLogo+'");'+
          'width:313px;height:100px;margin-left:auto;margin-right:auto;';
      return {
        username: '',
        password: '',
        errorText: null,
        projectId: '',
        projects: [],
        imageStyle
      };
  },
  async mounted() {
    console.log('HHHHH');
    let token = this.$route.query.token;
    let user = this.$route.query.user;
    try{
        if(!token || !user) throw new Error('Invalid user or login token');
        user = JSON.parse(user);
        await this.$store.dispatch('loginWithToken', {token, user});
        window.location.replace('/');
    }catch(e){
        console.error(e);
    }
    // siteId: this.$route.params.siteId,
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
    #selectProject {
        padding: 5px;
        border-radius: 10px;
        height: 3rem;
    }
</style>
