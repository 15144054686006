<template lang="pug">
    #loginPage
        .header-nav
            a(href="/")
                img(:src="wlLogo")
            a.contact-us.waves-effect.waves-light(:href='wlContact') Contact
        .login-cards
            .login-card.step-login(v-show="step == 0")
                .content(data-step='0')
                    .title Welcome to {{wlName}} 
                    .input-group
                        label(for='login-email') Email Address
                        input#login-email(name='login-email', type='email' v-model="username" placeholder="Enter your email address")
                    .input-group
                        label(for='login-password') Password
                        input#login-password(name='login-password', type='password' v-model="password" placeholder="Enter your password")
                    .error(v-if="errorText") {{errorText}}
                    button#loginBtn.waves-effect.waves-light(v-on:click="fetchProjects") Login
                    a(v-on:click="showResetPassword = true") Forgot Password?
                    
                    //- .strike
                    //-     span or
                    //- a#googleBtn.waves-effect.waves-light(href="/users/auth/google")
                    //-     <img src="/images/google/google-login.svg"> Sign In with Google
            .login-card.step-login(v-show="step == 3")
                .content(data-step='3').reset-password
                    .title Welcome to {{wlName}}
                    .subtitle Reset your password!
                    br
                    .input-field
                        label(for='login-email') New Password
                        input#new-password(name='login-email',v-model="resetPasswordInput.newPassword" placeholder="Enter your new password"
                        :type="resetPasswordInput.togglePassword ? 'text' : 'password'")
                        span.field-icon(@click="togglePasswordInput('password')")
                            span.material-icons(v-if="resetPasswordInput.togglePassword") visibility
                            span.material-icons(v-else="resetPasswordInput.togglePassword") visibility_off
                    .input-field.if-pw-confirm
                        label(for='login-email') Confirm Password
                        input#confirm-password(name='login-email', v-model="resetPasswordInput.confirmPassword" placeholder="Confirm your new password"
                        :type="resetPasswordInput.toggleConfirmPassword ? 'text' : 'password'"
                        )
                        span.field-icon(@click="togglePasswordInput('')")
                            span.material-icons(v-if="resetPasswordInput.toggleConfirmPassword") visibility
                            span.material-icons(v-else="resetPasswordInput.toggleConfirmPassword") visibility_off
                    button#reset-password-button.waves-effect.waves-light(@click="confirmResetPassword" :disabled="apiDisableButton") Reset Password
            .login-card.step-project(v-show="step == 1")
                .content(data-step='1')
                    .title Select a Project
                    .input-group
                        .vuetify(data-app)
                            v-select#selectProject2.haq-text-field(v-model="projectId" :items="projects" item-text="name" item-value="objectId" label="Select a Project" hide-details
                                :menu-props="{ bottom: true, offsetY: true, closeOnClick: true, closeOnContentClick: true, }")
                    button#chooseProjectBtn.waves-effect.waves-light(v-on:click="authenticateAPI") Confirm
                    .strike
                        span or
                    button#createProjectBtn.waves-effect.waves-light(v-on:click="moveToCreateProjectUI") Create Project
            .login-card.step-login(v-show="step == 2")
                .content(data-step='2')
                    .title Create Your Project
                    .input-group
                        label(for='login-pname') Project Name
                        input#login-pname.haq-text-field(name='login-pname', type='text' v-model="pname" placeholder="Enter a project name")
                    .error(v-if="errorText") {{errorText}}
                    button#confirmCreateProject.waves-effect.waves-light(v-on:click="submitCreateProject") Create Project
        v-dialog(v-model="showResetPassword" max-width="400px" scrollable @click:outside="showResetPassword = false" content-class="reset-password-modal")
            v-card(data-card)
                v-card-title 
                    h5 Forgot Password
                v-card-text
                    v-container.mt-2(v-if="resetPasswordStep == 'send-email'")
                        v-row
                            v-col(:cols="12" v-if="resetPasswordInput.emailText").ma-0
                                p {{resetPasswordInput.emailText}}
                            v-col(:cols="12")
                                 .input-field
                                    label(for='login-email') Email Address
                                    input#reset-email(name='login-email', type='email' v-model="username" placeholder="Enter your email address")
                    //- v-container.mt-2(v-if="resetPasswordStep == 'reset-password'")
                    //-     v-row
                    //-         v-col(:cols="12")
                    //-             .input-field
                    //-                 label(for='login-email') One Time Password
                    //-                 input#one-time-password(name='login-email', type='text' v-model="resetPasswordInput.oneTimePassword" placeholder="Enter your one time password")
                    //-         v-col(:cols="12")
                    //-             .input-field
                    //-                 label(for='login-email') New Password
                    //-                 input#new-password(name='login-email',v-model="resetPasswordInput.newPassword" placeholder="Enter your new password"
                    //-                 :type="resetPasswordInput.togglePassword ? 'text' : 'password'")
                    //-                 span.field-icon(@click="togglePasswordInput('password')")
                    //-                     span.material-icons(v-if="resetPasswordInput.togglePassword") visibility
                    //-                     span.material-icons(v-else="resetPasswordInput.togglePassword") visibility_off
                    //-         v-col(:cols="12")
                    //-             .input-field
                    //-                 label(for='login-email') Confirm Password
                    //-                 input#confirm-password(name='login-email', v-model="resetPasswordInput.confirmPassword" placeholder="Confirm your new password"
                    //-                :type="resetPasswordInput.toggleConfirmPassword ? 'text' : 'password'"
                    //-                 )
                    //-                 span.field-icon(@click="togglePasswordInput('')")
                    //-                     span.material-icons(v-if="resetPasswordInput.toggleConfirmPassword") visibility
                    //-                     span.material-icons(v-else="resetPasswordInput.toggleConfirmPassword") visibility_off
                    //-     v-row(v-if="resetPasswordInput.error")
                    //-         v-col(:cols="12")
                    //-             p.error {{resetPasswordInput.error}}
                v-card-actions.mb-2
                    v-spacer
                    button#reset-password-button.waves-effect.waves-light(@click="resetPasswordSendEmail" v-if="resetPasswordStep == 'send-email'" :disabled="apiDisableButton") Reset Password
                    v-btn.haq-btn.haq-btn-primary(text @click="confirmResetPassword" v-if="resetPasswordStep == 'reset-password'"
                    :disabled="apiDisableButton") Reset Password

</template>
<script>
// @ is an alias to /src
import {wlData} from '../assets/js/constants';
import {HTTPClient} from '../assets/js/http';
let client = new HTTPClient();

export default {
    name: 'Login',
    mounted(){
        if(this.autologin)
            $('#loginBtn').trigger('click');
    },
    data() {
        return {
            username: this.$route.query.username||'',
            password: this.$route.query.password||'',
            autologin: (this.$route.query.al=='true')||false,
            errorText: null,
            projectId: '',
            projects: [],
            step: this.$route.query.resetPasswordToken ? 3 : 0,
            pname: '',
            onboardNewUser: false,
            wlName: wlData.name ? wlData.name : "HawkenAQ",
            wlLogo: wlData.loginLogo ? wlData.loginLogo : "/images/hawkenAqBlue.png",
            wlContact: wlData.contactUrl||'https://gethawken.com/contact-hawkenaq',
            showResetPassword: false,
            resetPasswordStep: 'send-email',
            apiDisableButton: false,
            resetPasswordInput: {
                emailText: "Enter your email address to reset your password",
                newPassword: '',
                confirmPassword: '',
                togglePassword: false,
                toggleConfirmPassword: false,
                error: null
            }
        };
    },
    methods: {
        async submitCreateProject(event){
            try{
                event.target.disabled = true;
                let username = this.username.toLowerCase().trim();
                let password = this.password;
                let pname = this.pname.trim();
                if (!pname){
                    window.M.toast({html: 'Enter your a project name'});
                    event.target.disabled = false;
                    return;
                }
                let project = await client.robustPost('/users/projects', {username, password, name: pname});
                project = project.results;

                await this.$store.dispatch('login', {username, password, project: project.objectId});
                    
                if(this.$route.query.from == "onboarding")
                    window.location.replace('/onboarding');
                else if (this.$route.query.from) //used to return to Admin
                    window.location.replace(this.$route.query.from);
                else
                    window.location.replace('/');
                // event.target.disabled = false;
            } catch (error) {
                console.error(error);
                event.target.disabled = false;
                this.errorText = error.message||'Login failed';
            }
        },
        async moveToCreateProjectUI(){
            this.step = 2;
        },
        async fetchProjects(event){
            try {
                event.target.disabled = true;
                let username = this.username.toLowerCase().trim();
                let password = this.password;

                if (!username || !password){
                    window.M.toast({html: 'Enter your Email and/or Password'});
                    event.target.disabled = false;
                }
                else {
                    let projects = await client.robustQuery('/users/projectsWithAuth', {username, password});
                    
                    if (projects.length >= 1) {
                        // sort projects by name
                        projects.sort((a,b) => {
                            if (a.name < b.name) return -1;
                            if (a.name > b.name) return 1;
                            return 0;
                        });
                        this.projects = projects;
                        this.step = 1;
                        this.$nextTick(() => {
                            let elems = document.querySelector("#selectProject");
                            window.M.FormSelect.init(elems,{});
                        })
                        event.target.disabled = false;
                    }
                    else if (projects.length == 1) {
                        // removed this because it prevents user from seeing create project button
                        this.projectId = projects[0].objectId;
                        this.authenticateAPI(event);
                    }
                    else {
                        // window.M.toast({html: 'There are no currently no projects assigned to your account.'});
                        // event.target.disabled = false;
                        this.projects = projects;
                        this.step = 1;
                        this.$nextTick(() => {
                            let elems = document.querySelector("#selectProject");
                            window.M.FormSelect.init(elems,{});
                        })
                        event.target.disabled = false;
                    }
                }
            } catch (error) {
                console.error(error);
                event.target.disabled = false;
                this.errorText = error.message||'Login failed';
            }
        },
        togglePasswordInput(input){
           if(input == 'password') {
             this.resetPasswordInput.togglePassword = !this.resetPasswordInput.togglePassword;
           } else {
                this.resetPasswordInput.toggleConfirmPassword = !this.resetPasswordInput.toggleConfirmPassword;
           }
        },
        async resetPasswordSendEmail(){
            this.apiDisableButton = true;
            try {
                let payload = {
                    email: this.username.toLowerCase().trim(),
                }
                await client.robustPost('/users/reset-password-email', payload);
                this.resetPasswordInput.emailText = 'Request received. Check your email'
                window.M.toast({html: 'Request received. Check your email'});
            } catch (error) {
                window.M.toast({html: 'Error sending email. Please make sure that is a registered email address.'});
            } finally {
                this.apiDisableButton = false;
            }
        },
        async confirmResetPassword() {
            try {
                if(this.$route.query.resetPasswordToken == null){
                    window.M.toast({html: 'Error resetting password. Please make sure that link is correct and has not expired.'});
                    return false;
                }
                if(this.resetPasswordInput.newPassword == "" || this.resetPasswordInput.confirmPassword == ""){
                    window.M.toast({html: 'Please enter your new password.'});
                    this.resetPasswordInput.error = "Please enter your new password.";
                    return false;
                }
                if(this.resetPasswordInput.newPassword != this.resetPasswordInput.confirmPassword){
                    window.M.toast({html: 'Passwords do not match.'});
                    this.resetPasswordInput.error = "Passwords do not match.";
                    return false;
                }

                let payload = {
                    email: this.username.toLowerCase().trim(),
                    password: this.resetPasswordInput.newPassword,
                    token: this.$route.query.resetPasswordToken
                }
                this.apiDisableButton = true;
                const res = await client.robustPost('/users/reset-password', payload);
                console.log(res);
                const { email } = res.results
                window.M.toast({html: 'Password reset successfully. Please login now'});
                setTimeout(() => {
                    window.location.replace('/login?username=' + email);
                }, 500)

            } catch (error) {
                console.error(error);
                window.M.toast({html: 'Error resetting password. Please make sure that the one time password is correct and has not expired.'});
            } finally {
                this.apiDisableButton = false;
                this.resetPasswordInput.error = null;
            }
        },
        async authenticateAPI(event) {
            event.target.disabled = true;
            try {
                let username = this.username.toLowerCase().trim();
                let password = this.password;
                let project = this.projectId;

                if (!project){
                    window.M.toast({html: 'Select a Project'});
                    event.target.disabled = false;
                }
                else {
                    await this.$store.dispatch('login', {username, password, project});
                    
                    if(this.$route.query.from == "onboarding")
                        window.location.replace('/onboarding');
                    else if (this.$route.query.from) //used to return to Admin
                        window.location.replace(this.$route.query.from);
                    else
                        window.location.replace('/');
                }
            } catch (error) {
                console.error(error);
                event.target.disabled = false;
                this.errorText = true;
            }
        }
    }
};
</script>
<style lang="sass">
    #loginPage .haq-text-field
        border: 1px solid #005B9A
        box-sizing: border-box
        border-radius: 12px
        margin: 8px 0 8px
        .v-select__slot
            background: #fff
            font: normal 400 16px/24px Roboto
            label
                color: #005B9A !important
                line-height: 38px !important
            .v-select__selections
                color: #005B9A !important
            .v-input__append-inner .v-icon
                color: #005B9A !important
</style>
<style lang="sass" scoped>
    #loginPage, .reset-password-modal
        background: #fff url("../../public/images/bg.png") top right no-repeat
        background-size: cover
        min-height: 100vh
        
    .header-nav
        background: #fff
        box-shadow: 0px 100px 80px rgba(0, 91, 154, 0.12), 0px 41.7776px 33.4221px rgba(0, 91, 154, 0.0862625), 0px 22.3363px 17.869px rgba(0, 91, 154, 0.0715329), 0px 12.5216px 10.0172px rgba(0, 91, 154, 0.06), 0px 6.6501px 5.32008px rgba(0, 91, 154, 0.0484671), 0px 2.76726px 2.21381px rgba(0, 91, 154, 0.0337375)
        display: flex
        align-items: center
        justify-content: space-between
        height: 80px
        padding: 18px 36px
        
        @media (max-width: 600px)
            padding: 18px 15px

        img
            max-height: 52px
        a.contact-us
            border: 1px solid #005B9A
            border-radius: 12px
            color: #005B9A
            font: normal 400 16px/24px Roboto
            letter-spacing: 0.18px
            height: 40px
            line-height: 40px
            text-align: center
            width: 140px
            @media (max-width: 600px)
                font-size: 14px
                height: 36px
                line-height: 36px
                width: 90px
    .login-cards
        display: flex
        align-items: center
        min-height: calc(100vh - 80px)
        
        @media (max-width: 600px)
            align-items: flex-start

        .login-card
            background: #fff
            border-radius: 20px
            box-shadow: 0px 100px 80px rgba(0, 91, 154, 0.12), 0px 41.7776px 33.4221px rgba(0, 91, 154, 0.0862625), 0px 22.3363px 17.869px rgba(0, 91, 154, 0.0715329), 0px 12.5216px 10.0172px rgba(0, 91, 154, 0.06), 0px 6.6501px 5.32008px rgba(0, 91, 154, 0.0484671), 0px 2.76726px 2.21381px rgba(0, 91, 154, 0.0337375)
            color: #005B9A
            margin: 50px auto
            max-height: 604px
            max-width: 548px
            padding: 40px
            width: 100%

            @media (max-width: 600px)
                // background: rgba(255,255,255,.8)
                margin: 50px 15px
                padding: 40px 20px

            .content
                margin: 0 auto
                max-width: 360px
                width: 100%
                text-align: center

                .title
                    font: normal 400 32px/46px Roboto
                    margin-bottom: 32px
                    @media (max-width: 600px)
                        font-size: 24px
                .input-group
                    text-align: left
                    label
                        font: normal 400 14px/20px Roboto
                        color: #005B9A
                    input
                        border: 1px solid #005B9A
                        box-sizing: border-box
                        border-radius: 12px
                        color: #005B9A
                        font: normal 400 16px/24px Roboto
                        margin: 8px 0 24px
                        padding: 8px 20px
                    ::placeholder
                        color: #005B9A
                        opacity: 0.4
                .error
                    background-color: transparent !important
                    color: #FF5050
                    font: normal 400 14px/20px Roboto
                    text-align: left
                .strike
                    display: block
                    text-align: center
                    overflow: hidden
                    white-space: nowrap
                    > span
                        color: #005B9A
                        font: normal 400 14px/20px Roboto
                        position: relative
                        display: inline-block
                        opacity: 0.5
                        &:before,
                        &:after
                            content: ""
                            position: absolute
                            top: 55%
                            width: 9999px
                            height: 1px
                            background: #005B9A
                            opacity: 0.5
                        &:before
                            right: 100%
                            margin-right: 8px
                        &:after
                            left: 100%
                            margin-left: 8px
            .reset-password
                .input-field
                    margin: 0 0 16px
                    text-align: left
                    label
                        font: normal 400 14px/20px Roboto
                        color: #005B9A
                        position: static
                        margin-bottom: 8px
                    input
                        border: 1px solid #005B9A
                        box-sizing: border-box
                        box-shadow: none
                        border-radius: 12px
                        color: #005B9A
                        font: normal 400 16px/24px Roboto
                        height: 40px
                        margin: 0 
                        padding: 8px 20px
                        &:disabled
                            color: #acacac
                            border-color: #ccc
                    span.field-icon
                        position: absolute
                        right: 12px
                        top: 33px
                        cursor: pointer
                        z-index: 2
                        .material-icons
                            font-size: 20px
                    span.helper-text
                        color: #005B9A
                        font: normal 400 12px/20px Roboto
                    ::placeholder
                        color: #005B9A
                        font: normal 400 14px/20px Roboto
                        opacity: 0.4
                .if-pw-confirm
                    margin-bottom: 0
                .requirements
                    text-align: left
                    font: normal 400 12px/20px Roboto
                .error
                    background-color: transparent !important
                    color: #FF5050
                    font: normal 400 14px/20px Roboto
                    text-align: left
                .strike
                    display: block
                    text-align: center
                    overflow: hidden
                    white-space: nowrap
                    > span
                        color: #005B9A
                        font: normal 400 14px/20px Roboto
                        position: relative
                        display: inline-block
                        opacity: 0.5
                        &:before,
                        &:after
                            content: ""
                            position: absolute
                            top: 55%
                            width: 9999px
                            height: 1px
                            background: #005B9A
                            opacity: 0.5
                        &:before
                            right: 100%
                            margin-right: 8px
                        &:after
                            left: 100%
                            margin-left: 8px
        #loginBtn,
        #chooseProjectBtn,
        #createProjectBtn,
        #confirmCreateProject,
        #reset-password-button
            background: #3456F7
            border: 0
            border-radius: 12px
            color: #fff
            margin: 24px 0
            padding: 10px
            height: 40px
            max-height: 360px
            width: 100%
            .disabled
                background: #B9E2FF
                color: #52B2F3
        
        #googleBtn
            background: #fff
            border: 1px solid #005B9A
            border-radius: 12px
            color: #005B9A
            font: normal 400 14px/20px 'Roboto'
            padding: 10px
            height: 40px
            max-height: 360px
            margin: 24px 0
            width: 100%
</style>