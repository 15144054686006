<template lang="pug">
    #accountSettingsPage
        .new-pw-cards
            .new-pw-card.step-login
                .content
                    .title Notifications
                    .subtitle Where would you like to receive alerts?
                    v-checkbox(v-model="notifications.email" label="Email")
                    v-checkbox(v-model="notifications.sms" label="SMS")
                v-btn.haq-btn-primary.waves-effect.waves-light(:disabled="isDisabled" @click="updateNotifications") Update Notifications
        
</template>
<script>
import {HTTPClient} from '../../assets/js/http';
let client = new HTTPClient();

export default {
    name: 'Account',
    data(){
        let notifications = this.$store.state.user?.notifications || {
            email: false,
            sms: false
        }
        console.log(this.$store.state.user)
        return {notifications, isDisabled: false}
    },
    methods: {
        loadNotificationsFromState(){
             let notifications = this.$store.state.user?.notifications || {
                email: false,
                sms: false
            }
            this.notifications = notifications;
        },
        async updateNotifications(){
            try {
                this.isDisabled = true
                let res = await client.robustPost("/users/notifications", this.notifications);
                window.M.toast({
                    html: "Saved notifications"
                })
                this.$store.commit("setUserNotifications", this.notifications)
            } catch (error) {
                console.error(error);
                window.M.toast({
                    html: "Cannot update notifications. Please contact support@hawkenaq.com if problem persists"
                })
            } finally {
                this.isDisabled = false;
            }
        }
    },
    mounted(){
        this.loadNotificationsFromState();
    },
    watch: {
        '$store.state.user': {
            handler(){
                this.loadNotificationsFromState()
            }
        }
    }
};
</script>

<style lang="sass" scoped>
    .new-pw-cards
        .new-pw-card
            color: var(--app-primary-txt)
            .content
                max-width: 360px
                width: 100%
                .title
                    font: normal 400 32px/46px Roboto
                    margin-bottom: 10px
                    @media (max-width: 600px)
                        font-size: 24px
                .subtitle
                    font: normal 400 16px/24px Roboto
                .input-field
                    margin: 0 0 16px
                    text-align: left
                    label
                        font: normal 400 14px/20px Roboto
                        color: var(--app-primary-txt)
                        position: static
                        margin-bottom: 8px
                    input
                        border: 1px solid var(--app-primary-txt)
                        box-sizing: border-box
                        box-shadow: none
                        border-radius: 12px
                        color: var(--app-primary-txt)
                        font: normal 400 16px/24px Roboto
                        height: 40px
                        margin: 0 
                        padding: 8px 20px
                        // &:disabled
                        //     color: #acacac
                        //     border-color: #ccc
                    span.field-icon
                        position: absolute
                        right: 12px
                        top: 33px
                        cursor: pointer
                        z-index: 2
                        .material-icons
                            font-size: 20px
                    span.helper-text
                        color: var(--app-primary-txt)
                        font: normal 400 12px/20px Roboto
                    ::placeholder
                        color: var(--app-primary-txt)
                        font: normal 400 14px/20px Roboto
                        opacity: 0.4
                .if-pw-confirm
                    margin-bottom: 0
                .requirements
                    text-align: left
                    font: normal 400 12px/20px Roboto
                .error
                    background-color: transparent !important
                    color: #FF5050
                    font: normal 400 14px/20px Roboto
                    text-align: left
                .strike
                    display: block
                    text-align: center
                    overflow: hidden
                    white-space: nowrap
                    > span
                        color: #005B9A
                        font: normal 400 14px/20px Roboto
                        position: relative
                        display: inline-block
                        opacity: 0.5
                        &:before,
                        &:after
                            content: ""
                            position: absolute
                            top: 55%
                            width: 9999px
                            height: 1px
                            background: #005B9A
                            opacity: 0.5
                        &:before
                            right: 100%
                            margin-right: 8px
                        &:after
                            left: 100%
                            margin-left: 8px
        .step-success
            .v-icon
                color: #A9ECDE
                font-size: 24px
                margin: 0 4px 4px 0
        .haq-btn-primary
            background: #3456F7
            border: 0
            border-radius: 12px
            color: #fff
            margin: 24px 0
            padding: 10px
            height: 40px
            max-height: 360px
            width: 360px
            .disabled
                background: #B9E2FF
                color: #52B2F3
</style>