/*eslint no-undef: "warn"*/

<template lang="pug">
	#kiosk-wrapper(:class="{small: s}")
		#kiosk(v-if="site" :class="{small: s}" :style="$store.state.theme == 'dark' ? 'background-color:var(--app-primary-bg)' : 'background-color:var(--app-secondary-bg)'")
			#k-branded(:style="{backgroundColor: bgcolor ? bgcolor : 'var(--card-primary-bg)',color: textcolor ? textcolor : 'var(--text-color-1)'}")
				.row.top
					.col.m12
						.logo(:class="{'logo-center':(logo1center === true)}")
							img.responsive-img(v-if="$store.state.logo", :src="$store.state.logo" style="height:100px")
							img.responsive-img(v-else-if="brandingLogo1", :src="brandingLogo1.url.replace('http','https')")
						.time {{ timestamp }}
				.row.middle
					.col.m12
						.brand-measurement-highlight.carousel
							.carousel-slide(v-for="(i,slideindex) in carouselMeasurements", :class="'slide-count-' + carouselMeasurements.length",:style="getSlideAnimationDelay(slideindex)")
								.highlight-name(:class="{small: s}" style="color:var(--text-color-1)") {{ getKioskDis(i) }}
									.highlight-subname(v-if="zone" style="color:var(--text-color-1)") {{ zone.name }}
								.highlight-curScore(:class="{small: s}")
									KioskDonutIndicator(v-if="$store.state.themes" :id="siteId",:curVal="Math.round(i.curScore)",:units="'0'",:donutcolor="donutcolor ? donutcolor : 'green'",:textcolor="$store.state.themes.txt1")
									KioskDonutIndicator(v-else :id="siteId",:curVal="Math.round(i.curScore)",:units="'0'",:donutcolor="donutcolor ? donutcolor : 'rgb(33, 150, 243)'",:textcolor="textcolor ? textcolor : 'black'")
								.highlight-progress(v-if="$store.state.themes" :style="{color: donutcolor ? donutcolor : 'green'}") {{ getIndicatorText(i) }}
								.highlight-progress(v-else :style="{color: donutcolor ? donutcolor : 'rgb(33, 150, 243)'}") {{ getIndicatorText(i) }}
				.row.bottom(v-if="logo2center")
					.col.m12
						.logo(:class="{'logo-center':(logo2center === true)}")
							img.responsive-img(v-if="brandingLogo2", :src="brandingLogo2.url.replace('http','https')")
			//- #k-cards(:style="$store.state.theme == 'dark' ? 'background-color:var(--app-primary-bg)' : 'background-color:var(--app-secondary-bg)'")
			#k-cards
				.k-card(v-for="(i,index) in componentMeasurements", :class="'card-' + getKioskKind(i.type)")
					.k-card-measurement(:class="getKioskKind(i.type) + ' ' + (i.isAlerting ? getAlertCssClass(i.alertConfigs[0].alertType) : '')")
						.k-card-measurement-progress
							span(v-if="i.isAlerting") {{i.alertConfigs[0].alertType === 'warning' ? 'Warning' : 'Critical'}}
							span.progress-excellent(v-else-if="i.curScore >= 75") Excellent
							span(v-else-if="i.curScore < 75 && i.curScore >= 50") Good
							span(v-else) Poor
						.k-card-measurement-icon
							MeasurementIcons(v-if="i.isAlerting" :measurement="getKioskKind(i.type)",iconcolor="#fff")
							MeasurementIcons(v-else :measurement="getKioskKind(i.type)",:iconcolor="iconcolor ? iconcolor : 'white'",:size="40")
						.k-card-measurement-curVal {{getDisplayVal(i)}}
							span {{i.unit}}
						.k-card-measurement-name {{i.name}}
</template>

<script>
import { HTTPClient } from "../assets/js/http";
import KioskDonutIndicator from '@/components/KioskDonutIndicator.vue'
import MeasurementIcons from '@/components/MeasurementIcons.vue';
let client = new HTTPClient();

export default {
	name: "Kiosk",
	components: {
		KioskDonutIndicator,
		MeasurementIcons,
	},
	props: {
		site: Object,
		kioskUUID: String,
		s: Boolean,
	},
	data() {
		return {
			siteId: this.$route.query.siteId,
			zoneId: this.$route.query.zoneId,
			carouselMeasurements: [],
			styles: "",
			componentMeasurements: [],
			timestamp:'',
			kioskId: this.$route.params.id || this.kioskUUID,
			zone: null,

			bgcolor: this.site?.project.branding?.bgcolor || '#FFFFFF',
			donutcolor: this.site?.project.branding?.donutcolor || '#02C39A',
			textcolor: this.site?.project.branding?.textcolor || '#1D213C',
			logo1center: this.site?.project.branding?.logo1center,
			logo2center: this.site?.project.branding?.logo2center,
			iconcolor: this.site?.project.branding?.iconcolor,
			brandingLogo1: this.site?.brandingLogo1 || this.site?.project?.brandingLogo1,
			brandingLogo2: this.site?.brandingLogo2 || this.site?.project?.brandingLogo2,
		}
	},

	methods: {
		getCurrentTime() {
			const today = new Date();
			this.timestamp = today.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
		},
		getSlideAnimationDelay(index) {
			let delay = ['0s','6s','12s','18s']
			return 'animation-delay:' + delay[index].toString() + ';';
		},
		getKioskKind(kind) {
			let k = kind.toLowerCase();
			if(k.includes('airquality') || k.includes('aq') || k.includes('scoreow'))
				return 'airquality';
			if(['co2', 'carbonmonoxide', 'ozone'].includes(k))
				return 'co2';
			if(['pollutant', 'pm2.5', 'pm10'].includes(k))
				return 'pollutant'
			return k.toLowerCase();			
		},
		getAlertCssClass(alert){
			if(alert === 'warning')
				return 'msmt-alarm msmt-warning';
			else if (alert === 'critical')
				return 'msmt-alarm msmt-critical';
			else
				return '';
		},
		getDisplayVal(m){
			if(m.type.includes("score")){
				return m.curScore;
			}
			if(!isNaN(Number(m.curVal))){
				return Math.round(m.curVal)
			} else {
				return m.curVal
			}
		},
		resizeToScreen(id){
			try{
				let that = this;
				let el = $('#'+id)[0];
				let iw = window.innerWidth;
				let ih = window.innerHeight;
				if(iw > window.outerWidth || ih > window.outerHeight){
					iw = window.outerWidth;
					ih = window.outerHeight;
				}
				let sr = iw / ih;
				let er = el.clientWidth / el.clientHeight; 
				// use the relative aspect ratio to determine which dimension to resize by
				if(sr < er){
					// use width
					let b = iw / el.clientWidth;
					let c = Math.floor(b*100)/100;
					let d = Math.round(el.clientWidth*(1-c)*50)/100;
					let dd = Math.round(el.clientHeight*(1-c)*50)/100;
					if(c < 1){
						$(el).css('transform', 'translate(-'+d+'px, -'+dd+'px) scale('+c+')');
					}else if(c > 1){
						dd = Math.round(el.clientHeight*(c-1)*50)/100;
						$(el).css('transform', 'translate(0px, '+dd+'px) scale('+c+')');
					}else{
						$(el).css('transform', 'scale('+c+')');
					}
				}else{
					// use height
					let b = ih / el.clientHeight;
					let c = Math.floor(b*100)/100;
					// let dd = Math.round(el.clientHeight*(c-1)*50)/100;
					// $(el).css('transform', 'translate(0px, '+dd+'px) scale('+c+')');
					let d = Math.round(el.clientWidth*(1-c)*50)/100;
					let dd = Math.round(el.clientHeight*(1-c)*50)/100;
					let ddd = Math.round(Math.abs(iw-el.clientWidth*c)*50)/100;
					if(c < 1){
						$(el).css('transform', 'translate(-'+(d-ddd)+'px, -'+dd+'px) scale('+c+')');
					}else if(c > 1){
						dd = Math.round(el.clientHeight*(c-1)*50)/100;
						$(el).css('transform', 'translate(0px, '+dd+'px) scale('+c+')');
					}else{
						$(el).css('transform', 'scale('+c+')');
					}
				}
				if(!window['resizelistener'+id]){
					window['resizelistener'+id] = true;
					// window.addEventListener('resize', () => {
					// 	// detect window rotation and refresh
					// 	// console.log('resize');
					// 	that.resizeToScreen(id);
					// });
					let lastOrient = null;
					window.addEventListener('orientationchange', function(e){
						that.resizeToScreen(id);
						if(e.target.screen.orientation.angle == 0)
							window.location.reload();
						if(Math.abs(e.target.screen.orientation.angle)==90)
							window.location.reload();
						if(lastOrient != null){
							// console.log('lastOrient', lastOrient);
							// console.log('orientation', e.target.screen.orientation.angle);
							if(Math.abs(lastOrient) == 90 && Math.abs(e.target.screen.orientation.angle) != 90)
								window.location.reload();
							if(Math.abs(lastOrient) != 90 && Math.abs(e.target.screen.orientation.angle) == 90)
								window.location.reload();
						}
						lastOrient = e.target.screen.orientation.angle;
					});
				}
			}catch(e){
				console.error(e);
			}
		},
		applyStyling(){
			if(this.$store.themes){
				this.styles=`background-color:var(--app-primary-bg);color:var(--text-color-1)`
			} else {
				this.styles=`background-color:${this.site?.project?.branding?.bgcolor};color:${this.site?.project?.branding?.textcolor};`
			}
		},
		getTheme(theme,customTheme){
			if(customTheme)
				this.$store.dispatch('setCustomTheme', [theme, customTheme]);
			else
				this.$store.dispatch('setDefaultTheme', theme);
		},
		async getMeasurements(){
			try{
				let measurementRes;
				if(this.zoneId!= null && this.zoneId!= ""){
					let zoneRes = await client.robustQuery('/api/zone/public/' + this.zoneId);
					this.zone = zoneRes;
				}
				if(this.site && this.kioskId)
					measurementRes = await client.robustQuery(`/api/kiosk/${this.kioskId}/measurements`);
				else
					measurementRes  = await client.robustQuery('/api/measurements', {siteId: this.siteId, zoneId: this.zoneId});
				return measurementRes;
			} catch (error) {
				console.error(error);
				setTimeout(function() {
					window.location.href = '/login'
				}, 250);
			}
		},
		async loadSite() {
			try {
				let scores = ['scoreaq', 'scoreow'];
				let components = ['spacetemperature','relativehumidity', 'co2', 'pm2.5', 'infectiontransmissibility', 'timeincomfortzone',
				'ionlevel', 'ionizerstatus', 'ionizationtime', 'pm10', 'carbonmonoxide', 'ozone', 'scoreaf', 'scoreaq', 'scoreow','equipment'];
				let measurementsRes = await this.getMeasurements();
				
				if(!this.site){
					let siteRes = await client.robustQuery('/api/site', {siteId: this.siteId});
					this.site = siteRes.site;
				}
				this.carouselMeasurements = [];
				this.componentMeasurements = [];
				
				measurementsRes.results.forEach(m => {
					if(scores.includes(m.type.toLowerCase()) && m.curScore > 0 && this.carouselMeasurements.length < 4)
						this.carouselMeasurements.push(m);
					// if(components.includes(m.type.toLowerCase()) && m.curVal && this.componentMeasurements.length < 3)
					// 	this.componentMeasurements.push(m);
				});
				for(let c of components){
					for(let m of measurementsRes.results){
						if(this.componentMeasurements.length >= 3)
							break;
						if(m.type.toLowerCase() == c)
							this.componentMeasurements.push(m);
					}
				}
			} catch (error) {
				console.error(error);
				window.M.toast({html: 'Cannot Load Site'})
			} finally {
				//setTimeout(() => {window.M.AutoInit();}, 100);
			}
		}
	},
	mounted() {
		let theme = this.$store.state.theme;
		let customTheme = this.$store.state.project?.themes;
        this.getTheme(theme,customTheme);

		this.loadSite();
		setInterval(this.loadSite, 1000 * 60 * 10)
	},
	created(){
		setInterval(this.getCurrentTime, 1000);
	},
	updated(){
		this.resizeToScreen('kiosk');
	},
	computed: {
		getKioskDis(){
			let scoreNames = {
				scoreAQ: "Current Air Quality",
				scoreOW: "Occupant Comfort",
				scoreOA: "Overall"
			}
			return(m) => {
				if(this.zone)
					return scoreNames[m.type];
				else
					return scoreNames[m.type];
			}
		},
		getIndicatorText(){
			return (i) => {
				if(i.curScore >= 75)
					return "Excellent"
				else if(i.curScore < 75 ** i.curScore >= 50)
					return "Good"
				else
					return "Poor"
			}
		}
	}
};
</script>
<style lang="scss">
	.embed_documentation_footer--documentationFooter--2gpG4 {
		display: none;
	}
</style>
<style lang="scss" scoped>
	$airquality: var(--airquality);
	$co2: var(--co);
	$humidity: var(--rh);
	$pollutant: var(--plt);
	$temperature: var(--spt);
	$ventilation: var(--vt);
	$infectiontransmissibility: var(--plt);
	$equipment: var(--spt);

	@keyframes kf1 {
		0%,100% { opacity: 1; }
	}
	@keyframes kf2 {
		50% { opacity: 1; }
		80% { opacity: 0; }
	}
	@keyframes kf3 {
		33% { opacity: 1; }
		53% { opacity: 0; }
	}
	@keyframes kf4 {
		25% { opacity: 1; }
		45% { opacity: 0; }
	}

	#kiosk-wrapper {
		min-height: 100vh;
		width: 100vw;
		background-color:#000;
		&.small {
			height: 265px;
			width: unset;
			background-color: unset;
		}
	}

	#kiosk,
	#k-branded,
	#k-cards {
		height: 100vh;
		max-height: 840px;
		min-height: 840px;
	}

	#k-branded,
	#k-cards {
		float: left;
	}

	#kiosk {
		background-color: var(--kiosk-bg);
		font-family: 'Roboto', sans-serif;
		font-weight: 400;
		margin: 0 auto;
		max-width: 1280px;
		min-height: 840px;
		min-width: 1280px;
		width: 100%;
		-webkit-font-smoothing: antialiased;
		overflow: hidden;

		.row {
			margin-bottom: 0;
		}
		&.small {
			position: relative;
			top: -280px;
			left: -350px;
			transform: scale(.45) !important;
			margin-top: 50px;
			border-radius: 40px;
		}
	}

	#k-branded {
		position: relative;
		width: 67.89%;
		.top {
			height: 15%;
			.logo {
				padding-top: 10px;
				text-align: left;
				img {
					max-height: 100px;
				}
				&.logo-center {
					text-align: center;
				}
			}
			.time {
				font-size: 1.6rem;
				opacity: .7;
				position: absolute;
				top: 48px;
				right: 48px;
			}
		}
		.bottom {
			height: 10%;
			.logo {
				text-align: left;
				img {
					max-height: 70px;
				}
				&.logo-center {
					text-align: center;
				}
			}
		}
		.middle {
			height: 75%;
			.carousel {
				min-height: 623px;
				min-width: 850px;
				.carousel-slide {
					left: 0;
					top: 0;
					opacity: 0;
					position: absolute;
					text-align: center;
					width: 100%;
					height: 100%;
					.highlight-name {
						font-size: 4em;
						font-weight: 300;
						line-height: 162.3px;
					}
					.highlight-subname {
						position: absolute;
						font-size: 1.65rem;
						line-height: 1em;
						top: 0;
						width: 100%;
					}
					.highlight-curScore{
						.progressIndicator {
							margin: 0 auto;
							fill: grey;
						}
					}
					.highlight-progress {
						font-size: 4em;
						line-height: 162.3px;
						opacity: .7;
					}
					// transitions
					&.slide-count-1 { animation: kf1 6s infinite; }
					&.slide-count-2 { animation: kf2 12s infinite; }
					&.slide-count-3 { animation: kf3 18s infinite; }
					&.slide-count-4 { animation: kf4 24s infinite; }
				}
			}
		}
	}

	#k-cards {
		background: var(--app-primary-bg);
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		font-weight: 400;
		padding: 48px;
		width: 32.11%;

		.k-card {
			flex: 1 100%;
			max-height: 258px;
			max-width: 315px;

			&:last-child {
				max-height: 226px;
				margin-bottom: 0;
			}
			.k-card-measurement {
				background: var(--card-tertiary-bg);
				// background: #25294A;
				border-radius: 20px;
				min-width: 315px;
				min-height: 226px;
				padding: 16px 24px 0 32px;
				box-shadow: var(--box-shadow);

				&.spacetemperature { background: var(--spt2); }
				&.relativehumidity { background: var(--rh2) }
				&.airquality { background: var(--airquality2) }
				&.ventilation { background: var(--vt2); }
				&.co2 { background: var(--co2); }
				&.pollutant { background: var(--plt2); }
				&.pm10 { background: var(--plt2); }
				&.timeincomfortzone {background: var(--rh2)}
				&.infectiontransmissibility {background: var(--spt2)}
				&.infectiontransmissibility { background: var(--spt2)}
				&.equipment { background: var(--spt2)}

				.k-card-measurement-progress {
					color: #E8912B;
					font-size: 1.2rem;
					line-height: 2rem;
					text-align: right;

					&.progress-excellent { color: #02C39A; }
				}
				.k-card-measurement-progress {
					color: #E8912B;
					font-size: 1.2rem;
					line-height: 2rem;
					text-align: right;

					.progress-excellent { color: #02C39A; }
				}
				.k-card-measurement-icon {
					height: 40px;
					width: 40px;
					margin-bottom: 18px;
				}
				.k-card-measurement-curVal {
					font-size: 3.2rem;
					font-weight: 300;
					line-height: 3.3rem;

					span {
						color: rgba(#fff,.7);
						font-size: 1.2rem;
						margin-left: 5px;
					}
				}
				.k-card-measurement-name {
					font-size: 1.65rem;
					line-height: 2rem;
				}

				&.msmt-alarm {
					color: #fff;
					.k-card-measurement-progress {
						color: #fff;
						.progress-excellent { color: #fff; }
					}
				}
				&.msmt-warning {background: var(--score-yellow-color);}
				&.msmt-critical {background: var(--score-red-color);}
			}
		}
	}
</style>