<template lang="pug">
    .tree(v-show="nodeData.visible" :id="nodeData.id" :data-depth="nodeData.depth" :data-parent="nodeData.parent")
        .row
            .col.s12.treeNode
                span(:style="`margin-left:${25 * nodeData.depth}px`")
                font-awesome-icon(:icon="icon.name" :style="icon.style")
                span(v-if="nodeData.children")
                    span(v-on:click="expandCollapseChildren()").expansionControl {{nodeData.expanded ? '-': '+'}}
                span(style="margin-left:20px")
                    span {{nodeData.name}}
                    span(v-if="nodeData.platformType && step!= 'measurementSelection'").platformType {{nodeData.platformType}}
                    span(v-if="nodeData.curVal" style="margin-left:10px") {{nodeData.curVal}} {{nodeData.unit}}
                    span(v-if="nodeData.pointType").deviceChip
                        font-awesome-icon(icon="circle" :style="{color: '#3498db'}")
                        span  {{nodeData.pointType}}
                span(v-if="nodeData.type=='point'" v-on:click="addSelectee()").selecteeAdd +
    </template>

<style lang="scss" scoped>
    .treeNode {
        padding: 3px !important;
        line-height: 2.5rem;
        margin-bottom: 10px;
        color: #999;
        &:hover {
			background-color: #085691;
            color: #ffffff;
            .selecteeAdd {
                display: inline-block;
                cursor: pointer;
            }
		}
    }
    .selecteeAdd {
        padding: 4px;
        background-color: #2ecc71;
        color: white;
        float: right;
        display: none;
        size: 20px
    }
    .expansionControl {
        margin-left: 10px;
        cursor: pointer;
        color: white;
    }
    .platformType {
        background-color: #3498db;
        color: #ffffff;
        padding: 0px 8px;
        display: inline-block;
        border-radius: 50px;
        margin-left: 10px;
    }
    .deviceChip {
        background-color:#95a5a6;
        color: #ffffff;
        padding: 0px 8px;
        display: inline-block;
        border-radius: 50px;
        margin-left: 10px;
    }
</style>
<script>
export default {
    name: 'TreeNode',
    props: {
        nodeData: Object,
        searchQuery: String,
        step: String
    },
    methods: {
        addSelectee(){
            this.$emit('selecteeAdded', this.nodeData)
        },
        expandCollapseChildren(){
            console.log(`Expand/Collapse tree node ${this.nodeData.id}`)
            this.$emit('expandCollapseTreeNode', this.nodeData);
        }
    },
    data() {
        let icon = {
            style: null,
            name: null
        }
        let name = null;
        if(this.nodeData.type === 'zone'){
            icon.style = {color: '#2ecc71'}
            icon.name = "square"
        }
        if(this.nodeData.type === "site"){
            icon.style = {color: '#74b9ff'}
            icon.name = "globe"
        }
        if(this.nodeData.type === 'point'){
            icon.style = {color: '#3498db'}
            icon.name = "certificate"
        }
        if(this.nodeData.type === "pointType"){
            icon.style = {color: '#3498db'}
            icon.name = "circle"
        }
         if(this.nodeData.type === "measurement"){
            icon.style = {color: '#27ae60'}
            icon.name = "ruler"
        }
        if(this.nodeData.type === "device"){
            icon.style = {color: '#ecf0f1'}
            icon.name = "tablet"
        }
        return {
            icon,
            name
        }
    },
    mounted(){
    }
}
</script>