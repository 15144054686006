/*eslint no-undef: "warn"*/

<template lang="pug">
	#onboarding
		.testimg
			img#aq1(src="/images/aq1.png")

		//- .col.previewimg
		//- 	img#aq1(src="/images/aq1.png")
		//- 	img#aq2(src="/images/aq2.png")
		//- 	img#aq3(src="/images/aq3.png")
		//- 	img#aq4(src="/images/aq4.png")
		//- 	img#aq5(src="/images/aq5.png")
		//- 	img#aq6(src="/images/aq6.png")
</template>

<style lang="scss">
	.embed_documentation_footer--documentationFooter--2gpG4 {
		display: none;
	}
</style>
<style lang="scss" scoped>
	.testimg {
		text-align: center;
	}

	.previewimg,
	.previewimg img {
		max-width: 1280px;
		width: 100%;
	}

	.pointCollection {
		line-height: 2.5rem;
		border-collapse: collapse;
		border-top: 1px solid #2C2C36;
		border-bottom: 1px solid #2C2C36;
		.pointCollectionItem {
			border-top: 1px solid #2C2C36;
			border-bottom: 1px solid #2C2C36;
			padding-left: 10px;
			&:hover {
				background-color: #222236;
				cursor: pointer;
			}
		}
	}
	.treeNode {
		&:hover {
			background-color: #222236;
		}
		padding-top: 3px;
		padding-bottom: 3px;
	}
	.pointSelectionMode {
		background-color: #085691;
		padding-top: 3px;
		padding-bottom: 3px;
	}
	.notAPointOfInterest {
		color: #999;
	}
	.previewimg {
		margin: 0 auto;
		position: relative;
		height: 100vh;
	}
	.previewimg img {
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		animation-name: fade;
		animation-iteration-count: infinite;
		animation-duration: 18s;
	}
	@keyframes fade {
		0% {opacity: 0;}
		5% {opacity: 1;}
		21% {opacity: 1;}
		26% {opacity: 0;}
		37% {opacity: 0;}
		100% {opacity: 0;}
	}

	#aq1 { animation-delay: 0s; }
	#aq2 { animation-delay: 3s; }
	#aq3 { animation-delay: 6s; }
	#aq4 { animation-delay: 9s; }
	#aq5 { animation-delay: 12s; }
	#aq6 { animation-delay: 15s; }

</style>
<script>
// import Navbar from '@/components/Navbar.vue';
// import SiteSelector from '../components/SiteSeletctor.vue';
// import { HTTPClient } from "../assets/js/http";
// import {pointTypes} from '../assets/js/constants';
// import { v4 as uuidv4 } from 'uuid';
// let client = new HTTPClient();

export default {
	name: "Kiosk Demo",
	components: {
		// Navbar
	},
	data() {		
		return null;
	},
	methods: {
		
	},
	mounted() {
		console.log('asdfe');
		console.log(window.$());
		// eslint-disable-next-line no-undef
		console.log('asdf', $('#myframe'));
		// eslint-disable-next-line no-undef
		$('#myframe').on('load', function(){
			console.log('!!!!!');
			// let head = $('#myframe').contents().find('head');
			// let css = '<style>.embed_documentation_footer--documentationFooter--2gpG4 {display:none;}</style>';
			// $(head).append(css);
			// let myiframe = document.getElementById('myframe');
		});
	},
	created(){
		// console.log('asdf', window.$());
		// console.log('asdf', this.$refs.myframe);
		
	}
};
</script>
