<template lang="pug">
    #step4
        p Angle the antenna straight up as shown below. For best results, place the HawkenAQ Hub on top of a cabinet or shelf so it has good connectivity with all areas of your building.
        .stepImage(style="background-image: url(/images/kitSetup3.png); margin-bottom:1.5em; max-height:260px;")
        button.btn.waves-effect.white.haq-btn.blue-text(@click="previousStep") Back
        button.btn.waves-effect.haq-btn.haq-color.right(@click="nextStep") Next
</template>
<script>
export default {
    name: "Step4",
    data(){
        return {}
    },
    methods: {
        nextStep(){
            // this.$emit('nextStep')
            this.$emit('hubOnboarded');
        },
        previousStep(){
            this.$emit('previousStep')
        }
    }
}
</script>