/* eslint-disable jsdoc/require-description */
let pointTypesObj = {
    "airFlow": {
        dis: "Space Air Flow Sensor"
    },
    "damperPositionStatus": {
        dis: "Damper Position Sensor"
    },
    "fanStatus": {
        dis: "Fan Off/On"
    },
    "returnAirFlow": {
        dis: "Return Air Flow Sensor"
    },
    "outsideAirFlow": {
        dis: "Outside Air Flow Sensor"
    },
    "outsideAirTemperature": {
        dis: 'Outside Air Temperature'
    },
    "cO2Sensor": {
        dis: "CO2 Sensor"
    },
    "humiditySensor": {
        dis: "Humidity Sensor"
    },
    "spaceTemperature": {
        dis: "Space Temperature"
    },
    "occupiedMode": {
        dis: "Occupied Mode"
    },
    "motionSensor": {
        dis: "Motion Sensor"
    },
    "activePure": {
        dis: "Active Pure"
    },
    "staticPressure": {
        dis: "Static Pressure"
    },
    "vocSensor": {
        dis: "VOC Sensor"
    },
    "boilerStatus": {
        dis: "Boiler Status"
    },
    "boilerPressure": {
        dis: "Boiler Pressure"
    },
    "supplyFanStatus": {
        dis: "Supply Fan Status"
    },
    "ionLevel": {
        dis: "Ion Level"
    },
    "ionizerStatus": {
        dis: "Ionizer Status"
    }
};

let listOfMeasurements = [
    {
        name: 'CO2',
        accepts: ['cO2Sensor'],
        kind: 'Number',
        unit: 'ppm',
        selected: false,
        type: "measurement",
        measurementType: 'co2'
    },
    {
        name: 'VOC',
        accepts: ['vocSensor'],
        kind: 'Number',
        unit: 'ppb',
        selected: false,
        type: "measurement",
        measurementType: 'voc'
    },
    {
        name: 'Active Pure',
        accepts: ['activePure'],
        kind: 'Bool',
        selected: false,
        type: "measurement",
        measurementType: 'activePure'
    },
    {
        name: 'Time In Active Pure',
        accepts: ['activePure'],
        kind: 'Bool',
        selected: false,
        type: "measurement",
        measurementType: 'timeInActivePure'
    },
    {
        name: "Space Temperature",
        accepts: ["spaceTemperature"],
        kind: 'Number',
        selected: false,
        type: "measurement",
        measurementType: "spaceTemperature"
    },
    {
        name: "Relative Humidity",
        accepts: ["humiditySensor"],
        kind: 'Number',
        selected: false,
        type: "measurement",
        measurementType: "relativeHumidity"
    },
    // {
    //     name: "Equipment",
    //     accepts: ["equipmentValue"],
    //     kind: "Inherit",
    //     selected: false,
    //     type: "measurement",
    //     measurementType: "equipment"
    // },
    {
        name: "Time In Comfort Zone",
        accepts: ["spaceTemperature", "humiditySensor"],
        kind: "Number",
        selected: false,
        type: 'measurement',
        measurementType: 'timeInComfortZone'
    },
    {
        name: "Infection Transmissibility",
        accepts: ["spaceTemperature", "humiditySensor"],
        kind: "String",
        selected: false,
        type: 'measurement',
        measurementType: 'infectionTransmissibility'
    },
    {
        name: "Fresh Air Cycling",
        accepts: ["airFlow"],
        kind: "Number",
        selected: false,
        type: 'measurement',
        measurementType: 'freshAirCycling',
        unit: "AC/h", // Air Changes per hour,
        config: [
            {
                name: "Volume",
                kind: "Number",
                value: 0
            }
        ]
    },
    {
        name: "Ventilation",
        accepts: ["airFlow"],
        kind: "Number",
        selected: false,
        type: 'measurement',
        measurementType: 'ventilation',
        unit: "AC/h", // Air Changes per hour,
        config: [
            {
                name: "Area",
                kind: "Number",
                value: 0
            }
        ]
    },
    {
        name: "Ion Level",
        accepts: ["ionLevel"],
        kind: "Number",
        selected: false,
        type: "measurement",
        measurementType: "ionLevel",
        unit: "ion/cc",
        config: null
    },
    {
        name: "Ionizer Status",
        accepts: ['ionizerStatus'],
        kind: "Bool",
        selected: false,
        type: "measurement",
        measurementType: "ionizerStatus",
        unit: null,
        config: null
    },
    {
        name: "Ionization Time",
        accepts: ['ionizerStatus'],
        kind: "Bool",
        selected: false,
        type: "measurement",
        measurementType: "ionizationTime",
        unit: null,
        config: null
    },
    {
        name: "Differential Pressure",
        accepts: ["differentialPressure"],
        kind: "Number",
        selected: false,
        type: "measurement",
        measurementType: "differentialPressure",
        unit: "inH20",
        config: null
    },
    {
        name: "Ozone",
        accepts: ["ozoneSensor"],
        kind: "Number",
        selected: false,
        type: "measurement",
        measurementType: "ozone"
    },
    {
        name: "Carbon Monoxide",
        accepts: ["coSensor"],
        kind: "Number",
        selected: false,
        type: "measurement",
        measurementType: "carbonMonoxide"
    },
    {
        name: "PM 2.5",
        accepts: ["pm2.5Sensor"],
        kind: "Number",
        selected: false,
        type: "measurement",
        measurementType: "pm2.5"
    },
    {
        name: "PM 10",
        accepts: ["pm10Sensor"],
        kind: "Number",
        selected: false,
        type: "measurement",
        measurementType: "pm10"
    }
]
let wlData = {
    'CommanderAQ': {
        'name': 'CommanderAQ',
        'brandImageTheme': 'brandImageC',
        'loginLogo': '/images/commanderAQBlack.png',
        'emailLogo': 'https://app.commanderaq.com/images/commanderAQBlack.png', // full url
        'inviteFromEmail': 'invite@commanderaq.com',
        'darkCornerLogo': '/images/logo/dark/commander.png',
        'lightCornerLogo': '/images/logo/light/commander.png',
        'contactUrl': 'https://gethawken.com/contact-hawkenaq',
        'baseDomain': 'commanderaq.com',
        'poweredByFooter': 'Powered by HawkenIoT'
    },
    'HawkenAQ': {
        'name': 'HawkenAQ',
        'brandImageTheme': 'brandImage',
        'loginLogo': '/images/hawkenAqBlue.png',
        'emailLogo': 'https://app.hawkenaq.com/images/hawkenAqBlue.png', // full url
        'inviteFromEmail': 'alerts@mg.hawkenaq.com',
        'darkCornerLogo': '/images/logo/dark/hawken.png',
        'lightCornerLogo': '/images/logo/light/hawken.png',
        'contactUrl': 'https://gethawken.com/contact-hawkenaq',
        'baseDomain': 'hawkenaq.com',
        'pageTitle': 'HawkenIoT - Every building. One platform.',
        'poweredByFooter': 'Powered by HawkenIoT'
    },
    'Onformant': {
        'name': 'Onformant',
        'brandImageTheme': 'brandImage',
        'loginLogo': '/images/onformantFull.png',
        'emailLogo': 'https://app.onformant.com/images/onformantFull.png', // full url
        'inviteFromEmail': 'invite@onformant.com',
        'darkCornerLogo': '/images/onformantFull.png',
        'lightCornerLogo': '/images/onformantFull.png',
        'contactUrl': 'https://www.onformant.com/support',
        'baseDomain': 'onformant.com',
        'faviconPath': '/favicon-onformant.png',
        'poweredByFooter': 'Powered by Onformant'
    }
}

let measurements = {
    listOfMeasurements,
    /**
     * @description Takes input of measurement and point array and returns Array of Measurements Available
     * @param {Object} m 
     * @param {Array} pointsArray 
     */
    measurementAvailable(m, pointsArray){
        let matches =  pointsArray.filter(function(el){
            return el.pointType!== "equipmentValue" && m.accepts.indexOf(el.pointType) > -1 
        });
        return matches.length == m.accepts.length ? matches : null
    },
    /**
     * 
     * @param {Array<Object>} pointsArray 
     */
    equipmentMeasurementsAvailable(pointsArray){
        let matches = pointsArray.filter((el) => el.pointType === "equipmentValue");
        if(matches.length < 1){
            return null;
        }
       
        let eMeasurements = [];
        for(let p of matches){
            let m = {
                accepts: ["equipmentValue"],
                selected: false,
                type: "measurement",
                measurementType: "equipment"
            }
            m.name = p.name.replace("- equipmentValue", "").replace(`${p.curVal}`, "").replace("undefined",  "");
            m.unit = p.unit;
            eMeasurements.push({
                m,
                points: [p]
            });
        }
        return eMeasurements;
    },
    /**
     * 
     * @param {Array} pointsArray 
     * @returns {Array} List of Matched Measurements.
     */
    matchMeasurements(pointsArray){
        let matches = [];
        for(let m of listOfMeasurements){
            let measurementsAvailable = this.measurementAvailable(m , pointsArray);
            if(measurementsAvailable){
                matches.push({
                    m,
                    points: measurementsAvailable
                })
            }
        }

        let equipmentMeasurementsAvailable = this.equipmentMeasurementsAvailable(pointsArray);
        if(equipmentMeasurementsAvailable){
            matches = matches.concat(equipmentMeasurementsAvailable);
        }

        return matches;
    }
}
let wld = null;
if(typeof(window) != 'undefined'){
    if(window.location.host.match(/commanderaq.com/)){
        wld = wlData.CommanderAQ;
    }else if(window.location.host.match(/onformant.com/)){
        wld = wlData.Onformant;
    }else{
        wld = wlData.HawkenAQ;
    }
}else{
    wld = wlData.HawkenAQ;
}

module.exports = {pointTypes: pointTypesObj, wlData:wld, measurements, fullWLData: wlData};
