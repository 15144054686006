<template lang="pug">
    .tree(v-show="nodeData.visible!== false" :data-parent="nodeData.parents.join('|')" :data-original-deviceRef="nodeData.originalDeviceRef")
        .row
            .col.s12.treeNodeSelector(v-bind:class="{selectorSelected : nodeData.selected}")
                span(:style="`margin-left:${this.nodeData.depth*25}px`")
                font-awesome-icon(:icon="icon.name" :style="icon.style")
                span(style="margin-left:20px" v-show="!controls.editable" v-bind:class="{editableName: nodeData.type === 'zone'}" @click="selectorSelected")
                    span {{nodeData.name}}
                    span(v-if="nodeData.platformType")
                    span(v-if="nodeData.platformType").platformType {{nodeData.platformType}}
                    .deviceChip(v-if="nodeData.deviceName")
                        font-awesome-icon(icon="tablet" :style="{color: '#ecf0f1'}")
                        span  {{nodeData.deviceName}}
                    span(v-if="nodeData.pointType").deviceChip
                        font-awesome-icon(icon="circle" :style="{color: '#3498db'}")
                        span  {{nodeData.pointType}}
                div(v-if="controls.editable" style="position:relative")
                    input(type="text" v-model="nodeData.name" :id="nodeData.id" v-on:keyup.enter="editZone").browser-default.editName
                span(v-if="controls.removeChildren").removeChildren
                    font-awesome-icon(:icon="controls.removeChildren.icon" :style="controls.removeChildren.style" @click="removeChildren")
                span(v-if="controls.delete").deleteButton
                    font-awesome-icon(:icon="controls.delete.icon" :style="controls.delete.style" @click="removePoint")
                span(v-if="controls.relegate").relegateButton
                    font-awesome-icon(:icon="controls.relegate.icon" :style="controls.relegate.style" @click="zoneRelegate")
                span(v-if="controls.edit").editButton
                    font-awesome-icon(:icon="controls.edit.icon" :style="controls.edit.style" @click="editZone")
</template>
<style lang="scss" scoped>
    .treeNodeSelector {
        padding-top: 3px;
        line-height: 2.5rem;
        margin-bottom: 15px;
        color: #999;
        &:hover {
			background-color: #085691;
            color: #ffffff;
            cursor: pointer;
            .editButton, .relegateButton, .deleteButton, .removeChildren {
                display: inline;
            }
            .editName {
                bottom: -15px;
            }
        }
    }
    .editButton, .relegateButton, .deleteButton, .removeChildren {
        display: none;
        float: right;
        margin-left: 20px;
    }
    .editableName {
        cursor: text;
    }
    .editName {
        padding:5px;
        size: 10px;
        border-radius: 50px;
        position: absolute;
        bottom: -5px;
        left: 25px
        
    }
    .selectorSelected {
            background-color: #085691;
            color: #ffffff;
    }
     .expansionControl {
        margin-left: 10px;
        cursor: pointer;
        color: white;
    }
    .deviceChip {
        background-color:#95a5a6;
        color: #ffffff;
        padding: 0px 8px;
        display: inline-block;
        border-radius: 50px;
        margin-left: 10px;
    }
    .platformType {
        background-color: #3498db;
        color: #ffffff;
        padding: 0px 8px;
        display: inline-block;
        border-radius: 50px;
        margin-left: 10px;
    }
</style>
<script>
export default {
    name: 'TreeNodeSelector',
    props: {
        nodeData: Object,
        depth: Number,
        step: String
    },
   data() {
        let icon = {
            style: null,
            name: null
        }
        let controls = {editable: false}
        if(this.nodeData.type === 'zone'){
            icon.style = {color: '#2ecc71'}
            icon.name = "square"
            controls.edit = {
                style: {color: '#f1c40f', size: "32px"},
                icon: 'edit'
            };
            controls.removeChildren = {
                style: {color: '#ff7675'},
                icon: 'trash'
            }
            if(this.nodeData.depth > 0){
                controls.relegate = {
                    icon: 'arrow-circle-left',
                    style: {color: '#e74c3c'}
                }
            }
        }
        if(this.nodeData.type === 'point'){
            icon.style = {color: '#3498db'}
            icon.name = "certificate"
            controls.edit = {
                style: {color: '#f1c40f', size: "32px"},
                icon: 'edit'
            };
            controls.delete = {
                style: {color: '#e74c3c'},
                icon: 'trash'
            }
        }
        if(this.nodeData.type === "site"){
            icon.style = {color: '#74b9ff'}
            icon.name = "globe"
        }
        if(this.nodeData.type === "measurement"){
            icon.style = {color: '#9b59b6'}
            icon.name = "ruler"
        }
        return {
            icon,
            controls
        }
    },
    methods: {
        selectorSelected(){
            console.log(this.nodeData);
            if(this.nodeData.type === 'zone' && this.step!== "pointTypeSelection" && this.step!== "measurementSelectionStep"){
                this.$emit('selectorAdded', this.nodeData);
            }
            if(this.nodeData.type === 'site' && this.step!== "pointTypeSelection" && this.step!== "measurementSelectionStep"){
                this.$emit('selectorAdded', this.nodeData);
            }
            if(this.nodeData.type == 'point' && this.step== "pointTypeSelection"){
                this.$emit('selectorAdded', this.nodeData);
            }
            if(this.nodeData.type == "measurement" && this.step === "measurementSelectionStep"){
                 this.$emit('selectorAdded', this.nodeData);
            }

        },
        editZone(){
        this.controls.editable = !this.controls.editable
            if(this.controls.editable){
                this.controls.edit = {
                    icon: 'save',
                    style: {color: '#3498db'}
                }
            } else {
                this.controls.edit = {
                    style: {color: '#f1c40f', size: "32px"},
                    icon: 'edit'
                }
            }
        },
        zoneRelegate(){
            console.log('Relegater')
            if(this.nodeData.type === "zone"){
                this.$emit('relegateZone', this.nodeData);
            }
        },
        removePoint(){
            console.log('Removing, ' + this.nodeData.name);
            if(this.nodeData.type === "point"){
                this.$emit('removePointFromZone', this.nodeData);
            }
        },
        removeChildren(){
            this.$emit("removeChildren", this.nodeData.id);
        }
    }
}
</script>