<template lang="pug">
    #passwordSettingsPage
        .new-pw-cards
            .new-pw-card.step-login
                .content
                    .subtitle Change your password
                    br
                    .input-field
                        label(for='password') Current Password
                        input#password.validate(name='password' :type="togglePassword ? 'text' : 'password'" v-model="password" placeholder="Enter password")
                        span.field-icon(@click="togglePasswordInput('password')")
                            span.material-icons(v-if="togglePassword") visibility
                            span.material-icons(v-else="togglePassword") visibility_off
                        .error(v-if="passwordErrorText") {{passwordErrorText}}

                    .input-field
                        label(for='new-password') New Password
                        input#new-password.validate(name='new-password' :type="toggleNewPassword ? 'text' : 'password'" v-model="newPassword" placeholder="Enter password")
                        span.field-icon(@click="togglePasswordInput('newPassword')")
                            span.material-icons(v-if="toggleNewPassword") visibility
                            span.material-icons(v-else="toggleNewPassword") visibility_off
                        //- span.helper-text Password must be a minimum of 8 characters long
                    .input-field.if-pw-confirm
                        label(for='confirm-new-password') Confirm New Password
                        input#confirm-new-password(name='confirm-new-password' :type="toggleConfirmNewPassword ? 'text' : 'password'" v-model="confirmNewPassword" placeholder="Re-enter password")
                        span.field-icon(@click="togglePasswordInput('confirmNewPassword')")
                            span.material-icons(v-if="toggleConfirmNewPassword") visibility
                            span.material-icons(v-else="toggleConfirmNewPassword") visibility_off
                        .requirements Use 8 or more characters.
                        .error(v-if="newPasswordErrorText") {{newPasswordErrorText}}
                    
                    button.haq-btn-primary.waves-effect.waves-light(v-on:click="validateCurrentPassword") Update Password
            .new-pw-card.step-success(v-show="passwordUpdateSuccess")
                .content
                    v-icon mdi-check-circle
                    | Password successfully updated. Confirmation email sent.
</template>
<script>
import {HTTPClient} from '../../assets/js/http';
let client = new HTTPClient();

export default {
    name: 'Password',
    data() {
        return {
            user: this.$store.state.user,
            email: this.$store.state.user?.username,
            project: this.$store.state.project,
            password: null,
            newPassword: null,
            confirmNewPassword: null,
            togglePassword: 0,
            toggleNewPassword: 0,
            toggleConfirmNewPassword: 0,
            passwordUpdateSuccess: false,
            passwordErrorText: null,
            newPasswordErrorText: null,
        };
    },
    methods: {
        togglePasswordInput(toggle) {
            if(toggle == 'password')
                this.togglePassword == 0 ? this.togglePassword = 1 : this.togglePassword = 0;
            else if(toggle == 'newPassword')
                this.toggleNewPassword == 0 ? this.toggleNewPassword = 1 : this.toggleNewPassword = 0;
            else if(toggle == 'confirmNewPassword')
                this.toggleConfirmNewPassword == 0 ? this.toggleConfirmNewPassword = 1 : this.toggleConfirmNewPassword = 0;
        },
        validatePassword(newPassword){
            if(newPassword.length >= 8)
                return true;
            return false;
        },
        async validateCurrentPassword() {
            try {
                let result = await client.robustPost('/users/verifyPassword',{email: this.email, password: this.password});
                if(result.successMessage) {
                    this.passwordErrorText = null;
                    this.updatePassword();
                }
                    
			} catch (error) {
				// console.error(error);
				this.passwordErrorText = "Incorrect password. Please try again.";
			}
        },
        async updatePassword() {
            try {
                let newPasswordSame = this.password === this.newPassword;
                let passwordsMatch = this.newPassword === this.confirmNewPassword;
                let isValid = this.validatePassword(this.newPassword);

                if(newPasswordSame)
                    this.newPasswordErrorText = 'Password must be different from old password.';
                if(!passwordsMatch)
                    this.newPasswordErrorText = 'Passwords do not match.';
                if(!isValid)
                    this.newPasswordErrorText = 'Password is too short.';
                if(!isValid || !passwordsMatch || newPasswordSame)
                    return;

                if(isValid && passwordsMatch && !newPasswordSame) {
                    this.newPasswordErrorText = null;
                    let result = await client.robustPost('/users/changePassword',{email: this.email, password: this.newPassword});
                    if(result.successMessage) {
                        this.notifyUser();
                        this.passwordUpdateSuccess = true;
                        this.password = this.newPassword = this.confirmNewPassword = null;                        
                        setTimeout(() => this.passwordUpdateSuccess = false, 3000);                        
                    }
                }
            }
            catch(error) {
                console.log(error)
            }
        },
        async notifyUser() {
            console.log("Notifying",this.project)
            await client.robustPost('/users/changedPassword/notify',{user: this.user, project: this.project}).then(function(result){
                console.log('Email delivered',result);
            }).catch(function(error){
                console.log("Email not delivered", error);
            })
        },
    },
};
</script>
<style lang="sass" scoped>
    .new-pw-cards
        .new-pw-card
            color: var(--app-primary-txt)
            .content
                max-width: 360px
                width: 100%

                .title
                    font: normal 400 32px/46px Roboto
                    margin-bottom: 32px
                    @media (max-width: 600px)
                        font-size: 24px
                .subtitle
                    font: normal 400 16px/24px Roboto
                .input-field
                    margin: 0 0 16px
                    text-align: left
                    label
                        font: normal 400 14px/20px Roboto
                        color: var(--app-primary-txt)
                        position: static
                        margin-bottom: 8px
                    input
                        border: 1px solid var(--app-primary-txt)
                        box-sizing: border-box
                        box-shadow: none
                        border-radius: 12px
                        color: var(--app-primary-txt)
                        font: normal 400 16px/24px Roboto
                        height: 40px
                        margin: 0 
                        padding: 8px 20px
                        // &:disabled
                        //     color: #acacac
                        //     border-color: #ccc
                    span.field-icon
                        position: absolute
                        right: 12px
                        top: 33px
                        cursor: pointer
                        z-index: 2
                        .material-icons
                            font-size: 20px
                    span.helper-text
                        color: var(--app-primary-txt)
                        font: normal 400 12px/20px Roboto
                    ::placeholder
                        color: var(--app-primary-txt)
                        font: normal 400 14px/20px Roboto
                        opacity: 0.4
                .if-pw-confirm
                    margin-bottom: 0
                .requirements
                    text-align: left
                    font: normal 400 12px/20px Roboto
                .error
                    background-color: transparent !important
                    color: #FF5050
                    font: normal 400 14px/20px Roboto
                    text-align: left
                .strike
                    display: block
                    text-align: center
                    overflow: hidden
                    white-space: nowrap
                    > span
                        color: #005B9A
                        font: normal 400 14px/20px Roboto
                        position: relative
                        display: inline-block
                        opacity: 0.5
                        &:before,
                        &:after
                            content: ""
                            position: absolute
                            top: 55%
                            width: 9999px
                            height: 1px
                            background: #005B9A
                            opacity: 0.5
                        &:before
                            right: 100%
                            margin-right: 8px
                        &:after
                            left: 100%
                            margin-left: 8px
        .step-success
            .v-icon
                color: #A9ECDE
                font-size: 24px
                margin: 0 4px 4px 0
        .haq-btn-primary
            background: #3456F7
            border: 0
            border-radius: 12px
            color: #fff
            margin: 24px 0
            padding: 10px
            height: 40px
            max-height: 360px
            width: 100%
            .disabled
                background: #B9E2FF
                color: #52B2F3
</style>