<template lang="pug">
    div(:id="id").progressIndicator
        svg(xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 36 36" fill="none")
            path(d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" fill="none" stroke-width="3" stroke="#1D213C" style="stroke-opacity: .09;")
            path(d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" fill="none" :stroke="donutcolor" stroke-width="3" :stroke-dasharray="curVal+',100'" stroke-linecap="round")
            text(:x="textwidth" y="23.5" :fill="textcolor" font-size="14px" font-weight="300") {{curVal}}

</template>

<script>
export default {
    name: 'DonutIndicator',
    props: {
        id: String,
        donutcolor: String,
        textcolor: String,
        curVal: Number,
        units: String,
        size: {
            type: Number,
            default: 293,
        },
    },
    data() {
        let tw = this.curVal.toString().length > 2 ? '6' : '10';

        return {
            textwidth: tw,
        }

    },
}
</script>