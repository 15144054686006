<template lang='pug'>
    .haq-onboarding-tooltip-wrap
        .haq-tooltip(:id="'onboarding-tooltip-' + id" :class="[cssPosition, cssArrowPosition]")
            .message {{message}}
            .btn(@click="completeTooltip") Got it!
</template>
<script>
import {HTTPClient} from '@/assets/js/http';
let client = new HTTPClient();

export default {
    name: 'TooltipsSite',
    props: {
        id: Number,
        name: String,
        position: String,
        arrowAlignment: String,
        message: String,
    },
    data() {
        let arrowPosition = this.position.toLowerCase() === 'top' ? 'bottom' : 'top';
        return {
            cssPosition: this.position,
            cssArrowPosition: 'arrow-' + arrowPosition + '-' + this.arrowAlignment.toLowerCase(),
        }
    },
	methods: {
        next(){
            console.log("emittinggggg!!!",this.id)
            this.$emit('next')
        },
		completeTooltip(){
            let user = this.$store.state.user;

            if(!user.onboardingComplete)
                user.onboardingComplete = {"tooltips": {}};
            else if(!user.onboardingComplete?.tooltips)
                user.onboardingComplete.tooltips = {};
            
            let newOnboardingStep = {
                "step": this.id,
                "name": this.name,
                "complete": true
            }
            user.onboardingComplete.tooltips[this.id] = newOnboardingStep;

            client.robustPost(`/api/users/${user.objectId}`,user).then(function(result){
                console.log('User Updated',result);
            }).catch(function(error){
                console.log("User Not Updated", error);
            })

            this.next();
        },
	},
};
</script>
<style lang="sass" scoped>
    // temp - while I figure out a more eloquent way
    #onboarding-tooltip-0
        left: -50px
        &.arrow-top-left:after
            left: 31%
    #onboarding-tooltip-1
        top: -35px


    .haq-onboarding-tooltip-wrap
        position: relative
    .haq-tooltip
        background: #4E7CFF
        border-radius: 8px
        cursor: auto
        display: flex
        flex-direction: column
        padding: 16px
        position: absolute
        width: 250px
        z-index: 100

        .message
            font: normal normal 18px/24px 'Roboto'
            margin-bottom: 12px
        .btn
            background: #fff
            border: 1px solid #fff
            border-radius: 12px
            color: #021449
            font: normal normal 18px/45px 'Roboto'
            letter-spacing: 0.18px
            height: 45px
            width: 200px
    
    .haq-tooltip.top
        top: -75px
    
    .haq-tooltip.bottom
        top: 75px
    

    @mixin tooltipArrow
        border-left: solid 6px transparent
        border-right: solid 6px transparent
        content: ''
        margin-left: -5px
        position: absolute
        height: 0
        width: 0
    @mixin tooltipArrowTop
        @include tooltipArrow
        border-bottom: solid 5px #4E7CFF
        bottom: 100%
    @mixin tooltipArrowBottom
        @include tooltipArrow
        border-top: solid 5px #4E7CFF
        top: 100%
    
    .arrow-top-left:after
        @include tooltipArrowTop        
        left: 10%
    .arrow-top-center:after
        @include tooltipArrowTop
        left: 50%
    .arrow-top-right:after
        @include tooltipArrowTop
        left: 90%
    .arrow-bottom-left:after
        @include tooltipArrowBottom
        left: 10%
    .arrow-bottom-center:after
        @include tooltipArrowBottom
        left: 50%
    .arrow-bottom-right:after
        @include tooltipArrowBottom
        left: 90%
</style>